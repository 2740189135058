<template>
  <div v-if="order.date_delivered == null && statusShip == 3">
    <v-btn @click="HandlerMoveFinal(order)" block dark color="red"
      >Finalizado</v-btn
    >
    <move-toltip-component
      :order="order"
      v-if="
        order.user_btn_prepeared != null ||
        order.user_btn_delivered != null ||
        order.user_btn_in_transit != null
      "
    />
  </div>
</template>

<script>
import MoveToltip from "./Utils/moveToltip";
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
    statusShip: {
      type: Number,
      required: true,
      default: 3,
    },
  },
  components: {
    "move-toltip-component": MoveToltip,
  },

  methods: {
    async HandlerMoveFinal(item) {
      try {
        const request = { shipping_id: item.id, country_id: 2, warehouse_id: 13 };
        await this.$store.dispatch("orders/MOVE_TO_DELIVERED", request);
        this.$snotify.success("Se ha movido el envio", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>