var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"mb-5",attrs:{"height":"auto"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}})],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Permisos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Permisos","filled":"","rounded":"","dense":"","items":_vm.data_permision,"item-text":"name","item-value":"id","multiple":"","small-chips":"","error-messages":errors},model:{value:(_vm.data.permissions_b2b_main),callback:function ($$v) {_vm.$set(_vm.data, "permissions_b2b_main", $$v)},expression:"data.permissions_b2b_main"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Marca","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"label":"Marca","filled":"","rounded":"","dense":"","items":_vm.brands,"item-text":"name","item-value":"id","multiple":"","small-chips":"","error-messages":errors},model:{value:(_vm.data.b2b_brands),callback:function ($$v) {_vm.$set(_vm.data, "b2b_brands", $$v)},expression:"data.b2b_brands"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Grupos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Grupos","filled":"","rounded":"","dense":"","items":_vm.groups,"item-text":"name","item-value":"id","multiple":"","small-chips":"","error-messages":errors},model:{value:(_vm.data.b2b_groups),callback:function ($$v) {_vm.$set(_vm.data, "b2b_groups", $$v)},expression:"data.b2b_groups"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Empresa","color":"black","dense":"","filled":"","rounded":"","placeholder":"Nombre de la empresa","error-messages":errors},model:{value:(_vm.data.buyer_store[0].company_name),callback:function ($$v) {_vm.$set(_vm.data.buyer_store[0], "company_name", $$v)},expression:"data.buyer_store[0].company_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":_vm.docTypes[_vm.store_id].name,"rules":Object.assign({}, {required: true},
                  _vm.docTypes[_vm.store_id].validation)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":_vm.docTypes[_vm.store_id].name,"color":"black","dense":"","filled":"","rounded":"","placeholder":"Ingresa tu cuit","error-messages":errors},model:{value:(_vm.data.doc_number),callback:function ($$v) {_vm.$set(_vm.data, "doc_number", $$v)},expression:"data.doc_number"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"disabled":_vm.store_id === null,"items":_vm.dataState,"item-text":"name","item-value":"id","label":"Provincia","color":"black","dense":"","filled":"","rounded":"","placeholder":"Ingresa tu nombre","error-messages":errors},model:{value:(_vm.data.state_id),callback:function ($$v) {_vm.$set(_vm.data, "state_id", $$v)},expression:"data.state_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Provincia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Localidad","color":"black","dense":"","filled":"","rounded":"","placeholder":"Ingresa tu nombre","error-messages":errors},model:{value:(_vm.data.location),callback:function ($$v) {_vm.$set(_vm.data, "location", $$v)},expression:"data.location"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"sitio web","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Sitio web","color":"black","dense":"","filled":"","rounded":"","placeholder":"Apellido de contacto","error-messages":errors},model:{value:(_vm.data.buyer_store[0].website_url),callback:function ($$v) {_vm.$set(_vm.data.buyer_store[0], "website_url", $$v)},expression:"data.buyer_store[0].website_url"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-0 rounded-small",attrs:{"label":"Correo","autocomplete":"off","color":"black","dense":"","filled":"","rounded":"","placeholder":"Ingresa tu correo electronico","error-messages":errors},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"denominacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Nombre","color":"black","dense":"","filled":"","rounded":"","placeholder":"Nombre de contacto","error-messages":errors},model:{value:(_vm.data.first_name),callback:function ($$v) {_vm.$set(_vm.data, "first_name", $$v)},expression:"data.first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"denominacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Apellido","color":"black","dense":"","filled":"","rounded":"","placeholder":"Apellido de contacto","error-messages":errors},model:{value:(_vm.data.last_name),callback:function ($$v) {_vm.$set(_vm.data, "last_name", $$v)},expression:"data.last_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"denominacion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Teléfono","color":"black","dense":"","filled":"","rounded":"","placeholder":"Apellido de contacto","error-messages":errors},model:{value:(_vm.data.buyer_store[0].contact_phone),callback:function ($$v) {_vm.$set(_vm.data.buyer_store[0], "contact_phone", $$v)},expression:"data.buyer_store[0].contact_phone"}})]}}],null,true)})],1),(_vm.action === 0)?_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"contraseña","rules":{
                  required: _vm.action == 0 ? true : false,
                  confirmed: 'confirmation',
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-0 rounded-small",attrs:{"label":"Contraseña","autocomplete":"off","type":"password","color":"black","dense":"","filled":"","rounded":"","placeholder":"Ingresa tu contraseña","error-messages":errors},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]}}],null,true)})],1):_vm._e(),(_vm.action === 0)?_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":{ required: _vm.action == 0 ? true : false },"vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 rounded-small",attrs:{"label":"Confirmar Contraseña","autocomplete":"off","type":"password","color":"black","dense":"","filled":"","rounded":"","placeholder":"Confirma tu nombre contraseña","error-messages":errors},model:{value:(_vm.data.confirm_password),callback:function ($$v) {_vm.$set(_vm.data, "confirm_password", $$v)},expression:"data.confirm_password"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"d-flex"},[_c('v-switch',{attrs:{"label":"¿Factura?"},model:{value:(_vm.data.b2b_invoice),callback:function ($$v) {_vm.$set(_vm.data, "b2b_invoice", $$v)},expression:"data.b2b_invoice"}})],1)])],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v(" Cancelar ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerAction)}}},[_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar" : "Guardar"))])]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }