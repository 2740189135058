var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',[(!_vm.showRegisterImage)?_c('v-col',{attrs:{"md":"12"}},[_c('v-row',{staticClass:"justify-between align-center mb-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"accent shadow-4 px-8",attrs:{"rounded":"","dark":"","block":"","large":""},on:{"click":_vm.HandlerAddImage}},[_vm._v(" Añadir Imagen ")])],1)],1),_c('v-card',{staticClass:"shadow-1 mt-5"},[(!_vm.showRegisterImage)?_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.dataImages,"disable-pagination":"","hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.HandlerEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Imagen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.HandlerShowDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Imagen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.HandlerCopy(item.image_url)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v(" mdi-content-copy ")])],1)]}}],null,true)},[_c('span',[_vm._v("Copiar url")])])]}}],null,true)}):_vm._e()],1)],1):_vm._e(),(_vm.showRegisterImage)?_c('v-col',{attrs:{"md":"12"}},[_c('section',{attrs:{"id":"register"}},[_c('register-image',{attrs:{"dataUpdate":_vm.dataUpdate,"action":_vm.action},on:{"dialog:change":_vm.HandlerResponse}})],1)]):_vm._e()],1),_c('v-row',{attrs:{"justify":"end"}},[(!_vm.showRegisterImage)?_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}},[_c('v-card',{attrs:{"loading":_vm.loadingDelete}},[_c('v-card-text',[_c('h3',{staticClass:"pt-4"},[_vm._v("Deseas eliminar esta imagen")]),_c('p',[_vm._v("Al aceptar estas de acuerdo en eliminar la imagen")]),(_vm.imagesData == null)?_c('v-img',{attrs:{"src":require("@/assets/img/no_image.jpg")}}):_c('v-img',{attrs:{"height":"200","widht":"auto","contain":"","src":_vm.imagesData.image_url,"lazy-src":_vm.imagesData.image_url},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"loading":_vm.loadingDelete,"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){_vm.showDelete = !_vm.showDelete}}},[_vm._v(" Cancelar ")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"loading":_vm.loadingDelete,"rounded":"","color":"success"},on:{"click":_vm.HandlerDelete}},[_vm._v(" Continuar ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }