<template>
  <div v-if="order.date_delivered == null && statusShip == 2">
    <div v-if="order.date_in_transit == null">
      <v-btn @click="HandlerMoveToTransist(order)" block dark color="green">
        En tránsito
      </v-btn>
      <move-toltip-component
        :order="order"
        v-if="
          order.user_btn_prepeared != null ||
          order.user_btn_delivered != null ||
          order.user_btn_in_transit != null
        "
      />
    </div>
    <div v-else>
      <v-btn dark color="green"> Desmarcar en tránsito </v-btn>
    </div>
  </div>
</template>

<script>
import MoveToltip from "./Utils/moveToltip";
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
    statusShip: {
      type: Number,
      required: true,
      default: 2,
    },
  },
  components: {
    "move-toltip-component": MoveToltip,
  },
  methods: {
    async HandlerMoveToTransist(item) {
      try {
        const request = { shipping_id: item.id, country_id: 1 };
        const response = await this.$store.dispatch(
          "orders/MOVE_TO_TRANSIT",
          request
        );
        this.$snotify.success("Se ha movido el envio", "Exitos!");
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>