<template>
  <v-container>
    <!-- <v-row class="mt-5 mb-5">
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model.trim="brand"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Marca"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="products"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Productos con Proyección"
          single-line
          hide-details
        ></v-select>
      </v-col>

      <v-col class="d-flex justify-end" cols="12" md="4">
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetSales()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="shadow-1">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="data"
            :items-per-page="20"
            class="elevation-0"
            disable-pagination
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // filters
      brand: "",
      products: "",
      loadingFilters: false,

      // Data
      loading: false,
      sku: "",
      name: "",

      // Table
      headers: [
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "sku",
        },
        { text: "Nombre", value: "name" },
        { text: "Marca", value: "fat" },
        { text: "Fisico", value: "stock" },
        { text: "Ingresos", value: "entry" },
        { text: "Proyectado", value: "projected" },
        { text: "Meses de Stock", value: "stock_month" },
        { text: "Ventas 90 Dias", value: "sales_n_days" },
      ],
      data: [],

      // Pagination
      page: 1,
      pagination: {},
    };
  },

  created() {
    this.HandlerGetStocks();
  },

  watch: {
    page(val) {
      this.HandlerGetStocks(val);
    },
  },

  methods: {
    async HandlerGetStocks(page) {
      try {
        this.loading = true;
        const request = {
          page: page || 1,
          per_page: 20,
          sku: this.sku,
          name: this.name,
        };
        const response = await this.$store.dispatch(
          "stocks/GET_STOCKS",
          request
        );
        this.data = response.data.data.data;
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>