<template>
  <v-dialog v-model="showLoader" persistent scrollable max-width="250">
    <v-card>
      <v-progress-linear
        v-if="showLoader"
        indeterminate
        :color="color"
        rounded
        height="6"
      ></v-progress-linear>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showLoader: {
      type: Boolean,
      required: true,
      default: false,
    },
    color: {
      type: String,
      required: true,
      default: "primary",
    },
  },
};
</script>

<style>
</style>