<template>
  <div>
    <div
      v-for="(itemPay, indexPay) in payment.payment"
      :key="indexPay + 'n'"
      v-show="itemPay.status == 'approved'"
    >
      <!-- *** METODO DE PAGO *** -->
      <div v-if="itemPay.length > 1">
        <p class="mb-0">
          <span class="subtitle-2 font-weight-bold mr-1">Pago {{ indexPay + 1 }}: </span>
          {{ HandlerPaymentType(itemPay) }}
        </p>
      </div>
      <div v-else>
        <p class="my-0 py-0">
          <span class="mr-1 subtitle-2 font-weight-bold">Método de pago:</span>
          {{ HandlerPaymentType(payment.payment[0]) }}
        </p>
        <p class="my-0 py-0">
          <span class="mr-1 subtitle-2 font-weight-bold">Monto de cobro:</span>
          {{ payment.paid_amount | currency }}
        </p>
      </div>
    </div>
    <!-- *** ESTADO *** -->
    <p class="mb-0">
      <span class="subtitle-2 font-weight-bold">Estado:</span>
      <span class="ml-1" :style="color_status">
        {{ HandlerStatus(payment.order_status) }}
      </span>
    </p>
    <p v-if="payment.order_status == 'cancelled'">
      Motivo:
      {{ HandlerStatusCancelled(payment.status_detail) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    payment: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {
      color_status: "green",
    };
  },

  methods: {
    HandlerPaymentType(payment) {
      switch (payment.payment_type) {
        case "ticket":
          return "Efectivo";
        case "credit_card":
          return "Crédito " + payment.payment_method_id;
        case "account_money":
          return "Dinero en Mercadopago";
        case "debit_card":
          return payment.payment_method_id;
        default:
          return payment.payment_type;
      }
    },
    HandlerStatus(status) {
      switch (status) {
        case "paid":
          this.color_status = "color: #2c9a8f; font-weight: 500";
          return "Pagada";
        case "partially_paid":
          this.color_status = "color: yellow; font-weight: 500";
          return "Parcialmente paga";
        case "cancelled":
          this.color_status = 'color: #cc3e6c; font-weight: 500"';
          return "CANCELADA";
        default:
          break;
      }
    },
    HandlerStatusCancelled(status) {
      switch (status) {
        case "Mediations cancel the order":
          return "Cancelado por mediación";
        case "Feedback not fulfilled from seller":
          return "Falta de respuesta del Vendedor";
        default:
          return status;
      }
    },
  },
};
</script>

<style>
</style>