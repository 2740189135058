<template>
  <div>
    <p class="mb-0">
      <span class="mr-1 subtitle-2 font-weight-bold"> Venta: </span>
      #{{ order.meli_id }}
    </p>
    <p v-if="order.contabilium_invoice_id" class="mb-0">
      <span class="mr-1 subtitle-2 font-weight-bold"> Factura: </span>
      <a @click.prevent="HandlerPrintBill(order, 'invoice')" href="#"
        >#{{ order.bill_number }}</a
      >
    </p>
    <div v-else-if="order.order_invoice.length > 0">
      <p
        class="mb-0"
        v-for="(order_invoice, index) in order.order_invoice"
        :key="index"
      >
        <span class="mr-1 subtitle-2 font-weight-bold"> Factura: </span>
        <a @click.prevent="HandlerPrintBill(order_invoice, 'invoice')" href="#">
          #{{ order_invoice.bill_number }}</a
        >
      </p>
    </div>
    <p v-else class="mb-0">
      <span class="mr-1 subtitle-2 font-weight-bold"> Factura: </span>
      #{{ order.bill_number }}
    </p>
    <!-- <p class="my-0" v-if="order.order_item.length > 0">
      <span class="subtitle-2 font-weight-bold mr-1"> Depósito: </span>
      {{ order.order_item[0] }}
    </p> -->
    <p class="my-0">
      <span class="subtitle-2 font-weight-bold mb-0">
        Fecha Creación (sistema):
      </span>
      <br />
      {{ order.created_at | date }}
      <span v-if="order.store.name != 'Ventas Landing'">
        a las
        {{ order.created_at | hrsMoment }}
      </span>
    </p>
    <p class="mb-0" v-if="order.store.name == 'MercadoLibre'">
      <span class="subtitle-2 font-weight-bold mb-0">
        Fecha de creación de la orden (ML):
      </span>
      <br />
      {{ order.date_created | date }}
      a las
      {{ order.date_created | date1 }}
    </p>
    <p class="mb-0" v-if="order.store.name == 'MercadoLibre'">
      <span class="subtitle-2 font-weight-bold mb-0">
        Fecha de confirmación de la orden (ML):
      </span>
      <br />
      {{ order.date_closed | date }}
      a las
      {{ order.date_closed | date1 }}
    </p>
    <p class="mb-0" v-if="order.store.name == 'Ventas Landing'">
      <span class="subtitle-2 font-weight-bold mb-0">
        Fecha de creación de la orden (MP):
      </span>
      <br />
      {{ order.date_created | dateHour }}
      <!-- a las
      {{ order.date_created | date1 }} -->
    </p>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    order: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  filters: {
    date(val) {
      return moment(val).locale("es").format("DD-MM-YYYY");
    },
    date1(val) {
      return moment(val).locale("es").format("HH:mm");
    },
    dateHour(val) {
      return moment(val).locale("es").format("DD-MM-YYYY HH:mm");
    },

    hrsMoment(val) {
      return moment(val).subtract(3, 'hours').format("HH:mm")
    }
  },

  methods: {
    async HandlerPrintBill(order, type) {
      try {
        // console.log(order);
        // console.log(type);
        this.loadingPrintBill = true;
        const id = order.id;
        const path =
          type == "ticket" ? order.path_label : order.invoice_path_label;
        const indexDelete = path.search("/");
        const name = path.slice(indexDelete + 1);
        const request = {
          path: path,
          id: id,
          type: type,
        };
        const response = await this.$store.dispatch(
          "orders/PRINT_LABEL",
          request
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPrintBill = false;
      }
    },
  },
};
</script>

<style>
</style>