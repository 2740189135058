var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order.ticket_name != null)?_c('div',[(_vm.order.downloaded_by != null)?_c('div',[(_vm.order.date_in_transit == null)?_c('div',[(_vm.order.shipping_method_name == 'Chazki72')?_c('div',[_c('p',{staticClass:"small",staticStyle:{"margin-bottom":"0px"}}),_c('v-btn',{attrs:{"color":"#2dace1"}},[_vm._v(" Factura ya impresa ")])],1):_vm._e()]):_vm._e(),_c('v-btn',{attrs:{"dark":"","block":"","color":"#F5AB36"},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'ticket')}}},[_vm._v(" Reimprimir etiqueta ")]),(_vm.order.user_prepared != null)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight-bold subtitle-2 mr-1"},[_vm._v("Descargada por:")]),_c('span',[_vm._v(_vm._s(_vm.order.user_prepared.email))])]):_vm._e(),(_vm.order.user_transit != null)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight-bold subtitle-2 mr-1"},[_vm._v("Descargada por:")]),_c('span',[_vm._v(_vm._s(_vm.order.user_transit.email))])]):_vm._e(),(_vm.order.user_delivered != null)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"font-weight-bold subtitle-2 mr-1"},[_vm._v("Descargada por:")]),_c('span',[_vm._v(_vm._s(_vm.order.user_delivered.email))])]):_vm._e()],1):_c('div',{staticClass:"my-2"},[(
        (_vm.order.shipping_method_name == 'Chazki72' ||
          _vm.order.shipping_method_name == 'chazki' ||
          _vm.order.shipping_method_name == 'zippin') &&
        _vm.order.order_invoice == null
      )?_c('div',[_c('v-btn',{staticClass:"white--text",attrs:{"block":"","color":"#2dace1","disabled":_vm.order.order.meli_proof !== null ? true : false},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'invoice')}}},[_vm._v(" Imprimir Factura ")])],1):_vm._e(),(
        (_vm.order.shipping_method_name == 'Chazki72' ||
          _vm.order.shipping_method_name == 'chazki' ||
          _vm.order.shipping_method_name == 'Logis24' ||
          _vm.order.shipping_method_name == 'zippin') &&
        _vm.order.order_invoice !== null &&
        (_vm.order.status_invoice_show == 1 || _vm.order.status_invoice_show == null)
      )?_c('div',[_c('v-btn',{staticClass:"white--text",attrs:{"block":"","color":"#2dace1","disabled":_vm.order.order.meli_proof !== null ? true : false},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'invoice')}}},[_vm._v(" Imprimir Factura ")])],1):_vm._e(),(_vm.order.shipping_method_name == 'Chazki72')?_c('div',[_c('v-btn',{attrs:{"block":"","dark":"","color":"#F4A425"},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'ticket')}}},[_vm._v(" Reimprimir Etiqueta a ")])],1):_c('div',[(_vm.order.shipping_method_name == 'chazki')?_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loadingPrintBill,"block":"","color":"#F5AB36","disabled":_vm.order.order.meli_proof == null ? true : false},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'ticket')}}},[_vm._v(" Imprimir Etiqueta ")]):(_vm.order.shipping_method_name == 'zippin')?_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loadingPrintBill,"block":"","color":"#F5AB36","disabled":_vm.order.order.meli_proof == null ? true : false},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'ticket')}}},[_vm._v(" Imprimir Etiqueta ")]):(_vm.order.order_invoice !== null)?_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loadingPrintBill,"block":"","color":"#F5AB36"},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'ticket')}}},[_vm._v(" Imprimir Etiqueta ")]):_c('v-btn',{attrs:{"loading":_vm.loadingPrintBill,"block":"","dark":"","color":"#F5AB36"},on:{"click":function($event){return _vm.HandlerPrintBill(_vm.order, 'ticket')}}},[_vm._v(" Imprimir Etiqueta ")])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }