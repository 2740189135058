var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"shadow-1 mt-5",attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Registrar Producto Contabilium ")])],1)],1),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"SKU","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"SKU","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.HandlerRegisterProductContabilium)}},model:{value:(_vm.sku_contabilium),callback:function ($$v) {_vm.sku_contabilium=$$v},expression:"sku_contabilium"}})]}}],null,true)})],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":_vm.close}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerRegisterProductContabilium)}}},[_vm._v(" Registrar ")])]}}],null,true)})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }