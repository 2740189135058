<template>
  <IndexDeposits />
</template>

<script>
import IndexDeposits from "@/components/Deposits/IndexDeposits";
export default {
  components: {
    IndexDeposits,
  },
};
</script>

<style>
</style>