<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Nombre / Documento"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="search"
          @keyup.enter="HandlerGetTransfer()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Venta"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="search_operation_number"
          @keyup.enter="HandlerGetTransfer()"
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12" sm="6" md="3">
        <v-select
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="store_id"
          :items="data_stores"
          item-text="name"
          item-value="id"
          @change="HandlerGetTransfer()"
        ></v-select>
      </v-col> -->
      <v-col cols="12" sm="6" md="3">
        <v-select
          @change="HandlerGetTransfer()"
          label="Estatus"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="status"
          :items="data_status"
          item-text="text"
          item-value="value"
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="12">
        <v-card class="shadow-5">
          <v-data-table
            :headers="headers"
            :items="dataTransfer"
            hide-default-footer
            :items-per-page="-1"
            class="elevation-0"
          >
            <template v-slot:[`item.buyer.first_name`]="{ item }">
              <span class="text-uppercase">
                {{ item.buyer.first_name }} {{ item.buyer.last_name }}
              </span>
            </template>
            <template v-slot:[`item.total_amount`]="{ item }">
              {{ item.total_amount | currency }}
            </template>
            <template v-slot:[`item.shipping_cost`]="{ item }">
              {{ item.shipping_cost | currency }}
            </template>
            <template v-slot:[`item.total_amount_with_shipping`]="{ item }">
              {{ item.total_amount_with_shipping | currency }}
            </template>
            <template v-slot:[`item.buyer.doc_number`]="{ item }">
              {{ item.buyer.doc_type }}: {{ item.buyer.doc_number }}
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{ item.created_at | date }}
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                v-if="item.payment[0].status == 'pending'"
                @click="HandlerStatusChange(item, 'approved')"
                class="accent shadow-4"
                rounded
                :disabled="item.payment[0].voucher_url ? false : true"
              >
                Aprobar
              </v-btn>
              <v-tooltip bottom v-if="item.payment[0].status == 'pending'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerStatusChange(item, 'rejected')"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-thumb-down</v-icon>
                  </v-btn>
                </template>
                <span>Rechazar</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.payment[0].voucher_url">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerShowVoucher(item)"
                    icon
                    :color="isDark ? 'primary' : 'green'"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'primary' : 'green'"
                      >mdi-text-box</v-icon
                    >
                  </v-btn>
                </template>
                <span>Ver comprobante de pago</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex justify-end mt-3">
          <v-pagination
            v-if="Object.keys(paginate).length > 0"
            :length="paginate.lastPage"
            v-model="page"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  props: {
    action: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "buyer.first_name",
        },
        {
          text: "Documento",
          align: "start",
          sortable: false,
          value: "buyer.doc_number",
        },
        {
          text: "Operación",
          align: "start",
          sortable: false,
          value: "meli_id",
        },
        {
          text: "Monto",
          align: "start",
          sortable: false,
          value: "total_amount",
        },
        {
          text: "Envío",
          align: "start",
          sortable: false,
          value: "shipping_cost",
        },
        {
          text: "Monto con envío",
          align: "start",
          sortable: false,
          value: "total_amount_with_shipping",
        },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
        },

        { text: "Accion", value: "action" },
      ],

      categoryName: "",
      dataSubCategory: [],
      dataUpdate: [],
      dataTransfer: [],
      paginate: {},
      loadingFilePhoto: false,
      filePhoto: [],

      data_status: [
        {
          text: "Aprobado",
          value: "approved",
        },
        {
          text: "Pendiente",
          value: "pending",
        },
        {
          text: "Rechazado",
          value: "rejected",
        },
      ],
      status: "pending",
      store_id: "",
      data_stores: [],
      search_operation_number: "",
      search: "",
    };
  },

  watch: {
    page(val) {
      this.HandlerGetTransfer(val);
    },
  },

  created() {
    this.HandlerGetTransfer();
    this.HandlerGetStore();
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  filters: {
    date(val) {
      return moment(val).format("DD-MM-YYYY HH:mm:ss");
    },
  },

  methods: {
    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: "",
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data.filter((index) => {
          return index.id !== 2;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetTransfer(page) {
      try {
        const request = {
          page: page || 1,
          per_page: 20,
          paginate: true,
          status: this.status,
          store_id: this.store_id,
          search_operation_number: this.search_operation_number,
          search: this.search,
          country_id: 2,
        };
        const response = await this.$store.dispatch(
          "orders/GET_TRANSFER",
          request
        );

        console.log("response", response);
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataTransfer = response.data.data.data;
        this.dataTransfer = this.dataTransfer.map((transfer) => {
          return {
            ...transfer,
            shipping_cost:
              transfer.total_amount_with_shipping - transfer.total_amount,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerStatusChange(item, status) {
      try {
        this.loadingStatus = true;

        const request = {
          order_id: item.id,
          status: status,
        };
        await this.$store.dispatch("orders/UPDATE_TRANSFER_STATUS", request);
        this.$snotify.success("Cargado con exitos", "Exitos");

        this.HandlerGetTransfer();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingStatus = false;
      }
    },

    async HandlerShowVoucher(item) {
      window.open(item.payment[0].voucher_url);
    },
  },
};
</script>

<style></style>
