<template>
  <section>
    <register-components
      v-if="showRegister"
      :data="dataAdd"
      :action="actionAdd"
      :loader="loadingRegister"
      @dialog:change="HandlerResponse"
    />
    <v-container fluid class="pb-0 pl-0 pr-0" v-if="status_card">
      <v-row class="justify-space-between align-center">
        <v-col cols="auto mb-2">
          <v-btn
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({}, 0)"
            >Añadir categoría</v-btn
          >
        </v-col>
        <div class="row-input-box">
          <v-text-field
            label="Categoría"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="search"
            @keyup.enter="HandlerGetCategories(1, search)"
          ></v-text-field>
          <v-btn
            fab
            small
            color="primary"
            class="btn-icon"
            @click="HandlerGetCategories(1, search)"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-container>

    <div v-if="!loadingCategories && status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :headers="headers"
          :items="getCategories"
          :expanded.sync="expanded"
          show-expand
          :items-per-page="itemsPerPage"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="HandlerAddData(item, 1)"
              v-if="!loadingDelete[item.id]"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="CheckCategoryDeletable(item)"
              v-if="!loadingDelete[item.id]"
            >
              mdi-delete
            </v-icon>
            <v-progress-circular
              size="20"
              class="d-flex justify-center"
              indeterminate
              color="primary"
              v-else
            ></v-progress-circular>
          </template>

          <template v-slot:expanded-item="{ item }">
            <td
              :colspan="3"
              class="px-12 py-4"
              v-if="item.specifications.length"
            >
              <v-row
                v-for="specification in item.specifications"
                :key="specification.id"
              >
                <v-col cols="5">{{ specification.name }}</v-col>
                <v-col cols="4">{{ specification.measuring_unit }}</v-col>
                <v-col cols="3">
                  <v-icon
                    @click="HandlerDeleteSpecification(item, specification)"
                    v-if="!loadingDelete[specification.id]"
                  >
                    mdi-delete
                  </v-icon>
                  <v-progress-circular
                    size="20"
                    class="d-flex justify-center"
                    indeterminate
                    color="primary"
                    v-else
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </td>
            <td :colspan="3" class="px-12 py-4" v-else>
              <div>Esta categoría no tiene especificaciones.</div>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="getPaginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <div v-if="loadingCategories" class="d-flex justify-center">
      <v-progress-circular
        size="300"
        class="d-flex justify-center my-5"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <custom-modal
      v-if="showDeleteConfirmModal"
      :active="showDeleteConfirmModal"
      :action="HandlerConfirmDelete"
      :closeModal="HandlerCloseModal"
      confirmBtn
      :title="'Confirmar eliminación'"
      max-width="600"
      :withImageTitle="false"
      closeText="cancelar"
    >
      <div class="delete-message">
        {{ infoDeleteCategory.message }}
      </div>
    </custom-modal>
  </section>
</template>

<script>
import RegisterCategory from "./Utils/RegisterCategory.vue";
import CustomModal from "../CustomModal/customModal.vue";

export default {
  components: {
    "register-components": RegisterCategory,
    "custom-modal": CustomModal,
  },
  data() {
    return {
      status_card: true,
      loadingRegister: false,
      loadingCategories: false,
      loadingDelete: {},
      expanded: [],
      dataAdd: {},
      actionAdd: 0,
      singleExpand: false,
      showRegister: false,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      search: "",
      itemsPerPage: 50,
      page: 1,
      indexAddSpecification: null,
      showDeleteConfirmModal: false,
      infoDeleteCategory: { item: null, message: "" },
    };
  },
  created() {
    this.HandlerGetCategories();
  },
  watch: {
    page(val) {
      this.HandlerGetCategories(val);
    },
  },

  filters: {},

  computed: {
    getCategories() {
      return this.$store.getters["products/GET_CATEGORIES"];
    },
    getPaginate() {
      return this.$store.getters["products/GET_PAGINATE"];
    },
  },

  methods: {
    async HandlerGetCategories(page = 1, search = "") {
      try {
        this.loadingCategories = true;
        const request = {
          page: page,
          per_page: this.itemsPerPage,
          name: search,
          paginate: true,
        };
        await this.$store.dispatch("products/GET_CATEGORIES", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCategories = false;
      }
    },

    HandlerAddData(data, action) {
      this.status_card = !this.status_card;
      this.dataAdd = { ...data };
      this.showRegister = true;
      this.actionAdd = action;
    },

    async HandlerDeleteCategory(item) {
      try {
        this.loadingDelete[item.id] = true;
        this.$forceUpdate();
        const request = {
          product_category_id: item.id,
        };
        await this.$store.dispatch("products/DELETE_CATEGORY", request);
        this.$snotify.success(`Categoría eliminada`, "¡Exitos!");
        this.HandlerGetCategories();
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ha ocurrido un error eliminando la categoría",
          "¡Error!"
        );
      } finally {
        this.loadingDelete[item.id] = false;
        this.$forceUpdate();
      }
    },

    async CheckCategoryDeletable(item) {
      try {
        this.loadingDelete[item.id] = true;
        this.$forceUpdate();
        const request = {
          product_category_id: item.id,
        };
        const response = await this.$store.dispatch(
          "products/CHECK_CATEGORY_DELETABLE",
          request
        );
        if (response) {
          this.HandlerDeleteCategory(item);
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status == 400) {
          this.infoDeleteCategory = {
            item: item,
            message: error?.response?.data?.message,
          };
          this.showDeleteConfirmModal = true;
        } else {
          this.loadingDelete[item.id] = false;
          this.$forceUpdate();
          this.$snotify.error(
            "Ha ocurrido un error eliminando la categoría",
            "¡Error!"
          );
        }
      }
    },

    HandlerCloseModal() {
      this.loadingDelete[this.infoDeleteCategory?.item?.id] = false;
      this.$forceUpdate();
      this.showDeleteConfirmModal = false;
      this.infoDeleteCategory = { item: null, message: "" };
    },

    HandlerConfirmDelete() {
      this.HandlerDeleteCategory(this.infoDeleteCategory.item);
      this.HandlerCloseModal();
    },

    async HandlerDeleteSpecification(item, specification) {
      try {
        this.loadingDelete[specification.id] = true;
        this.$forceUpdate();
        const request = {
          product_category_id: item.id,
          data: { specifications_ids: [specification.id] },
        };
        await this.$store.dispatch(
          "products/DELETE_SPECIFICATIONS_TO_CATEGORY",
          request
        );
        const indexCategory = this.getCategories.findIndex(
          (elem) => elem.id === item.id
        );

        if (indexCategory !== -1) {
          const newSpecifications = this.getCategories[
            indexCategory
          ].specifications.filter((elem) => elem.id !== specification.id);
          this.getCategories[indexCategory].specifications = newSpecifications;
        }
        this.$snotify.success(`Especficación eliminada`, "¡Exitos!");
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ha ocurrido un error eliminando la especificación",
          "¡Error!"
        );
      } finally {
        this.loadingDelete[specification.id] = false;
        this.$forceUpdate();
      }
    },

    async CreateCategory(data) {
      try {
        this.loadingRegister = true;
        const createCategoryData = {
          name: data.name,
        };
        const newCategory = await this.$store.dispatch(
          "products/REGISTER_CATEGORIES",
          createCategoryData
        );
        const specificationsArray = data?.specifications?.map(
          (elem) => elem.id
        );
        const addSpecificacionData = {
          product_category_id: newCategory.id,
          data: { specifications_ids: specificationsArray },
        };

        if (addSpecificacionData?.data?.specifications_ids?.length > 0) {
          await this.$store.dispatch(
            "products/ADD_SPECIFICATIONS_TO_CATEGORY",
            addSpecificacionData
          );
        }
        this.$snotify.success(`Categoría registrada`, "¡Exitos!");
        this.status_card = !this.status_card;
        this.showRegister = false;
        this.HandlerGetCategories();
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con el registro", "¡Error!");
      } finally {
        this.loadingRegister = false;
      }
    },

    async UpdateCategory(data, oldData) {
      try {
        this.loadingRegister = true;
        const updateCategoryData = {
          product_category_id: data.id,
          data: { name: data.name },
        };
        const deleteSpecificationsArray =
          oldData?.specifications?.length > 0
            ? oldData?.specifications?.filter((item) => {
                const foundItem = data?.specifications?.find(
                  (dataItem) => dataItem.id === item.id
                );
                return !foundItem;
              })
            : [];
        const deleteIdsSpecificationArray = deleteSpecificationsArray.map(
          (elem) => elem.id
        );
        const deleteSpecificacionData = {
          product_category_id: data.id,
          data: { specifications_ids: deleteIdsSpecificationArray },
        };

        const addSpecificationsArray =
          oldData?.specifications?.length > 0
            ? data.specifications?.filter((item) => {
                const foundItem = oldData?.specifications?.find(
                  (oldItem) => oldItem.id === item.id
                );
                return !foundItem;
              })
            : data?.specifications;
        const addIdsSpecificationArray = addSpecificationsArray.map(
          (elem) => elem.id
        );
        const addSpecificacionData = {
          product_category_id: data.id,
          data: { specifications_ids: addIdsSpecificationArray },
        };

        await this.$store.dispatch(
          "products/UPDATE_CATEGORY",
          updateCategoryData
        );
        if (deleteSpecificacionData?.data?.specifications_ids?.length > 0) {
          await this.$store.dispatch(
            "products/DELETE_SPECIFICATIONS_TO_CATEGORY",
            deleteSpecificacionData
          );
        }

        let categoryMessage = '';

        if (addSpecificacionData?.data?.specifications_ids?.length > 0) {
          const categoryEdited = await this.$store.dispatch(
            "products/ADD_SPECIFICATIONS_TO_CATEGORY",
            addSpecificacionData
          );
          if(categoryEdited?.message){
            categoryMessage = categoryEdited?.message;
          }
        }
        this.$snotify.success(`Categoría editada ${categoryMessage}`, "¡Exitos!");
        this.status_card = !this.status_card;
        this.showRegister = false;
        this.HandlerGetCategories();
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la edición", "¡Error!");
      } finally {
        this.loadingRegister = false;
      }
    },

    HandlerResponse(newData, action, oldData) {
      if (newData) {
        if (action === 0) {
          this.CreateCategory(newData);
        } else {
          this.UpdateCategory(newData, oldData);
        }
      } else {
        this.status_card = !this.status_card;
        this.showRegister = false;
      }
    },
  },
};
</script>

<style>
.row-input-box {
  display: flex;
  flex-direction: row;
  padding: 16px 16px 0 16px;
}
.btn-icon {
  margin: 6px 0 0 16px;
}
.specifications-box {
  display: flex;
  flex-direction: row;
}

.delete-message {
  font-size: 16px;
}
</style>
