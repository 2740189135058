<template>
  <v-container class="mt-5">
    <v-row class="justify-between align-center mb-5">
      <v-col cols="12" md="3" class="py-0">
        <v-btn
          rounded
          dark
          block
          large
          @click="HandlerShowData"
          v-if="showData"
          class="accent shadow-4 px-8"
        >
          Añadir Categoria
        </v-btn>
      </v-col>
    </v-row>
    <!-- <v-card> -->
    <CategoryData
      @dialog:change="HandlerResponseData"
      v-if="showData"
      action="0"
    />
    <RegisterData
      :dataUpdate="dataUpdate"
      :dataCategory="dataCategory"
      :action="action"
      @dialog:change="HandlerResponse"
      v-else-if="!showData"
    />
    <!-- </v-card> -->
  </v-container>
</template>

<script>
import CategoryData from "./Utils/CategoryData";
import RegisterData from "./Utils/RegisterData";
export default {
  components: {
    CategoryData,
    RegisterData,
  },
  data() {
    return {
      showData: true,
      dataCategory: [],
      dataUpdate: [],
      action: 0,
    };
  },

  methods: {
    HandlerShowData() {
      this.showData = !this.showData;
      this.dataUpdate = {
        sub_category: [],
      };
    },

    HandlerResponse(data, action) {
      this.showData = !this.showData;
      this.action = 0;
      if (action == 0) {
        this.dataCategory.push(data.data[0]);
      } else {
        const indexupdate = this.dataCategory
          .map((element) => element.id)
          .indexOf(data.data[0].id);
        if (indexupdate > -1) {
          this.dataCategory.splice(indexupdate, 1, data.data[0]);
        }
      }
    },

    HandlerResponseData(data) {
      this.showData = !this.showData;
      this.dataUpdate = { ...data };
      this.action = 1;
    },
  },
};
</script>

<style>
</style>