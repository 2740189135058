<template>
  <v-row class="mt-5">
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          @input="clearOperationNumber"
          @keyup.enter="HandlerGetDataByFilter"
          v-model="operation_number"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Venta"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          @keyup.enter="HandlerGetDataByFilter"
          v-model="client_name"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Nombre / Email"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          @keyup.enter="HandlerGetDataByFilter"
          v-model="product_name"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Producto / SKU"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="dataSeller"
          item-text="name"
          item-value="seller_id"
          v-model="seller"
          menu-props="auto"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Vendedor"
          single-line
          hide-details
        ></v-select>
      </v-col>

      <!-- <v-col cols="12" sm="4" md="3">
        <v-select
          :items="dataStore"
          item-text="name"
          item-value="id"
          v-model="store"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Tienda"
          single-line
          hide-details
        ></v-select>
      </v-col> -->
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="dataWarehouse"
          item-text="name"
          item-value="id"
          v-model="warehouse_id"
          filled
          rounded
          dense
          class="rounded-small"
          name="deposito"
          label="Deposito"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-menu
          v-model="menu_start"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @keyup.enter="HandlerGetDataByFilter"
              v-model="date"
              label="Desde"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="date"
            @input="menu_start = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-menu
          v-model="menu_end"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @keyup.enter="HandlerGetDataByFilter"
              v-model="date_end"
              label="Hasta"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="date_end"
            @input="menu_end = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="dataTypeSend"
          item-text="name"
          item-value="value"
          v-model="typeSend"
          menu-props="auto"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Modo de envío"
          single-line
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-text-field
          @input="clearOperationNumberMP"
          @keyup.enter="HandlerGetDataByFilter"
          v-model="mp_payment_id"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="# Operación MP"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="mx-2 mt-2" justify="space-between">
      <v-col cols="12" md="4" class="d-flex justify-start">
        <v-pagination
          v-model="myPage"
          :length="paginate.lastPage"
          :total-visible="$vuetify.breakpoint.name == 'xs' ? 5 : 7"
          circle
        ></v-pagination>
      </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetDataByFilter()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  props: {
    paginate: {
      type: Object,
      required: true,
      default: () => {},
    },
    page: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      operation_number: "",
      client_name: "",
      product_name: "",
      seller: "",
      dataSeller: [],
      dataStore: [],
      store: null,
      date: null,
      menu_start: false,
      date_end: null,
      menu_end: false,
      mp_payment_id: "",
      dataTypeSend: [
        { name: "Todos", value: "" },
        { name: "Flex", value: "Flex" },
        { name: "Colecta", value: "Colecta" },
        // { name: "Chazki", value: "Chazki" },
        // { name: "Logis24", value: "Logis24" },
        // { name: "Retiro cliente", value: "Retiro cliente" },
        // { name: "Logis24", value: "Logis24" },
      ],
      dataWarehouse: [],
      warehouse_id: null,
      typeSend: null,
      pageUpdate: "",
    };
  },

  created() {
    this.HandlerGetSeller();
    this.HandlerGetStore();
    this.HandlerGetWarehouse();
  },

  computed: {
    myPage: {
      get() {
        return this.page;
      },
      set(val) {
        const filterData = {
          page: 1,
          per_page: 10,
          paginate: true,
          meli_order_id: this.operation_number,
          search_buyer: this.client_name.toLowerCase(),
          search_order_item: this.product_name.toLowerCase(),
          seller_id: this.seller,
          store_id: this.store == null ? "" : this.store,
          date_from: this.date == null ? "" : this.date,
          date_to: this.date_end == null ? "" : this.date_end,
          shipping_method_name: this.typeSend == null ? "" : this.typeSend,
        };
        this.$emit("filters:change", val, 0, filterData);
      },
    },
  },

  methods: {
    async HandlerGetSeller() {
      try {
        const response = await this.$store.dispatch("orders/GET_SELLER");
        const filterData = response.data.data.filter((value) => {
          if (value.name != "TETE8158324") {
            return value;
          }
        });
        this.dataSeller = filterData;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          name: "",
          paginate: false,
          b2b_active: false,
          country_id: 2,
        };

        const response = await this.$store.dispatch(
          "orders/GET_STORE",
          request
        );
        this.dataStore = response.data.data;
        this.dataStore.push({
          name: "Todos",
          id: "",
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetWarehouse() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          name: "",
          paginate: false,
        };
        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS",
          request
        );
        this.dataWarehouse = response.data.data;
        this.dataWarehouse = this.dataWarehouse.filter((index) => {
          return (
            index.id == 3 || index.id == 5 || index.id == 8 || index.id == 10
          );
        });
        this.dataWarehouse.push({
          name: "Todos",
          id: "",
        });
      } catch (error) {
        console.log(error);
      }
    },

    clearOperationNumber(input) {
      this.operation_number = input.replaceAll(" ", "");
    },

    clearOperationNumberMP(input) {
      this.mp_payment_id = input.replaceAll(" ", "");
    },

    HandlerReset() {
      this.operation_number = "";
      this.client_name = "";
      this.product_name = "";
      this.date = null;
      this.date_end = null;
      this.typeSend = null;
      this.store = null;
      this.seller = "";
      this.mp_payment_id = "";
      this.warehouse_id = null;
      this.HandlerGetDataByFilter();
    },

    async HandlerGetDataByFilter() {
      try {
        this.loading = true;
        this.$emit("filters:change", true, 2, {});

        const request = {
          page: 1,
          per_page: 10,
          paginate: true,
          meli_order_id: this.operation_number,
          mp_payment_id: this.mp_payment_id,
          search_buyer: this.client_name.toLowerCase(),
          search_order_item: this.product_name.toLowerCase(),
          seller_id: this.seller,
          store_id: this.store == null ? "" : this.store,
          date_from: this.date == null ? "" : this.date,
          date_to: this.date_end == null ? "" : this.date_end,
          shipping_method_name: this.typeSend == null ? "" : this.typeSend,
          warehouse_id: this.warehouse_id,
          country_id: 2,
        };

        const response = await this.$store.dispatch(
          "orders/GET_ORDERS",
          request
        );

        const responseData = {
          paginate: {
            lastPage: response.data.data.lastPage,
            page: response.data.data.page,
            perPage: response.data.data.perPage,
            total: response.data.data.total,
          },
          data: [...response.data.data.data],
        };
        this.$emit("filters:change", responseData, 1, request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        // this.$emit("filters:change", false, 3, {});
      }
    },
  },
};
</script>

<style>
</style>