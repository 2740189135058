<template>
  <v-container>
    <v-row>
      <!-- {{ data.images }} -->
      <v-col cols="12">
        <alert
          :data="errorData"
          :active="activeAlert"
          :colorAlert="colorAlert"
          @dialog:change="eventdialog"
        />
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <div
            class="
              font-weight-medium
              primary--text
              mb-5
              d-flex
              justify-space-between
            "
            style="font-size: 26px"
          >
            <span v-if="action == 0"> Nuevo Producto </span>
            <span v-if="action == 1"> Editar Producto </span>
            <span v-if="action == 2">
              <!-- Datos de la publicacion -->
              <div>{{ dataPublication.title }}</div>
              <p>{{ dataPublication.item_id }}</p>
            </span>
            <!-- {{ action == 0 ? "Nuevo" : "Editar" }} Producto -->
            <v-btn icon @click="closeModal()" v-if="action !== 2">
              <v-icon color="primary">mdi-arrow-left-bold</v-icon>
            </v-btn>
          </div>
          <v-card class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-title class="pa-0 shadow-1">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
                <v-card-title class="font-weight-medium primary--text">
                  Datos Generales
                </v-card-title>
              </v-img>
            </v-card-title>

            <vuescroll
              :ops="{
                bar: { background: '#bacfcec0', keepShow: true }
              }"
            >
              <v-card-text>
                <v-form>
                  <v-row>
                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider name="marca" v-slot="{ errors }">
                        <v-autocomplete
                          :items="dataBrand"
                          item-text="name"
                          item-value="id"
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Marca"
                          name="brand_id"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.brand_id"
                          :error-messages="errors"
                          @change="HandlerGetGenerateSku"
                        >
                          <template v-slot:[`item`]="{ item }">
                            <span
                              v-if="Object.keys(item).length > 0"
                              class="text-capitalize"
                            >
                              {{ item.name }}
                            </span>
                          </template>
                          <template v-slot:[`selection`]="{ item }">
                            <span
                              v-if="Object.keys(item).length > 0"
                              class="text-capitalize"
                            >
                              {{ item.name }}
                            </span>
                          </template>
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider name="name" v-slot="{ errors }">
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Nombre"
                          name="name"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.short_name"
                          @input="HandlerComercialDesignation"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider name="modelo" v-slot="{ errors }">
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Modelo"
                          name="model"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.pattern_name"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="Código de barras"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Código de barras"
                          name="barcode"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.barcode"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <!-- <v-col cols="12" sm="6" md="3">
                      <ValidationProvider name="sku" v-slot="{ errors }">
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="SKU"
                          name="sku"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.sku"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="Activar B2B"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="items"
                          item-text="title"
                          item-value="value"
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Activar B2B"
                          name="show_b2b"
                          prepend-inner-icon="mdi-car-lifted-pickup"
                          color="blue-grey"
                          v-model="data.show_b2b"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col> -->

                    <v-col cols="12" sm="6" md="6">
                      <ValidationProvider
                        name="denominacion comercial"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Denominación Comercial"
                          name="name"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.name"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="Grupo"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="itemsGroup"
                          item-text="title"
                          item-value="value"
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Grupo"
                          name="show_b2b"
                          prepend-inner-icon="mdi-card-text"
                          color="blue-grey"
                          v-model="data.group"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                      <ValidationProvider
                        name="notas"
                        rules=""
                        v-slot="{ errors }"
                      >
                        <v-textarea
                          rows="1"
                          v-model="data.notes"
                          filled
                          rounded
                          class="rounded-small"
                          name="description"
                          label="Notas"
                          :error-messages="errors"
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </vuescroll>
          </v-card>

          <v-divider class="mx-15 mb-6"></v-divider>

          <v-card class="mb-5">
            <v-card-text>
              <v-card-title class="font-weight-medium primary--text">
                Dimensiones
              </v-card-title>
              <v-row dense>
                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider
                    name="peso"
                    v-slot="{ errors }"
                    rules="numeric"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Peso (grs)"
                      name="weight"
                      prepend-inner-icon="mdi-card-text"
                      color="blue-grey"
                      type="number"
                      v-model="data.weight"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider
                    name="largo"
                    v-slot="{ errors }"
                    rules="numeric"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Largo (cm)"
                      name="length"
                      prepend-inner-icon="mdi-card-text"
                      color="blue-grey"
                      type="number"
                      v-model="data.length"
                      @input="HandleVolumen"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider
                    name="ancho"
                    v-slot="{ errors }"
                    rules="numeric"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Ancho (cm)"
                      name="width"
                      prepend-inner-icon="mdi-card-text"
                      color="blue-grey"
                      type="number"
                      v-model="data.width"
                      @input="HandleVolumen"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider
                    name="profundidad"
                    v-slot="{ errors }"
                    rules="numeric"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Profundidad (cm)"
                      name="height"
                      prepend-inner-icon="mdi-card-text"
                      color="blue-grey"
                      type="number"
                      v-model="data.height"
                      @input="HandleVolumen"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-card-text>
              <v-card-title class="font-weight-medium primary--text">
                Imagenes
              </v-card-title>
              <v-row dense v-if="action == 0">
                <v-col cols="12" sm="6" md="3">
                  <ValidationProvider
                    name="imagenes"
                    :rules="action == 0 ? 'image|size:2000' : ''"
                    v-slot="{ errors }"
                  >
                    <v-file-input
                      prepend-icon
                      prepend-inner-icon="mdi-camera-image"
                      multiple
                      label="cargar imagenes"
                      v-model="data.images_publications"
                      filled
                      rounded
                      dense
                      :error-messages="errors"
                    ></v-file-input>
                  </ValidationProvider>
                </v-col>
              </v-row>

              <v-row dense v-if="action !== 0">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-sheet class="mx-auto" elevation="0" max-width="auto">
                    <v-slide-group
                      v-model="model"
                      class="pa-4"
                      active-class="primary"
                      show-arrows
                    >
                      <v-slide-item
                        v-for="(item, index) in data.images"
                        :key="index"
                        v-slot="{ active, toggle }"
                      >
                        <v-card
                          :color="active ? undefined : 'grey lighten-1'"
                          class="ma-4"
                          height="200"
                          width="100"
                          @click="toggle"
                        >
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                            <v-img
                              class="mx-5"
                              v-if="!active && updateImage == false"
                              contain
                              height="200"
                              width="100"
                              :src="item"
                              :lazy-src="item"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="black lighten-5"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <v-progress-circular
                              v-if="!active && updateImage == true"
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                            <!-- <v-scale-transition> -->
                            <v-icon
                              @click="HandlerShowImage(item, index)"
                              v-if="active"
                              color="white"
                              size="48"
                              v-text="'mdi-image-edit'"
                            ></v-icon>
                            <v-icon
                              @click="deleteImagePublication(item)"
                              v-if="active"
                              color="white"
                              size="48"
                              v-text="'mdi-image-remove'"
                            ></v-icon>
                            <!-- </v-scale-transition> -->
                          </v-row>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                    <v-row>
                      <v-col cols="3" v-if="action !== 2">
                        <ValidationProvider
                          name="imagenes"
                          :rules="action == 0 ? 'required' : ''"
                          v-slot="{ errors }"
                        >
                          <v-file-input
                            prepend-icon
                            prepend-inner-icon="mdi-camera-image"
                            multiple
                            label="cargar imagenes"
                            v-model="data.images_publications"
                            filled
                            rounded
                            dense
                            :error-messages="errors"
                          ></v-file-input>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <input
                    v-show="false"
                    ref="inputUpload"
                    type="file"
                    @change="HandlerSaveImage"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-5" v-if="action !== 2">
            <v-card-title class="font-weight-medium primary--text">
              Overview
            </v-card-title>
            <v-card-text>
              <ValidationProvider name="resumen" rules="" v-slot="{ errors }">
                <wysiwyg v-model="data.resume" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text>
            <v-card-title class="font-weight-medium primary--text">
              Descripción
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                name="descripcion"
                rules=""
                v-slot="{ errors }"
              >
                <wysiwyg v-model="data.description" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text>
            <!-- Especificaciones -->
            <v-card-title class="font-weight-medium primary--text">
              Especificaciones
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                name="descripcion"
                rules=""
                v-slot="{ errors }"
              >
                <wysiwyg v-model="data.specs" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-progress-linear
              v-if="loadingCategories"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>
            <v-card-text>
              <v-card-title class="font-weight-medium primary--text">
                Categoría
              </v-card-title>
              <v-row dense style="margin:0px;">
                <v-col cols="10" sm="4" md="4" style="padding:0px 12px;">
                  <ValidationProvider
                    :name="`Nombre de categoría`"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      label="Selecciona una categoría"
                      filled
                      rounded
                      dense
                      class="rounded-small"
                      :name="`Nombre de categoría`"
                      :value="categoryData.id"
                      @input="elem => selectCategory(elem)"
                      :items="getCategories"
                      item-text="name"
                      item-value="id"
                      color="blue-grey"
                      :error-messages="errors"
                    >
                    </v-autocomplete>
                  </ValidationProvider>
                </v-col>
                <v-col cols="2" sm="8" md="8" class="delete-box">
                  <button @click.prevent="() => deleteCategory()">
                    <v-icon large>
                      mdi-delete
                    </v-icon>
                  </button>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  class="py-0 my-0"
                  v-if="categoryData.specifications_values.length > 0"
                >
                  <div class="subtitle-specification">
                    Especificaciones
                  </div>
                </v-col>
                <v-row
                  v-for="(specification,
                  idx) in categoryData.specifications_values"
                  :key="specification.id"
                  style="margin:0px;"
                >
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      :name="`Nombre de especificación ${idx + 1}`"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        disabled
                        label="Nombre"
                        :name="`Nombre de especificación ${idx + 1}`"
                        type="text"
                        color="blue-grey"
                        :error-messages="errors"
                        v-model="categoryData.specifications_values[idx].name"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3" md="3">
                    <ValidationProvider
                      :name="`Unidad de especificación ${idx + 1}`"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        disabled
                        label="Unidad"
                        :name="`Unidad de especificación ${idx + 1}`"
                        type="text"
                        color="blue-grey"
                        :error-messages="errors"
                        v-model="
                          categoryData.specifications_values[idx].measuring_unit
                        "
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="3" md="3" class="d-flex justify-center">
                    <ValidationProvider
                      :name="`Valor de especificación ${idx + 1}`"
                      v-slot="{ errors }"
                    >
                      <v-switch
                        v-model="categoryData.specifications_values[idx].value"
                        :label="
                          categoryData.specifications_values[idx].value
                            ? 'Sí'
                            : 'No'
                        "
                        v-if="showSwitch(idx)"
                      ></v-switch>
                      <v-text-field
                        v-else
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Valor"
                        :name="`Valor de especificación ${idx + 1}`"
                        type="text"
                        color="blue-grey"
                        :error-messages="errors"
                        v-model="categoryData.specifications_values[idx].value"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="1"
                    md="1"
                    v-if="categoryData.specifications_values[idx].value == null"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="mt-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#f44"
                              d="M20 17h2v-2h-2v2m0-10v6h2V7M4 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8l-6-6m-1 1.5L16.5 9H11Z"
                            />
                          </svg>
                        </div>
                      </template>
                      <span
                        >Por favor agregue el valor a la especificación</span
                      >
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-row>
              <v-col
                v-if="categoryData.length < 1"
                cols="12"
                class="btn-add-box"
              >
                <button @click.prevent="addCategory" class="btn-add">
                  Agregar Categoría
                </button>
              </v-col>
            </v-card-text>
          </v-card>

          <v-divider class="mx-15 mb-6"></v-divider>

          <v-row justify="end" class="mb-4">
            <v-hover v-slot:default="{ hover }">
              <v-btn
                text
                rounded
                class="mr-1"
                :color="hover ? 'error' : 'grey'"
                @click="closeModal"
                >Cancelar</v-btn
              >
            </v-hover>

            <v-hover v-slot:default="{ hover }">
              <v-btn
                rounded
                :loading="loading"
                :disabled="register_disabled"
                color="success"
                :class="hover ? 'shadow-2' : 'shadow-4'"
                class="px-8"
                @click="passes(HandlerAction)"
              >
                {{ action == 0 ? "Registrar" : "Editar" }}
              </v-btn>
            </v-hover>
          </v-row>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vuescroll from "vuescroll";
import moment from "moment";

import alert from "../Utils/Alert";
export default {
  components: {
    alert,
    vuescroll
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    action: {
      type: Number,
      required: false,
      default: 0
    },
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    dataBrand: {
      type: Array,
      required: true,
      default: () => []
    },
    dataPublication: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      model: null,
      items: [
        {
          title: "SI",
          value: true
        },
        {
          title: "NO",
          value: false
        }
      ],
      stateData: [],
      locationData: [],
      loadingState: false,
      loadingLocation: false,
      loadingCategories: false,
      categoryData: this.initializeCategoryData(this.data?.category),
      time: null,
      time_open: null,
      menu_open: false,
      time_close: null,
      menu_close: false,
      showPassword: false,
      loading: false,
      errorData: "",
      activeAlert: false,
      colorAlert: "",
      dataModels: [],
      dataCountry: [],
      itemsGroup: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],

      disabled: true,

      menu2: false,
      date: null,
      register_disabled: false,
      updateImage: false
    };
  },

  created() {
    if (this.action == 0) {
      this.data.availability_date = moment().format("YYYY-MM-DD");
    }

    if (this.data.products_erp) {
      this.disabled = this.data.products_erp.length > 0 ? false : true;
    }

    this.HandlerGetCountry();

    this.HandlerGetCategories();

    this.data.volume =
      (this.data.length * this.data.width * this.data.height) / 1000000;
  },

  watch: {
    state_id(val) {
      this.HandlerGetLocations(val);
    }
  },

  computed: {
    getCategories() {
      return this.$store.getters["products/GET_CATEGORIES"];
    }
  },

  methods: {
    async HandlerGetCountry() {
      try {
        this.loading = true;

        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        console.log(response);

        this.dataCountry = response.data.data;
        // .filter((index) => index.id !== 3);

        if (this.action == 1) {
          if (this.data.products_erp.length > 0) {
            this.dataCountry = this.dataCountry.map(index => {
              let product = this.data.products_erp.find(
                item => item.country_id == index.id
              );

              if (product !== undefined) {
                return {
                  ...index,
                  ...product,
                  id: index.id,
                  sku: product.sku,
                  // country_id: product.id,
                  // currency: product.currency.symbol,
                  base_price: product.base_price,
                  fob1: product.fob1,
                  fob2: product.fob2,
                  tax: product.tax,
                  active_b2b: product.active_b2b,
                  active_b2c: product.active_b2c
                };
              } else {
                return {
                  ...index,
                  sku: "",
                  // country_id: product.id,
                  // currency: product.currency.symbol,
                  base_price: 0,
                  fob1: 0,
                  fob2: 0,
                  tax: 0,
                  active_b2b: false,
                  active_b2c: false
                };
              }
            });
          } else {
            this.brand_id = this.data.brand_id;
            this.product_id = this.data.id;
          }
          await this.HandlerGetGenerateSku();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetModels() {
      try {
        const request = { brand_id: this.data.brand_id };
        const response = await this.$store.dispatch(
          "products/GET_MODELS",
          request
        );
        this.dataModels = response.data.data;
        console.log("modelos", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetGenerateSku() {
      try {
        this.loading = true;

        const request = {
          brand_id: this.data.brand_id,
          product_id: this.data?.id ? this.data.id : ""
        };
        const response = await this.$store.dispatch(
          "products/GET_GENERATE_SKU",
          request
        );

        this.dataCountry = this.dataCountry.map(index => {
          const data = response.data.data.find(item => {
            return item.country_id == index.id;
          });
          if (data !== undefined) {
            index.sku = data.sku;
          }
          return index;
        });
        console.log("aqui estan", this.dataCountry);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    closeModal() {
      this.$emit("dialog:change", false);
    },

    HandlerAction() {
      if (this.action == 0) this.HandlerRegister();
      else this.HandlerUpdate();
    },

    async HandlerRegister() {
      try {
        this.loading = true;

        const products_erp = this.dataCountry.map(index => {
          return {
            sku: index.sku,
            country_id: index.id,
            // currency: index.currency.symbol,
            // base_price: index.base_price,
            tax: index.tax,
            active_b2b: index.active_b2b,
            // active_b2c: index.active_b2c,
            right: index.right,
            statistics: index.statistics
          };
        });

        const addRequestCategories = {
          id: this.categoryData.id,
          specifications: this.categoryData.specifications_values.map(spec => {
            return {
              id: spec.id,
              value: spec.value
            };
          })
        };

        const request = new FormData();

        if (this.data.images_publications !== undefined) {
          this.data.images_publications.forEach(file =>
            request.append("images_publications[]", file)
          );
        } else {
          request.append("images_publications[]", []);
        }
        request.append("products_erp", JSON.stringify(products_erp));
        // request.append("availability_date", this.data.availability_date);
        request.append("barcode", this.data.barcode);
        request.append("brand_id", this.data.brand_id);
        request.append("description", this.data.description);
        request.append("height", this.data.height);
        request.append("length", this.data.length);
        request.append("name", this.data.name);
        request.append("pattern_name", this.data.pattern_name);
        request.append("resume", this.data.resume);
        request.append("short_name", this.data.short_name);
        request.append(
          "sku",
          this.dataCountry.find(index => index.id == 1).sku
        );
        request.append("specs", this.data.specs);
        request.append("volume", this.data.volume);
        request.append("weight", this.data.weight);
        request.append("width", this.data.width);
        request.append("group", this.data.group);
        request.append("notes", this.data.notes);
        if (addRequestCategories?.id) {
          request.append("category", JSON.stringify(addRequestCategories));
        }

        // const request = {
        //   ...this.data,
        //   sku: this.dataCountry.find((index) => index.id == 1).sku,
        //   products_erp: this.dataCountry.map((index) => {
        //     return {
        //       sku: index.sku,
        //       country_id: index.id,
        //       currency: index.currency.symbol,
        //       base_price: index.base_price,
        //       tax: index.tax,
        //       active_b2b: index.active_b2b,
        //       active_b2c: index.active_b2c,
        //     };
        //   }),
        // };
        // console.log('request', request)
        const response = await this.$store.dispatch(
          "products/REGISTER_PRODUCTS",
          request
        );
        // console.log("response", response.data);
        this.data.id = response.data.data.id;
        this.$snotify.success("Productos creado con éxito.", "¡Hecho!");
        this.disabled = false;

        this.closeModal();
      } catch (error) {
        console.log("error", error);
        // this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
        this.register_disabled = true;
      }
    },

    async HandlerUpdate() {
      try {
        this.loading = true;
        const products_erp = this.dataCountry.map(index => {
          const {
            rights_percentage,
            statistics_percentage,
            cif_factor_percentage,
            fees_percentage,
            dispatch_expenses_percentage,
            misc_costs_percentage,
            country_tax_percentage,
            stock_months_coverage_default
          } = index;
          return {
            sku: index.sku,
            country_id: index.id,
            tax: index.tax,
            active_b2b: index.active_b2b,
            right: index.right,
            statistics: index.statistics,
            fob1: index.fob1,
            fob2: index.fob2,
            rights_percentage,
            statistics_percentage,
            cif_factor_percentage,
            fees_percentage,
            country_tax_percentage,
            misc_costs_percentage,
            dispatch_expenses_percentage,
            stock_months_coverage_default
          };
        });

        const deleteCategoryId =
          this.data?.category?.id !== this.categoryData?.id
            ? this.data?.category?.id
            : null;

        const addRequestCategories =
          this.data?.category?.id == this.categoryData?.id
            ? null
            : {
                id: this.categoryData?.id,
                specifications: this.categoryData?.specifications_values?.map(
                  spec => {
                    return {
                      id: spec?.id,
                      value: spec?.value
                    };
                  }
                )
              };

        const valuesChangingSpecifications = () => {
          if (this.data?.category?.id !== this.categoryData?.id) {
            return null;
          }
          const differentValues = this.categoryData?.specifications_values?.filter(
            elem => {
              const specification = this.data?.category?.specifications_values?.find(
                el => el?.specification?.id == elem?.id
              );
              return elem?.value !== specification?.value;
            }
          );
          return differentValues;
        };

        const requestSpecificationsValues =
          valuesChangingSpecifications()?.length > 0
            ? valuesChangingSpecifications().map(item => {
                const newValues = { id: item?.valueId, value: item?.value };
                return newValues;
              })
            : [];

        const requestDeleteCategory = {
          product_id: this.data?.id,
          data: { category: deleteCategoryId }
        };

        const request = new FormData();

        if (this.data.images_publications !== undefined) {
          this.data.images_publications.forEach(file =>
            request.append("images_publications[]", file)
          );
        } else {
          request.append("images_publications[]", []);
        }
        request.append("products_erp", JSON.stringify(products_erp));
        // request.append("availability_date", this.data.availability_date);
        request.append("barcode", this.data.barcode);
        request.append("brand_id", this.data.brand_id);
        request.append("description", this.data.description);
        request.append("height", this.data.height);
        request.append("length", this.data.length);
        request.append("name", this.data.name);
        request.append("pattern_name", this.data.pattern_name);
        request.append("resume", this.data.resume);
        request.append("short_name", this.data.short_name);
        request.append(
          "sku",
          this.dataCountry.find(index => index.id == 1).sku
        );
        request.append("specs", this.data.specs);
        request.append("volume", this.data.volume);
        request.append("weight", this.data.weight);
        request.append("width", this.data.width);
        request.append("group", this.data.group);
        request.append("notes", this.data.notes);
        if (addRequestCategories?.id) {
          request.append(
            "category_to_add",
            JSON.stringify(addRequestCategories)
          );
        }
        if (requestSpecificationsValues?.length > 0) {
          request.append(
            "specifications_values",
            JSON.stringify(requestSpecificationsValues)
          );
        }

        const requestdata = {
          request: request,
          id: this.data.id
        };

        if (requestDeleteCategory?.data?.category) {
          await this.$store.dispatch(
            "products/DELETE_CATEGORY_TO_PRODUCT",
            requestDeleteCategory
          );
        }

        await this.$store.dispatch("products/UPDATE_PRODUCTS", requestdata);
        this.$snotify.success("Productos editado con éxito.", "¡Hecho!");
        this.disabled = false;
        // this.closeModal();
      } catch (error) {
        console.log("error", error);
        // this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },

    async HandlerSyncErp(country) {
      try {
        let erps;
        if (country == "Argentina") {
          erps = "contabilium";
        } else {
          erps = "contabilium_cl";
        }

        this.loading = true;
        const request = {
          product_id: this.data.id,
          erps: [erps]
        };
        const response = await this.$store.dispatch(
          "products/SYNC_PRODUCTS",
          request
        );
        console.log("response", response);

        this.$snotify.success("Sincronizado con éxito.", "¡Hecho!");
        // this.closeModal();
      } catch (error) {
        this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },
    eventdialog() {
      this.activeAlert = false;
    },

    HandleVolumen() {
      this.data.volume =
        (this.data.length * this.data.width * this.data.height) / 1000000;
    },

    HandlerComercialDesignation() {
      console.log("**");
      const brand = this.dataBrand.find(
        index => index.id == this.data.brand_id
      );
      const text = `${brand.name} ${this.data.short_name}`;
      this.data.name = text.toUpperCase();
    },

    async addImagePublication() {
      try {
        this.loadingAdd = true;
        const request = new FormData();
        this.images_publications.forEach(file =>
          request.append("images_publications[]", file)
        );

        const response = await this.$store.dispatch(
          "products/ADD_PRODUCT_IMAGE",
          { data: request, id: this.dataUpdate.id }
        );

        const images = JSON.parse(response.data.data.images);

        const newImage = images[images.length - 1];

        this.dataUpdate.images.push(newImage);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAdd = false;
      }
    },

    async HandlerSaveImage(evt) {
      try {
        this.updateImage = true;
        const request = new FormData();
        request.append("images_publications[]", evt.target.files[0]);
        request.append("url_image", this.positionImage.url);
        request.append("publication_id", this.dataUpdate.id);

        const requestData = {
          publication_id: this.dataUpdate.id,
          data: request
        };
        const response = await this.$store.dispatch(
          "publications/UPLOAD_IMAGE",
          requestData
        );

        const newImage = JSON.parse(response.data.data.images)[
          this.positionImage.index
        ];

        this.dataUpdate.images.splice(this.positionImage.index, 1, newImage);

        this.$snotify.success("Imagen Cargada", "Exitos!");
      } catch (error) {
        console.log(error);
      } finally {
        this.updateImage = false;
      }
    },

    HandlerShowImage(item, index) {
      this.positionImage = { url: item, index: index };
      this.$refs.inputUpload.click();
    },

    async deleteImagePublication(image) {
      try {
        this.loadingAdd = true;
        const images = this.data.images;
        const deleteImage = images.indexOf(image);

        const request = {
          data: {
            url_image: image
          },
          id: this.data.id,
          position_delete: deleteImage,
          id_product: this.data.id
        };

        await this.$store.dispatch("products/DELETE_PRODUCT_IMAGE", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAdd = false;
      }
    },

    initializeCategoryData(categories) {
      if (categories) {
        const newCategory = {
          name: categories.name,
          id: categories.id,
          specifications_values: categories.specifications_values.map(el => {
            const specification = {
              id: el.specification.id,
              name: el.specification.name,
              measuring_unit: el.specification.measuring_unit,
              value: el.value,
              valueId: el.id
            };
            return specification;
          })
        };
        return newCategory;
      } else {
        return { name: "", id: null, specifications_values: [] };
      }
    },

    async HandlerGetCategories() {
      try {
        this.loadingCategories = true;
        const request = {
          paginate: false
        };
        await this.$store.dispatch("products/GET_CATEGORIES", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCategories = false;
      }
    },

    showSwitch(index) {
      if (
        this.categoryData?.specifications_values[index]?.measuring_unit ==
        "SI/NO"
      ) {
        return true;
      }
      return false;
    },

    selectCategory(categoryId) {
      const newCategory = this.getCategories.find(
        elem => elem.id == categoryId
      );
      const newSpecificationsValues = newCategory.specifications.map(elem => {
        const specification = {
          id: elem.id,
          name: elem.name,
          measuring_unit: elem.measuring_unit,
          value: null,
          valueId: null
        };
        return specification;
      });

      this.categoryData.name = newCategory.name;
      this.categoryData.id = newCategory.id;
      this.categoryData.specifications_values = newSpecificationsValues;
    },

    addCategory() {
      this.categoryData = { name: "", id: null, specifications_values: [] };
    },

    deleteCategory() {
      this.categoryData = { name: "", id: null, specifications_values: [] };
    }
  }
};
</script>
<style>
.subtitle-specification {
  color: #706dac;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
}
.btn-add {
  color: #2196f3;
  text-decoration: underline;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
}
.btn-add-box {
  margin-top: -22px;
  margin-bottom: 14px;
  z-index: 2;
}
.delete-box {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  padding-top: 13px !important;
}
</style>
