var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('alert',{attrs:{"data":_vm.errorData,"active":_vm.activeAlert,"colorAlert":_vm.colorAlert},on:{"dialog:change":_vm.eventdialog}}),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"\n            font-weight-medium\n            primary--text\n            mb-5\n            d-flex\n            justify-space-between\n          ",staticStyle:{"font-size":"26px"}},[(_vm.action == 0)?_c('span',[_vm._v(" Nuevo Producto ")]):_vm._e(),(_vm.action == 1)?_c('span',[_vm._v(" Editar Producto ")]):_vm._e(),(_vm.action == 2)?_c('span',[_c('div',[_vm._v(_vm._s(_vm.dataPublication.title))]),_c('p',[_vm._v(_vm._s(_vm.dataPublication.item_id))])]):_vm._e(),(_vm.action !== 2)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closeModal()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-left-bold")])],1):_vm._e()],1),_c('v-card',{staticClass:"mb-5"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Datos Generales ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
              bar: { background: '#bacfcec0', keepShow: true }
            }}},[_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"marca"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"items":_vm.dataBrand,"item-text":"name","item-value":"id","required":"","filled":"","rounded":"","dense":"","label":"Marca","name":"brand_id","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors},on:{"change":_vm.HandlerGetGenerateSku},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [(Object.keys(item).length > 0)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"selection",fn:function(ref){
            var item = ref.item;
return [(Object.keys(item).length > 0)?_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}}],null,true),model:{value:(_vm.data.brand_id),callback:function ($$v) {_vm.$set(_vm.data, "brand_id", $$v)},expression:"data.brand_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre","name":"name","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors},on:{"input":_vm.HandlerComercialDesignation},model:{value:(_vm.data.short_name),callback:function ($$v) {_vm.$set(_vm.data, "short_name", $$v)},expression:"data.short_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"modelo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Modelo","name":"model","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.pattern_name),callback:function ($$v) {_vm.$set(_vm.data, "pattern_name", $$v)},expression:"data.pattern_name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Código de barras"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Código de barras","name":"barcode","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.barcode),callback:function ($$v) {_vm.$set(_vm.data, "barcode", $$v)},expression:"data.barcode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"denominacion comercial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Denominación Comercial","name":"name","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Grupo","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.itemsGroup,"item-text":"title","item-value":"value","required":"","filled":"","rounded":"","dense":"","label":"Grupo","name":"show_b2b","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.group),callback:function ($$v) {_vm.$set(_vm.data, "group", $$v)},expression:"data.group"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"notas","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{staticClass:"rounded-small",attrs:{"rows":"1","filled":"","rounded":"","name":"description","label":"Notas","error-messages":errors},model:{value:(_vm.data.notes),callback:function ($$v) {_vm.$set(_vm.data, "notes", $$v)},expression:"data.notes"}})]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-text',[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Dimensiones ")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"peso","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Peso (grs)","name":"weight","prepend-inner-icon":"mdi-card-text","color":"blue-grey","type":"number","error-messages":errors},model:{value:(_vm.data.weight),callback:function ($$v) {_vm.$set(_vm.data, "weight", $$v)},expression:"data.weight"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Largo (cm)","name":"length","prepend-inner-icon":"mdi-card-text","color":"blue-grey","type":"number","error-messages":errors},on:{"input":_vm.HandleVolumen},model:{value:(_vm.data.length),callback:function ($$v) {_vm.$set(_vm.data, "length", $$v)},expression:"data.length"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"ancho","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Ancho (cm)","name":"width","prepend-inner-icon":"mdi-card-text","color":"blue-grey","type":"number","error-messages":errors},on:{"input":_vm.HandleVolumen},model:{value:(_vm.data.width),callback:function ($$v) {_vm.$set(_vm.data, "width", $$v)},expression:"data.width"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"profundidad","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Profundidad (cm)","name":"height","prepend-inner-icon":"mdi-card-text","color":"blue-grey","type":"number","error-messages":errors},on:{"input":_vm.HandleVolumen},model:{value:(_vm.data.height),callback:function ($$v) {_vm.$set(_vm.data, "height", $$v)},expression:"data.height"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-5"},[_c('v-card-text',[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Imagenes ")]),(_vm.action == 0)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"imagenes","rules":_vm.action == 0 ? 'image|size:2000' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-camera-image","multiple":"","label":"cargar imagenes","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.data.images_publications),callback:function ($$v) {_vm.$set(_vm.data, "images_publications", $$v)},expression:"data.images_publications"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.action !== 0)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12"}},[_c('v-sheet',{staticClass:"mx-auto",attrs:{"elevation":"0","max-width":"auto"}},[_c('v-slide-group',{staticClass:"pa-4",attrs:{"active-class":"primary","show-arrows":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},_vm._l((_vm.data.images),function(item,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var active = ref.active;
            var toggle = ref.toggle;
return [_c('v-card',{staticClass:"ma-4",attrs:{"color":active ? undefined : 'grey lighten-1',"height":"200","width":"100"},on:{"click":toggle}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[(!active && _vm.updateImage == false)?_c('v-img',{staticClass:"mx-5",attrs:{"contain":"","height":"200","width":"100","src":item,"lazy-src":item},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)}):_vm._e(),(!active && _vm.updateImage == true)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(active)?_c('v-icon',{attrs:{"color":"white","size":"48"},domProps:{"textContent":_vm._s('mdi-image-edit')},on:{"click":function($event){return _vm.HandlerShowImage(item, index)}}}):_vm._e(),(active)?_c('v-icon',{attrs:{"color":"white","size":"48"},domProps:{"textContent":_vm._s('mdi-image-remove')},on:{"click":function($event){return _vm.deleteImagePublication(item)}}}):_vm._e()],1)],1)]}}],null,true)})}),1),_c('v-row',[(_vm.action !== 2)?_c('v-col',{attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"name":"imagenes","rules":_vm.action == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-camera-image","multiple":"","label":"cargar imagenes","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.data.images_publications),callback:function ($$v) {_vm.$set(_vm.data, "images_publications", $$v)},expression:"data.images_publications"}})]}}],null,true)})],1):_vm._e()],1)],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"inputUpload",attrs:{"type":"file"},on:{"change":_vm.HandlerSaveImage}})],1)],1):_vm._e()],1)],1),(_vm.action !== 2)?_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Overview ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"resumen","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('wysiwyg',{model:{value:(_vm.data.resume),callback:function ($$v) {_vm.$set(_vm.data, "resume", $$v)},expression:"data.resume"}}),(errors.length > 0)?_c('div',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Descripción ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('wysiwyg',{model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}}),(errors.length > 0)?_c('div',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1),_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Especificaciones ")]),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('wysiwyg',{model:{value:(_vm.data.specs),callback:function ($$v) {_vm.$set(_vm.data, "specs", $$v)},expression:"data.specs"}}),(errors.length > 0)?_c('div',{staticStyle:{"color":"red","font-size":"12px"}},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)})],1)],1):_vm._e(),_c('v-card',{staticClass:"mb-5"},[(_vm.loadingCategories)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-text',[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Categoría ")]),_c('v-row',{staticStyle:{"margin":"0px"},attrs:{"dense":""}},[_c('v-col',{staticStyle:{"padding":"0px 12px"},attrs:{"cols":"10","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Nombre de categoría"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"label":"Selecciona una categoría","filled":"","rounded":"","dense":"","name":"Nombre de categoría","value":_vm.categoryData.id,"items":_vm.getCategories,"item-text":"name","item-value":"id","color":"blue-grey","error-messages":errors},on:{"input":function (elem) { return _vm.selectCategory(elem); }}})]}}],null,true)})],1),_c('v-col',{staticClass:"delete-box",attrs:{"cols":"2","sm":"8","md":"8"}},[_c('button',{on:{"click":function($event){$event.preventDefault();return (function () { return _vm.deleteCategory(); })($event)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-delete ")])],1)]),(_vm.categoryData.specifications_values.length > 0)?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('div',{staticClass:"subtitle-specification"},[_vm._v(" Especificaciones ")])]):_vm._e(),_vm._l((_vm.categoryData.specifications_values),function(specification,idx){return _c('v-row',{key:specification.id,staticStyle:{"margin":"0px"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":("Nombre de especificación " + (idx + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","disabled":"","label":"Nombre","name":("Nombre de especificación " + (idx + 1)),"type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.categoryData.specifications_values[idx].name),callback:function ($$v) {_vm.$set(_vm.categoryData.specifications_values[idx], "name", $$v)},expression:"categoryData.specifications_values[idx].name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":("Unidad de especificación " + (idx + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","disabled":"","label":"Unidad","name":("Unidad de especificación " + (idx + 1)),"type":"text","color":"blue-grey","error-messages":errors},model:{value:(
                        _vm.categoryData.specifications_values[idx].measuring_unit
                      ),callback:function ($$v) {_vm.$set(_vm.categoryData.specifications_values[idx], "measuring_unit", $$v)},expression:"\n                        categoryData.specifications_values[idx].measuring_unit\n                      "}})]}}],null,true)})],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","sm":"3","md":"3"}},[_c('ValidationProvider',{attrs:{"name":("Valor de especificación " + (idx + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [(_vm.showSwitch(idx))?_c('v-switch',{attrs:{"label":_vm.categoryData.specifications_values[idx].value
                          ? 'Sí'
                          : 'No'},model:{value:(_vm.categoryData.specifications_values[idx].value),callback:function ($$v) {_vm.$set(_vm.categoryData.specifications_values[idx], "value", $$v)},expression:"categoryData.specifications_values[idx].value"}}):_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Valor","name":("Valor de especificación " + (idx + 1)),"type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.categoryData.specifications_values[idx].value),callback:function ($$v) {_vm.$set(_vm.categoryData.specifications_values[idx], "value", $$v)},expression:"categoryData.specifications_values[idx].value"}})]}}],null,true)})],1),(_vm.categoryData.specifications_values[idx].value == null)?_c('v-col',{attrs:{"cols":"12","sm":"1","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mt-3"},'div',attrs,false),on),[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"#f44","d":"M20 17h2v-2h-2v2m0-10v6h2V7M4 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8l-6-6m-1 1.5L16.5 9H11Z"}})])])]}}],null,true)},[_c('span',[_vm._v("Por favor agregue el valor a la especificación")])])],1):_vm._e()],1)})],2),(_vm.categoryData.length < 1)?_c('v-col',{staticClass:"btn-add-box",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn-add",on:{"click":function($event){$event.preventDefault();return _vm.addCategory($event)}}},[_vm._v(" Agregar Categoría ")])]):_vm._e()],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-row',{staticClass:"mb-4",attrs:{"justify":"end"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":_vm.closeModal}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.register_disabled,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerAction)}}},[_vm._v(" "+_vm._s(_vm.action == 0 ? "Registrar" : "Editar")+" ")])]}}],null,true)})],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }