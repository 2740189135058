<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div
          class="font-weight-medium primary--text mb-5"
          style="font-size: 26px"
        >
          Ventas no completadas
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="store_id"
          :items="data_stores"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Venta"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="meli_order_id"
          @keyup.enter="HandlerGetOrderNC(page)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-2 my-1" justify="space-between">
      <v-col cols="12" md="4" class="d-flex justify-start"> </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          :loading="loadingStore"
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetOrderNC(page)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn :loading="loadingStore" fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :loading="loadingTable"
          loading-text="Cargando... Por favor espere"
          :headers="headers"
          :items="getOrders"
          hide-default-footer
          disable-pagination
          fixed-header
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.meli_id`]="{ item }">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ item.meli_id }}</v-list-item-title>
                <div style="color: rgba(0, 0, 0, 0.6)">
                  {{ item.buyer.first_name }}
                  {{ item.buyer.last_name }}
                </div>
                <!-- <v-list-item-subtitle>{{
                  item.buyer.email
                }}</v-list-item-subtitle> -->
                <v-list-item-subtitle
                  >{{ item.buyer.doc_type }}:
                  {{ item.buyer.doc_number }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:[`item.shipping`]="{ item }">
            <v-list-item two-line>
              <v-list-item-content>
                <div v-if="item.store_id == 0">
                  <v-list-item-title>
                    <div v-if="item.meli_shipping_id !== null">Tiene Envio</div>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.meli_shipping_id !== null">{{
                    item.shipping == null
                      ? "No se genero el envio"
                      : item.shipping.shipment_type
                  }}</v-list-item-subtitle>
                </div>
                <div v-else>
                  <v-list-item-title>{{
                    item.store_pickup
                      ? "Retiro en tienda"
                      : "Entrega a domicilio"
                  }}</v-list-item-title>
                  <v-list-item-subtitle v-if="!item.store_pickup">{{
                    item.shipping == null
                      ? "No se genero el envio"
                      : item.shipping.shipment_type
                  }}</v-list-item-subtitle>
                </div>
              </v-list-item-content>

              <v-list-item-action v-if="item.store_id == 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="loadingShipping"
                      @click="HandlerShippingML(item)"
                      color="#FFA000"
                      v-if="
                        item.meli_shipping_id !== null && item.shipping == null
                      "
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>mdi-truck-cargo-container</v-icon>
                    </v-btn>
                  </template>
                  <span>Generar Envio</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="loadingShipping"
                      @click="HandlerShippingStore(item)"
                      color="#FFA000"
                      v-if="!item.store_pickup && item.shipping == null"
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon>mdi-truck-cargo-container</v-icon>
                    </v-btn>

                    <!-- <v-icon
                      @click="HandlerShippingStore(item)"
                      color="#FFA000"
                      v-if="!item.store_pickup && item.shipping == null"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-truck-cargo-container
                    </v-icon> -->
                  </template>
                  <span>Generar Envio</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
          <template v-slot:[`item.order_status`]="{ item }">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-capitalize">
                  {{ item.order_status | orderStatus }}
                </v-list-item-title>
                <v-list-item-title>
                  {{ item.date_created | date }}
                </v-list-item-title>
                <span v-if="item.order_item.length == 0" style="color: red"
                  >No tiene producto</span
                >
                <div v-if="item.payment.length > 0">
                  <v-list-item-subtitle
                    v-if="item.payment[0].payment_type_id == 'bank_transfer'"
                    >Pago con transferencia</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    v-if="item.payment[0].payment_type_id == 'bank_transfer'"
                    >{{
                      item.payment[0].voucher_url == null
                        ? "no cargo voucher "
                        : "voucher  cargado  "
                    }}</v-list-item-subtitle
                  >
                </div>
              </v-list-item-content>
              <v-list-item-action v-if="item.order_item.length == 0">
                <v-tooltip bottom v-if="item.store_id === 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="HandlerShowRegisterSku(item)"
                      color="#AFB42B"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-cart-variant
                    </v-icon>
                  </template>
                  <span>Agregar Producto</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
          <template v-slot:[`item.order_invoice`]="{ item }">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title
                  >Cantidad de facturas emitidas:
                  <span v-if="item.store_id > 0">
                    {{ item.order_invoice.length }}
                  </span>
                  <span v-else>
                    {{ item.bill_number == null ? 0 : 1 }}
                  </span></v-list-item-title
                >
                <span v-if="item.store_id > 0">
                  <v-list-item-subtitle v-if="item.order_invoice.length == 0"
                    >No se ha generado factura
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-for="(invoice, i) in item.order_invoice"
                    :key="i"
                  >
                    <v-list-item-title
                      >Deposito: {{ invoice.warehouse.name }}</v-list-item-title
                    >
                    <span v-if="invoice.bill_number == null"
                      >Factura no emitida
                    </span>
                    <span
                      v-else-if="
                        invoice.bill_number === '0000000000' ||
                          invoice.bill_number === '0012-00000000'
                      "
                    >
                      Factura en borrador
                    </span>
                    <span v-else>
                      {{ invoice.bill_number }}
                    </span>
                  </v-list-item-subtitle>
                </span>
                <span v-else>
                  <span v-if="item.bill_number == null">
                    Factura no emitida
                  </span>
                  <span
                    v-else-if="
                      item.bill_number === '0000000000' ||
                        item.bill_number === '0012-00000000'
                    "
                  >
                    Factura en borrador
                  </span>
                </span>
              </v-list-item-content>

              <v-list-item-action v-if="item.store_id > 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="loadingInvoice"
                      @click="HandlerInvoiceMP(item)"
                      color="#26C6DA"
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.order_invoice.length == 0"
                      icon
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Facturar</span>
                </v-tooltip>

                <div v-for="(invoice, i) in item.order_invoice" :key="i">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :loading="loadingInvoice"
                        @click="HandlerInvoiceMP(item)"
                        color="#26C6DA"
                        v-bind="attrs"
                        v-on="on"
                        v-if="
                          invoice.bill_number == null ||
                            invoice.bill_number === '0000000000' ||
                            invoice.bill_number === '0012-00000000'
                        "
                        icon
                      >
                        <v-icon>mdi-clipboard-text</v-icon>
                      </v-btn>
                    </template>
                    <span>Facturar</span>
                  </v-tooltip>
                </div>
              </v-list-item-action>
              <v-list-item-action v-else>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :loading="loadingInvoice"
                      @click="HandlerInvoiceML(item)"
                      color="#26C6DA"
                      v-bind="attrs"
                      v-on="on"
                      v-if="
                        item.bill_number == null ||
                          item.bill_number === '0000000000' ||
                          item.bill_number === '0012-00000000'
                      "
                      icon
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Facturar</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>

            <!-- <div>
              Cantidad de facturas emitidas: {{ item.order_invoice.length }}
            </div>
            <div v-if="item.order_invoice.length == 0">
              No se ha generado factura
            </div>
            <div v-for="(invoice, i) in item.order_invoice" :key="i">
              <div v-if="invoice.bill_number == null">No se genero factura</div>
              <div
                v-else-if="
                  invoice.bill_number === '0000000000' ||
                  invoice.bill_number === '0012-00000000'
                "
              >
                Factura en borrador
              </div>
              <div v-else>
                {{ invoice.bill_number }}
              </div>
            </div> -->
          </template>
          <template v-slot:[`item.invoice_error`]="{ item }">
            <v-list-item two-line>
              <v-list-item-content>
                <span v-if="item.invoice_error == null">
                  No hay error de facturación
                </span>
                <span v-else>
                  <v-list-item-title>{{
                    item.invoice_error.message
                  }}</v-list-item-title>
                  <div style="color: rgba(0, 0, 0, 0.6)">
                    {{ item.invoice_error.updated_at }}
                  </div>
                </span>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <v-pagination
          v-model="page"
          :length="paginate.lastPage"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
    <v-dialog v-model="showRegisterSku" max-width="400">
      <v-card>
        <v-card-title>Ingresa el SKU del producto</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="sku"
            filled
            rounded
            label="Indica el SKU"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="mt-n10">
            <v-btn
              color="secondary"
              @click="showRegisterSku = false"
              class="mr-2"
            >
              Cancelar
            </v-btn>
            <v-btn color="primary" @click="HandlerRegisterProduct"
              >Enviar</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      loadingTable: false,
      loadingShipping: false,

      getOrders: [],
      data_stores: [
        {
          id: 5,
          name: "B2B Argentina"
        },
        {
          id: 6,
          name: "B2B Chile"
        },
        {
          id: 10,
          name: "B2b Mexico"
        },
        {
          id: 3,
          name: "EDIFIER"
        },
        {
          id: 7,
          name: "Edifier Chile"
        },
        {
          id: 1,
          name: "Stadio Argentina"
        },
        {
          id: 13,
          name: "Stadio Chile"
        },
        {
          id: 11,
          name: "Stadio Mexico"
        },
        {
          id: 0,
          name: "Mercado Libre"
        }
      ],

      headers: [
        {
          text: "#Venta",
          align: "start",
          sortable: false,
          value: "meli_id",
          class: "primary--text",
          width: "20px"
        },
        {
          text: "Estatus de venta",
          align: "start",
          sortable: false,
          value: "order_status",
          class: "primary--text"
        },
        {
          text: "Facturación",
          align: "start",
          sortable: false,
          value: "order_invoice",
          class: "primary--text"
        },
        {
          text: "Errores de facturación",
          align: "start",
          sortable: false,
          value: "invoice_error",
          class: "primary--text"
        },
        {
          text: "Envio",
          align: "start",
          sortable: false,
          value: "shipping",
          class: "primary--text"
        }
      ],

      paginate: {},
      page: 1,
      per_page: 10,
      store_id: 0,
      meli_order_id: "",
      loadingInvoice: false,
      showRegisterSku: false,
      loadingSKU: false,
      sku: "",
      order_id: null
    };
  },

  created() {
    this.HandlerGetOrderNC(this.page);
    // this.HandlerGetStore();
  },

  watch: {
    page() {
      this.HandlerGetOrderNC(this.page);
    },
    store_id() {
      this.page = 1;
      this.HandlerGetOrderNC(this.page);
    }
  },

  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    },

    orderStatus(val) {
      switch (val) {
        case "paid":
          return "pagada";
        case "pending":
          return "pendiente";
        case "cancelled":
          return "cancelada";
        default:
          break;
      }
    }
  },

  methods: {
    async HandlerGetOrderNC(page = 1) {
      this.loadingTable = true;
      this.loadingStore = true;

      try {
        const request = {
          page: page,
          per_page: this.per_page,
          store_id: this.store_id,
          meli_order_id: this.meli_order_id
        };
        const response = await this.$store.dispatch(
          "orders/GET_ORDERS_NOT_COMPLETED",
          request
        );
        this.getOrders = response.data.data.data;

        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
        this.loadingStore = false;
      }
    },

    async HandlerShowRegisterSku(order) {
      this.order_id = order.id;
      this.showRegisterSku = true;
    },

    async HandlerRegisterProduct() {
      try {
        this.loadingSKU = true;
        const request = {
          sku: this.sku,
          order_id: this.order_id
        };
        await this.$store.dispatch("orders/REGISTER_PRODUCT", request);
        this.sku = "";
        this.order_id = null;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSKU = false;
      }
    },

    async HandlerInvoiceMP(order) {
      try {
        this.loadingInvoice = true;
        const request = {
          order_id: order.id
        };
        await this.$store.dispatch("orders/INVOICE_MP", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingInvoice = false;
      }
    },

    async HandlerShippingStore(order) {
      try {
        this.loadingShipping = true;
        const request = {
          order_id: order.id
        };
        await this.$store.dispatch("orders/SHIPPING_STORE", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingShipping = false;
      }
    },

    async HandlerShippingML(order) {
      try {
        this.loadingShipping = true;
        const request = {
          order_id: order.id
        };
        await this.$store.dispatch("orders/SHIPPING_ML", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingShipping = false;
      }
    },

    async HandlerInvoiceML(order) {
      try {
        this.loadingInvoice = true;
        const request = {
          order_id: order.id
        };
        await this.$store.dispatch("orders/INVOICE_ML", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingInvoice = false;
      }
    },

    HandlerReset() {
      this.store_id = 0;
      this.meli_order_id = "";
      this.page = 1;
      this.HandlerGetOrderNC(this.page);
    }
  }
};
</script>

<style></style>
