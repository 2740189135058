var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-medium primary--text mb-5",staticStyle:{"font-size":"26px"}},[_vm._v(" "+_vm._s(_vm.status_card ? "Listados de marcas" : "Registrar Marca")+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.status_card)?_c('v-btn',{staticClass:"accent shadow-4 px-8",attrs:{"large":"","rounded":""},on:{"click":function($event){return _vm.registerBrand({})}}},[_vm._v(" Añadir Marca ")]):_vm._e()],1)],1),(_vm.status_card)?_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadow-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"height":"700","fixed-header":"","headers":_vm.headers,"items":_vm.dataCategory,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50"}},[_c('img',{attrs:{"src":item.image_url}})]),_vm._v(" "+_vm._s(item.name)+" ")],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',[(!_vm.loadingFilePhoto)?_c('v-file-input',{staticClass:"mb-4",attrs:{"multiple":"","label":"File input","hide-input":"","prepend-icon":"mdi-image-album"},on:{"change":function($event){return _vm.HandlerUploadPhoto(item)}},model:{value:(_vm.filePhoto),callback:function ($$v) {_vm.filePhoto=$$v},expression:"filePhoto"}}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),(_vm.action == '0')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.HandlerDialogEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e()],1)]}}],null,true)})],1),_c('v-dialog',{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Editar marcas ")]),_c('v-card-text',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"nombre","filled":"","rounded":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"label":"codigo","filled":"","rounded":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.HandlerEdit()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.showRegisterModel),callback:function ($$v) {_vm.showRegisterModel=$$v},expression:"showRegisterModel"}},[_c('v-card',[_c('v-card-text',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small pt-5",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre","name":"name","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"imgen","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Imagen","name":"name","color":"blue-grey","prepend-icon":"","error-messages":errors},model:{value:(_vm.data.image),callback:function ($$v) {_vm.$set(_vm.data, "image", $$v)},expression:"data.image"}})]}}],null,true)}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"red--text",attrs:{"text":""},on:{"click":function($event){_vm.showRegisterModel = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"primary",on:{"click":function($event){return passes(_vm.handlerRegister)}}},[_vm._v(" Guardar ")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }