<template>
  <v-container ref="container">
    <v-row>
      <v-col style="flex-grow: 0">
        <v-btn color="primary" @click="getNotifications(false)">
          Filtrar
        </v-btn>
      </v-col>
      <v-col style="flex-grow: 0">
        <v-btn color="primary" @click="resetFilters()">
          Reiniciar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-select
          label="Evento"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="filters.event"
          :items="
            Object.entries(notificationsTypes).map(([key, value]) => ({
              name: value,
              id: key
            }))
          "
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Pais"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="filters.countryId"
          :items="countrys"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="filters.storeId"
          :items="stores"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mb-5 " style="margin-top: -25px">
      <v-col>
        <v-text-field
          v-model="filters.meli_id"
          label="ID"
          filled
          rounded
          dense
          class="rounded-small"
          name="id"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-menu
          v-model="openFrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.from"
              label="Desde"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="filters.from"
            @input="openFrom = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="openTo"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filters.to"
              label="Hasta"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="filters.to"
            @input="openTo = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <!-- <v-col>
        <v-text-field
          label="Sku"
          v-model="filters.sku"
          filled
          rounded
          dense
          class="rounded-small"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Min rent"
          v-model="filters.minRent"
          filled
          rounded
          dense
          class="rounded-small"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Max rent"
          v-model="filters.maxRent"
          type="number"
          filled
          rounded
          dense
          class="rounded-small"
        ></v-text-field>
      </v-col> -->
    </v-row>

    <!-- <v-progress-linear
      v-if="loading === true"
      indeterminate
      color="primary"
      rounded
      height="6"
    ></v-progress-linear> -->
    <v-data-table
      :headers="headers"
      :items="notifications.data"
      :loading="loading"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :server-items-length="notifications.total"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50]
      }"
      class="elevation-1 small-table-body"
    >
      <template v-slot:[`item.id`]="{ item }">
        <span>
          {{ item.context.item_id || item.context.id }}
        </span>
      </template>
      <template v-slot:[`item.storeName`]="{ item }">
        <span>
          {{
            (item.context.store_name && item.context.store_name.name) ||
              item.context.store_name
          }}
        </span>
      </template>
      <template v-slot:[`item.topic`]="{ item }">
        <span>
          {{ notificationsTypes[item.topic] }}
        </span>
      </template>
      <template v-slot:[`item.prevPvp`]="{ item }">
        <span>
          {{ item.context.pvp && item.context.pvp.prev | currencyValue }}
        </span>
      </template>
      <template v-slot:[`item.currPvp`]="{ item }">
        <span>
          {{ item.context.pvp && item.context.pvp.curr | currencyValue }}
        </span>
      </template>
      <template v-slot:[`item.prevRent`]="{ item }">
        <span
          :style="{
            color: getRentabilityStatusColor(
              item.context.rentStatus && item.context.rentStatus.prev
            ),
            'font-weight': 500
          }"
        >
          {{ item.context.rentStatus && item.context.rentStatus.prev }}
        </span>
      </template>
      <template v-slot:[`item.currRent`]="{ item }">
        <span
          :style="{
            color: getRentabilityStatusColor(
              item.context.rentStatus && item.context.rentStatus.curr
            ),
            'font-weight': 500
          }"
        >
          {{ item.context.rentStatus && item.context.rentStatus.curr }}
        </span>
      </template>
      <template v-slot:[`item.catalogPrev`]="{ item }">
        <span
          :style="{
            color: getCatalogStatusColor(
              item.context.catalog && item.context.catalog.prev
            ),
            'font-weight': 500
          }"
        >
          {{
            item.context.catalog && item.context.catalog.prev | catalogStatus
          }}
        </span>
      </template>
      <template v-slot:[`item.catalogCurr`]="{ item }">
        <span
          :style="{
            color: getCatalogStatusColor(
              item.context.catalog && item.context.catalog.curr
            ),
            'font-weight': 500
          }"
        >
          {{
            item.context.catalog && item.context.catalog.curr | catalogStatus
          }}
        </span>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <v-dialog v-model="dialog" v-if="item.context.statusByMethod">
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              style="color: blue; text-decoration: underline"
            >
              {{ item.context.title }}
            </span>
          </template>
          <v-data-table
            :headers="paymentMethodsHeaders"
            :items="item.context.statusByMethod"
            hide-default-footer
          >
            <template v-slot:[`item.prevRent`]="{ item }">
              <span
                :style="{
                  color: getRentabilityStatusColor(item.prev.status),
                  'font-weight': 500
                }"
              >
                {{ item.prev.status }}
              </span>
            </template>
            <template v-slot:[`item.currRent`]="{ item }">
              <span
                :style="{
                  color: getRentabilityStatusColor(item.curr.status),
                  'font-weight': 500
                }"
              >
                {{ item.curr.status }}
              </span>
            </template>
            <!-- <template v-slot:[`item.method`]="{ methodItem }">
              <span>
                {{ methodItem.prev.method }}
              </span>
            </template> -->
          </v-data-table>
        </v-dialog>
        <span v-else>
          {{ item.context.title }}
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      totalItems: 0,
      dialog: false,
      loading: false,
      page: 1,
      openFrom: false,
      openTo: false,
      itemsPerPage: 10,
      filters: {
        countryId: 4,
        storeId: 0,
        event: "ALL",
        from: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
        meli_id: ""
      },
      notificationsTypes: {
        RENT_CHANGE_ML: "Cambio de rentabilidad ML",
        RENT_CHANGE_FLEX: "Cambio de rentabilidad Flex",
        CATALOG_STATUS_CHANGE: "Cambio de status catálogo",
        PAUSED_ML: "Publicación pausada ML",
        PAUSED_FLEX: "Publicación pausada Flex",
        PAUSED_ML_AUTO: "Publicación pausada ML (Automático)",
        PAUSED_FLEX_AUTO: "Publicación pausada Flex (Automático)",
        PRICE_MODIF_ML_AUTO:
          "Modificación precio de publicación ML (Automático)",
        PROMO_APPLIED_ML_AUTO:
          "Promoción aplicada a publicación ML (Automático)",
        PRICE_INCREASE_ML_AUTO:
          "Aumento de precio, publicación de catálogo (Automático)",
        PRICE_CHANGE_ML: "Cambio de precio",
        ALL: "Todos"
      },

      stores: [
        {
          name: "B2B Argentina",
          id: 5
        },
        {
          name: "B2B Chile",
          id: 6
        },
        {
          name: "Edifier ARG",
          id: 3
        },
        {
          name: "Edifier CL",
          id: 7
        },
        {
          name: "Stadio ARG",
          id: 1
        },
        {
          name: "Stadio CL",
          id: 13
        },
        {
          name: "ML MX Global",
          id: 758172970
        },
        {
          name: "ML CL GLobal",
          id: "1054293185"
        },
        {
          name: "ML ARG",
          id: 154314631
        },
        {
          name: "ML CL",
          id: 158291322
        },
        {
          name: "Todos",
          id: 0
        }
      ],
      countrys: [
        {
          name: "Mexico",
          id: 3
        },
        {
          name: "Argentina",
          id: 1
        },
        {
          name: "Chile",
          id: 2
        },
        {
          name: "Todos",
          id: 4
        }
      ],
      paymentMethodsHeaders: [
        {
          text: "Metodo",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "prev.method"
        },
        {
          text: "Prev rent",
          class: "small-table-header green-background",
          align: "left",
          sortable: false,
          value: "prevRent"
        },
        {
          text: "Curr rent",
          class: "small-table-header green-background",
          align: "left",
          sortable: false,
          value: "currRent"
        },
        {
          text: "Prev pvp",
          class: "small-table-header blue-background",
          align: "left",
          sortable: false,
          value: "prev.pvp"
        },
        {
          text: "Curr pvp",
          class: "small-table-header blue-background",
          align: "left",
          sortable: false,
          value: "curr.pvp"
        },
        {
          text: "Prev desc",
          class: "small-table-header orange-background",
          align: "left",
          sortable: false,
          value: "prev.discount"
        },
        {
          text: "Curr desc",
          class: "small-table-header orange-background",
          align: "left",
          sortable: false,
          value: "curr.discount"
        }
      ],
      headers: [
        {
          text: "Fecha",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Evento",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "topic"
        },
        {
          text: "Tienda",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "storeName"
        },
        {
          text: "item id",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "id"
        },
        {
          text: "Publicacion",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "title"
        },
        {
          text: "SKU",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "context.sku"
        },
        {
          text: "Prev rent",
          class: "small-table-header green-background",
          align: "left",
          sortable: false,
          value: "prevRent"
        },
        {
          text: "Curr rent",
          class: "small-table-header green-background",
          align: "left",
          sortable: false,
          value: "currRent"
        },
        {
          text: "Prev pvp",
          class: "small-table-header blue-background",
          align: "left",
          sortable: false,
          value: "prevPvp"
          // value: "context.pvp.prev"
        },
        {
          text: "Curr pvp",
          class: "small-table-header blue-background",
          align: "left",
          sortable: false,
          // value: "context.pvp.curr"
          value: "currPvp"
        },
        {
          text: "Prev catalog",
          class: "small-table-header yellow-background",
          align: "left",
          sortable: false,
          value: "catalogPrev"
        },
        {
          text: "Curr catalog",
          class: "small-table-header yellow-background",
          align: "left",
          sortable: false,
          value: "catalogCurr"
        },
        {
          text: "Prev desc",
          class: "small-table-header orange-background",
          align: "left",
          sortable: false,
          value: "context.discount.prev"
        },
        {
          text: "Curr desc",
          class: "small-table-header orange-background",
          align: "left",
          sortable: false,
          value: "context.discount.curr"
        },
        {
          text: "Prev status",
          class: "small-table-header ",
          align: "left",
          sortable: false,
          value: "context.isActive.prev"
        },
        {
          text: "Curr status",
          class: "small-table-header ",
          align: "left",
          sortable: false,
          value: "context.isActive.curr"
        },
        {
          text: "Link",
          class: "small-table-header gray-background",
          align: "left",
          sortable: false,
          value: "context.link"
        }
      ]
    };
  },
  async created() {
    this.getNotifications();
  },

  methods: {
    resetFilters() {
      this.filters = {
        countryId: 4,
        storeId: 0,
        event: "ALL",
        from: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      };
      this.getNotifications();
    },
    async getNotifications(pageChange = false) {
      this.loading = true;
      if (!pageChange) {
        this.page = 1;
      }
      const page = this.page;
      const itemsPerPage = this.itemsPerPage;
      const { countryId, storeId, event, from, to, meli_id } = this.filters;
      const payload = {
        page,
        per_page: itemsPerPage,
        country_id: countryId,
        store_id: storeId,
        topic: event,
        from_date: from,
        to_date: to,
        ml_item_id: meli_id
      };
      if (countryId === 4) {
        delete payload.country_id;
      }
      if (storeId === 0) {
        delete payload.store_id;
      }
      if (event === "ALL") {
        delete payload.topic;
      }
      if (!meli_id) {
        delete payload.ml_item_id;
      }
      await this.$store.dispatch("publications/GET_NOTIFICATIONS", payload);
      this.loading = false;
    },
    getRentabilityStatusColor(value) {
      const statusMap = {
        "No rentable": "red",
        Rentable: "green",
        "Sobre precio": "#fc9803"
      };
      return statusMap[value] || "black";
    },
    getCatalogStatusColor(value) {
      const statusMap = {
        winning: "green",
        not_listed: "black",
        listed: "red",
        competing: "red",
        sharing_first_place: "blue"
      };

      return statusMap[value] || "black";
    }
  },

  watch: {
    page: {
      handler() {
        this.getNotifications(true);
      }
    },
    itemsPerPage: {
      handler() {
        this.getNotifications(false);
      }
    }
  },

  filters: {
    currencyValue(value) {
      if (value === undefined) {
        return;
      }
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(Number(value).toFixed(2));
      return AMOUNT_FORMAT;
    },
    catalogStatus(value) {
      const statusMap = {
        winning: "Ganando",
        not_listed: "Sin listar",
        listed: "Perdiendo",
        competing: "Perdiendo",
        sharing_first_place: "Compitiendo"
      };

      return statusMap[value] || value;
    }
  },

  computed: {
    notifications() {
      const data = this.$store.getters["publications/GET_NOTIFICATIONS"];

      return {
        data: data.data,
        total: Number(data.total)
      };
    }
  }
};
</script>
