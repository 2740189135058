<template>
  <v-container fluid class="mt-5">
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-row>
        <v-col md="12">
          <v-row class="justify-between align-center mb-5" v-if="!showRegister">
            <v-col cols="12" md="3" class="py-0">
              <v-btn
                rounded
                dark
                block
                large
                @click="HandlerUpdateFill()"
                class="accent shadow-4 px-8"
              >
                Añadir Imagen
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="!showRegister">
        <v-col cols="12" md="3">
          <v-select
            label="Tienda"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="store_id"
            :items="data_stores"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>

        <v-col cols="12" md="3">
          <v-select
            label="Sección"
            item-text="label"
            item-value="value"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="typePromotion"
            :items="typePromotionItems"
          ></v-select>
        </v-col>
      </v-row>

      <div
        class="font-weight-medium primary--text mb-5"
        style="font-size: 26px"
        v-if="showRegister"
      >
        {{ update ? "Editar" : "Crear" }} Imagen
      </div>
      <v-card class="mb-5">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          rounded
          height="6"
        ></v-progress-linear>

        <vuescroll
          :ops="{
            bar: { background: '#bacfcec0', keepShow: true },
          }"
        >
          <v-card-text>
            <v-form>
              <v-row dense>
                <v-col cols="12" sm="12" md="12" v-if="!showRegister">
                  <v-card-title class="font-weight-light primary--text">
                    Imagenes Cargadas
                  </v-card-title>

                  <v-data-table
                    :headers="headers"
                    :items="dataImages"
                    :items-per-page="-1"
                    hide-default-footer
                    class="elevation-0"
                  >
                    <template v-slot:[`item.image_url`]="{ item }">
                      <v-avatar tile size="50">
                        <v-img contain :src="item.image_url"></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
                      <v-btn icon @click="HandlerUpdate(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="HandlerDelete(item.id)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col cols="12" sm="12" md="8" v-if="showRegister">
                  <!-- <v-card-title class="font-weight-light primary--text">
                    Se debe cargar una imagen y el texto de la misma
                  </v-card-title> -->
                  <v-row>
                    <v-col cols="12" sm="12" md="6" class="pb-0">
                      <!-- tipo -->
                      <ValidationProvider
                        name="tienda"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="data_stores"
                          item-text="name"
                          item-value="id"
                          v-model="store_id"
                          small-chips
                          label="Tienda"
                          filled
                          rounded
                          dense
                          no-data-text="No hay datos para mostrar"
                          clearable
                          :error-messages="errors"
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" class="pb-0">
                      <!-- tipo -->
                      <ValidationProvider
                        name="section"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="typePromotionItems"
                          item-text="label"
                          item-value="value"
                          filled
                          rounded
                          dense
                          label="Sección"
                          v-model="type"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" class="pb-0">
                      <!-- tipo -->
                      <ValidationProvider
                        name="displayDevice"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="['Desktop', 'Mobile']"
                          filled
                          rounded
                          dense
                          label="Dispositivo"
                          v-model="device"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" class="pb-0">
                      <!-- imagen -->
                      <ValidationProvider
                        name="imagen"
                        :rules="update ? '' : 'required'"
                        v-slot="{ errors }"
                      >
                        <v-file-input
                          @change="onFileChange"
                          prepend-icon
                          prepend-inner-icon="mdi-camera-image"
                          label="Cargar Imagen"
                          v-model="image_promotion"
                          filled
                          rounded
                          dense
                          :error-messages="errors"
                        ></v-file-input>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      class="pb-0"
                      v-if="type == 'Categorias' && store_id != undefined"
                    >
                      <!-- imagen -->
                      <ValidationProvider name="imagen" v-slot="{ errors }">
                        <v-select
                          :items="data_categories"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          label="Categorias"
                          v-model="categories"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" sm="12" md="6" class="pb-0">
                      <!-- posicion -->
                      <ValidationProvider
                        name="posicion"
                        rules="numeric|required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          filled
                          rounded
                          dense
                          label="Posicion"
                          v-model="position"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col
                      v-if="type == 'Carrusel'"
                      cols="12"
                      sm="12"
                      md="12"
                      class="pb-0 d-flex"
                    >
                      <v-row>
                        <v-col cols="12" sm="12" md="6" class="pb-0">
                          <!-- carcateristicas -->
                          <ValidationProvider
                            name="features"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              filled
                              rounded
                              dense
                              label="Caracteristicas"
                              v-model="features"
                              :error-messages="errors"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" class="pb-0">
                          <v-btn color="primary" @click="HandlerAddFeatures">
                            agregar caracteristicas
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" sm="12" md="8">
                      <v-alert
                        dense
                        border="left"
                        type="info"
                        v-if="itemsFeatures.length == 4"
                      >
                        Maximo 4 caracteristicas
                      </v-alert>
                      <v-list-item
                        v-for="(item, index) in itemsFeatures"
                        :key="index"
                      >
                        <v-list-item-content>
                          <v-list-item-title>{{ item }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon @click="HandlerSubFeatures(index)">
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" class="pt-0" v-if="type != 'Categorias'">
                      <ValidationProvider name="url" v-slot="{ errors }">
                        <v-text-field
                          filled
                          rounded
                          dense
                          label="Url"
                          v-model="url_promotions"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-btn
                    v-if="showRegister && !update"
                    @click="passes(HandlerRegister)"
                    color="primary"
                  >
                    Cargar
                  </v-btn>
                  <v-btn
                    v-if="showRegister && update"
                    @click="passes(HandlerUpdatedUploadImage)"
                    color="primary"
                  >
                    Actualizar
                  </v-btn>
                  <v-btn
                    @click="
                      () => {
                        showRegister = !showRegister;
                        update = !update;
                      }
                    "
                    color="secondary"
                    class="ml-1"
                  >
                    Cancelar
                  </v-btn>
                </v-col>

                <v-col
                  cols="4"
                  sm="12"
                  md="4"
                  v-if="showRegister"
                  class="d-flex justify-center"
                >
                  <v-card
                    v-if="url"
                    :loading="loading"
                    class="mx-start mb-2 elevation-0"
                    max-width="374"
                    height="auto"
                  >
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>

                    <v-img height="auto" :src="url"></v-img>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </vuescroll>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import vuescroll from "vuescroll";
export default {
  components: { vuescroll },
  data() {
    return {
      loading: false,
      showRegister: false,
      update: false,
      textButton: "Carrusel",
      // IMAGES

      position: null,
      url: null,
      // LISTA IMAGENES
      headers: [
        { text: "Image", value: "image_url" },
        { text: "Posicion", value: "position" },
        { text: "Sección", value: "type" },
        { text: "Dispositivo", value: "display_device" },
        { text: "Accion", value: "action" },
      ],
      dataImages: [],
      itemsFeatures: [],
      features: "",
      url_promotions: "",
      //Select Promotion
      typePromotion: "banners_principales",
      // Data
      select_item: null,
      image_promotion: [],
      type: "banners_principales",
      store_id: 1,
      data_stores: [],
      data_categories: [],
      categories: "",
      device: "",
      promotionItems: [
        { label: "Carrusel", value: "Carrusel" },
        { label: "Destacados", value: "Destacados" },
        { label: "Categorias", value: "Categorias" },
      ],
      sectionItems: [
        { label: "Banners principales", value: "banners_principales" },
        { label: "Productos destacados", value: "productos_destacados" },
        { label: "Novedades", value: "novedades" },
      ],
      typePromotionItems: [],
    };
  },

  created() {
    this.HandlerGetListPromotions();
    this.HandlerGetStore();
    this.typePromotionItems = this.sectionItems;
  },

  watch: {
    typePromotion(val) {
      this.HandlerGetListPromotions(val.toLowerCase());
    },

    type(val) {
      if (val == "Categorias") {
        this.HandlerGetCategory();
      }
    },

    store_id() {
      this.typePromotion = "banners_principales";
      this.typePromotionItems = this.sectionItems;
      this.HandlerGetListPromotions(this.typePromotion);
      this.HandlerGetCategory();
    },

    typePromotionItems() {
      this.type = "";
    },
  },

  methods: {
    onFileChange() {
      this.url = URL.createObjectURL(this.image_promotion);
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: "",
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetCategory() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          paginate: false,
          name: "",
          store_id: this.store_id,
        };
        const response = await this.$store.dispatch(
          "category/GET_CATEGORIES",
          request
        );
        this.data_categories = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetListPromotions(type = "banners_principales") {
      try {
        const request = {
          store_id: this.store_id,
          type: type.toLocaleLowerCase(),
          page: 1,
          per_page: 12,
          paginate: true,
        };

        const response = await this.$store.dispatch(
          "publications/LIST_PROMOTIONS",
          request
        );

        this.dataImages = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerDelete(promotion_id) {
      try {
        const request = {
          promotion_id: promotion_id,
        };

        await this.$store.dispatch("publications/DELETE_PROMOTIONS", request);

        await this.HandlerGetListPromotions(this.typePromotion);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerRegister() {
      try {
        const request = new FormData();
        request.append("store_id", this.store_id);
        request.append("type", this.type.toLowerCase());
        request.append("images_promotions", this.image_promotion);
        request.append("position", this.position);
        request.append("url", this.url_promotions);
        request.append("display_device", this.device);
        request.append("active", true);
        if (this.itemsFeatures.length > 0) {
          request.append("features", JSON.stringify(this.itemsFeatures));
        }
        if (this.categories) {
          request.append("category_id", this.categories);
        }

        const resp = await this.$store.dispatch(
          "publications/UPLOAD_IMAGE_CARRUSEL_PROMOTION",
          request
        );
        if (resp.data.type === "size") {
          this.$snotify.error(resp.data.message, "Error!");
          return;
        }

        this.showRegister = !this.showRegister;
        this.HandlerGetListPromotions(this.typePromotion);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerUpdatedUploadImage() {
      try {
        const request = new FormData();
        request.append("store_id", this.store_id);
        request.append("type", this.type.toLowerCase());
        request.append("images_promotions", this.image_promotion);
        request.append("position", this.position);
        request.append("url", this.url_promotions);
        request.append("display_device", this.device);
        if (this.itemsFeatures.length > 0) {
          request.append("features", JSON.stringify(this.itemsFeatures));
        }
        if (this.categories) {
          request.append("category_id", this.categories);
        }

        const requestData = {
          id: this.select_item.id,
        };
        const resp = await this.$store.dispatch(
          "publications/UPDATE_PROMOTIONS",
          {
            data: request,
            dataId: requestData,
          }
        );

        if (resp.data.type === "size") {
          this.$snotify.error(resp.data.message, "Error!");
          return;
        }
        this.showRegister = !this.showRegister;
        this.HandlerGetListPromotions(this.typePromotion);
        this.textButton = "Carrusel";
      } catch (error) {
        console.log(error);
      }
    },

    HandlerUpdate(value) {
      console.log(value);
      this.showRegister = !this.showRegister;
      this.update = true;
      this.select_item = value;
      this.position = value.position;
      this.url = value.image_url;
      this.image_promotion = value.image_url;
      this.itemsFeatures = value.features == null ? [] : value.features;
      this.url_promotions = value.url;
      this.type = value.type;
      this.device = value.display_device;

      switch (value.type) {
        case "carrusel":
          this.type = "Carrusel";
          break;
        case "categorias":
          this.type = "Categorias";
          break;
        default:
          break;
      }
    },

    HandlerAddFeatures() {
      const count_features = this.itemsFeatures.length;

      if (count_features < 4) {
        this.itemsFeatures.push(this.features);
        this.features = "";
      }
    },

    HandlerSubFeatures(index) {
      this.itemsFeatures.splice(index, 1);
    },

    HandlerUpdateFill() {
      this.showRegister = true;
      this.update = false;
      this.type = "";
      this.url = null;
      this.position = null;
      this.features = "";
      this.url_promotions = "";
      this.itemsFeatures = [];
      this.image_promotion = [];
    },

    HandlerGetCategoriesStore() {
      console.log("FIltrar Categorias por Tienda");
    },
  },
};
</script>

<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
