<template>
  <div>
    <div
      v-if="
        order.meli_shipping_id == null ||
          order.shipping == null ||
          order.shipping_status == 'to_be_agreed'
      "
    >
      <p
        class="subtitle-1 subtitle-2 font-weight-bold"
        v-if="order.store_pickup"
      >
        Retiro en tienda
      </p>
      <p class="subtitle-1 subtitle-2 font-weight-bold" v-else>
        Envío a Coordinar
      </p>
      <div v-if="order.buyer != null">
        <p class="mb-0">
          <span class="subtitle-2 font-weight-bold">
            Recibe/ Retira:
          </span>
          {{ `${order.buyer.first_name} ${order.buyer.last_name}` }}
        </p>
        <p class="mb-0">
          <span class="subtitle-2 font-weight-bold">
            Teléfono:
          </span>
          {{ order.buyer.phone }}
        </p>
        <p class="mb-0">
          <span class="subtitle-2 font-weight-bold">
            Email:
          </span>
          {{ order.buyer.email }}
        </p>
      </div>
    </div>

    <div
      v-else-if="
        order.shipping !== null && order.shipping.shipping_mode == 'custom'
      "
    >
      <p class="subtitle-1 subtitle-2 font-weight-bold">Envío Personalizado</p>
    </div>
    <div v-if="order.shipping !== null">
      <shipping-types
        :payment="order"
        v-if="order.shipping.shipping_method_name !== 'edifier_chile'"
      />
      <div
        v-if="
          order.shipping.status != 'cancelled' &&
            order.shipping.tracking_number != null
        "
      >
        <p class="mb-0" v-if="order.shipping.shipping_method_name != null">
          <span class="subtitle-2 font-weight-bold mr-1">Método:</span>
          {{ order.shipping.shipping_method_name }}
        </p>
        <p class="mb-0" v-if="order.shipping.tracking_number != null">
          <span class="subtitle-2 font-weight-bold"> Nº de seguimiento: </span>
          {{ order.shipping.tracking_number }}
        </p>
        <p class="mb-0" v-if="order.shipping.estimated_delivery_limit != null">
          <span class="subtitle-2 font-weight-bold"> Fecha prometida: </span>

          {{ order.shipping.estimated_delivery_limit | date }}
        </p>

        <status-sub-status-types :order="order" />
      </div>

      <p class="mb-0">
        <span class="subtitle-2 font-weight-bold mr-1">
          Domicilio o retira:
        </span>
        <span>
          {{ order.shipping.shipping_method_name }}
        </span>
      </p>
      <p class="mb-0">
        <span class="subtitle-2 font-weight-bold"> Costo del envío: </span>
        <span>
          {{ order.shipping.cost | currency }}
        </span>
      </p>
    </div>

    <div v-else>
      <div
        v-if="
          order.shipping !== null && order.shipping.logistic_type == 'chazki'
        "
      >
        <p class="text-uppercase">Chazki</p>
        <br />
        <p class="mb-0">
          <span class="subtitle-2 font-weight-bold">Método:</span>
          {{ order.shipping.shipping_method_name }}
        </p>
        <p class="font-p mb-0">
          <span class="subtitle-2 font-weight-bold"> Nº de seguimiento: </span>
          {{ order.shipping.tracking_number }}
        </p>
        <status-sub-status-types :order="order" />
      </div>
      <div
        v-if="
          order.shipping !== null && order.shipping.logistic_type == 'Logis24'
        "
      >
        <!-- <p class="text-uppercase">Logis24 ****</p> -->
        <!-- <br /> -->
        <p class="mb-0">
          <span class="subtitle-2 font-weight-bold">Método:</span>
          {{ order.shipping.shipping_method_name }}
        </p>
        <p class="font-p mb-0">
          <span class="subtitle-2 font-weight-bold"> Nº de seguimiento: </span>
          {{ order.shipping.tracking_number }}
        </p>
        <status-sub-status-types :order="order" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import StatusSubStatus from "./Types/status-sub-tatus-type";
import ShippingType from "./Types/shipping-type";
export default {
  props: {
    order: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  components: {
    "status-sub-status-types": StatusSubStatus,
    "shipping-types": ShippingType,
  },
  filters: {
    date(val) {
      return moment(val)
        .locale("es")
        .format("DD-MM-YYYY");
    },
    date1(val) {
      return moment(val)
        .locale("es")
        .format("hh:mm");
    },
  },
};
</script>

<style></style>
