<template>
  <v-container class="mt-5">
    <v-col cols="12">
      <div
        class="font-weight-medium primary--text mb-5"
        style="font-size: 26px"
      >
        Listados de transferencias Chile
      </div>
    </v-col>
    <TransferData
      @dialog:change="HandlerResponseData"
      v-if="showData"
      action="0"
    />
  </v-container>
</template>

<script>
import TransferData from "./Utils/TransferData";
export default {
  components: {
    TransferData,
  },
  data() {
    return {
      showData: true,
      dataCategory: [],
      dataUpdate: [],
      action: 0,
    };
  },

  methods: {
    HandlerShowData() {
      this.showData = !this.showData;
      this.dataUpdate = {
        sub_category: [],
      };
    },

    HandlerResponseData(data) {
      this.showData = !this.showData;
      this.dataUpdate = { ...data };
      this.action = 1;
    },
  },
};
</script>

<style>
</style>