<template>
  <div>
    <!-- <pre>
      {{ orderItem }}
    </pre> -->
    <v-data-table
      :headers="headers"
      :items="orderItem"
      :items-per-page="5"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:[`item.unit_price`]="{ item }">
        <span v-if="store_id == 0">
          {{ item.unit_price | currency }}
        </span>
        <span v-else>
          <span v-if="installments == '1'">
            {{ item.publication.price.pvp | currency }}
          </span>
          <span v-else-if="installments == '18'">
            {{ item.publication.price.pvp_18_installments | currency }}
          </span>
          <span v-else>
            {{ item.publication.price.pvp_transfer | currency }}
          </span>
        </span>
        <!-- {{ priceTypePayment(item) | currency }} -->
        <!-- {{ item.unit_price | currency }} -->
      </template>
      <template v-slot:[`item.sku`]="{ item }">
        <span v-if="item.product.product_erp == null">
          {{ item.seller_sku }}
        </span>
        <span v-else>
          {{ item.product.product_erp[0].sku }}
        </span>
      </template>
      <template v-slot:[`item.warehouse`]="{ item }">
        {{ item.warehouse != null ? item.warehouse.name : "" }}
      </template>
      <template v-slot:[`item.seller_sku`]="{ item }">
        {{ item.seller_sku }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    orderItem: {
      type: Array,
      default: () => [],
      required: true,
    },
    installments: {
      type: null,
      default: "",
      required: true,
    },
    store_id: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Cantidad",
          align: "left",
          sortable: false,
          value: "quantity",
          class: "primary--text",
        },
        {
          text: "Código",
          value: "sku",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Descripción",
          value: "product.name",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Depósito",
          value: "warehouse",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Código de barras",
          value: "product.barcode",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Precio unitario",
          value: "unit_price",
          class: "primary--text",
          sortable: false,
        },
      ],
      dataProducts: [],
    };
  },
  created() {
    console.log("oderd item", this.orderItem);
  },
  filters: {
    currency(value) {
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency",
      }).format(value);
      return AMOUNT_FORMAT;
    },
  },
  methods: {
    priceTypePayment(item) {
      // console.log("this.installments", this.installments);
      if (this.store_id == 0) {
        return item.unit_price;
      } else {
        let pvp = 0;
        switch (this.installments) {
          case "1":
            pvp = item.publication.price.pvp;
            break;
          case "18":
            pvp = item.publication.price.pvp_18_installments;
            break;

          default:
            pvp = item.publication.price.pvp_transfer;
            break;
        }
        console.log("pvp", item.publication.price.pvp);
        console.log(
          "pvp_18_installments",
          item.publication.price.pvp_18_installments
        );
        // console.log("pvp_transfer", item.publication.price.pvp_transfer);
        // console.log("pvp", pvp);
        return pvp;
      }
    },
  },
};
</script>

<style>
</style>