<template>
  <div>
    <p
      v-if="
        payment.shipping.status != 'cancelled' &&
        payment.shipping.logistic_type != 'fulfillment' &&
        payment.shipping.shipping_mode != 'to_be_agreed'
      "
      class="mb-7 subtitle-1 font-weight-bold text-uppercase"
    >
      Mercadoenvíos tradicional
    </p>
    <p
      v-else-if="
        payment.shipping.status != 'cancelled' &&
        payment.shipping.logistic_type != 'fulfillment' &&
        payment.shipping.shipping_mode == 'to_be_agreed'
      "
      class="mb-7 subtitle-1 font-weight-bold text-uppercase"
    >
      Envío a Coordinar
    </p>
    <p
      v-else-if="
        payment.shipping.status != 'cancelled' &&
        payment.shipping.logistic_type == 'fulfillment'
      "
      class="mb-7 subtitle-1 font-weight-bold text-uppercase"
      style="color: #2c9a8f; font-weight: 600"
    >
      Mercadoenvíos FULL
    </p>
    <p
      v-else
      class="mb-7 subtitle-1 font-weight-bold text-uppercase"
      style="color: #cc3e6c; font-weight: 600"
    >
      Envío Cancelado
    </p>
  </div>
</template>

<script>
export default {
  props: {
    payment: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
};
</script>

<style>
</style>