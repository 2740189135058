<template>
  <section>
    <register-components
      v-if="showRegister"
      :data="dataAdd"
      :action="actionAdd"
      :loader="loadingAddSpecification"
      @dialog:change="HandlerResponse"
    />
    <v-container fluid class="pb-0 pl-0 pr-0" v-if="status_card">
      <v-row class="justify-space-between align-center">
        <v-col cols="auto mb-2">
          <v-btn
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({}, 0)"
            >Añadir Especificación</v-btn
          >
        </v-col>
        <div class="row-input-box">
          <v-text-field
            label="Especificación"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="search"
          ></v-text-field>
          <v-btn
            fab
            small
            color="primary"
            class="btn-icon"
            @keyup.enter="HandlerGetSpecifications(1, search)"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-container>

    <div v-if="!loadingSpecifications && status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :headers="headers"
          :items="getSpecifications"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              class="mr-2"
              @click="HandlerAddData(item, 1)"
              v-if="!loadingDelete[item.id]"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              @click="CheckSpecificationDeletable(item)"
              v-if="!loadingDelete[item.id]"
            >
              mdi-delete
            </v-icon>
            <v-progress-circular
              size="20"
              class="d-flex justify-center"
              indeterminate
              color="primary"
              v-else
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="getPaginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <div v-if="loadingSpecifications" class="d-flex justify-center">
      <v-progress-circular
        size="300"
        class="d-flex justify-center my-5"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <custom-modal
      v-if="showDeleteConfirmModal"
      :active="showDeleteConfirmModal"
      :action="HandlerConfirmDelete"
      :closeModal="HandlerCloseModal"
      confirmBtn
      :title="'Confirmar eliminación'"
      max-width="600"
      :withImageTitle="false"
      closeText="cancelar"
    >
      <div class="delete-message">
        {{ infoDeleteSpecification.message }}
      </div>
    </custom-modal>
  </section>
</template>

<script>
import RegisterSpecifications from "./Utils/RegisterSpecifications.vue";
import CustomModal from "../CustomModal/customModal.vue";

export default {
  components: {
    "register-components": RegisterSpecifications,
    "custom-modal": CustomModal,
  },
  data() {
    return {
      status_card: true,
      loadingSpecifications: false,
      loadingAddSpecification: false,
      loadingDelete: {},
      dataAdd: {},
      actionAdd: 0,
      showRegister: false,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "measuring_unit",
          class: "primary--text",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      search: "",
      itemsPerPage: 20,
      page: 1,
      showDeleteConfirmModal: false,
      infoDeleteSpecification: { item: null, message: "" },
    };
  },
  created() {
    this.HandlerGetSpecifications();
  },
  watch: {
    page(val) {
      this.HandlerGetSpecifications(val);
    },
  },

  filters: {},

  computed: {
    getSpecifications() {
      return this.$store.getters["products/GET_SPECIFICATIONS"];
    },
    getPaginate() {
      return this.$store.getters["products/GET_PAGINATE"];
    },
  },

  methods: {
    async HandlerGetSpecifications(page = 1, search = "") {
      try {
        this.loadingSpecifications = true;
        const request = {
          paginate: true,
          page: page,
          per_page: this.itemsPerPage,
          name: search,
        };
        await this.$store.dispatch("products/GET_SPECIFICATIONS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSpecifications = false;
      }
    },

    HandlerAddData(data, action) {
      this.status_card = !this.status_card;
      this.dataAdd = { ...data };
      this.showRegister = true;
      this.actionAdd = action;
    },

    async HandlerDeleteSpecification(item) {
      try {
        this.loadingDelete[item.id] = true;
        this.$forceUpdate();
        const request = {
          specification_id: item.id,
        };
        await this.$store.dispatch("products/DELETE_SPECIFICATION", request);
        this.$snotify.success(`Especificación eliminada`, "¡Exitos!");
        this.HandlerGetSpecifications();
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ha ocurrido un error eliminando la especificación",
          "¡Error!"
        );
      } finally {
        this.loadingDelete[item.id] = false;
        this.$forceUpdate();
      }
    },

    async CheckSpecificationDeletable(item) {
      try {
        this.loadingDelete[item.id] = true;
        this.$forceUpdate();
        const request = {
          specification_id: item.id,
        };
        const response = await this.$store.dispatch(
          "products/CHECK_SPECIFICATION_DELETABLE",
          request
        );
        if (response) {
          this.HandlerDeleteSpecification(item);
        }
      } catch (error) {
        console.log(error);
        if (error?.response?.status == 400) {
          this.infoDeleteSpecification = {
            item: item,
            message: error?.response?.data?.message,
          };
          this.showDeleteConfirmModal = true;
        } else {
          this.loadingDelete[item.id] = false;
          this.$forceUpdate();
          this.$snotify.error(
            "Ha ocurrido un error eliminando la especificación",
            "¡Error!"
          );
        }
      }
    },

    HandlerCloseModal() {
      this.loadingDelete[this.infoDeleteSpecification?.item?.id] = false;
      this.$forceUpdate();
      this.showDeleteConfirmModal = false;
      this.infoDeleteSpecification = { item: null, message: "" };
    },

    HandlerConfirmDelete() {
      this.HandlerDeleteSpecification(this.infoDeleteSpecification.item);
      this.HandlerCloseModal();
    },

    async CreateSpecification(data) {
      try {
        this.loadingAddSpecification = true;
        const request = {
          name: data.name,
          measuring_unit: data.measuring_unit,
        };
        await this.$store.dispatch("products/REGISTER_SPECIFICATIONS", request);
        this.$snotify.success(`Especificación registrada`, "¡Exitos!");
        this.status_card = !this.status_card;
        this.showRegister = false;
        this.HandlerGetSpecifications();
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con el registro", "¡Error!");
      } finally {
        this.loadingAddSpecification = false;
      }
    },

    async UpdateSpecification(data) {
      try {
        this.loadingAddSpecification = true;
        const request = {
          specification_id: data.id,
          data: {
            name: data.name,
            measuring_unit: data.measuring_unit,
          },
        };
        await this.$store.dispatch("products/UPDATE_SPECIFICATION", request);
        this.$snotify.success(`Especificación editada`, "¡Exitos!");
        this.status_card = !this.status_card;
        this.showRegister = false;
        this.HandlerGetSpecifications();
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la edición", "¡Error!");
      } finally {
        this.loadingAddSpecification = false;
      }
    },

    HandlerResponse(data, action) {
      if (data) {
        if (action === 0) {
          this.CreateSpecification(data);
        } else {
          this.UpdateSpecification(data);
        }
      } else {
        this.status_card = !this.status_card;
        this.showRegister = false;
      }
    },
  },
};
</script>

<style>
.row-input-box {
  display: flex;
  flex-direction: row;
  padding: 16px 16px 0 16px;
}
.btn-icon {
  margin: 6px 0 0 16px;
}
.specifications-box {
  display: flex;
  flex-direction: row;
}
.delete-message {
  font-size: 16px;
}
</style>
