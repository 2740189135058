<template>
  <section>
    <v-container fluid class="mt-5 mb-5">
      <div>
        <v-row class="mt-10">
          <v-col cols="12" md="12">
            <v-data-table
              :loading="loadingTable"
              :headers="headers"
              :items="publications"
              hide-default-footer
              disable-pagination
              fixed-header
              height="530"
              :page.sync="page"
              @page-count="pageCount = $event"
            >
              <template v-slot:[`item.name`]="{ item }">
                <span v-if="item.buyer != null">
                  {{ item.buyer.first_name }} {{ item.buyer.last_name }}
                </span>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span>
                  {{ item.created_at | date }}
                </span>
              </template>
              <template v-slot:[`item.pay`]="{ item }">
                <span v-if="item.payment != null">
                  <div v-for="(value, index) in item.payment" :key="index">
                    <span class="font-weight-medium">
                      Pago {{ index + 1 }}:
                    </span>
                    <span>{{ value.transaction_amount | currency }}</span>
                  </div>
                </span>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  v-if="
                    item.store_id == 0 &&
                    (item.order_invoice.length == 0 ||
                      item.contabilium_invoice_id == null)
                  "
                  small
                  rounded
                  color="accent shadow-4"
                  @click="InvoiceOrderML(item)"
                  :loading="loading"
                >
                  Facturar MercadoLibre
                </v-btn>
                <v-btn
                  :loading="loading"
                  small
                  rounded
                  color="accent shadow-4"
                  @click="InvoiceOrder(item)"
                  v-if="
                    item.store_id > 0 &&
                    (item.order_invoice.length == 0 ||
                      item.contabilium_invoice_id == null)
                  "
                >
                  Facturar Tienda
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="mt-4 mr-4" justify="end">
          <v-pagination
            v-model="page"
            :length="paginate.lastPage"
            :total-visible="7"
            circle
          ></v-pagination>
        </v-row>
      </div>
      <!-- <div v-else class="mt-15">
        <v-row justify="center">
          <v-progress-circular
            :size="300"
            :width="7"
            indeterminate
            color="#7E82FA"
          ></v-progress-circular>
        </v-row>
      </div> -->
    </v-container>
  </section>
</template>

<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      status_card: true,
      loading: false,
      page: 1,
      dataProducts: [],
      paginate: {},
      accountsML: [],
      filterDataValue: {},
      // Components
      loadingTable: false,
      headers: [
        {
          text: "Venta #",
          align: "left",
          sortable: false,
          value: "meli_id",
          class: "primary--text",
        },
        {
          text: "Nombre Cliente",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Monto",
          align: "left",
          sortable: false,
          value: "pay",
          class: "primary--text",
        },
        {
          text: "Fecha",
          align: "left",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Accion",
          align: "left",
          sortable: false,
          value: "action",
          class: "primary--text",
        },
      ],
      publications: [],
    };
  },
  created() {
    this.HandlerGetOrder(this.page);
  },

  watch: {
    page(val) {
      this.HandlerGetOrder(val);
    },
  },

  filters: {
    date(val) {
      return moment(val).locale("es").format("DD-MM-YYYY");
    },
    date1(val) {
      return moment(val).locale("es").format("hh:mm");
    },
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    async HandlerGetOrder(page) {
      try {
        this.loading = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 10,
          paginate: true,
          meli_order_id: "",
          search_buyer: "",
          search_order_item: "",
          date_from: "",
          date_to: "",
          store_id: "",
          status_invoice: [2],
          country_id: 1,
        };

        const response = await this.$store.dispatch(
          "orders/GET_ORDERS",
          request
        );

        this.publications = response.data.data.data;

        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataProducts = response.data.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async InvoiceOrder(order_id) {
      try {
        this.loading = true;
        const request = {
          order_id: order_id.id,
        };
        await this.$store.dispatch("products/INVOICE_ORDER", request);
        this.HandlerGetOrder();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async InvoiceOrderML(order_id) {
      try {
        this.loading = true;
        const request = {
          order_id: order_id.id,
        };
        await this.$store.dispatch("products/INVOICE_ORDER_ML", request);
        this.HandlerGetOrder();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>