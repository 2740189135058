<template>
  <ValidationObserver ref="obs" v-slot="{}">
    <v-container class="mt-n0">
      <div
        class="font-weight-medium primary--text mb-5"
        style="font-size: 26px"
      >
        <!-- {{ actionData == 0 ? "Crear Tienda" : "Editar Tienda" }} -->
        Crear / Editar Tienda
      </div>
      <v-card :loading="loading" class="mb-4">
        <v-card-title class="pa-0 shadow-1">
          <v-img
            :src="require('@/assets/illustrations/bg_modal_card.svg')"
            cover
            position="left bottom"
            class="align-center"
            height="65px"
          >
            <v-card-title class="font-weight-medium primary--text">
              Datos Basicos
            </v-card-title>
          </v-img>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="3">
              <ValidationProvider
                name="Pais"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :items="dataCurrency"
                  item-text="name"
                  item-value="id"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Pais"
                  v-model="dataUpdateStore.country_id"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="Moneda"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :items="dataCurrencySearch"
                  item-text="name"
                  item-value="id"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Moneda"
                  v-model="dataUpdateStore.currency_id"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="Nombre"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdateStore.name"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Nombre"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="Logo"
                :rules="actionData == 0 ? 'required' : ''"
                v-slot="{ errors }"
              >
                <div class="d-flex">
                  <v-file-input
                    v-model="file"
                    append-icon="mdi-camera"
                    prepend-icon=""
                    filled
                    rounded
                    dense
                    class="rounded-small"
                    label="Logo"
                    truncate-length="15"
                    :error-messages="errors"
                  ></v-file-input>
                  <v-avatar v-if="actionData == 1" class="ml-2">
                    <img :src="dataUpdateStore.url" alt="Logo Tienda" />
                  </v-avatar>
                </div>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="app_url"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdateStore.app_url"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Url"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="18 cuotas"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdateStore.percentage_18_installments"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Porcentaje 18 cuotas"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="porcentaje transferencia"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdateStore.percentage_transfer"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Porcentaje en transferencia"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <v-checkbox
                v-model="dataUpdateStore.b2b_active"
                label="Activar B2B"
                class="mt-0"
              ></v-checkbox>
              <v-checkbox
                v-model="dataUpdateStore.out_stock"
                label="Sin Stock"
                class="mt-0"
              ></v-checkbox>
              <v-checkbox
                v-if="
                  /edifier|stadio/i.test(dataUpdateStore.name) &&
                    dataUpdateStore.country_id == 1
                "
                v-model="dataUpdateStore.display_full_prices"
                label="Mostrar opciones de precios"
                :disabled="loading_update_full_prices"
                class="mt-0"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row>
        <v-col md="12">
          <v-card class="font-weight-medium primary--text">
            <v-card-title>Seleccionar Categorias</v-card-title>
            <v-card-text>
              <v-row justify="space-between">
                <v-col md="2">
                  <v-btn
                    v-if="register"
                    href="#register"
                    @click="HandlerRegisterCategory"
                    rounded
                    class="accent shadow-4 mt-3"
                  >
                    Registrar
                  </v-btn>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    @keyup.enter="HandlerGetCategories"
                    v-model="searchStore"
                    append-icon="mdi-magnify"
                    filled
                    rounded
                    dense
                    class="rounded-small"
                    name="Buscar"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-data-table
                :headers="dessertHeaders"
                :items="dataCategories"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                class="elevation-0"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="2"
                        v-for="(sub_item, index) in item.sub_category"
                        :key="index"
                      >
                        <v-checkbox
                          :input-value="
                            sub_categories.includes(sub_item.id) ? true : false
                          "
                          :label="sub_item.name"
                          @change="
                            HandlerPushSubCategory(sub_item.id, sub_item)
                          "
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
              <transition name="fade">
                <div
                  v-if="showErrorCategories"
                  style="color: red; font-size: 12px"
                >
                  El campo subcategoria es requerido
                </div>
              </transition>

              <div class="d-flex justify-end">
                <v-pagination
                  circle
                  v-model="page"
                  :length="paginateCategories.lastPage"
                ></v-pagination>
              </div>
              <v-divider class="mx-10 mt-2"></v-divider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal({}, false)"
                >
                  Cancelar
                </v-btn>
              </v-hover>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  :loading="loading"
                  rounded
                  class="mr-1"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  @click="HandlerSendData"
                >
                  Guardar
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="12" v-if="showRegister">
          <section id="register">
            <v-card>
              <register-category
                :dataUpdate="dataUpdate"
                :action="action"
                @dialog:change="HandlerResponse"
              />
            </v-card>
          </section>
        </v-col>
      </v-row>
    </v-container>
  </ValidationObserver>
</template>

<script>
import RegisterDataCategory from "../../Category/Utils/RegisterData";
export default {
  props: {
    dataUpdateStore: {
      type: Object,
      default: () => {},
      required: false,
    },
    actionData: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  components: {
    "register-category": RegisterDataCategory,
  },
  data() {
    return {
      originalDataCategories: [],
      dataCategories: [],
      paginateCategories: {},
      dataCountry: [],
      country_value: "",
      dataCurrency: [],
      currency_id: "",
      dataCurrencySearch: [],
      name: "",
      file: [],
      selectCategories: [],
      showRegister: false,
      dataUpdate: {},
      loading_update_full_prices: false,
      action: 0,
      page: 1,
      searchStore: "",
      register: true,
      sub_categories: [],

      expanded: [],
      dessertHeaders: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "", value: "data-table-expand" },
      ],

      showErrorCategories: false,
      loading: false,
    };
  },
  created() {
    if (this.actionData == 1) {
      const subCategoriesID = this.dataUpdateStore.sub_category.map(
        (val) => val.id
      );
      this.sub_categories = [...subCategoriesID];
      console.log(this.sub_categories);
    }
    this.HandlerGetCountry();
    this.HandlerGetCurrency();
    this.HandlerGetCategories();
  },
  watch: {
    "dataUpdateStore.country_id": function(newVal) {
      const search = this.dataCurrency
        .map((value) => {
          if (value.id == newVal) {
            return value.currency;
          }
        })
        .filter((fil) => fil != undefined);
      this.dataCurrencySearch = search;
    },

    page(val) {
      this.HandlerGetCategories(val);
    },

    sub_categories() {
      if (this.sub_categories.length > 0) {
        this.showErrorCategories = false;
      } else {
        this.showErrorCategories = true;
      }
    },
  },
  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetCurrency() {
      try {
        const response = await this.$store.dispatch("country/GET_CURRENCIES");
        this.dataCurrency = response.data.data;
        if (this.actionData == 1) {
          const search = this.dataCurrency
            .map((value) => {
              if (value.id == this.dataUpdateStore.country_id) {
                return value.currency;
              }
            })
            .filter((fil) => fil != undefined);
          this.dataCurrencySearch = search;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetCategories(page) {
      try {
        const request = {
          page: page || 1,
          per_page: 20,
          paginate: true,
          store_id: "",
          name: this.searchStore.toLowerCase(),
        };
        const response = await this.$store.dispatch(
          "category/GET_CATEGORIES",
          request
        );
        this.paginateCategories = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.originalDataCategories = response.data.data.data;
        this.dataCategories = response.data.data.data;
        console.log(this.dataCategories);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerCreateCategoryCildren(item) {
      const filterData = item.map((val) => {
        const filterSubCategory = val.sub_category.map((value) => {
          return { id: value.id, name: value.name };
        });
        return {
          id: val.id,
          name: val.name,
          children: filterSubCategory,
        };
      });
      return filterData;
    },

    HandlerSelectCategory(item) {
      const arrayCategories = this.selectCategories;
      if (arrayCategories.length == 0) {
        arrayCategories.push(item.id);
      } else {
        if (arrayCategories.includes(item.id)) {
          const searchIndex = arrayCategories.indexOf(item.id);
          this.selectCategories.splice(searchIndex, 1);
        } else {
          this.selectCategories.push(item.id);
        }
      }
    },

    HandlerRegisterCategory() {
      this.showRegister = !this.showRegister;
      this.register = false;
      this.dataUpdate = {
        sub_category: [],
      };
    },

    HandlerResponse(item, action) {
      this.showRegister = false;
      if (action === 0) {
        // const filterData = this.HandlerCreateCategoryCildren([item.data]);
        this.dataCategories.push(item.data);
      }
    },

    // TODO OBTENER ID DE SUBCATEGORIAS CODIGO REFERENCIA
    HandlerGetSubCategoriesID(item) {
      return item
        .map((val) => {
          const SubCategorySearch = this.originalDataCategories.filter(
            (value) => {
              if (value.id == val) {
                return value;
              }
            }
          );
          return SubCategorySearch;
        })
        .map((e) => {
          const subCategoriesId = e[0].sub_category.map((x) => x.id);
          return subCategoriesId;
        })
        .flat();
    },

    async HandlerSearchStore() {
      try {
        const request = {
          name: this.searchStore,
        };
        const response = await this.$store.dispatch(
          "category/GET_CATEGORIES",
          request
        );

        const filterData = this.HandlerCreateCategoryCildren(
          response.data.data.data
        );

        this.dataCategories = filterData;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerSendData() {
      try {
        if (await this.$refs.obs.validate()) {
          this.loading = true;
          if (this.sub_categories.length == 0) {
            this.showErrorCategories = true;
            return;
          }
          const request = new FormData();
          request.append("logo", this.file);
          request.append("country_id", this.dataUpdateStore.country_id);
          request.append("currency_id", this.dataUpdateStore.currency_id);
          request.append("name", this.dataUpdateStore.name);
          request.append("app_url", this.dataUpdateStore.app_url);
          request.append("b2b_active", this.dataUpdateStore.b2b_active);
          request.append("out_stock", this.dataUpdateStore.out_stock);
          request.append("sub_categories", JSON.stringify(this.sub_categories));
          request.append(
            "percentage_18_installments",
            this.dataUpdateStore.percentage_18_installments
          );
          request.append(
            "percentage_transfer",
            this.dataUpdateStore.percentage_transfer
          );
          request.append(
            "display_full_prices",
            this.dataUpdateStore.display_full_prices
          );
          let response;
          if (this.actionData == 0) {
            response = await this.$store.dispatch(
              "store/REGISTER_STORE",
              request
            );
            this.$snotify.success("Tienda Registrada.", "¡Exitos!");
          } else {
            response = await this.$store.dispatch("store/UPDATE_STORE", {
              request: request,
              id: this.dataUpdateStore.id,
            });
            this.$snotify.success("Tienda Actualizada.", "¡Exitos!");
          }
          this.closeModal(response.data, 0);
        } else {
          this.showErrorCategories = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerPushSubCategory(sub_category_id, sub_item) {
      sub_item.active = !sub_item.active;
      if (this.sub_categories.length == 0) {
        this.sub_categories.push(sub_category_id);
      } else {
        if (this.sub_categories.includes(sub_category_id)) {
          const position = this.sub_categories.indexOf(sub_category_id);
          this.sub_categories.splice(position, 1);
        } else {
          this.sub_categories.push(sub_category_id);
        }
      }

      if (this.actionData == 1) {
        this.sub_categories_active = this.data_categories.reduce(
          (accumulator, currentValue) => {
            accumulator.push({
              id: currentValue.id,
              category: currentValue.name,
              subcategory: currentValue.sub_category.filter(
                (index) => index.active == true
              ),
            });
            return accumulator;
          },
          []
        );
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
