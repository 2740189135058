<template>
  <v-container fluid>
    <v-row v-if="showFilters">
      <v-col cols="12">
        <div
          class="font-weight-medium primary--text mb-5"
          style="font-size: 26px"
        >
          Listados de clientes
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="store_id"
          :items="data_stores"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Nombre"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="name"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Email"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="email"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Numero de documento"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="doc_number"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Numero de sku"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="sku"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Dias de creacion carrito"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="days_shopping_cart"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-2 my-1" justify="space-between" v-if="showFilters">
      <v-col cols="12" md="4" class="d-flex justify-start"> </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetClients()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!showData">
      <v-col cols="12" md="12">
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="getProducts"
          hide-default-footer
          disable-pagination
          fixed-header
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.fullname`]="{ item }">
            <span class="text-capitalize">
              {{ item.denominations.fullname }}
            </span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span>{{ item.denominations.email }}</span>
          </template>
          <template v-slot:[`item.province`]="{ item }">
            <span>{{ item.denominations.state }}</span>
          </template>
          <template v-slot:[`item.city`]="{ item }">
            <span>{{ item.denominations.location }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn fab @click="moreData(item)" x-small color="primary">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end" v-if="!showData">
      <v-col cols="6" md="4">
        <v-pagination
          v-model="page"
          :length="paginate.lastPage"
          circle
        ></v-pagination>
      </v-col>
    </v-row>

    <v-card v-if="showData" color="transparent" class="elevation-0">
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="
            () => {
              showData = !showData;
              showFilters = !showFilters;
            }
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div class="px-2 py-2">
        <v-card class="px-2 py-2">
          <div id="denomination">
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Denominación</div>
                <span>{{ dataClient.denominations.fullname }}</span>
              </v-col>
              <v-col cols="12" md="6">
                <span class="font-weight-medium">
                  {{ dataClient.denominations.store }}
                </span>
                <span class="font-weight-medium ml-md-4 ml-2">
                  {{ dataClient.denominations.created_at }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium">Datos fiscales</div>
                <span>CUIT: {{ dataClient.denominations.doc_number }}</span>
                <div class="font-weight-medium">Datos Contacto</div>
                <span>
                  <span class="font-weight-medium">Telefono:</span>
                  {{ dataClient.denominations.contact_phone }}
                </span>
                <span>
                  <span class="font-weight-medium ml-3">Email:</span>
                  {{ dataClient.denominations.email }}
                </span>
              </v-col>
              <v-col cols="12">
                <div>
                  <span>
                    <span class="font-weight-medium">Codigo postal: </span>
                    <span>{{ dataClient.denominations.zipcode }}</span>
                  </span>
                  <span class="ml-3">
                    <span class="font-weight-medium">Provincia:</span>
                    {{ dataClient.denominations.state }}
                  </span>
                  <span class="ml-3">
                    <span class="font-weight-medium">Ciudad:</span>
                    {{ dataClient.denominations.location }}
                  </span>
                </div>
                <div class="my-2">
                  <span>
                    <span class="font-weight-medium"># de departamento:</span>
                    {{ dataClient.denominations.department_number }}
                  </span>
                  <span class="ml-3">
                    <span class="font-weight-medium">Piso:</span>
                    {{ dataClient.denominations.floor_number }}
                  </span>
                </div>
                <div class="my-2">
                  <span>
                    <span class="font-weight-medium">Calle:</span>
                    {{ dataClient.denominations.street }}
                  </span>
                  <span class="ml-3">
                    <span class="font-weight-medium"># Calle:</span>
                    {{ dataClient.denominations.street_number }}
                  </span>
                </div>
                <div>
                  <span>
                    <span class="font-weight-medium">Entre calles:</span>
                    {{ dataClient.denominations.between_streets }}
                  </span>

                  <span class="ml-3">
                    <span class="font-weight-medium">Observaciones:</span>
                    {{ dataClient.denominations.observations }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3">
          <div id="products">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium">Productos</div>
                <v-data-table
                  :headers="headersProduct"
                  :items="dataClient.products"
                  hide-default-footer
                  disable-pagination
                ></v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3">
          <div id="products">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium">Tracking Visitas</div>
                <v-data-table
                  :headers="headersTracking"
                  :items="dataClient.tracking_visits"
                  hide-default-footer
                  disable-pagination
                ></v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3">
          <div id="products">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium">Carrito</div>
                <v-data-table
                  :headers="headersCart"
                  :items="dataClient.shopping_cart"
                  hide-default-footer
                  disable-pagination
                >
                  <template v-slot:[`item.name`]="{ item }">
                    <span class="text-capitalize" v-if="item.items != null">
                      <span v-if="item.items.length > 0">
                        {{ item.items[0].publication_b2b.keywords }}
                      </span>
                    </span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    <span class="text-capitalize" v-if="item.items != null">
                      <span v-if="item.items.length > 0">
                        {{ item.items[0].created_at }}
                      </span>
                    </span>
                  </template>
                  <template v-slot:[`item.quantity`]="{ item }">
                    <span class="text-capitalize" v-if="item.items != null">
                      <span v-if="item.items.length > 0">
                        {{ item.items[0].quantity }}
                      </span>
                    </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loadingTable: false,
      headers: [
        {
          text: "Denominación",
          align: "start",
          sortable: false,
          value: "fullname",
          class: "primary--text",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "verify",
          class: "primary--text",
        },
        {
          text: "Ultimo Contacto",
          align: "start",
          sortable: false,
          value: "last_enter",
          class: "primary--text",
        },
        {
          text: "Provincia",
          align: "start",
          sortable: false,
          value: "province",
          class: "primary--text",
        },
        {
          text: "Ciudad",
          align: "start",
          sortable: false,
          value: "city",
          class: "primary--text",
        },
        {
          text: "Zona",
          align: "start",
          sortable: false,
          value: "zone",
          class: "primary--text",
        },
        {
          text: "Accion",
          align: "start",
          sortable: false,
          value: "action",
          class: "primary--text",
        },
      ],
      headersProduct: [
        {
          text: "Modelo",
          align: "start",
          sortable: false,
          value: "item",
          class: "primary--text",
        },
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "quantity",
          class: "primary--text",
        },
        {
          text: "Fecha Compra",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Comprobante Fiscal",
          align: "start",
          sortable: false,
          value: "invoice",
          class: "primary--text",
        },
      ],
      headersTracking: [
        {
          text: "Tienda",
          align: "start",
          sortable: false,
          value: "store",
          class: "primary--text",
        },
        {
          text: "Visita",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
      ],
      headersCart: [
        {
          text: "Marca Modelo",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "quantity",
          class: "primary--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "status",
          class: "primary--text",
        },
      ],
      getProducts: [],
      data_stores: [],
      store_id: 5,
      paginate: {},
      page: 1,
      per_page: 20,
      doc_number: "",
      name: "",
      email: "",
      sku: "",
      days_shopping_cart: "",
      showData: false,
      dataClient: {},
      showFilters: true,
    };
  },

  created() {
    this.HandlerGetClients();
    this.HandlerGetStore();
  },

  watch: {
    page() {
      this.HandlerGetClients();
    },
    store_id() {
      this.HandlerGetClients();
    },
  },

  filters: {
    city(val) {
      switch (val) {
        case "1":
          return "Argentina";
        case "2":
          return "Chile";
        case "3":
          return "Mexico";
        default:
          break;
      }
    },

    origin(val, data_stores) {
      const getStore = data_stores.find((st) => st.id === val);
      if (getStore != undefined) {
        return getStore.name;
      }
    },
  },

  methods: {
    async HandlerGetClients() {
      try {
        const request = {
          b2b_status: false,
          buyer_store: 1,
          store_id: this.store_id,
          page: this.page,
          per_page: this.per_page,
          doc_number: this.doc_number,
          name: this.name,
          email: this.email,
          sku: this.sku,
          days_shopping_cart: this.days_shopping_cart,
        };
        const response = await this.$store.dispatch(
          "usersIndex/INDEX_CLIENTS_B2B",
          request
        );
        this.getProducts = response.data.data.data;
        console.log("this.getProducts", this.getProducts);
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: "",
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data.filter((index) => {
          return index.b2b_active == true;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerCopy(item) {
      const email = item.email;
      await this.$copyText(email);
      this.$snotify.success(`Correo copiado`, "Exitos");
    },

    HandlerReset() {
      this.doc_number = "";
      this.name = "";
      this.email = "";
      this.store_id = 1;
      this.sku = "";
      this.HandlerGetClients();
    },

    getAddress(address, value) {
      const principal = address.buyer_store.find((val) => {
        if (val.address != null && val.address.status == true) {
          return val;
        }
      });

      if (principal === undefined && value == "store_id") {
        return address.buyer_store[0].store_id;
      }

      if (principal != undefined && principal.address != null) {
        return principal.address[value];
      } else {
        return "";
      }
    },

    moreData(item) {
      this.showData = true;
      this.dataClient = { ...item };
      this.showFilters = false;
    },
  },
};
</script>

<style>
</style>