<template>
  <v-container ref="container">
    <v-row>
      <v-col>
        <v-select
          label="Pais"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="filters.countryId"
          :items="countrys"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Status"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="filters.status"
          :items="statusFilter"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
          label="Sku/Nombre"
          v-model="filters.sku"
          filled
          rounded
          dense
          class="rounded-small"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Min rent"
          v-model="filters.minRent"
          filled
          rounded
          dense
          class="rounded-small"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          label="Max rent"
          v-model="filters.maxRent"
          type="number"
          filled
          rounded
          dense
          class="rounded-small"
        ></v-text-field>
      </v-col>
      <v-col class="mt-2">
        <v-btn color="primary" @click="filterPublications">
          Filtrar
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="2">
        <v-switch
          v-model="filters.only_catalog"
          :label="`Solo catalogo`"
        ></v-switch>
      </v-col>
      <v-col cols="2">
        <v-switch
          v-model="filters.only_cost_0"
          :label="`Publicaciones con coste 0`"
        ></v-switch>
      </v-col>
      <v-col cols="2" v-if="userIsAbleToLiquidate">
        <v-switch
          v-model="filters.on_liquidate"
          :label="`En liquidación`"
        ></v-switch>
      </v-col>
      <v-col cols="2" v-if="userIsAbleToPausePub">
        <v-switch
          v-model="filters.on_auto_pause"
          :label="`En pausado automatico`"
        ></v-switch>
      </v-col>
      <v-col>
        <b>Total:</b> {{ getPublicationData.filter(p => !p.pub_id).length }}
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading === true"
      indeterminate
      color="primary"
      rounded
      height="6"
    ></v-progress-linear>
    <v-data-table
      dense
      ref="rentTable"
      :headers="headers"
      :hide-default-footer="true"
      :items="getPublicationData"
      fixed-header
      height="530"
      :items-per-page="-1"
      class="elevation-1 small-table-body fixed-colums-table"
    >
      <template v-slot:[`item.net_pvp`]="{ item }">
        {{ item.net_pvp | currencyValue }}
      </template>
      <template v-slot:[`item.pub_id`]="{ item }">
        <a v-if="item.store" :href="item.link" target="_blank">{{
          item.pub_id
        }}</a>
        <span v-else>{{ item.pub_id }}</span>
      </template>
      <template v-slot:[`item.cota_inf`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.cota_inf"
          @save="updateBounds({ productId: item.id, index: i, ...item })"
        >
          <span> {{ item.cota_inf }} </span>
          <template v-slot:input>
            <v-text-field
              type="number"
              v-model="item.cota_inf"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.cota_sup`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.cota_sup"
          @save="updateBounds({ productId: item.id, index: i, ...item })"
        >
          <span> {{ item.cota_sup }} </span>
          <template v-slot:input>
            <v-text-field
              type="number"
              v-model="item.cota_sup"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.competition_mode`]="{ item }">
        <v-edit-dialog
          v-if="!!item.competition_mode && item.pub_type === 'Catalogo'"
          @open="selectedCompetitionMode = [item.competition_mode]"
        >
          <span
            :style="{
              color: getCompetiteModeColor(item.competition_mode),
              'font-weight': 500
            }"
          >
            {{ competitionMode[item.competition_mode] }}
          </span>
          <template v-slot:input>
            <v-checkbox
              label="No competir"
              v-model="selectedCompetitionMode"
              class="shrink mr-0 mt-0"
              value="DO_NOT_COMPETE"
              hide-details="true"
            ></v-checkbox>
            <v-checkbox
              label="Competir para empatar"
              v-model="selectedCompetitionMode"
              class="shrink mr-0 mt-0"
              value="AIM_TO_TIE"
              hide-details="true"
            ></v-checkbox>
            <v-checkbox
              label="Competir para ganar"
              v-model="selectedCompetitionMode"
              class="shrink mr-0 mt-0"
              value="AIM_TO_WIN"
              hide-details="true"
            ></v-checkbox>
            <div class=" justify-center my-3" style="display: flex">
              <v-btn
                :loading="changingCompMode"
                color="primary"
                :small="true"
                @click="setAutomationOption(item)"
              >
                Actualizar
              </v-btn>
            </div>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.net_pvp_desc`]="{ item }">
        {{ item.net_pvp_desc | currencyValue }}
      </template>
      <template v-slot:[`item.liquidate`]="{ item }">
        <v-switch
          v-model="item.liquidate"
          v-if="userIsAbleToLiquidate"
          :disabled="loading === item.id"
          @change="val => updateLiquidateMode(item, item.id)"
          :style="{
            display: item.pub_type !== 'Catalogo' ? 'none' : 'block',
            marginTop: '-16px',
            marginBottom: '-32px'
          }"
        ></v-switch>
      </template>
      <template v-slot:[`item.allow_auto_pause`]="{ item }">
        <v-switch
          v-model="item.allow_auto_pause"
          v-if="userIsAbleToPausePub && item.pub_id"
          :disabled="loading === item.id"
          @change="val => updateAutoPauseMode(item, item.id)"
          :style="{
            marginTop: '-16px',
            marginBottom: '-32px'
          }"
        ></v-switch>
      </template>
      <template v-slot:[`item.rent_amount`]="{ item }">
        {{ item.rent_amount | currencyValue }}
      </template>
      <template v-slot:[`item.tax`]="{ item }">
        {{ item.tax | currencyValue }}
      </template>
      <template v-slot:[`item.cost`]="{ item }">
        {{ item.cost | currencyValue }}
      </template>
      <template v-slot:[`item.sales_fee`]="{ item }">
        {{ item.sales_fee | currencyValue }}
      </template>
      <template v-slot:[`item.provincial_tax`]="{ item }">
        {{ item.provincial_tax | currencyValue }}
      </template>
      <template v-slot:[`item.payment_fee`]="{ item }">
        {{ item.payment_fee | currencyValue }}
      </template>
      <template v-slot:[`item.delivery_fee`]="{ item }">
        {{ item.delivery_fee | currencyValue }}
      </template>
      <template v-slot:[`item.rent_percent`]="{ item }">
        {{ item.rent_percent | percentageFormater }}
      </template>
      <template v-slot:[`item.desc`]="{ item }">
        {{ item.desc | percentageFormater }}
      </template>
      <template v-slot:[`item.days_since_last_order`]="{ item }">
        {{
          item.days_since_last_order === null
            ? "Sin ventas"
            : item.days_since_last_order
        }}
      </template>
      <template v-slot:[`item.cat_status`]="{ item }">
        <span
          :style="{
            color: getCatalogStatusColor(item.cat_status),
            'font-weight': 500
          }"
        >
          {{ item.cat_status | catalogStatus }}
        </span>
      </template>
      <template v-slot:[`item.has_desc`]="{ item }">
        {{ item.has_desc | booleanFilter }} </template
      ><template v-slot:[`item.status`]="{ item }">
        <span
          :style="
            item.status
              ? { color: `green`, 'font-weight': 500 }
              : { color: `red`, 'font-weight': 500 }
          "
        >
          {{ item.status | booleanFilter }}
        </span>
      </template>
      <template v-slot:[`item.health`]="{ item }">
        <span
          :style="{
            color: getRentabilityStatusColor(item.health),
            'font-weight': 500
          }"
        >
          {{ item.health }}
        </span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      pubData: [],
      paginatedData: [],
      userIsAbleToLiquidate: false,
      userIsAbleToPausePub: false,
      changingCompMode: false,
      filters: {
        sku: "",
        countryId: 3,
        minRent: "",
        maxRent: "",
        status: "active",
        only_catalog: false,
        only_cost_0: false,
        on_liquidate: false,
        on_auto_pause: false
      },
      pageSize: 50,
      page: 0,
      testArr: Array(100).fill(0),
      loading: false,
      selectedCompetitionMode: ["DO_NOT_COMPETE"],
      competitionMode: {
        AIM_TO_WIN: "Competir para ganar",
        AIM_TO_TIE: "Competir para empatar",
        DO_NOT_COMPETE: "No competir"
      },

      countrys: [
        {
          name: "Mexico",
          id: 3
        },
        {
          name: "Argentina",
          id: 1
        },
        {
          name: "Chile",
          id: 2
        },
        {
          name: "Todos",
          id: 4
        }
      ],

      statusFilter: [
        { name: "Activa", id: "active" },
        { name: "Pausada", id: "paused" },
        { name: "Todos", id: "all" }
      ],

      headers: [
        {
          text: "Producto",
          class: " small-table-header gray-background--solid fixed-col",
          align: "left",
          sortable: false,
          value: "product"
        },
        {
          text: "CH",
          class: " small-table-header gray-background--solid",
          align: "left",
          sortable: false,
          value: "store"
        },
        {
          text: "Publication",
          class: "small-table-header gray-background--solid",
          align: "left",
          sortable: false,
          value: "pub_id"
        },
        {
          text: "Cota inf",
          class: "small-table-header gray-background--solid",
          align: "right",
          sortable: false,
          value: "cota_inf"
        },
        {
          text: "Cota sup",
          class: "small-table-header gray-background--solid",
          align: "right",
          sortable: false,
          value: "cota_sup"
        },
        {
          text: "PVP neto",
          class: " small-table-header green-background--solid",
          align: "right",
          sortable: false,
          value: "net_pvp"
        },
        {
          text: "Desc",
          class: " small-table-header green-background--solid",
          align: "right",
          sortable: false,
          value: "desc"
        },
        {
          text: "PVP neto desc",
          class: " small-table-header green-background--solid",
          align: "right",
          sortable: false,
          value: "net_pvp_desc"
        },
        {
          text: "Rent %",
          class: " small-table-header green-background--solid",
          align: "right",
          sortable: false,
          value: "rent_percent"
        },
        {
          text: "Health",
          class: " small-table-header green-background--solid",
          align: "center",
          sortable: false,
          value: "health"
        },
        {
          text: "Active ",
          class: " small-table-header green-background--solid",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Rent $",
          class: " small-table-header green-background--solid",
          align: "right",
          sortable: false,
          value: "rent_amount"
        },
        {
          text: "Costo",
          class: " small-table-header blue-background--solid",
          align: "right",
          sortable: false,
          value: "cost"
        },
        {
          text: "Taxes",
          class: " small-table-header blue-background--solid",
          align: "right",
          sortable: false,
          value: "provincial_tax"
        },
        {
          text: "Sales FEE",
          class: " small-table-header blue-background--solid",
          align: "right",
          sortable: false,
          value: "sales_fee"
        },
        {
          text: "Payment FEE",
          class: " small-table-header blue-background--solid",
          align: "right",
          sortable: false,
          value: "payment_fee"
        },
        {
          text: "Delivery FEE",
          class: " small-table-header blue-background--solid",
          align: "right",
          sortable: false,
          value: "delivery_fee"
        },
        {
          text: "AD DESC",
          class: "small-table-header yellow-background--solid",
          align: "left",
          sortable: false,
          value: "has_desc"
        },
        {
          text: "TYPE",
          class: " small-table-header yellow-background--solid",
          align: "left",
          sortable: false,
          value: "pub_type"
        },
        {
          text: "STATUS",
          class: " small-table-header yellow-background--solid",
          align: "center",
          sortable: false,
          value: "cat_status"
        },
        {
          text: "AUTOMATION MODE",
          class: " small-table-header yellow-background--solid",
          align: "center",
          sortable: false,
          value: "competition_mode"
        },
        {
          text: "Dias ULT VENT",
          class: " small-table-header orange-background--solid",
          align: "right",
          sortable: false,
          value: "days_since_last_order"
        },
        {
          text: "Para liquidar",
          class: " small-table-header orange-background--solid",
          align: "left",
          sortable: false,
          value: "liquidate"
        },
        {
          text: "Pausado automático",
          class: " small-table-header orange-background--solid",
          align: "left",
          sortable: false,
          value: "allow_auto_pause"
        }
      ]
    };
  },
  async created() {
    this.loading = true;
    await this.$store.dispatch("products/GET_PRODUCTS_RENTABILITY");
    this.filterPublications();
    const user = this.$store.getters["auth/GET_USER"];
    this.userIsAbleToLiquidate = user.permissions.some(
      p => p.name === "Liquidar producto"
    );
    this.userIsAbleToPausePub = user.permissions.some(
      p => p.name === "Pausar publicacion ML"
    );

    if (!this.userIsAbleToLiquidate) {
      this.headers = this.headers.filter(h => h.text !== "Para liquidar");
    }
    if (!this.userIsAbleToPausePub) {
      this.headers = this.headers.filter(h => h.text !== "Pausado automático");
    }

    this.loading = false;
  },

  methods: {
    filterPublications() {
      this.$store.dispatch(
        "products/FILTER_PRODUCTS_RENTABILITY",
        this.filters
      );
    },
    getCatalogStatusColor(value) {
      const statusMap = {
        winning: "green",
        not_listed: "black",
        listed: "red",
        competing: "red",
        sharing_first_place: "blue"
      };

      return statusMap[value] || "black";
    },
    getCompetiteModeColor(value) {
      const statusMap = {
        DO_NOT_COMPETE: "red",
        AIM_TO_TIE: "blue",
        AIM_TO_WIN: "green"
      };

      return statusMap[value] || "black";
    },
    getRentabilityStatusColor(value) {
      const statusMap = {
        "No rentable": "red",
        Rentable: "green",
        "Sobre precio": "#fc9803"
      };
      return statusMap[value] || "black";
    },

    async updateLiquidateMode(pub, productId) {
      this.loading = productId;
      await this.$store.dispatch("products/UPDATE_PUBLICATION", {
        pubId: pub.internal_pub_id,
        liquidate: pub.liquidate
      });
      this.loading = null;
    },

    async updateAutoPauseMode(pub, productId) {
      this.loading = productId;
      await this.$store.dispatch("products/UPDATE_PUBLICATION", {
        pubId: pub.internal_pub_id,
        allow_auto_pause: pub.allow_auto_pause
      });
      this.loading = null;
    },

    async setAutomationOption(item) {
      this.changingCompMode = true;
      await this.$store.dispatch("products/UPDATE_PUBLICATION", {
        pubId: item.internal_pub_id,
        competition_mode: this.selectedCompetitionMode[0]
      });
      await this.$store.dispatch("products/GET_PRODUCTS_RENTABILITY");
      this.$store.dispatch(
        "products/FILTER_PRODUCTS_RENTABILITY",
        this.filters
      );
      this.changingCompMode = false;
      this.$refs.container.click();
    },
    async reloadPublication(productId) {
      this.loading = productId;
      await this.$store.dispatch("products/GET_PRODUCTS_RENTABILITY");
      this.$store.dispatch(
        "products/FILTER_PRODUCTS_RENTABILITY",
        this.filters
      );
      this.loading = null;
    },
    async updateBounds(item) {
      // const results = await event;
      const { cota_inf, cota_sup, productId, rentability_bound_id } = item;

      const payload = {
        lower_bound: Number(cota_inf),
        upper_bound: Number(cota_sup)
      };
      try {
        this.loading = productId;
        await this.$store.dispatch("products/UPDATE_RENTABILITY_BOUNDS", {
          id: rentability_bound_id,
          ...payload
        });
        await this.$store.dispatch("products/GET_PRODUCTS_RENTABILITY");
        this.$store.dispatch(
          "products/FILTER_PRODUCTS_RENTABILITY",
          this.filters
        );
      } catch (error) {
        this.$snotify.error(error.response.data.message);
      } finally {
        this.loading = null;
      }
    }
  },

  watch: {
    page() {},
    selectedCompetitionMode(value) {
      if (value && value.length > 1) {
        this.selectedCompetitionMode = [value[1]];
      }
    }
  },

  filters: {
    currencyValue(value) {
      if (value === undefined) {
        return;
      }
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(Number(value).toFixed(2));
      return AMOUNT_FORMAT;
    },
    booleanFilter(value) {
      if (value === undefined) {
        return;
      }

      if (value) {
        return "SI";
      } else {
        return "NO";
      }
    },
    percentageFormater(value) {
      if (value === undefined) {
        return;
      }
      return `${Number(value)?.toFixed(2)}%`;
    },
    catalogStatus(value) {
      const statusMap = {
        winning: "Ganando",
        not_listed: "Sin listar",
        listed: "Perdiendo",
        competing: "Perdiendo",
        sharing_first_place: "Compitiendo"
      };

      return statusMap[value] || value;
    }
  },

  computed: {
    getPublicationData() {
      const data = this.$store.getters[
        "products/GET_PRODUCTS_RENTABILITY"
      ].reduce((acum, item) => {
        const { flex, name, mercadolibre, flex_b2b, id } = item;
        const flexPubData = [];
        const mlPubData = [];
        const b2bPubDAta = [];
        mercadolibre?.forEach(pub => {
          const {
            store_name,
            rentability,
            is_active,
            days_since_last_order,
            liquidate,
            allow_auto_pause,
            catalog_status,
            link,
            rentability_bounds,
            catalog_listing,
            competition_mode,
            item_id,
            id: internal_pub_id
          } = pub;
          if (store_name === "CBT") {
            return;
          }

          rentability.forEach(paymentMethod => {
            const {
              price,
              discount_percentage,
              discounted_price,
              rentability_percentage,
              rentability_value,
              rentability_status,
              has_desc,
              costs
            } = paymentMethod;
            const {
              taxes,
              sale_fee,
              payment_fee,
              delivery_fee,
              product_cost,
              provincial_tax
            } = costs;
            mlPubData.push({
              store: store_name,
              internal_pub_id,
              pub_id: item_id,
              net_pvp: price,
              link,
              competition_mode,
              cota_inf: rentability_bounds?.lower_bound,
              cota_sup: rentability_bounds?.upper_bound,
              rentability_bound_id: rentability_bounds?.id,
              desc: discount_percentage || 0,
              net_pvp_desc: discounted_price,
              rent_percent: rentability_percentage,
              health: rentability_status,
              status: is_active,
              days_since_last_order,
              liquidate,
              allow_auto_pause,
              rent_amount: rentability_value,
              tax: taxes,
              cost: product_cost,
              sales_fee: sale_fee,
              provincial_tax,
              payment_fee: payment_fee,
              delivery_fee: delivery_fee,
              has_desc: has_desc,
              pub_type: catalog_listing ? "Catalogo" : "Normal",
              cat_status: catalog_listing ? catalog_status : "",
              days: ""
            });
          });
        });
        flex?.forEach(pub => {
          const {
            id,
            store,
            rentability,
            product,
            is_active,
            rentability_bounds,
            days_since_last_order
          } = pub;
          const { name: store_name, id: store_id } = store;
          if (store_name === "MercadoLibre" || store_name === "Stadio Mexico") {
            return;
          }
          flexPubData.push({
            store: store_name,
            pub_id: id,
            link: `https://admin.flextd.com/publicaciones?store=${store_id}&sku=${product}&active=${is_active}`,
            cota_inf: rentability_bounds?.lower_bound,
            cota_sup: rentability_bounds?.upper_bound,
            rentability_bound_id: rentability_bounds?.id,
            days_since_last_order
          });

          rentability.forEach(paymentMethod => {
            const {
              payment_method,
              price,
              discount_percentage,
              discounted_price,
              rentability_percentage,
              rentability_status,
              rentability_value,
              has_desc,
              costs
            } = paymentMethod;
            const {
              taxes,
              sale_fee,
              payment_fee,
              delivery_fee,
              product_cost,
              provincial_tax
            } = costs;
            flexPubData.push({
              pub_id: payment_method,
              net_pvp: price,
              desc: discount_percentage || 0,
              net_pvp_desc: discounted_price,
              rent_percent: rentability_percentage,
              health: rentability_status,
              status: is_active,
              cost: product_cost,
              rent_amount: rentability_value,
              tax: taxes,
              sales_fee: sale_fee,
              provincial_tax,
              payment_fee: payment_fee,
              delivery_fee: delivery_fee,
              has_desc: has_desc,
              pub_type: "Normal",
              cat_status: "",
              days: ""
            });
          });
        });
        flex_b2b?.forEach(pub => {
          const {
            id,
            store,
            rentability,
            is_active,
            sku,
            days_since_last_order,
            rentability_bounds,
            country
          } = pub;
          const { name } = store;
          if (!rentability.length) {
            return;
          }
          b2bPubDAta.push({
            store: name,
            days_since_last_order,
            pub_id: id || 0,
            link: `https://admin.flextd.com/b2b_publication?store=${country.id}&sku=${sku}`
          });

          rentability.forEach(option => {
            const {
              quantity,
              price,
              discount_percentage,
              discounted_price,
              rentability_percentage,
              rentability_value,
              rentability_status,
              has_desc,
              costs
            } = option;
            const {
              taxes,
              sale_fee,
              payment_fee,
              delivery_fee,
              product_cost,
              provincial_tax
            } = costs;
            b2bPubDAta.push({
              pub_id: quantity,
              net_pvp: price,
              desc: discount_percentage || 0,
              net_pvp_desc: discounted_price,
              cota_inf: rentability_bounds?.lower_bound,
              cota_sup: rentability_bounds?.upper_bound,
              rentability_bound_id: rentability_bounds?.id,
              rent_percent: rentability_percentage,
              health: rentability_status,
              cost: product_cost,
              status: is_active,
              rent_amount: rentability_value,
              tax: taxes,
              sales_fee: sale_fee,
              provincial_tax,
              payment_fee: payment_fee,
              delivery_fee: delivery_fee,
              has_desc: has_desc,
              pub_type: "Normal",
              cat_status: "",
              days: ""
            });
          });
        });
        if (!mlPubData.length && !mlPubData.length && !b2bPubDAta.length) {
          return acum;
        }
        acum[id] = [
          { product: name },
          ...mlPubData,
          ...flexPubData,
          ...b2bPubDAta
        ];
        return acum;
      }, {});
      return Object.values(data).flat() || [];
    }
  }
};
</script>
