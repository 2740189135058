var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-between align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[(_vm.status_card)?_c('v-btn',{staticClass:"accent shadow-4 px-8",attrs:{"large":"","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.HandlerAddData({})}}},[_vm._v(" Añadir Producto ")]):_vm._e(),(_vm.activeAddData)?_c('register-component',{attrs:{"active":_vm.activeAddData,"data":_vm.dataAdd,"dataBrand":_vm.dataBrand,"action":_vm.actionAdd},on:{"dialog:change":_vm.HandlerResponse}}):_vm._e(),(_vm.activeErps)?_c('erps-component',{attrs:{"active":_vm.activeAddData,"data":_vm.dataAdd,"dataBrand":_vm.dataBrand,"action":1},on:{"dialog:change":_vm.HandlerResponse}}):_vm._e()],1)],1),(_vm.status_card)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Nombre / Marca / Modelo / SKU","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetProducts()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","items":_vm.items,"item-text":"name","item-value":"id","label":"Estatus","color":"blue-grey","single-line":"","hide-details":""},on:{"change":function($event){return _vm.HandlerGetProducts()}},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.HandlerSyncContabilium()}}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"tile":"","size":"30"}},[_c('v-img',{attrs:{"src":require("@/assets/flag/arg.svg"),"contain":""}})],1)],1)]}}],null,false,3658046733)},[_c('span',[_vm._v("Sincronizar Contabilium")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.HandlerSyncContabiliumCL()}}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"tile":"","size":"30"}},[_c('v-img',{attrs:{"src":require("@/assets/flag/chl.svg"),"contain":""}})],1)],1)]}}],null,false,1353477105)},[_c('span',[_vm._v("Sincronizar Contabilium")])]),_c('v-btn',{staticClass:"mr-3",attrs:{"loading":_vm.loadingBtn,"fab":"","small":""},on:{"click":function($event){_vm.showLoader = true}}},[_c('v-icon',{attrs:{"color":"accent"}},[_vm._v("mdi-file-excel")])],1),_c('v-btn',{staticClass:"mr-3",attrs:{"loading":_vm.loadingBtn,"fab":"","small":"","color":"primary"},on:{"click":function($event){_vm.saveImport = 'save'}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.HandlerReset()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-backup-restore")])],1),_c('v-btn',{staticClass:"ml-3",attrs:{"loading":_vm.exportingProducts,"fab":"","small":""},on:{"click":function($event){return _vm.exportProducts()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"inputUpload",attrs:{"type":"file"},on:{"change":_vm.HandlerImportFile}})],1)],1):_vm._e()],1),(_vm.registerProductContabilium)?_c('register-contabilium-product',{on:{"dialog:change":_vm.HandlerResponseRegister}}):_vm._e(),(_vm.status_card)?_c('div',[_c('v-card',{staticClass:"shadow-1 mt-5"},[_c('v-data-table',{attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.getProducts,"hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"530","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.has_null_specification",fn:function(ref){
var item = ref.item;
return [(item.has_null_specification)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"#f44","d":"M20 17h2v-2h-2v2m0-10v6h2V7M4 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8l-6-6m-1 1.5L16.5 9H11Z"}})])])]}}],null,true)},[_c('span',[_vm._v("Actualización requerida")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex",staticStyle:{"align-items":"center"}},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.HandlerEditItem(item)}}},[_vm._v(" mdi-pencil ")]),(!_vm.loadingFIleManual)?_c('v-file-input',{staticClass:"mb-4",attrs:{"multiple":"","label":"File input","hide-input":"","prepend-icon":"mdi-text-box-plus-outline"},on:{"change":function($event){return _vm.HandlerUploadManul(item)}},model:{value:(_vm.fileManual),callback:function ($$v) {_vm.fileManual=$$v},expression:"fileManual"}}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}),(_vm.userIsAbleToSeeErps)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.HandlerGoToErps(item)}}},[_vm._v(" mdi-currency-usd ")]):_vm._e()],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name != null)?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [(item.products_erp.length > 0)?_c('div',_vm._l((item.products_erp),function(item,i){return _c('div',{key:i},[_vm._v(" "+_vm._s(item.sku)+" ")])}),0):_c('div',[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{staticClass:"px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{class:item.brand === null ? 'red--text' : 'text-capitalize'},[(item.brand === null)?_c('span',[_vm._v("No tiene marca registrada")]):_c('span',[_vm._v(_vm._s(item.brand.name))])]),_c('v-list-item-subtitle',[_vm._v("Modelo: "),(
                    item.pattern_name == null || item.pattern_name == 'null'
                  )?_c('span',[_vm._v(" No tiene modelo registrada ")]):_c('span',[_vm._v(" "+_vm._s(item.pattern_name)+" ")])])],1)],1)]}},{key:"item.active",fn:function(ref){
                  var item = ref.item;
return [(item.products_erp.length > 0)?_c('div',_vm._l((item.products_erp),function(product,i){return _c('div',{key:i},[(_vm.loadingStatus == false)?_c('v-chip',{attrs:{"color":"transparent","small":""},on:{"click":function($event){return _vm.HandlerChangeStatus(item, product.country_id)}}},[_c('v-icon',{attrs:{"left":"","small":"","color":product.active_b2c === true ? 'success' : 'error'}},[_vm._v("mdi-radiobox-marked")]),_vm._v(" "+_vm._s(product.active_b2c === true ? "Activo" : "Inactivo")+" ("+_vm._s(product.country.name)+") ")],1):_vm._e()],1)}),0):_c('div',[_vm._v("Por cargar")])]}},{key:"item.created_at",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))])]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-text-field',{ref:"cantidad de pagina",attrs:{"type":"number","filled":"","rounded":"","dense":"","label":"Cantidad","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetData($event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{on:{"click":_vm.HandlerGetData}},[_vm._v(" mdi-book-open-page-variant ")])]},proxy:true}],null,false,3095674000),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-pagination',{attrs:{"length":_vm.getPaginate.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"500"},model:{value:(_vm.showLoader),callback:function ($$v) {_vm.showLoader=$$v},expression:"showLoader"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var passes = ref.passes;
return [_c('v-card',{attrs:{"loading":_vm.uploadFile}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Cargar archivo ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
              bar: { background: '#bacfcec0', keepShow: true }
            }}},[_c('v-card-text',{staticStyle:{"max-height":"350px"}},[_c('ValidationProvider',{attrs:{"name":"archivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-file-input',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Elige tu archivo","prepend-icon":"","prepend-inner-icon":"mdi-file-excel","error-messages":errors},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}],null,true)})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){_vm.showLoader = false}}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.uploadFile,"disabled":_vm.uploadFile,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerImportFile)}}},[_vm._v("Guardar")])]}}],null,true)})],1)],1)]}}],null,false,3264638022)})],1),(_vm.showLoadingSync)?_c('loader-component',{attrs:{"showLoader":_vm.showLoadingSync,"color":"primary"}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }