<template>
  <section class="mx-4 mx-md-0">
    <v-container fluid class="mt-5">
      <v-row style="justify-content: space-between; align-items: center;">
        <v-row class="mb-0">
          <v-col cols="12" sm="5" md="4">
            <v-select
              :items="dataCountry"
              item-text="name"
              item-value="code_iso"
              v-model="country_id"
              style="max-width:400px"
              menu-props="auto"
              filled
              rounded
              dense
              class="rounded-small"
              name="country"
              label="Pais"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <div v-if="!loadingQuestions">
      <v-card v-if="!getQuestions.length" class="mt-4">
        <div class="not-questions">
          No hay historial de preguntas en este momento
        </div>
      </v-card>
      <v-card
        v-for="(item, index) in getQuestions"
        :key="index"
        class="shadow-1 mt-5 py-8 px-8 py-md-5 px-md-5"
      >
        <v-row class="d-flex flex-row align-start justify-space-between">
          <v-col cols="12" sm="12" md="4" class="d-flex flex-row">
            <div class="mr-3">
              <v-img
                v-if="!item.image"
                contain
                src="@/assets/img/no_image.jpg"
                height="100"
                width="100"
              >
              </v-img>
              <v-img
                v-else
                contain
                height="80"
                width="80"
                :src="item.image"
                :lazy-src="item.image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div>
              <div class="title-item mb-1">{{ item.title }}</div>
              <div class="d-flex flex-row align-center">
                <v-icon class="mr-1">mdi-file-document-outline</v-icon>
                <div class="common-text">Publicación #{{ item.item_id }}</div>
              </div>
              <div class="common-text">Pregunta #{{ item.id }}</div>
              <div class="d-flex flex-row align-center mt-1" v-if="item.brand">
                <v-icon class="mr-1" color="#1BD0DD">mdi-check-decagram</v-icon>
                <div class="brand-text">{{ item.brand.name }}</div>
              </div>
            </div>
          </v-col>

          <v-col cols="12" sm="12" md="6" class="d-flex flex-column px-0">
            <div class="d-flex flex-row mb-4">
              <div class="mr-2">
                <v-icon class="mr-1">mdi-message-text-outline</v-icon>
              </div>
              <div class="d-flex flex-column">
                <div class="common-text text-dart">{{ item.text }}</div>
                <div class="d-flex flex-row">
                  <div class="common-text">Realizada por</div>
                  <div class="common-text text-dart mx-1">
                    {{ item.client }}
                  </div>
                  <div class="common-text">
                    {{ formatDate(item.question_date) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row mb-4">
              <div class="mr-2">
                <v-icon class="mr-1">mdi-message-reply-text-outline</v-icon>
              </div>
              <div class="d-flex flex-column">
                <div class="common-text text-dart">{{ item.answer }}</div>
                <div class="d-flex flex-row">
                  <div class="common-text text-purple" v-if="item.user">
                    Respondida por {{ item.user }}
                  </div>
                  <div class="common-text ml-1">
                    {{ formatDate(item.answer_date) }}
                  </div>
                </div>
              </div>
            </div>
          </v-col>

          <v-col
            cols="12"
            sm="12"
            md="2"
            class=" d-flex flex-column slign-center"
          >
            <div class="time-text my-2">
              {{
                calculateTimeDifference(item.question_date, item.answer_date)
              }}
            </div>
            <div class="common-text text-center">Tiempo de respuesta</div>
          </v-col>
        </v-row>
      </v-card>
      <v-row justify="end" class="mt-2 mb-4" v-if="getQuestions.length">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <div v-else class="d-flex justify-center">
      <v-progress-circular
        size="300"
        class="d-flex justify-center my-5"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </section>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      loadingQuestions: false,
      isChangingCountryIdInternally: false,
      dataCountry: [],
      page: 1,
      itemsPerPage: 12,
      country_id: "",
    };
  },

  created() {
    this.HandlerGetCountry();
  },

  watch: {
    page: {
      handler(val) {
        if (!this.isChangingCountryIdInternally) {
          this.HandlerGetQuestions(val, this.country_id);
        }
      },
      deep: true,
    },

    country_id(val) {
      this.isChangingCountryIdInternally = true;
      this.page = 1;
      this.HandlerGetQuestions(1, val);
      this.$nextTick(() => {
        this.isChangingCountryIdInternally = false;
      });
    },
  },

  computed: {
    getQuestions() {
      return this.$store.getters["store/GET_QUESTIONS"];
    },
    pagination() {
      return this.$store.getters["store/GET_PAGINATE_QUESTIONS"];
    },
  },

  methods: {
    async HandlerGetQuestions(page = 1, country_id = "AR") {
      try {
        this.loadingQuestions = true;
        const request = {
          page: page,
          per_page: this.itemsPerPage,
          paginate: true,
          countryId: country_id,
          status: "ANSWERED",
        };
        await this.$store.dispatch("store/GET_QUESTIONS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingQuestions = false;
      }
    },

    async HandlerGetCountry() {
      try {
        this.loadingQuestions = true;
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        const countries = response.data.data.filter((elem) => elem.id !== 4);
        this.country_id = countries[0].code_iso;
        this.dataCountry = countries;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingQuestions = false;
      }
    },

    formatDate(date) {
      moment.locale("es");
      const dateMoment = moment(date);
      const formatedDate = dateMoment.format("dddd D/MMM YYYY, h:mma");
      return formatedDate;
    },

    calculateTimeDifference(startDate, endDate) {
      const startDateMoment = moment(startDate);
      const endDateMoment = moment(endDate);
      const minutesAgo = endDateMoment.diff(startDateMoment, "minutes");
      const hoursAgo = endDateMoment.diff(startDateMoment, "hours");
      const daysAgo = endDateMoment.diff(startDateMoment, "days");

      if (daysAgo >= 1) {
        return `${daysAgo} día${daysAgo === 1 ? "" : "s"}`;
      } else if (hoursAgo >= 1) {
        return `${hoursAgo} hora${hoursAgo === 1 ? "" : "s"}`;
      } else {
        return `${minutesAgo} minuto${minutesAgo === 1 ? "" : "s"}`;
      }
    },
  },
};
</script>

<style>
.not-questions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.brand-text {
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.67);
  text-transform: uppercase;
  line-height: 18px;
}
.text-purple {
  color: #625fa4 !important;
}
.text-dart {
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}
.title-item {
  font-weight: 500;
  font-size: 15px;
  color: #625fa4 !important;
}
.price-text {
  font-weight: 500;
  font-size: 15px;
  color: #625fa4 !important;
}
.common-text {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.67);
}
.time-text {
  font-size: 17px;
  color: #379ac1;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
}
.text-bold {
  font-weight: 600;
}
@media only screen and (max-width: 1200px) {
  .brand-text {
    font-size: 14px;
  }
  .text-dart {
    font-size: 12px !important;
  }
  .title-item {
    font-size: 14px;
  }
  .price-text {
    font-size: 14px;
  }
  .common-text {
    font-size: 11px !important;
  }
  .time-text {
    font-size: 16px;
  }
}
</style>
