<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div
          class="font-weight-medium primary--text mb-5"
          style="font-size: 26px"
        >
          {{ status_card ? "Listados de marcas" : "Registrar Marca" }}
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn
          v-if="status_card"
          @click="registerBrand({})"
          class="accent shadow-4 px-8"
          large
          rounded
        >
          Añadir Marca
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="space-between" v-if="status_card">
      <v-col cols="12">
        <v-card class="shadow-5">
          <v-data-table
            :loading="loading"
            height="700"
            fixed-header
            :headers="headers"
            :items="dataCategory"
            hide-default-footer
            :items-per-page="-1"
            class="elevation-0"
          >
            <template v-slot:[`item.name`]="{ item }">
              <div class="d-flex">
                <v-avatar size="50" class="mr-3">
                  <img :src="item.image_url" />
                </v-avatar>
                {{ item.name }}
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex">
                <div>
                  <v-file-input
                    v-if="!loadingFilePhoto"
                    class="mb-4"
                    multiple
                    label="File input"
                    hide-input
                    prepend-icon="mdi-image-album"
                    @change="HandlerUploadPhoto(item)"
                    v-model="filePhoto"
                  ></v-file-input>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </div>
                <v-tooltip bottom v-if="action == '0'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="HandlerDialogEdit(item)"
                      icon
                      dark
                      class="mt-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon :color="isDark ? 'white' : '#6D6D6D'"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <!-- <div class="d-flex justify-end mt-3">
          <v-pagination
            :length="paginate.total"
            v-model="page"
            circle
          ></v-pagination>
        </div> -->
        <v-dialog v-model="dialog" width="500" persistent>
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Editar marcas
            </v-card-title>

            <v-card-text class="mt-5">
              <v-text-field
                label="nombre"
                v-model="name"
                filled
                rounded
              ></v-text-field>
              <v-text-field
                label="codigo"
                v-model="code"
                filled
                rounded
              ></v-text-field>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="dialog = false">
                Cancelar
              </v-btn>
              <v-btn color="primary" @click="HandlerEdit()"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-dialog v-model="showRegisterModel" max-width="600">
      <v-card>
        <v-card-text>
          <ValidationObserver ref="obs" v-slot="{ passes }">
            <ValidationProvider
              name="nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                required
                filled
                rounded
                class="rounded-small pt-5"
                dense
                label="Nombre"
                name="name"
                color="blue-grey"
                v-model="data.name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="imgen"
              rules="required"
              v-slot="{ errors }"
            >
              <v-file-input
                required
                filled
                rounded
                class="rounded-small"
                dense
                label="Imagen"
                name="name"
                color="blue-grey"
                v-model="data.image"
                prepend-icon=""
                :error-messages="errors"
              ></v-file-input>
            </ValidationProvider>
            <div class="d-flex justify-end">
              <v-btn text class="red--text" @click="showRegisterModel = false">
                Cancelar
              </v-btn>
              <v-btn class="primary" @click="passes(handlerRegister)">
                Guardar
              </v-btn>
            </div>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data() {
    return {
      loading: false,
      status_card: true,
      showRegisterModel: false,
      page: 1,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "code",
        },
        { text: "Accion", value: "action" },
      ],

      categoryName: "",
      dataSubCategory: [],
      dataUpdate: [],
      dataCategory: [],
      paginate: {},
      loadingFilePhoto: false,
      filePhoto: [],
      dialog: false,

      code: "",
      name: "",
      brand_id: "",

      data: {
        name: "",
        image: [],
      },
    };
  },

  watch: {
    page(val) {
      this.HandlerGetBrand(val);
    },
  },

  created() {
    this.HandlerGetBrand();
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    async HandlerGetBrand(page) {
      try {
        const request = {
          page: page || 1,
          per_page: 20,
          paginate: true,
          name: "",
          store_id: "",
        };
        const response = await this.$store.dispatch(
          "products/GET_BRAND",
          request
        );

        console.log("response", response);
        this.paginate = {
          total: response.data.total,
        };
        this.dataCategory = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerUploadPhoto(item) {
      try {
        this.loadingFilePhoto = true;
        const request = new FormData();
        request.append("image", this.filePhoto[0]);
        request.append("brand_id", item.id);
        // const requestData = { category_id: item.id };
        await this.$store.dispatch("products/UPDATE_IMAGE_BRAND", {
          data: request,
        });
        this.$snotify.success("Cargado con exitos", "Exitos");

        this.HandlerGetBrand();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFilePhoto = false;
      }
    },

    registerBrand(data) {
      this.showRegisterModel = true;
      this.dataRegister = { ...data };
    },

    async handlerRegister() {
      try {
        const request = new FormData();
        request.append("name", this.data.name);
        request.append("image", this.data.image);
        await this.$store.dispatch("products/CREATE_BRAND", request);
        this.HandlerGetBrand();
        this.showRegisterModel = false;
        this.$snotify.success("Registro Exitos", "Exitos");
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerEdit() {
      try {
        this.loading = true;
        const request = {
          id: this.brand_id,
          code: this.code,
          name: this.name,
        };
        console.log("request", request);
        await this.$store.dispatch("products/UPDATE_BRAND", request);
        this.$snotify.success("Cargado con exitos", "Exitos");

        this.HandlerGetBrand();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerDialogEdit(item) {
      this.dialog = true;
      this.brand_id = item.id;
      this.code = item.code;
      this.name = item.name;
    },
  },
};
</script>

<style>
</style>