var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-container',{staticClass:"mt-n0"},[_c('div',{staticClass:"font-weight-medium primary--text mb-5",staticStyle:{"font-size":"26px"}},[_vm._v(" Crear / Editar Caracteristica ")]),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}})],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Nombre","error-messages":errors},model:{value:(_vm.dataUpdateStore.name),callback:function ($$v) {_vm.$set(_vm.dataUpdateStore, "name", $$v)},expression:"dataUpdateStore.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('ValidationProvider',{attrs:{"name":"Categoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"items":_vm.dataCategories,"item-text":"name","item-value":"id","chips":"","small-chips":"","filled":"","rounded":"","dense":"","multiple":"","label":"Categoria","error-messages":errors},model:{value:(_vm.categories_id),callback:function ($$v) {_vm.categories_id=$$v},expression:"categories_id"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){return _vm.closeModal({}, false)}}},[_vm._v(" Cancelar ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"loading":_vm.loading,"rounded":"","color":"success"},on:{"click":_vm.HandlerSendData}},[_vm._v(" Guardar ")])]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }