<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="orderItem"
      :items-per-page="5"
      hide-default-footer
      class="elevation-0"
    >
      <!-- <template v-slot:[`item.product`]="{ item }">
        <v-avatar v-if="item.product.pictures == null" size="100" tile>
          <v-img contain src="@/assets/img/no_image.jpg"></v-img>
        </v-avatar>
        <v-avatar v-else size="100" tile>
          <v-img src="@/assets/img/no_image.jpg"></v-img>
        </v-avatar>
      </template> -->
      <template v-slot:[`item.quantity`]="{ item }">
        <span class="body-1" v-if="!action">
          {{ item.quantity }}
        </span>
        <span class="body-1" v-else>
          1
        </span>
      </template>
      <template v-slot:[`item.seller_sku`]="{ item }">
        <span class="body-1">
          {{ item.product.sku }}
        </span>
      </template>
      <template v-slot:[`item.product.name`]="{ item }">
        <span class="text-uppercase body-1">
          {{ item.product.name }}
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    orderItem: {
      type: Array,
      required: true,
      default: () => [],
    },
    action: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      headers: [
        {
          text: "SKU",
          class: "primary--text",
          align: "start",
          sortable: false,
          value: "seller_sku",
        },
        { text: "Producto", class: "primary--text", value: "product.name" },
        // { text: "Producto", class: "primary--text", value: "calories" },
        // { text: "Imagen", class: "primary--text", value: "product" },
        {
          text: "Codigo de barras",
          class: "primary--text",
          value: "product.barcode",
        },
        { text: "Cantidad", class: "primary--text", value: "quantity" },
      ],
    };
  },
};
</script>

<style>
</style>