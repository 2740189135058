var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('section',[_c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-between align-center"},[(_vm.actionData === 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Registrar ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
                bar: { background: '#bacfcec0', keepShow: true },
              }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Pais","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.dataCountry,"item-text":"name","item-value":"id","filled":"","rounded":"","dense":"","label":"Pais","error-messages":errors},on:{"change":_vm.HandlerCountryId},model:{value:(_vm.dataUpdateShip.country_id),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip, "country_id", $$v)},expression:"dataUpdateShip.country_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Proveedor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"disabled":_vm.dataUpdateShip.country_id == null,"items":_vm.dataProvisioner,"item-text":"name","item-value":"id","filled":"","rounded":"","dense":"","label":"Proveedor","error-messages":errors},model:{value:(_vm.dataUpdateShip.origin_id),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip, "origin_id", $$v)},expression:"dataUpdateShip.origin_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Deposito","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"disabled":_vm.dataUpdateShip.country_id == null,"items":_vm.dataWarehouse,"item-text":"name","item-value":"id","filled":"","rounded":"","dense":"","label":"Deposito que recibe","error-messages":errors},model:{value:(_vm.dataUpdateShip.finality_id),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip, "finality_id", $$v)},expression:"dataUpdateShip.finality_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"disabled":_vm.dataUpdateShip.country_id == null ||
                          _vm.actionData === 1,"items":_vm.dataProducts,"item-text":"name","item-value":"id","small-chips":"","label":"Productos","no-filter":"","filled":"","rounded":"","dense":"","search-input":_vm.searchProduct,"no-data-text":"Coloque dos caracteres (sku)","error-messages":errors},on:{"update:searchInput":function($event){_vm.searchProduct=$event},"update:search-input":function($event){_vm.searchProduct=$event},"keyup":function($event){return _vm.HandlerGetProducts()},"change":function($event){return _vm.HandlerGetProducts()}},model:{value:(_vm.dataUpdateShip.product_id),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip, "product_id", $$v)},expression:"dataUpdateShip.product_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Estatus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.statusItems,"item-text":"name","item-value":"id","filled":"","rounded":"","dense":"","label":"Estatus","error-messages":errors},model:{value:(_vm.dataUpdateShip.status_id),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip, "status_id", $$v)},expression:"dataUpdateShip.status_id"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Cantidad","error-messages":errors},model:{value:(_vm.dataUpdateShip.count),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip, "count", $$v)},expression:"dataUpdateShip.count"}})]}}],null,true)})],1)],1),(_vm.status_card)?_c('v-row',_vm._l((_vm.productsCard),function(product,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"elevation-0",class:_vm.isDark == true ? 'bg-card-dark' : 'bg-card-light'},[_c('v-card-title',{staticClass:"py-1"},[_c('div',{staticClass:"d-flex justify-space-around align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-avatar',[_c('img',{attrs:{"src":product.images,"alt":"product_img"}})])],1),_c('v-col',{attrs:{"cols":"col-9 ml-8"}},[_c('v-list-item',{staticClass:"py-0 px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-caption"}),_c('v-list-item-title',{staticClass:"font-caption text-uppercase"},[_vm._v(_vm._s(product.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"font-caption"},[_c('span',{},[_vm._v("SKU: "+_vm._s(product.sku))])]),_c('v-list-item-subtitle',{staticClass:"font-caption"},[_c('span',{},[_vm._v("Deposito: "+_vm._s(product.warehouse.name))])])],1)],1)],1),_c('v-col',{attrs:{"cols":"col-2"}},[_c('v-list-item',{staticClass:"py-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-caption"},[_vm._v("Marca: "+_vm._s(product.brand_name)+" ")]),_c('v-list-item-subtitle',{staticClass:"font-caption"},[_vm._v(" Cantidad: "+_vm._s(product.count)+" ")])],1)],1)],1)],1)],1)])],1)],1)}),1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover1 = ref.hover1;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover1 ? 'error' : 'grey'},on:{"click":function($event){return _vm.closeModal(0)}}},[_vm._v(" Cancelar ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"loading":_vm.loading,"rounded":"","color":"success"},on:{"click":_vm.HandlerRegister}},[_vm._v(" Guardar ")])]}}],null,true)})],1)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Editar "+_vm._s(_vm.dataUpdateShip.name)+" ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
                bar: { background: '#bacfcec0', keepShow: true },
              }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"disabled":"","filled":"","rounded":"","dense":"","label":"Pais","error-messages":_vm.errors},model:{value:(_vm.dataUpdateShip.products_erp[0].country.name),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip.products_erp[0].country, "name", $$v)},expression:"dataUpdateShip.products_erp[0].country.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"disabled":"","filled":"","rounded":"","dense":"","label":"Precio Base","error-messages":_vm.errors},model:{value:(_vm.dataUpdateShip.products_erp[0].base_price),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip.products_erp[0], "base_price", $$v)},expression:"dataUpdateShip.products_erp[0].base_price"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"disabled":"","filled":"","rounded":"","dense":"","label":"SKU","error-messages":_vm.errors},model:{value:(_vm.dataUpdateShip.products_erp[0].sku),callback:function ($$v) {_vm.$set(_vm.dataUpdateShip.products_erp[0], "sku", $$v)},expression:"dataUpdateShip.products_erp[0].sku"}})],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-5"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Transacciones ")]),_c('vuescroll',{attrs:{"ops":{
                bar: { background: '#bacfcec0', keepShow: true },
              }}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.dataUpdateShip.transaction),function(product,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-card',{staticClass:"elevation-0",class:_vm.isDark == true ? 'bg-card-dark' : 'bg-card-light'},[_c('v-card-title',{staticClass:"py-1"},[_c('div',{staticClass:"d-flex justify-space-around align-start"},[_c('v-row',[_c('v-col',{attrs:{"cols":"10","sm":"10","md":"10"}},[_c('v-list-item',{staticClass:"py-0 px-0",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-caption"},[_vm._v("Deposito: "),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(product.warehouse.name)+" ")])]),_c('v-list-item-title',{staticClass:"font-caption"},[_vm._v("Proveedor: "),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(product.provisioner.name)+" ")])]),_c('v-list-item-title',{staticClass:"font-caption"},[_vm._v("Tipo: "),_c('span',{staticClass:"font-weight-normal"},[_vm._v(" "+_vm._s(product.type_transaction.name)+" ")])]),_c('v-list-item-subtitle',{staticClass:"font-caption"},[_c('span',{},[_vm._v("Cantidad: "+_vm._s(product.count))])]),_c('v-list-item-subtitle',{staticClass:"font-caption"},[_c('span',{},[_vm._v("Fecha: "+_vm._s(_vm._f("date")(product.created_at)))])])],1)],1)],1)],1)],1)])],1)],1)}),1)],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover1 = ref.hover1;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover1 ? 'error' : 'grey'},on:{"click":function($event){return _vm.closeModal(0)}}},[_vm._v(" Cancelar ")])]}}],null,true)})],1)],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }