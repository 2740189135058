<template>
  <v-container fluid>
    <v-row class="mt-5 mb-5">
      <!-- <v-col cols="12" sm="4" md="4">
        <v-select
          v-model.trim="brand"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Marca"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="products"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Productos con Proyección"
          single-line
          hide-details
        ></v-select>
      </v-col> -->

      <!-- <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="days"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Dias"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-select
          :items="['FWL01', 'ROS01', 'CBA01', 'MLFULL', 'TODOS']"
          v-model.trim="warehouses"
          filled
          rounded
          dense
          class="rounded-small"
          name="Deposito"
          label="Deposito"
          single-line
          hide-details
        ></v-select>
      </v-col> -->

      <!-- <v-col cols="12" sm="4" md="4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              filled
              dense
              v-model="month"
              label="Picker in menu"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="month" type="month" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(month)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col> -->

      <!-- <v-col cols="12"> Suma total : {{ total | currency }} </v-col> -->

      <!-- <v-col class="d-flex justify-end" cols="12" md="12">
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetProfitabilityControl()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Ventas Totales: </span>
          <div class="text-right">{{ total.net_pvp | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Costos Totales: </span>
          <div class="text-right">{{ total.net_cost | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total OP FEE:  </span>
          <div class="text-right">{{ total.op_fee | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total In HAND:  </span>
          <div class="text-right"> {{ total.in_hand | currency }} </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total Flete:  </span>
          <div class="text-right">{{ total.freight | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total Payment :  </span>
          <div class="text-right">{{ total.payment | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total Market :  </span>
          <div class="text-right">{{ total.market | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total Marketing :  </span>
          <div class="text-right">{{ total.marketing | currency }}</div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Total IIBB :  </span>
          <div class="text-right">{{ total.iibb | currency }} </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Rent Total :  </span>
<div class="text-right">{{ total.rent_value | currency }} </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="2">
        <v-card class="shadow-1 px-2 py-2">
          <span class="font-weight-bold">Rent Total % :  </span>
          <div class="text-right">{{ total.rent_percentage_total }} % </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card class="shadow-1">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="data"
            :items-per-page="-1"
            class="elevation-0"
            disable-paginatio
            hide-default-footer
            fixed-header
            height="600"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <!-- <template v-slot:[`item.fw01`]="{ item }">
              <span>{{
                warehouseValue(item, 5).current_stock 
              }}</span>
            </template>
            <template v-slot:[`item.cba01`]="{ item }">
              <span>{{
                warehouseValue(item, 3).current_stock 
              }}</span>
            </template>
            <template v-slot:[`item.mlfull`]="{ item }">
              <span>{{
                warehouseValue(item, 8).current_stock 
              }}</span>
            </template>
            <template v-slot:[`item.ros01`]="{ item }">
              <span>{{
                warehouseValue(item, 10).current_stock 
              }}</span>
            </template> -->
            <template v-slot:[`item.meli_id`]="{ item }">
              <span style="cursor: pointer" @click="HandlerShowModal(item)">
                {{ item.meli_id }}
              </span>
            </template>
            <template v-slot:[`item.net_pvp`]="{ item }">
              <span>{{ item.net_pvp | currency }}</span>
            </template>
            <template v-slot:[`item.net_cost`]="{ item }">
              <span v-if="item.net_cost > 0">{{
                item.net_cost | currency
              }}</span>
              <span v-else>0</span>
            </template>
            <template v-slot:[`item.op_fee`]="{ item }">
              <span>{{ item.op_fee | currency }}</span>
            </template>
            <template v-slot:[`item.in_hand`]="{ item }">
              <span>{{ item.in_hand | currency }}</span>
            </template>
            <template v-slot:[`item.freight`]="{ item }">
              <span>{{ item.freight | currency }}</span>
            </template>
            <template v-slot:[`item.payment`]="{ item }">
              <span v-if="item.payment > 0">{{ item.payment | currency }}</span>
              <span v-else>0</span>
            </template>
            <template v-slot:[`item.market`]="{ item }">
              <span v-if="item.market > 0">{{ item.market | currency }}</span>
              <span v-else>0</span>
            </template>
            <template v-slot:[`item.marketing`]="{ item }">
              <span>{{ item.marketing | currency }}</span>
            </template>
            <template v-slot:[`item.iibb`]="{ item }">
              <span>{{ item.iibb | currency }}</span>
            </template>
            <template v-slot:[`item.rent_value`]="{ item }">
              <span>{{ item.rent_value | currency }}</span>
            </template>
            <template v-slot:[`item.rent_percentage`]="{ item }">
              <span>{{ item.rent_percentage }} %</span>
            </template>

            <template v-slot:[`item.id`]="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>

    <!-- MODAL -->

    <v-dialog v-model="showModal" max-width="900">
      <v-data-table
        :loading="loading"
        :headers="headersOrderItem"
        :items="orderItem.order_item"
        :items-per-page="-1"
        class="elevation-0"
        disable-paginatio
        hide-default-footer
        height="auto"
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.sku`]="{ item }">
          <span
            v-if="item.publication != null && item.publication.product != null"
          >
            {{ item.publication.product.sku }}
          </span>
        </template>
        <template v-slot:[`item.pub_name`]="{ item }">
          <span v-if="item.publication != null">
            {{ item.publication.keywords }}
          </span>
        </template>
        <template v-slot:[`item.prod_name`]="{ item }">
          <span
            v-if="item.publication != null && item.publication.product != null"
          >
            {{ item.publication.product.name }}
          </span>
        </template>
        <template v-slot:[`item.depos`]="{ item }">
          <span v-if="item.warehouse != null">
            {{ item.warehouse.name }}
          </span>
        </template>
        <template v-slot:[`item.bar_code`]="{ item }">
          <span
            v-if="item.publication != null && item.publication.product != null"
          >
            {{ item.publication.product.barcode }}
          </span>
        </template>
        <template v-slot:[`item.unit_price`]="{ item }">
          <span
            v-if="item.publication != null && item.publication.price != null"
          >
            {{ item.publication.price.pvp | currency }}
          </span>
        </template>
      </v-data-table>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // filters
      brand: "",
      products: "",
      loadingFilters: false,

      // Data
      loading: false,
      sku: "",
      name: "",
      days: 30,

      total: 0,

      // Table
      headers: [
        {
          text: "Operacion ID",
          align: "start",
          sortable: false,
          value: "meli_id",
          width: 20,
        },
        { text: "PVP NETO", align: "start", value: "net_pvp", width: 50 },
        { text: "COSTO NETO", value: "net_cost" },
        { text: "OP FEE", value: "op_fee" },
        { text: "In HAND", value: "in_hand" },
        { text: "FLETE", value: "freight" },
        { text: "PAYMENT", value: "payment", width: 20 },
        { text: "MARKET", value: "market" },
        { text: "MARKETING", value: "marketing" },
        { text: "IIBB", value: "iibb" },
        { text: "RENT $", value: "rent_value" },
        { text: "RENT %", value: "rent_percentage", width: 20 },
        { text: "FECHA", value: "created_at" },
      ],

      // TABLE ORDER ITEM
      headersOrderItem: [
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "quantity",
          width: 20,
        },
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "sku",
        },
        {
          text: "Nombre Publicación",
          align: "start",
          sortable: false,
          value: "pub_name",
        },
        {
          text: "Nombre Producto",
          align: "start",
          sortable: false,
          value: "prod_name",
        },
        {
          text: "Depósito",
          align: "start",
          sortable: false,
          value: "depos",
        },
        {
          text: "Código de barras",
          align: "start",
          sortable: false,
          value: "bar_code",
        },
        {
          text: "Precio unitario",
          align: "start",
          sortable: false,
          value: "unit_price",
        },
      ],

      data: [],
      showModal: false,
      orderItem: {},

      month: "",
      menu: false,

      // Pagination
      page: 1,
      pagination: {},
    };
  },

  created() {
    this.HandlerGetProfitabilityControl();
  },

  watch: {
    page(val) {
      console.log(val);
      this.HandlerGetProfitabilityControl(val);
    },
  },

  methods: {
    HandlerReset() {
      this.days = 0;
      (this.sku = ""), (this.name = "");
      this.HandlerGetProfitabilityControl(this.page);
    },
    async HandlerGetProfitabilityControl(page) {
      try {
        this.loading = true;
        const request = {
          page: page || 1,
          per_page: 20,
        };
        const response = await this.$store.dispatch(
          "stocks/GET_PROFITABILITY_CONTROL",
          request
        );

        console.log(response.data.data);
        this.data = response.data.data;
        this.total = response.data.total;
        // this.pagination = {
        //   lastPage: response.data.data.lastPage,
        //   page: response.data.data.page,
        //   perPage: response.data.data.perPage,
        //   total: response.data.data.total,
        // };
        // console.log(response.data.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerShowModal(item) {
      console.log(item);
      this.showModal = true;
      this.orderItem = { ...item };
    },

    // warehouseValue(item, value) {
    //   console.log('lelelelle', item)
    //   const warehouse = item.product_warehouse.find(
    //     (warh) => warh.warehouse_id == value
    //   );
    //   if (warehouse != undefined) {
    //     return warehouse;
    //   } else return "";
    // },
  },
};
</script>

<style>
</style>