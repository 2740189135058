<template>
  <section>
    <div class="font-weight-medium primary--text mb-5" style="font-size: 26px">
      {{ action == 0 ? "Nuevo" : "Editar" }} Proveedor
    </div>
    <v-card class="mb-5">
      <v-card-title class="pa-0 shadow-1">
        <v-img
          :src="require('@/assets/illustrations/bg_modal_card.svg')"
          cover
          position="left bottom"
          class="align-center"
          height="65px"
        >
          <v-card-title class="font-weight-medium primary--text">
            Datos personales
          </v-card-title>
        </v-img>
      </v-card-title>

      <vuescroll
        :ops="{
          bar: { background: '#bacfcec0', keepShow: true },
        }"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Como identificarlo"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Nombre"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Razon Social"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Nombre Fantasia"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Categoría Impositiva"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Personería"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Email"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Email facturazion"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  hint="Si deseas ingresar más de 1 dirección, separa las direcciones mediante una coma."
                  persistent-hint
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </vuescroll>
    </v-card>
    <v-divider class="mx-15 mb-6"></v-divider>
    <v-card class="mb-5">
      <v-card-title class="font-weight-medium primary--text">
        Retenciones
      </v-card-title>
      <v-card-subtitle class="font-weight-medium primary--text"
        >Se muestran los campos correspondientes a retenciones.
      </v-card-subtitle>
      <vuescroll
        :ops="{
          bar: { background: '#bacfcec0', keepShow: true },
        }"
      >
        <v-card-text>
          <p class="font-weight-medium">Ingresos brutos</p>
          <small>¿Es exento? (No se calculan retenciones)</small>
          <v-row class="ml-1">
            <v-checkbox v-model="checkboxAGIP" label="AGIP"></v-checkbox>
            <v-checkbox
              class="ml-4"
              v-model="checkboxARBA"
              label="ARBA"
            ></v-checkbox>
          </v-row>
        </v-card-text>
      </vuescroll>
    </v-card>
    <v-divider class="mx-15 mb-6"></v-divider>
    <v-card class="mb-5">
      <v-card-title class="font-weight-medium primary--text">
        Ganancias
      </v-card-title>
      <v-card-subtitle class="font-weight-medium primary--text"
        >Se muestran los campos correspondientes a ganancias.
      </v-card-subtitle>

      <vuescroll
        :ops="{
          bar: { background: '#bacfcec0', keepShow: true },
        }"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Situación en el impuesto a las Ganancias"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Regimen de ganancias por defecto"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </vuescroll>
    </v-card>
    <v-divider class="mx-15 mb-6"></v-divider>
    <v-card class="mb-5">
      <v-card-title class="font-weight-medium primary--text">
        Percepciones
      </v-card-title>
      <v-card-subtitle class="font-weight-medium primary--text"
        >Se muestran los campos correspondientes a percepciones.
      </v-card-subtitle>

      <vuescroll
        :ops="{
          bar: { background: '#bacfcec0', keepShow: true },
        }"
      >
        <v-card-text>
          <p class="font-weight-medium">Ingresos brutos</p>
          <small>¿Es exento? (No se calculan retenciones)</small>
          <v-row class="ml-1">
            <v-checkbox v-model="checkboxAGIP" label="AGIP"></v-checkbox>
            <v-checkbox
              class="ml-4"
              v-model="checkboxARBA"
              label="ARBA"
            ></v-checkbox>
          </v-row>
        </v-card-text>
      </vuescroll>
    </v-card>
    <v-divider class="mx-15 mb-6"></v-divider>
    <v-card class="mb-5">
      <v-card-title class="font-weight-medium primary--text">
        Domicilio
      </v-card-title>
      <v-card-subtitle class="font-weight-medium primary--text"
        >Estos campos no son obligatorios.
      </v-card-subtitle>

      <vuescroll
        :ops="{
          bar: { background: '#bacfcec0', keepShow: true },
        }"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Pais"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Ciudad"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Localidad"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Domicilio"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Piso"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Codigo Postal"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Teléfono"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </vuescroll>
    </v-card>
    <v-divider class="mx-15 mb-6"></v-divider>
    <v-card class="mb-5">
      <v-card-title class="font-weight-medium primary--text">
        Datos complementarios
      </v-card-title>
      <v-card-subtitle class="font-weight-medium primary--text">
        Estos datos no son necesarios, pero podrían serte de utilidad ;-)
      </v-card-subtitle>

      <vuescroll
        :ops="{
          bar: { background: '#bacfcec0', keepShow: true },
        }"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="8">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Categorias"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Límite Descubierto"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <ValidationProvider name="largo" v-slot="{ errors }">
                <v-textarea
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Observaciones"
                  name="long"
                  prepend-inner-icon="mdi-card-text"
                  color="blue-grey"
                  :error-messages="errors"
                ></v-textarea>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </vuescroll>
    </v-card>
    <v-row justify="end">
      <v-hover v-slot:default="{ hover }">
        <v-btn text rounded :color="hover ? 'error' : 'grey'" class="mr-2"
          >Cancelar</v-btn
        >
      </v-hover>
      <v-hover v-slot:default="{ hover }">
        <v-btn
          :class="hover ? 'shadow-2' : 'shadow-4'"
          rounded
          color="success"
          class="mr-4 mb-2"
          >Continuar</v-btn
        >
      </v-hover>
    </v-row>
  </section>
</template>

<script>
import vuescroll from "vuescroll";
export default {
  components: {
    vuescroll,
  },
  props: {
    action: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      checkboxAGIP: false,
      checkboxARBA: false,
    };
  },
};
</script>

<style>
</style>