<template>
  <div v-if="order.ticket_name != null">
    <div v-if="order.downloaded_by != null">
      <div v-if="order.date_in_transit == null">
        <div v-if="order.shipping_method_name == 'Chazki72'">
          <p class="small" style="margin-bottom: 0px">
            <!-- Adjuntar factura a esta venta -->
          </p>
          <v-btn color="#2dace1"> Factura ya impresa </v-btn>
        </div>
      </div>
      <v-btn
        @click="HandlerPrintBill(order, 'ticket')"
        dark
        block
        color="#F5AB36"
      >
        Reimprimir etiqueta
      </v-btn>
      <p class="mb-0" v-if="order.user_prepared != null">
        <span class="font-weight-bold subtitle-2 mr-1">Descargada por:</span>
        <span>{{ order.user_prepared.email }}</span>
      </p>
      <p class="mb-0" v-if="order.user_transit != null">
        <span class="font-weight-bold subtitle-2 mr-1">Descargada por:</span>
        <span>{{ order.user_transit.email }}</span>
      </p>
      <p class="mb-0" v-if="order.user_delivered != null">
        <span class="font-weight-bold subtitle-2 mr-1">Descargada por:</span>
        <span>{{ order.user_delivered.email }}</span>
      </p>
    </div>
    <div class="my-2" v-else>
      <div
        v-if="
          (order.shipping_method_name == 'Chazki72' ||
            order.shipping_method_name == 'chazki' ||
            order.shipping_method_name == 'zippin') &&
          order.order_invoice == null
        "
      >
        <!-- <p style="margin-bottom: 0px">Adjuntar factura a esta venta</p> -->
        <v-btn
          @click="HandlerPrintBill(order, 'invoice')"
          block
          color="#2dace1"
          class="white--text"
          :disabled="order.order.meli_proof !== null ? true : false"
        >
          Imprimir Factura
        </v-btn>
      </div>
      <div
        v-if="
          (order.shipping_method_name == 'Chazki72' ||
            order.shipping_method_name == 'chazki' ||
            order.shipping_method_name == 'Logis24' ||
            order.shipping_method_name == 'zippin') &&
          order.order_invoice !== null &&
          (order.status_invoice_show == 1 || order.status_invoice_show == null)
        "
      >
        <!-- <p style="margin-bottom: 0px">Adjuntar factura a esta venta</p> -->
        <v-btn
          @click="HandlerPrintBill(order, 'invoice')"
          block
          color="#2dace1"
          class="white--text"
          :disabled="order.order_invoice == null ? true : false"
        >
          Imprimir Factura
        </v-btn>
      </div>
      <div v-if="order.shipping_method_name == 'Chazki72'">
        <v-btn
          @click="HandlerPrintBill(order, 'ticket')"
          block
          dark
          color="#F4A425"
        >
          Reimprimir Etiqueta a
        </v-btn>
      </div>
      <div v-else>
        <v-btn
          v-if="order.shipping_method_name == 'chazki'"
          :loading="loadingPrintBill"
          @click="HandlerPrintBill(order, 'ticket')"
          block
          color="#F5AB36"
          :disabled="order.order.meli_proof == null ? true : false"
          class="white--text"
        >
          Imprimir Etiqueta
        </v-btn>
        <v-btn
          v-else-if="order.shipping_method_name == 'zippin'"
          :loading="loadingPrintBill"
          @click="HandlerPrintBill(order, 'ticket')"
          block
          color="#F5AB36"
          :disabled="order.order.meli_proof == null ? true : false"
          class="white--text"
        >
          Imprimir Etiqueta
        </v-btn>
        <v-btn
          v-else-if="order.order_invoice !== null"
          :loading="loadingPrintBill"
          @click="HandlerPrintBill(order, 'ticket')"
          block
          color="#F5AB36"
          class="white--text"
          :disabled="order.order.meli_proof == null ? true : false"
        >
          Imprimir Etiqueta
        </v-btn>
        <v-btn
          v-else
          :loading="loadingPrintBill"
          @click="HandlerPrintBill(order, 'ticket')"
          block
          dark
          color="#F5AB36"
        >
          Imprimir Etiqueta
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      loadingPrintBill: false,
    };
  },

  methods: {
    async HandlerPrintBill(order, type) {
      console.log("order_invoice", order);
      try {
        this.loadingPrintBill = true;
        const id = order.id;
        
        let path = "";

        if (type == "ticket") {
          path = order?.ticket_name || order.order.path_label;
        } else {
          path =
            order?.order_invoice.invoice_path_label ||
            order.order.invoice_path_label;
        }
        const name = path.split("/");
        const request = {
          path: path,
          id: id,
          type: type,
          country_id: 1,
        };

        console.log(request);
        const response = await this.$store.dispatch(
          "orders/PRINT_LABEL",
          request
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPrintBill = false;
      }
    },
  },
};
</script>

<style>
</style>