<template>
  <v-container fluid class="mt-5">
    <v-row class="mt-5 mb-5 d-flex">
      <v-col cols="12" sm="4" md="3">
        <!-- {{ dataReservation }} -->
        <v-select
          @change="HandlerGetUserSubscription()"
          v-model.trim="store_id"
          :items="dataReservation"
          item-text="name"
          item-value="id"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Tiendas"
          single-line
          hide-details
        ></v-select>
      </v-col>

      <v-col class="d-flex justify-end ml-auto" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetUserSubscription()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card class="shadow-1 mt-5">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="dataCoupon"
            disable-pagination
            hide-default-footer
            class="elevation-0"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <!-- <template v-slot:[`item.first_name`]="{ item }">
              <div>{{ item.first_name }} {{ item.last_name }}</div>
            </template> -->
            <template v-slot:[`item.doc_type`]="{ item }">
              <div>{{ item.doc_type }}: {{ item.doc_number }}</div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      is_active: true,
      store_id: 12,
      headers: [
        { text: "Nombre", sortable: false, value: "first_name" },
        { text: "Email", sortable: false, value: "email" },
        { text: "Telefono", sortable: false, value: "phone" },
        { text: "Documento", sortable: false, value: "doc_type" },
        { text: "Descripcion", sortable: false, value: "description" },
        // { text: "Publicacion", sortable: false, value: "publication.keywords" },
        // { text: "Accion", sortable: false, value: "action" },
      ],
      dataReservation: [],
      dataCoupon: [],
      page: 1,
      pagination: {},
      sku: "",
    };
  },

  created() {
    this.HandlerGetUserSubscription();
    this.HandlerGetStore();
  },

  watch: {
    page(val) {
      this.HandlerGetUserSubscription(val);
    },
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    updateData(input) {
      this.store_id = input.replace(" ", "");
    },

    async HandlerGetUserSubscription(page = 1) {
      try {
        this.loading = true;
        const request = {
          store_id: this.store_id,
          page: page,
          per_page: 20,
          paginate: true,
        };

        const response = await this.$store.dispatch(
          "publications/GET_USER_SUBSCRIPTIONS",
          request
        );

        this.dataCoupon = response.data.data.data;
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.meli_id = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetStore() {
      try {
        this.loading = true;
        const request = {
          store_id: this.store_id,
          page: "",
          per_page: "",
          paginate: false,
        };

        const response = await this.$store.dispatch("store/GET_STORE", request);

        this.dataReservation = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerUpdateUserNotificationProduct(item) {
      try {
        this.loading = true;
        const request = {
          id: item.id,
          is_active: !item.is_active,
        };

        await this.$store.dispatch(
          "publications/UPDATE_PRODUCT_NOTIFICATION",
          request
        );

        this.HandlerGetUserSubscription();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerDeleteUserNotificationProduct(item) {
      try {
        this.loading = true;
        const request = {
          id: item.id,
        };

        await this.$store.dispatch(
          "publications/DELETE_PRODUCT_NOTIFICATION",
          request
        );

        this.HandlerGetUserSubscription();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerReset() {
      this.store_id = "";
      this.is_active = true;
      this.sku = "";
      this.HandlerGetUserSubscription();
    },

    percentage(item) {
      const percentage =
        100 -
        ((item.unit_price - item.amount_coupons) * 100) / item.full_unit_price;
      return percentage.toFixed(2);
    },
  },
};
</script>

<style>
</style>