var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('div',{staticClass:"font-weight-medium primary--text mb-5 ml-3",staticStyle:{"font-size":"26px"}},[_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar" : "Añadir")+" Especificación ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"mb-5 px-2 py-2",attrs:{"height":"auto"}},[(_vm.loader)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":"","justify":"start"}},[_c('v-row',{staticStyle:{"margin":"0px"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre de especificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre","name":"Nombre de especificación","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.specificationData.name),callback:function ($$v) {_vm.$set(_vm.specificationData, "name", $$v)},expression:"specificationData.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Unidad de especificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.specifications,"item-text":"title","item-value":"value","required":"","filled":"","rounded":"","dense":"","label":"Unidad","name":"Unidad de especificación","color":"blue-grey","error-messages":errors},model:{value:(_vm.specificationData.measuring_unit),callback:function ($$v) {_vm.$set(_vm.specificationData, "measuring_unit", $$v)},expression:"specificationData.measuring_unit"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey',"disabled":_vm.loader},on:{"click":function($event){return _vm.closeModal(null, false)}}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loader,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerRegister)}}},[_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar" : "Añadir")+" ")])]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }