<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
        v-if="action == 0"
      >
        Crear Usuarios
      </div>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
        v-else
      >
        Editar Usuarios
      </div>
      <v-col cols="12">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-title class="pa-0">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
              </v-img>
            </v-card-title>

            <v-card-text>
              <v-form>
                <v-row dense :justify="action == 0 ? 'center' : 'start'">
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="nombre"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Nombre de usuario"
                        name="nombre"
                        prepend-inner-icon="mdi-account"
                        type="text"
                        color="blue-grey"
                        v-model="data.username"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="correo"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Correo"
                        name="email"
                        prepend-inner-icon="mdi-email"
                        type="text"
                        color="blue-grey"
                        v-model="data.email"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="IP"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="IP"
                        name="ip"
                        prepend-inner-icon="mdi-map-marker"
                        type="text"
                        color="blue-grey"
                        v-model="data.ip"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="rol"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="roles"
                        item-text="name"
                        item-value="id"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Roles"
                        name="rol"
                        prepend-inner-icon="mdi-account-multiple"
                        type="text"
                        color="blue-grey"
                        v-model="rolesID"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="password"
                      v-slot="{ errors }"
                      :rules="
                        action === 0
                          ? 'required|confirmed:confirmation'
                          : 'confirmed:confirmation'
                      "
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Contraseña"
                        name="contraseña"
                        prepend-inner-icon="mdi-key"
                        type="password"
                        color="blue-grey"
                        v-model="data.password"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    v-if="action === 0 || data.password"
                  >
                    <ValidationProvider
                      name="confirmar contraseña"
                      rules="required"
                      v-slot="{ errors }"
                      vid="confirmation"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Confirmar Contraseña"
                        name="confirmar contraseña"
                        prepend-inner-icon="mdi-key"
                        type="password"
                        color="blue-grey"
                        v-model="data.confirm_password"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal(null, false)"
                  :disabled="loading"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerAction)"
                  >{{ action == 1 ? "Editar" : "Añadir" }}</v-btn
                >
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
    <custom-modal
      v-if="showConfirmModal"
      :active="showConfirmModal"
      :action="HandlerUpdate"
      :closeModal="HandlerCloseModal"
      confirmBtn
      :title="'Confirmar cambio de contraseña'"
      max-width="600"
      :withImageTitle="false"
      closeText="cancelar"
      :loader="loading"
    >
      <div class="delete-message">
        ¿Seguro que desea editar la contraseña del usuario?
      </div>
    </custom-modal>
  </v-container>
</template>

<script>
import CustomModal from "../../CustomModal/customModal.vue";
export default {
  components: {
    "custom-modal": CustomModal
  },
  props: {
    action: {
      type: Number,
      required: false,
      default: 0
    },
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    roles: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      rolesID: "",
      showConfirmModal: false
    };
  },
  created() {
    if (this.action == 1) {
      this.rolesID = this.data.roles.map(val => val.id)[0];
      console.log(this.rolesID);
    }
  },
  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    HandlerAction() {
      if (this.action == 0) this.HandlerRegister();
      else {
        if (this.data.password) {
          this.showConfirmModal = true;
        } else {
          this.HandlerUpdate();
        }
      }
    },

    async HandlerRegister() {
      try {
        this.loading = true;
        const request = {
          username: this.data.username,
          password: this.data.password,
          ip: this.data.ip,
          email: this.data.email,
          roles: [this.rolesID]
        };
        const response = await this.$store.dispatch(
          "usersIndex/REGISTER_USER",
          request
        );
        this.$snotify.success("Usuario creado con éxito.", "¡Hecho!");
        this.closeModal(response.data, 1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerUpdate() {
      try {
        this.loading = true;
        const request = {
          data: {
            username: this.data.username,
            password: this.data.password,
            ip: this.data.ip,
            email: this.data.email,
            roles: [this.rolesID]
          },
          id: this.data.id
        };
        if (this.data.password) {
          const requestPass = {
            data: {
              password: this.data.password,
              password_confirmation: this.data.confirm_password
            },
            id: this.data.id
          };
          await this.$store.dispatch("usersIndex/UPDATE_PASSWORD", requestPass);
          this.$snotify.success("Contraseña editada con éxito.", "¡Hecho!");
        }
        const response = await this.$store.dispatch(
          "usersIndex/UPDATE_USERS",
          request
        );
        this.$snotify.success("Usuario editado con éxito.", "¡Hecho!");
        this.closeModal(response.data, 2);
      } catch (error) {
        console.log(error);
        this.$snotify.error(`Error al editar el usuario.`, "¡Error!");
      } finally {
        this.loading = false;
      }
    },

    HandlerCloseModal() {
      this.loading = false;
      this.showConfirmModal = false;
    }
  }
};
</script>

<style></style>
