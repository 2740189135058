<template>
  <div>
    <div class="mt-3">
      <p
        class="mb-0"
        v-if="
          shipping.status != 'cancelled' &&
          shipping.logistic_type != 'fulfillment' &&
          shipping.shipping_mode != 'to_be_agreed' &&
          shipping.shipping_mode != 'Chazki' &&
          shipping.shipping_mode != 'CABA 24'
        "
      >
        <span class="subtitle-2">Logis (tradicional)</span>
      </p>

      <p
        class="mb-0"
        v-else-if="
          shipping.status != 'cancelled' &&
          shipping.logistic_type != 'fulfillment' &&
          shipping.shipping_mode == 'to_be_agreed'
        "
      >
        <span class="subtitle-2">Envío a Coordinar</span>
      </p>
      <p
        class="mb-0"
        v-else-if="
          shipping.status != 'cancelled' &&
          shipping.logistic_type == 'fulfillment'
        "
      >
        <span style="color: green; font-weight: bold"> Logis (FULL) </span>
      </p>
      <p class="mb-0" v-else-if="shipping.shipping_mode == 'Chazki'">
        <span class="subtitle-2"> Envío Chazki </span>
      </p>
      <p class="mb-0" v-else-if="shipping.shipping_mode == 'CABA 24'">
        <span class="subtitle-2"> Envío CABA 24 </span>
      </p>
      <p class="mb-0" v-else>
        <span style="color: red; font-weight: bold"> Envío Cancelado </span>
      </p>
    </div>

    <p class="mb-1 subtitle-2" color="primary">
      <!-- <span class="subtitle-2 font-weight-bold"> Usuario: </span> -->
      <span
        class="body-1 font-weight-bold"
        v-if="
          shipping.order.buyer.first_name != null ||
          shipping.order.buyer.last_name != null
        "
      >
        {{
          `${shipping.order.buyer.first_name} ${shipping.order.buyer.last_name}`
        }}
      </span>
      <span v-else> Sin Datos </span>
    </p>
    <p
      class="mb-0"
      @click="HandlerCopy(shipping.order.buyer.email)"
      style="cursor: pointer"
    >
      <span class="subtitle-2 font-weight-bold">Email: </span>
      {{ shipping.order.buyer.email }}
    </p>
    

    <p v-if="shipping.order.store_id == 0" class="mb-0">
      <span class="subtitle-2 font-weight-bold mb-0">
        Fecha de creación de la orden (ML):
      </span>
      <br>
      {{ shipping.date_created | date1 }}
      a las
      {{ shipping.date_created | dateHRS }}
    </p>
    <p v-else class="mb-0">
      <span class="subtitle-2 font-weight-bold"> Creación (En Sistema) </span>
      {{ shipping.created_at }}
    </p>
  </div>
</template>

<script>
import moment from "moment-timezone";
export default {
  props: {
    shipping: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  filters: {
    date1(val) {
      return moment(val).locale("es").format("YYYY-MM-DD");
    },

    dateHRS(val) {
      return moment(val).locale("es").format("HH:mm");
    },

    date2() {
      return moment().format("DD-MM-YYYY");
    },

    hour2(value) {
      const hourParse = moment(value).format("HH:mm");
      const parseTimeZone = moment(hourParse, "HH:mm")
        .tz("America/Argentina/Buenos_Aires")
        .format("H:m");
      return parseTimeZone;
    },

    dateHour(val) {
      return moment(val).locale("es").format("DD-MM-YYYY HH:mm");
    },
  },

  methods: {
    async HandlerCopy(email) {
      await this.$copyText(email);
      this.$snotify.success(`Correo copaido`, "Exitos");
    },
  },
};
</script>

<style>
</style>