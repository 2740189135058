<template>
  <v-container>
    <ValidationObserver ref="obs" v-slot="{}">
      <div class="d-flex justify-space-between">
        <div
          class="font-weight-medium primary--text mb-5"
          style="font-size: 26px"
        >
          Crear / Editar Publicaciones
        </div>
        <v-btn icon @click="closeModal({}, false)">
          <v-icon size="32" color="primary">mdi-arrow-left-bold</v-icon>
        </v-btn>
      </div>
      <v-row>
        <v-col cols="12">
          <v-card class="mb-5">
            <vuescroll
              :ops="{
                bar: { background: '#bacfcec0', keepShow: true }
              }"
            >
              <v-card-title class="font-weight-medium primary--text">
                Datos
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="tienda"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="data_stores"
                        item-text="name"
                        item-value="id"
                        v-model="store_id"
                        small-chips
                        label="Tienda"
                        filled
                        rounded
                        dense
                        :search-input.sync="searchStore"
                        no-data-text="No hay datos para mostrar"
                        :error-messages="errors"
                        @change="getSelectStore"
                      >
                      </v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <v-autocomplete
                      :disabled="action == 1 || !store_id"
                      :items="data_products"
                      :loading="searchLoading"
                      item-text="name"
                      item-value="id"
                      v-model="dataUpdate.product_id"
                      small-chips
                      label="Productos"
                      no-filter
                      filled
                      rounded
                      dense
                      :search-input.sync="searchProduct"
                      no-data-text="Coloque dos caracteres (sku)"
                      @change="productSelect"
                    >
                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="titulo"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Titulo"
                        filled
                        dense
                        rounded
                        v-model="dataUpdate.keywords"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="3" v-if="store_id != 3">
                    <ValidationProvider
                      name="logo"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <div class="d-flex">
                      <v-file-input
                        :disabled="store_id == 1"
                        class="mr-2"
                        prepend-inner-icon="mdi-file-image"
                        prepend-icon
                        filled
                        dense
                        rounded
                        accept="image/*"
                        label="Inserte logo marca"
                        :error-messages="errors"
                        v-model="file_logo"
                      ></v-file-input>
                      <v-avatar v-if="action == 1">
                              <img
                                class="ml-0"
                                :src="dataUpdate.logo"
                                alt="Logo Publicaciones"
                              />
                            </v-avatar>
                      </div>
                    </ValidationProvider>
                  </v-col> -->

                  <!-- <v-col
                    cols="12"
                    sm="6"
                    md="3"
                    v-if="store_id != 3 && store_id != 5 && store_id != 6"
                  >
                    <ValidationProvider
                      name="MercadoLibre"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        :disabled="store_id == 1"
                        filled
                        dense
                        rounded
                        label="URL MercadoLibre"
                        :error-messages="errors"
                        v-model="dataUpdate.url_meli"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="umbral"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Umbral"
                        filled
                        dense
                        rounded
                        type="number"
                        v-model="dataUpdate.threshold"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider name="pvp" rules="" v-slot="{ errors }">
                      <v-text-field
                        readonly
                        label="PVP Base"
                        v-model="pvpBase"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="3" v-if="action == 0">
                    <ValidationProvider
                      name="% >> PVP"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="% >> PVP"
                        v-model="pvpPer"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col> -->

                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      nanme="Descuento sobre PVP Base"
                      v-slot="{ errors }"
                      :rules="{ maxDiscount: true }"
                    >
                      <v-text-field
                        label="Descuento sobre PVP Base"
                        v-model="dataUpdate.price.discount"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="PVP"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        readonly
                        label="PVP"
                        v-model="getPvp"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="14" sm="6" md="3">
                    <ValidationProvider
                      nanme="Descuento sobre PVP transferencias"
                      v-slot="{ errors }"
                      :rules="{ maxDiscount: true }"
                    >
                      <v-text-field
                        label="Descuento sobre PVP transferencias"
                        v-model="dataUpdate.price.transfer_discount"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="PVP transferencias"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        readonly
                        label="PVP transferencias"
                        v-model="getPvpTransfer"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-show="false">
                    <!-- v-if="store_id == 1 || store_id == 3" -->
                    <v-text-field
                      label="Porcentaje 18 cuotas"
                      v-model="percentage_18_installments"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="3" v-show="false">
                    <!-- v-if="store_id == 1 || store_id == 3" -->
                    <v-text-field
                      label="Porcentaje en transferencias"
                      v-model="percentage_transfer"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3" class="d-flex justify-start">
                    <div class="d-flex">
                      <v-checkbox
                        class="mx-2"
                        v-model="dataUpdate.out_stock"
                        label="Sin Stock"
                      ></v-checkbox>
                      <v-checkbox
                        v-model="dataUpdate.featured"
                        label="Destacado"
                        class="ml-auto"
                      ></v-checkbox>
                    </div>
                  </v-col>

                  <!-- <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    v-if="store_id == 5 || store_id == 6"
                  >
                    <v-text-field
                      label="Disponibilidad Alta"
                      v-model="dataUpdate.high_availability"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    v-if="store_id == 5 || store_id == 6"
                  >
                    <v-text-field
                      label="Disponibilidad Baja"
                      v-model="dataUpdate.low_availability"
                      filled
                      rounded
                      dense
                    ></v-text-field>
                  </v-col> -->
                  <!-- <v-col
                    cols="12"
                    sm="3"
                    md="3"
                    v-if="store_id == 5 || store_id == 6"
                  >
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          filled
                          rounded
                          dense
                          v-model="dataUpdate.availability_date"
                          label="Fecha disponibilidad"
                          prepend-icon=""
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="es-us"
                        no-title
                        v-model="dataUpdate.availability_date"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->

                  <!-- <v-col cols="12" v-if="store_id == 5 || store_id == 6">
                    <v-row>
                      <v-col cols="12" sm="3" md="2">
                        <v-text-field
                          label="Cantidad"
                          v-model="quantity"
                          filled
                          rounded
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" md="2">
                        <v-text-field
                          label="Descuento"
                          v-model="discount"
                          filled
                          rounded
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6" md="2">
                        <v-btn @click="HandlerAddStep" color="primary">
                          Agregar
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-data-table
                          dense
                          :headers="headersStep"
                          :items="dataStep"
                          :items-per-page="5"
                          class="elevation-0"
                        ></v-data-table>
                      </v-col>
                    </v-row>
                  </v-col> -->

                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-card-title class="pt-0" style="color: #00000099">
                      <span> Productos </span>
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="searchProduct"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                        @keyup.enter="HandlerGetProduct"
                      ></v-text-field>
                    </v-card-title>
                    <v-divider></v-divider>
                    <ValidationProvider
                      name="productos"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <span
                        :class="errors.length > 0 ? 'error-class' : ''"
                      ></span>
                      <v-data-table
                        dense
                        single-select
                        show-select
                        v-model="selected"
                        :loading="loading"
                        :headers="headers"
                        :items="productsData"
                        disable-pagination
                        hide-default-footer
                        class="elevation-0"
                        :page.sync="page"
                        @page-count="pageCount = $event"
                      >
                      </v-data-table>
                      <div style="color: red; font-size: 12px">
                        {{ errors[0] }}
                      </div>
                    </ValidationProvider>
                    <v-pagination
                      v-model="page"
                      :length="paginate.lastPage"
                      circle
                      total-visible="7"
                    ></v-pagination>
                  </v-col> -->
                </v-row>
              </v-card-text>
            </vuescroll>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title class="font-weight-medium primary--text">
              Categorias
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-card-title>
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar"
                      filled
                      rounded
                      dense
                      class="rounded-small"
                      single-line
                      hide-details
                      @keyup="HandlerGetCategory()"
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    v-model="selectedCategory"
                    :search="search"
                    :headers="dessertHeaders"
                    :items="data_categories"
                    :expanded.sync="expanded"
                    show-expand
                    item-key="id"
                    no-data-text="Su tienda no posee categorias asociadas"
                    class="elevation-0"
                  >
                    <!-- :search="search" -->
                    <template v-slot:expanded-item="{ headers, item }">
                      <td :colspan="headers.length">
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            v-for="(sub_item, index) in item.sub_category"
                            :key="index"
                          >
                            <v-checkbox
                              :input-value="
                                sub_categories.includes(sub_item.id)
                                  ? true
                                  : false
                              "
                              :label="sub_item.name"
                              @change="
                                HandlerPushSubCategory(sub_item.id, sub_item)
                              "
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <!-- More info about {{ item.name }} -->
                      </td>
                    </template>

                    <template v-slot:[`item.name`]="{ item }">
                      <span class="text-capitalize">{{ item.name }}</span>
                    </template>
                  </v-data-table>
                  <div
                    v-if="showErrorNotificacion"
                    style="color: red; font-size: 12px"
                  >
                    La subcategoria es requerida
                  </div>
                  <!-- </ValidationProvider> -->
                </v-col>
                <v-col cols="12" sm="6">
                  <v-card flat outlined class="mx-auto" max-width="500">
                    <v-app-bar color="teal" dark>
                      <v-toolbar-title>
                        Subcategorias seleccionadas
                      </v-toolbar-title>
                    </v-app-bar>

                    <v-list>
                      <v-list-group
                        v-for="item in sub_categories_active"
                        :key="item.id"
                        v-model="item.active"
                        :prepend-icon="item.action"
                        no-action
                      >
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title
                              class="text-capitalize"
                              v-text="item.category"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item
                          v-for="child in item.subcategory"
                          :key="child.id"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="child.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="12">
          <v-card class="mb-5">
            <v-card-title class="pa-0 shadow-1">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
                <v-card-title class="font-weight-medium primary--text">
                  Imagenes
                </v-card-title>
              </v-img>
            </v-card-title>

            <vuescroll
              :ops="{
                bar: { background: '#bacfcec0', keepShow: true }
              }"
            >
              <v-card-text>
                <!-- <v-row v-if="action == 0">
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="imagenes"
                      :rules="action == 0 ? 'required|image|size:2000' : ''"
                      v-slot="{ errors }"
                    >
                      <v-file-input
                        prepend-icon
                        prepend-inner-icon="mdi-camera-image"
                        multiple
                        label="cargar imagenes"
                        v-model="images_publications"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-file-input>
                    </ValidationProvider>
                  </v-col>
                </v-row> -->

                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-sheet class="mx-auto" elevation="0" max-width="auto">
                      <!-- {{dataUpdate.images}} -->
                      <v-slide-group
                        v-model="model"
                        class="pa-4"
                        active-class="primary"
                        show-arrows
                      >
                        <v-slide-item
                          v-for="(item, index) in dataUpdate.images"
                          :key="index"
                          v-slot="{ active, toggle }"
                        >
                          <!-- {{ active }} -->
                          <v-card
                            :color="active ? undefined : 'grey lighten-1'"
                            class="ma-4"
                            height="200"
                            width="100"
                            @click="toggle"
                          >
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center"
                            >
                              <v-img
                                class="mx-5"
                                v-if="!active && updateImage == false"
                                contain
                                height="200"
                                width="100"
                                :src="item"
                                :lazy-src="item"
                              >
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height ma-0"
                                    align="center"
                                    justify="center"
                                  >
                                    <v-progress-circular
                                      indeterminate
                                      color="black lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                              <v-progress-circular
                                v-if="!active && updateImage == true"
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                              <!-- <v-scale-transition> -->
                              <!-- {{active}} -->
                              <v-icon
                                @click="HandlerShowImage(item, index)"
                                v-if="active"
                                color="white"
                                size="48"
                                v-text="'mdi-image-edit'"
                              ></v-icon>
                              <v-icon
                                @click="deleteImagePublication(item)"
                                v-if="active"
                                color="white"
                                size="48"
                                v-text="'mdi-image-remove'"
                              ></v-icon>
                              <!-- </v-scale-transition> -->
                            </v-row>
                          </v-card>
                        </v-slide-item>
                      </v-slide-group>
                      <!-- <v-row>
                        <v-col cols="3">
                          <ValidationProvider
                            name="imagenes"
                            :rules="action == 0 ? 'required' : ''"
                            v-slot="{ errors }"
                          >
                            <v-file-input
                              prepend-icon
                              prepend-inner-icon="mdi-camera-image"
                              multiple
                              label="cargar imagenes"
                              v-model="images_publications"
                              filled
                              rounded
                              dense
                              :error-messages="errors"
                            ></v-file-input>
                          </ValidationProvider>
                          <v-btn color="primary" @click="addImagePublication">
                            Agregar Imagen
                          </v-btn>
                        </v-col>
                      </v-row> -->
                    </v-sheet>
                    <input
                      v-show="false"
                      ref="inputUpload"
                      type="file"
                      @change="HandlerSaveImage"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </vuescroll>
            <!-- <v-card-actions class="mb-4">
              <v-spacer></v-spacer>
              <v-btn class="accent shadow-4 px-8 mr-1">
                Agregar / Modificar
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
        <v-col cols="12" v-if="store_id == 1 || store_id == 3 || store_id == 7">
          <v-card>
            <v-card-title class="font-weight-medium primary--text">
              Características de la publicación
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    :items="data_features"
                    item-text="name"
                    item-value="id"
                    v-model="dataUpdate.features"
                    small-chips
                    label="Características"
                    multiple
                    filled
                    rounded
                    dense
                    no-data-text="No hay datos para mostrar"
                    clearable
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title class="font-weight-medium primary--text">
              Resumen
            </v-card-title>
            <v-card-text>
              <ValidationProvider
                name="resumen"
                rules="required"
                v-slot="{ errors }"
              >
                <!-- <v-textarea
                  label="Resumen"
                  filled
                  rounded
                  dense
                  v-model="dataUpdate.resume"
                  :error-messages="errors"
                ></v-textarea> -->
                <wysiwyg v-model="dataUpdate.resume" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text>
            <v-card-title
              class="font-weight-medium primary--text"
              v-if="store_id != 3 && store_id != 7"
            >
              Descripción
            </v-card-title>
            <v-card-text v-if="store_id != 3 && store_id != 7">
              <ValidationProvider
                name="descripcion"
                rules="required"
                v-slot="{ errors }"
              >
                <!-- <v-textarea
                  label="Descripcion"
                  filled
                  rounded
                  dense
                  v-model="dataUpdate.description"
                  :error-messages="errors"
                ></v-textarea> -->
                <wysiwyg v-model="dataUpdate.description" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text>
            <!-- Especificaciones -->
            <v-card-title
              v-if="store_id == 1 || store_id == 11"
              class="font-weight-medium primary--text"
            >
              Especificaciones
            </v-card-title>
            <v-card-text v-if="store_id == 1 || store_id == 11">
              <ValidationProvider
                name="descripcion"
                rules="required"
                v-slot="{ errors }"
              >
                <!-- <v-textarea
                  label="Descripcion"
                  filled
                  rounded
                  dense
                  v-model="dataUpdate.description"
                  :error-messages="errors"
                ></v-textarea> -->
                <wysiwyg v-model="dataUpdate.specs" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text>
            <!-- QA -->
            <!-- <v-card-title
              v-if="store_id == 1"
              class="font-weight-medium primary--text"
            >
              QA de la publicación
            </v-card-title>
            <v-card-text v-if="store_id == 1">
              <ValidationProvider
                name="QA"
                rules="required"
                v-slot="{ errors }"
              >
                <wysiwyg v-model="dataUpdate.qa" />
                <div
                  v-if="errors.length > 0"
                  style="color: red; font-size: 12px"
                >
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </v-card-text> -->
            <!-- publicacion HTML -->
            <!-- v-if="store_id == 3" -->
            <v-card-title
              class="font-weight-medium primary--text"
              v-if="
                store_id == 3 ||
                  store_id == 7 ||
                  store_id == 13 ||
                  store_id == 1
              "
            >
              Caracteristicas del producto
              {{ store_id == 3 || store_id == 7 ? "EDIFIER" : "STADIO" }}
            </v-card-title>
            <!--  -->
            <v-card-text
              v-if="
                store_id == 3 ||
                  store_id == 7 ||
                  store_id == 13 ||
                  store_id == 1
              "
            >
              <ValidationProvider
                name="publicacion html"
                :rules="store_id === 3 || store_id === 7 ? 'required' : ''"
                v-slot="{ errors }"
              >
                <v-textarea
                  filled
                  rounded
                  dense
                  v-model="dataUpdate.text_html"
                  :error-messages="errors"
                ></v-textarea>
              </ValidationProvider>
              <div v-html="dataUpdate.text_html"></div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  :loading="loadingPublication"
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal({}, false)"
                >
                  Cancelar
                </v-btn>
              </v-hover>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  :loading="loadingPublication"
                  rounded
                  class="mr-1"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  @click="HandlerActions"
                >
                  Guardar
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col md="12">
        <v-card class="mb-5">
          <vuescroll
            :ops="{
              bar: { background: '#bacfcec0', keepShow: true },
            }"
          >
            <v-card-text>
              <v-tabs>
                <v-tab>Overview</v-tab>
                <v-tab>Descripcion</v-tab>
                <v-tab>Especificaciones</v-tab>
                <v-tab>QA</v-tab>
                <v-tab-item class="mt-5">
                  <v-textarea
                    label="Overview"
                    filled
                    dense
                    rounded
                  ></v-textarea>
                </v-tab-item>
                <v-tab-item class="mt-5">
                  <v-textarea
                    label="Descripcion"
                    filled
                    rounded
                    dense
                  ></v-textarea>
                </v-tab-item>
                <v-tab-item class="mt-5">
                  <v-textarea
                    label="Especificaciones"
                    filled
                    rounded
                    dense
                  ></v-textarea>
                </v-tab-item>
                <v-tab-item class="mt-5">
                  <v-textarea label="QA" filled rounded dense></v-textarea>
                </v-tab-item>
              </v-tabs>
            </v-card-text>
          </vuescroll>
        </v-card>
      </v-col> -->
      </v-row>
    </ValidationObserver>
  </v-container>
</template>

<script>
import moment from "moment";
import vuescroll from "vuescroll";
export default {
  props: {
    dataUpdate: {
      type: Object,
      required: false,
      default: () => {}
    },
    action: {
      type: Number,
      required: false,
      default: 0
    }
  },
  components: {
    vuescroll
    // draggable,
  },
  data() {
    return {
      model: null,
      array_sub_category: [],
      file: [],
      images_publications: [],
      data_categories: [],
      category: [],
      sub_categories_active: [],
      sub_categories: [],
      data_stores: [],
      data_products: [],
      keywords: "",
      threshold: "",
      pvp: "",
      pvc: "",
      iva: "",
      out_stock: "",
      featured: "",
      description: "",
      text_html: "",
      store_id: "",
      product_id: "",
      search: "",
      timerId: null,
      searchLoading: false,
      expanded: [],
      dessertHeaders: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "", value: "data-table-expand" }
      ],
      headersStep: [
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "quantity"
        },
        {
          text: "Descuento",
          align: "start",
          sortable: false,
          value: "discount"
        }
      ],

      searchStore: "",
      searchProduct: "",
      positionImage: null,

      //Products
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text"
        },
        {
          text: "Sku",
          value: "sku",
          sortable: false,
          class: "primary--text"
        }
      ],

      selectedCategory: [],
      file_logo: [],
      showErrorNotificacion: false,

      loading: false,
      page: 1,
      productsData: [],
      paginate: {},
      selected: [],
      loadingPublication: false,
      updateImage: false,

      loadingAdd: false,
      data_features: [],
      features: [],

      dataStep: [],

      quantity: "",
      discount: "",

      high_availability: 0,
      low_availability: 0,
      date_availability: null,
      menu2: false,
      date: null,

      percentage_18_installments: "",
      percentage_transfer: "",
      pvpBase: 0,
      pvpPer: 0,
      imagesProduct: []
    };
  },
  watch: {
    store_id(value) {
      if (value != null) {
        // this.HandlerGetProduct(value, this.page);
        this.HandlerGetCategory(value);
      }
    },

    page(value) {
      this.HandlerGetProduct(this.store_id, value);
    },

    searchProduct() {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      if (this.searchProduct) {
        this.timerId = setTimeout(() => {
          this.HandlerGetProducts();
          this.timerId = null;
        }, 1000);
      }
    }
  },

  computed: {
    GET_USER() {
      return this.$store.getters["auth/GET_USER"];
    },
    // getPvp() {
    //   return this.pvpBase + this.pvpBase * (this.pvpPer / 100);
    // },
    getPvp() {
      return this.dataUpdate?.price?.pvp || this.pvpBase;
    },

    getPvpTransfer() {
      return (
        this.dataUpdate.price.pvp_transfer ||
        Math.round(this.pvpBase * this.dataUpdate?.price?.percentage_transfer)
      );
    }
  },

  created() {
    if (this.action == 1) {
      // this.arrayImages = this.dataUpdate.images;
      this.store_id = this.dataUpdate.store_id;
      // this.selected.push(this.dataUpdate.product);

      this.sub_categories = this.dataUpdate.sub_category.map(value => value.id);

      if (this.store_id == 1 || this.store_id == 3 || this.store_id == 7) {
        this.dataUpdate.features = this.dataUpdate.features.map(
          value => value.id
        );

        this.HandlerFeaturesSelect(this.sub_categories);
      }

      this.dataStep = this.dataUpdate.b2b_step;
      this.HandlerGetCategory();

      // this.product_id = this.dataUpdate.product_id

      // this.sub_categories_active = this.dataUpdate.sub_category.map((value) => {
      //   let arraySubCategories = [];
      //   arraySubCategories.push({
      //     active: true,
      //     category_id: value.category.id,
      //     id: value.id,
      //     name: value.name,
      //     created_at: value.created_at,
      //     updated_at: value.updated_at,
      //   });
      //   return {
      //     active: false,
      //     category: value.category.name,
      //     id: value.category.id,
      //     subcategory: arraySubCategories,
      //   };
      // });
    } else {
      this.dataUpdate.availability_date = moment().format("YYYY-MM-DD");
      this.HandlerGetProducts();
    }

    this.HandlerGetStore();
  },

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: ""
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data;

        if (this.GET_USER.roles.filter(index => index.id == 10).length > 0) {
          this.data_stores = response.data.data.filter(index => {
            return index.id == 1;
          });
        } else {
          this.data_stores = response.data.data
            .filter(index => {
              return index.b2b_active == false;
            })
            .filter(index => {
              return index.id !== 2;
            });
        }

        if (this.action == 1) {
          this.HandlerGetProducts();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetProduct(deposit_id, page) {
      try {
        const request = {
          search: this.searchProduct,
          active: true,
          page: page || 1,
          per_page: 10
        };
        const response = await this.$store.dispatch(
          "products/GET_PRODUCTS",
          request
        );
        this.productsData = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } //finally {}
    },

    async HandlerGetCategory() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          paginate: false,
          name: this.search,
          store_id: this.store_id
        };
        const response = await this.$store.dispatch(
          "category/GET_CATEGORIES",
          request
        );
        this.data_categories = response.data.data;
        if (this.action == 1) {
          this.HandlerSubcategorySelect(
            this.dataUpdate.sub_category.map(index => index.id)
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    productSelect() {
      if (this.dataUpdate.product_id) {
        this.pvpBase = this.data_products[0].products_erp[0].base_price;
        this.dataUpdate.price.iva = this.data_products[0].products_erp[0].tax;
        this.dataUpdate.images = [...this.data_products[0].images];
        this.dataUpdate.resume = this.data_products[0].resume;
        this.dataUpdate.specs = this.data_products[0].specs;
        this.dataUpdate.description = this.data_products[0].description;
        this.dataUpdate.text_html = this.data_products[0].text_html;
      } else {
        this.pvpBase = null;
        this.dataUpdate.price.iva = null;
        this.dataUpdate.images = null;
        this.dataUpdate.resume = null;
        this.dataUpdate.specs = null;
        this.dataUpdate.description = null;
        this.dataUpdate.text_html = null;
      }
    },

    async HandlerGetProducts() {
      if (
        (this.searchProduct !== null && this.searchProduct.length > 1) ||
        this.dataUpdate.product_id > 0
      ) {
        this.searchLoading = true;
        try {
          let country = this.data_stores.find(
            index => index.id == this.store_id
          );

          let country_id;

          if (country !== undefined) {
            country_id = country.country_id;
          }

          const request = {
            page: 1,
            per_page: 20,
            paginate: false,
            active: true,
            sku:
              this.searchProduct !== ""
                ? this.searchProduct
                : this.dataUpdate.product.name,

            country_id: country_id
          };

          if (this.dataUpdate.product_id > 0) {
            request.product_id = this.dataUpdate.product_id;
          }

          const response = await this.$store.dispatch(
            "products/GET_PRODUCTS",
            request
          );

          this.data_products = response.data.data;
          if (response.data.data.length == 1 && this.action == 1) {
            this.pvpBase = this.data_products[0].products_erp[0].base_price;
            this.dataUpdate.price.iva = this.data_products[0].products_erp[0].tax;
          }

          // if (this.action !== 1) {
          //   this.dataUpdate.keywords = this.data_products.name;
          // }
          // this.dataUpdate.description = this.data_products.description;
          // const product_erps = this.data_products.map(
          //   (index) => index.products_erp
          // )[0];

          // console.log('const product_erps', product_erps)

          // console.log('**** product_erps', product_erps[0])
          // if (this.action == 1) {
          //   this.dataUpdate.price.iva = product_erps[0]?.tax
          //     ? product_erps[0].tax
          //     : 0;
          // }
        } catch (error) {
          console.log(error);
        } finally {
          this.searchLoading = false;
        }
      } else if (this.searchProduct?.length == 0) {
        this.data_products = [];
      }
    },

    HandlerPushSubCategory(sub_category_id, sub_item) {
      sub_item.active = !sub_item.active;
      if (this.sub_categories.length == 0) {
        this.sub_categories.push(sub_category_id);
      } else {
        if (this.sub_categories.includes(sub_category_id)) {
          const position = this.sub_categories.indexOf(sub_category_id);
          this.sub_categories.splice(position, 1);
        } else {
          this.sub_categories.push(sub_category_id);
        }
      }

      this.HandlerSubcategorySelect(this.sub_categories);
      this.HandlerFeaturesSelect(this.sub_categories);
    },

    async HandlerFeaturesSelect(sub_categories) {
      try {
        const request = {
          sub_categories: sub_categories,
          store_id: this.store_id
        };

        const response = await this.$store.dispatch(
          "publications/GET_FEATURES",
          request
        );

        this.data_features = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPublication = false;
      }
    },

    HandlerSubcategorySelect(sub_categories) {
      this.sub_categories_active = this.data_categories
        .map(value => {
          const map = value.sub_category
            .map(val => {
              if (sub_categories.includes(val.id)) {
                return val;
              }
            })
            .filter(res => res != undefined);
          return {
            id: value.id,
            category: value.name,
            subcategory: [...map]
          };
        })
        .filter(res => res.subcategory.length > 0);
    },

    async HandlerActions() {
      if (await this.$refs.obs.validate()) {
        if (this.action == 0) this.HandlerRegisterPublications();
        else this.HandlerUpdatePublications();
      } else {
        if (this.selectedCategory.length == 0)
          this.showErrorNotificacion = true;
        this.$snotify.warning("Debes completar todos los campos", "Alerta!");
      }
    },

    async HandlerRegisterPublications() {
      try {
        if (this.sub_categories.length == 0) {
          this.showErrorNotificacion = true;
          return;
        }
        this.loadingPublication = true;
        this.showErrorNotificacion = false;
        const request = new FormData();
        // this.imagesProduct.forEach((file) =>
        //   request.append("images_publications[]", file)
        // );
        // this.images_publications.forEach(image => request.append('images_publications[]', image));
        // request.append("sub_categories", JSON.stringify([2]));
        request.append(
          "images_publications",
          JSON.stringify(this.dataUpdate.images)
        );
        request.append("store_id", this.store_id);
        request.append("b2b_step", JSON.stringify(this.dataStep));
        request.append("product_id", this.dataUpdate.product_id);
        request.append("sub_categories", JSON.stringify(this.sub_categories));
        request.append("keywords", this.dataUpdate.keywords);
        request.append("threshold", this.dataUpdate.threshold);
        request.append(
          "features",
          JSON.stringify(
            this.dataUpdate.features == undefined
              ? []
              : this.dataUpdate.features
          )
        );

        request.append("pvp", this.getPvp);
        request.append(
          "percentage_18_installments",
          this.percentage_18_installments
        );
        request.append("percentage_transfer", this.percentage_transfer);
        request.append(
          "pvc",
          this.dataUpdate.price.pvc == ""
            ? 0
            : this.dataUpdate.price.pvc == undefined
            ? 0
            : this.dataUpdate.price.pvc
        );
        request.append(
          "iva",
          this.dataUpdate.price.iva == ""
            ? 0
            : this.dataUpdate.price.iva == undefined
            ? 0
            : this.dataUpdate.price.iva
        );
        request.append(
          "out_stock",
          this.dataUpdate.out_stock == undefined
            ? false
            : this.dataUpdate.out_stock
        );
        request.append(
          "featured",
          this.dataUpdate.featured == undefined
            ? false
            : this.dataUpdate.featured
        );
        request.append("resume", this.dataUpdate.resume);
        request.append("description", this.dataUpdate.description);
        request.append("specs", this.dataUpdate.specs);
        request.append("qa", this.dataUpdate.qa);
        request.append("logo", this.file_logo);
        request.append("url_meli", this.dataUpdate.url_meli);
        request.append("text_html", this.dataUpdate.text_html);
        request.append("base_pvp", this.pvpBase);
        request.append("discount", this.dataUpdate.price.discount);
        request.append(
          "transfer_discount",
          this.dataUpdate.price.transfer_discount
        );

        if (this.store_id == 5 || this.store_id == 6) {
          request.append(
            "high_availability",
            this.dataUpdate.high_availability
          );
          request.append("low_availability", this.dataUpdate.low_availability);
          request.append(
            "availability_date",
            this.dataUpdate.availability_date
          );
        }

        const response = await this.$store.dispatch(
          "publications/REGISTER_PUBLICATIONS",
          request
        );

        this.closeModal(response, false);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPublication = false;
      }
    },

    async HandlerUpdatePublications() {
      try {
        this.loadingPublication = true;
        const request = new FormData();
        this.images_publications.forEach(file =>
          request.append("images_publications[]", file)
        );
        request.append("publication_id", this.dataUpdate.id);
        request.append("store_id", this.store_id);
        request.append("b2b_step", JSON.stringify(this.dataStep));
        request.append("product_id", this.dataUpdate.product_id);
        request.append("sub_categories", JSON.stringify(this.sub_categories));
        request.append(
          "features",
          JSON.stringify(
            this.dataUpdate.features == undefined
              ? []
              : this.dataUpdate.features
          )
        );
        request.append("keywords", this.dataUpdate.keywords);
        request.append("threshold", this.dataUpdate.threshold);
        request.append("pvp", this.getPvp);

        request.append(
          "percentage_18_installments",
          this.dataUpdate.price.percentage_18_installments
        );
        request.append(
          "percentage_transfer",
          this.dataUpdate.price.percentage_transfer
        );

        request.append(
          "pvc",
          this.dataUpdate.price.pvc == ""
            ? 0
            : this.dataUpdate.price.pvc == undefined
            ? 0
            : this.dataUpdate.price.pvc
        );
        request.append(
          "iva",
          this.dataUpdate.price.iva == ""
            ? 0
            : this.dataUpdate.price.iva == undefined
            ? 0
            : this.dataUpdate.price.iva
        );
        request.append(
          "out_stock",
          this.dataUpdate.out_stock == undefined
            ? false
            : this.dataUpdate.out_stock
        );
        request.append(
          "featured",
          this.dataUpdate.featured == undefined
            ? false
            : this.dataUpdate.featured
        );
        request.append("resume", this.dataUpdate.resume);
        request.append("description", this.dataUpdate.description);
        request.append("specs", this.dataUpdate.specs);
        request.append("qa", this.dataUpdate.qa);
        request.append("logo", this.file_logo);
        request.append("url_meli", this.dataUpdate.url_meli);
        request.append("text_html", this.dataUpdate.text_html);
        request.append("base_pvp", this.pvpBase);
        request.append("discount", this.dataUpdate.price.discount);
        request.append(
          "transfer_discount",
          this.dataUpdate.price.transfer_discount
        );

        if (this.store_id == 5 || this.store_id == 6) {
          request.append(
            "high_availability",
            this.dataUpdate.high_availability
          );
          request.append("low_availability", this.dataUpdate.low_availability);
          request.append(
            "availability_date",
            this.dataUpdate.availability_date
          );
        }

        const response = await this.$store.dispatch(
          "publications/UPDATED_PUBLICATIONS",
          { id: this.dataUpdate.id, data: request }
        );

        this.$snotify.success("Has actualizado la publicacion", "Exitos!");
        this.closeModal(response, false);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPublication = false;
      }
    },

    HandlerShowImage(item, index) {
      this.positionImage = { url: item, index: index };
      this.$refs.inputUpload.click();
    },

    async HandlerSaveImage(evt) {
      try {
        this.updateImage = true;
        const request = new FormData();
        request.append("images_publications[]", evt.target.files[0]);
        request.append("url_image", this.positionImage.url);
        request.append("publication_id", this.dataUpdate.id);

        const requestData = {
          publication_id: this.dataUpdate.id,
          data: request
        };

        const response = await this.$store.dispatch(
          "publications/UPLOAD_IMAGE",
          requestData
        );

        const indexImage = this.positionImage.index;
        const imageResponse = JSON.parse(response.data.data.images);

        this.dataUpdate.images.splice(indexImage, 1, imageResponse[indexImage]);

        this.$snotify.success("Imagen Cargada", "Exitos!");
      } catch (error) {
        console.log(error);
      } finally {
        this.updateImage = false;
      }
    },

    async addImagePublication() {
      try {
        this.loadingAdd = true;
        const request = new FormData();
        this.images_publications.forEach(file =>
          request.append("images_publications[]", file)
        );

        const response = await this.$store.dispatch(
          "publications/ADD_PUBLICATION_IMAGE",
          { data: request, id: this.dataUpdate.id }
        );

        const images = JSON.parse(response.data.data.images);

        const newImage = images[images.length - 1];

        this.dataUpdate.images.push(newImage);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAdd = false;
      }
    },

    async deleteImagePublication(image) {
      try {
        this.loadingAdd = true;
        const request = {
          data: {
            url_image: image
          },
          id: this.dataUpdate.id
        };

        await this.$store.dispatch(
          "publications/DELETE_PUBLICATION_IMAGE",
          request
        );

        const images = this.dataUpdate.images;

        const deleteImage = images.indexOf(image);

        this.dataUpdate.images.splice(deleteImage, 1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAdd = false;
      }
    },

    HandlerAddStep() {
      this.dataStep.push({
        id: 0,
        quantity: this.quantity,
        discount: this.discount
      });

      this.quantity = "";
      this.discount = "";
    },

    getSelectStore() {
      const search = this.data_stores.find(val => val.id == this.store_id);
      this.percentage_18_installments = search.percentage_18_installments;
      this.percentage_transfer = search.percentage_transfer;
    }
  }
};
</script>

<style></style>
