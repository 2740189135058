<template>
  <v-container fluid class="mt-7">
    <v-row>
      <v-col md="12">
        <v-row class="justify-between align-center mb-5">
          <v-col cols="12" md="3" class="py-0">
            <v-btn
              v-if="!showRegisterCurrency"
              rounded
              dark
              block
              large
              @click="HandlerAddCurrency"
              class="accent shadow-4 px-8"
            >
              Añadir Moneda
            </v-btn>
          </v-col>
        </v-row>
        <v-card class="shadow-1 mt-5">
          <v-data-table
            :loading="loading"
            v-if="!showRegisterCurrency"
            :headers="headers"
            :items="dataCurrency"
            :items-per-page="5"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerEdit(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar Moneda</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="12" v-if="showRegisterCurrency">
        <div
          class="font-weight-medium primary--text mb-5"
          style="font-size: 26px"
        >
          Crear / Editar Monedas
        </div>
        <v-card class="shadow-5">
          <ValidationObserver ref="obs" v-slot="{ passes }">
            <v-img
              :src="require('@/assets/illustrations/bg_modal_card.svg')"
              cover
              position="left bottom"
              class="align-center"
              height="65px"
            >
            </v-img>
            <v-card-text>
              <v-row v-if="showRegisterCurrency">
                <v-col md="3">
                  <ValidationProvider
                    name="pais"
                    :rules="!showRegisterCurrency ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <v-select
                      :items="dataCountry"
                      item-text="name"
                      item-value="id"
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Pais"
                      v-model="country_id"
                      :error-messages="errors"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col md="3">
                  <ValidationProvider
                    name="nombre"
                    :rules="!showRegisterCurrency ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Nombre"
                      v-model="name"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col md="3">
                  <ValidationProvider
                    name="simbolo"
                    :rules="!showRegisterCurrency ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Simbolo"
                      v-model="symbol"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col md="3">
                  <ValidationProvider
                    name="codigo"
                    :rules="!showRegisterCurrency ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Codigo"
                      v-model="code"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="showRegisterCurrency">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="HandlerAddCurrency"
                  >Cancelar</v-btn
                >
              </v-hover>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2 mr-3' : 'shadow-4 mr-3'"
                  class="px-8"
                  @click="passes(HandlerRegisterCountry)"
                >
                  Guardar
                </v-btn>
              </v-hover>
            </v-card-actions>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>

    <h3>Cotizaciones</h3>
    <v-data-table
      :loading="loading"
      v-if="!showRegisterCurrency"
      :headers="ratesHeaders"
      :items="dataRate"
      :items-per-page="5"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:[`item.exchange_rate`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.exchange_rate"
          @save="updateRate(item)"
        >
          <span>
            {{ item.exchange_rate | currencyValue }}
          </span>
          <template v-slot:input>
            <!-- {{item.products_erp[0].base_price}} -->
            <v-text-field
              type="number"
              v-model="item.exchange_rate"
              label="Edit"
              single-line
            ></v-text-field>
          </template>
        </v-edit-dialog>

        <v-progress-circular
          v-if="false"
          size="300"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      country_id: "",
      name: "",
      symbol: "",
      code: "",
      headers: [
        { text: "Pais", sortable: false, value: "name" },
        { text: "Nombre", sortable: false, value: "currency.name" },
        { text: "Simbolo", sortable: false, value: "currency.symbol" },
        { text: "Codigo", sortable: false, value: "currency.code" },
        { text: "Acciones", sortable: false, value: "action" },
      ],
      ratesHeaders: [
        { text: "Descripción", value: "description" },
        { text: "Valor", value: "exchange_rate" },
      ],
      dataCountry: [],
      dataCurrency: [],
      dataRate: [],
      showRegisterCurrency: false,
    };
  },

  created() {
    this.HandlerGetCountry();
    this.HandlerGetCurrencies();
    this.HandlerGetRates();
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  filters: {
    currencyValue(value) {
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency",
      }).format(value);
      return AMOUNT_FORMAT;
    },
  },

  methods: {
    async updateRate(item) {
      await this.$store.dispatch("country/UPDATE_COTIZATION", item);
    },
    async HandlerGetRates() {
      const response = await this.$store.dispatch("country/GET_COTIZATIONS");
      this.dataRate = response.data;
      console.log(this.dataRate);
    },
    async HandlerGetCountry() {
      try {
        this.loding = true;
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loding = false;
      }
    },

    async HandlerGetCurrencies() {
      try {
        const response = await this.$store.dispatch("country/GET_CURRENCIES");
        this.dataCurrency = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerRegisterCountry() {
      try {
        const request = {
          country_id: this.country_id,
          name: this.name,
          symbol: this.symbol,
          code: this.code,
        };

        const response = await this.$store.dispatch(
          "country/REGISTER_CURRENCY",
          request
        );

        const indexupdate = this.dataCurrency
          .map((element) => element.id)
          .indexOf(response.data.data[0].id);

        if (indexupdate > -1) {
          this.dataCurrency.splice(indexupdate, 1, response.data.data[0]);
        }

        this.showRegisterCurrency = !this.showRegisterCurrency;
        this.$snotify.success("Se ha registrado correctamente", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },

    HandlerEdit(item) {
      this.country_id =
        item.currency == null ? item.id : item.currency.country_id;
      this.name = item.currency == null ? "" : item.currency.name;
      this.symbol = item.currency == null ? "" : item.currency.symbol;
      this.code = item.currency == null ? "" : item.currency.code;
      this.showRegisterCurrency = !this.showRegisterCurrency;
    },

    HandlerAddCurrency() {
      this.country_id = "";
      this.name = "";
      this.symbol = "";
      this.code = "";
      this.showRegisterCurrency = !this.showRegisterCurrency;
    },
  },
};
</script>

<style></style>
