<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn @click="HandlerCountry(1, '1939629737084098')">
          <!-- href="https://auth.mercadolibre.com/authorization?response_type=code&client_id=1939629737084098&redirect_uri=https://admin.flextd.com/mercado_libre" -->
          Mercado libre ARG
        </v-btn>
        <v-btn @click="HandlerCountry(2, '2817029940887819')" class="ml-3">
          <!-- href="https://auth.mercadolibre.com/authorization?response_type=code&client_id=2817029940887819&redirect_uri=https://admin.flextd.com/mercado_libre" -->
          Mercado libre CHI
        </v-btn>
      </v-col>
      <!-- <v-col cols="12" sm="4" md="4">
      </v-col> -->
      <v-col cols="12" sm="12" md="12">
        <v-card class="shadow-1 mt-5">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="data"
            :items-per-page="50"
            class="elevation-0"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      data: [],
      headers: [
        { text: "Nombre", sortable: false, value: "name" },
        { text: "User ML", sortable: false, value: "seller_id" },
        { text: "Link", sortable: false, value: "user_permalink" },
        { text: "Estatus", sortable: false, value: "is_active" },
      ],
    };
  },

  created() {
    this.HandlerGeAuthML();
    this.HandlerGetMlAccounts();
  },

  methods: {
    async HandlerGetMlAccounts() {
      try {
        const response = await this.$store.dispatch("ml/GET_ML_ACCOUNT");
        this.data = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGeAuthML() {
      try {
        if (
          this.$route.query.code != null ||
          this.$route.query.code != undefined
        ) {
          const request = {
            code: this.$route.query.code,
            country_id: localStorage.getItem("country_id"),
          };
          const response = await this.$store.dispatch("auth/AUTH_ML", request);
          console.log(response);
          this.HandlerGetMlAccounts();
        }
      } catch (error) {
        console.log(error);
      } finally {
        localStorage.removeItem("country_id");
      }
    },

    HandlerCountry(country_id, client_id) {
      localStorage.setItem("country_id", country_id);

      location.href = `https://auth.mercadolibre.com/authorization?response_type=code&client_id=${client_id}&redirect_uri=https://admin.flextd.com/mercado_libre`;
    },
  },
};
</script>
