var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.orderItem,"items-per-page":5,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.unit_price))+" ")])]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [(item.product.product_erp == null)?_c('span',[_vm._v(" "+_vm._s(item.seller_sku)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.product.product_erp[0].sku)+" ")])]}},{key:"item.warehouse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.warehouse != null ? item.warehouse.name : "")+" ")]}},{key:"item.seller_sku",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.seller_sku)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }