var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.order.meli_shipping_id == null ||
      _vm.order.shipping == null ||
      _vm.order.shipping_status == 'to_be_agreed'
    )?_c('div',[_c('p',{staticClass:"subtitle-1 subtitle-2 font-weight-bold"},[_vm._v("Envío a Coordinar")]),(_vm.order.buyer != null)?_c('div',[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Recibe/ Retira: ")]),_vm._v(" "+_vm._s(((_vm.order.buyer.first_name) + " " + (_vm.order.buyer.last_name)))+" ")]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Teléfono: ")]),_vm._v(" "+_vm._s(_vm.order.buyer.phone)+" ")]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Email: ")]),_vm._v(" "+_vm._s(_vm.order.buyer.email)+" ")])]):_vm._e()]):(
      _vm.order.shipping !== null && _vm.order.shipping.shipping_mode == 'custom'
    )?_c('div',[_c('p',{staticClass:"subtitle-1 subtitle-2 font-weight-bold"},[_vm._v("Envío Personalizado")])]):_vm._e(),(
      _vm.order.shipping !== null &&
      (_vm.order.shipping.shipping_mode == 'me2' ||
        _vm.order.shipping.shipping_mode == 'me1')
    )?_c('div',[_c('shipping-types',{attrs:{"payment":_vm.order}}),(
        _vm.order.shipping.status != 'cancelled' &&
        _vm.order.shipping.tracking_number != null
      )?_c('div',[(_vm.order.shipping.shipping_method_name != null)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold mr-1"},[_vm._v("Método:")]),_vm._v(" "+_vm._s(_vm.order.shipping.shipping_method_name)+" ")]):_vm._e(),(_vm.order.shipping.tracking_number != null)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Nº de seguimiento: ")]),_vm._v(" "+_vm._s(_vm.order.shipping.tracking_number)+" ")]):_vm._e(),(_vm.order.shipping.estimated_delivery_limit != null)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Fecha prometida: ")]),_vm._v(" "+_vm._s(_vm._f("date")(_vm.order.shipping.estimated_delivery_limit))+" ")]):_vm._e(),_c('status-sub-status-types',{attrs:{"order":_vm.order}})],1):_vm._e(),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold mr-1"},[_vm._v(" Domicilio o retira: ")]),_c('span',[_vm._v(" "+_vm._s(_vm.order.shipping.shipping_method_name)+" ")])]),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Costo del envío: ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.order.shipping.cost))+" ")])])],1):_c('div',[(
        _vm.order.shipping !== null && _vm.order.shipping.logistic_type == 'chazki'
      )?_c('div',[_c('p',{staticClass:"text-uppercase"},[_vm._v("Chazki")]),_c('br'),_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v("Método:")]),_vm._v(" "+_vm._s(_vm.order.shipping.shipping_method_name)+" ")]),_c('p',{staticClass:"font-p mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Nº de seguimiento: ")]),_vm._v(" "+_vm._s(_vm.order.shipping.tracking_number)+" ")]),_c('status-sub-status-types',{attrs:{"order":_vm.order}})],1):_vm._e(),(
        _vm.order.shipping !== null && _vm.order.shipping.logistic_type == 'Logis24'
      )?_c('div',[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v("Método:")]),_vm._v(" "+_vm._s(_vm.order.shipping.shipping_method_name)+" ")]),_c('p',{staticClass:"font-p mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Nº de seguimiento: ")]),_vm._v(" "+_vm._s(_vm.order.shipping.tracking_number)+" ")]),_c('status-sub-status-types',{attrs:{"order":_vm.order}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }