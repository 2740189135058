var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Venta","filled":"","rounded":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetSales()}},model:{value:(_vm.meli_id),callback:function ($$v) {_vm.meli_id=$$v},expression:"meli_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12","sm":"12","md":"12"}},[_c('v-card',{class:_vm.HandlerResponsive},[_c('v-toolbar',{staticClass:"px-md-10 mb-1",attrs:{"flat":"","color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Estatus del envio")])],1),_c('v-tabs',{staticClass:"mt-3",attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[(_vm.currentTab == item)?_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"primary","content":_vm.paginate.total,"transition":"slide-x-transition"}},[_vm._v(" "+_vm._s(item)+" ")]):_c('span',[_vm._v(" "+_vm._s(item)+" ")])],1)})],2)],1)],1),_c('v-col',{attrs:{"col":"12","md":"12"}},[_c('v-card',{staticClass:"shadow-1 mt-5",attrs:{"flat":""}},[_c('v-data-table',{attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.data,"hide-default-footer":"","disable-pagination":"","fixed-header":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return _vm._l((item.order_item),function(value,index){return _c('div',{key:index,attrs:{"col":"12","sm":"12","md":"12"}},[(value.product != null || value.product != undefined)?_c('div',[_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Nombre: ")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(value.product.name)+" ")])]),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("SKU:")]),_vm._v(" "+_vm._s(value.product.sku)+" ")]),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Codigo de barras:")]),_vm._v(" "+_vm._s(value.product.barcode)+" ")]),_c('div',[_c('span',{staticClass:"font-weight-medium"},[_vm._v("Cantidad:")]),_vm._v(" "+_vm._s(value.quantity)+" ")])]):_vm._e()])})}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.order_item.length > 0)?_c('div',{staticClass:"d-flex justify-start"},[(item.order_item[0].product.pictures == null)?_c('v-avatar',{attrs:{"size":"100","tile":""}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/img/no_image.jpg")}})],1):_c('v-avatar',{attrs:{"size":"100","tile":""}},[_c('v-img',{attrs:{"src":JSON.parse(item.order_item[0].product.pictures)[0],"lazy-src":JSON.parse(item.order_item[0].product.pictures)[0]},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)})],1)],1):_vm._e(),(item.order_item.length == 0)?_c('div',[_c('v-avatar',{attrs:{"size":"100","tile":""}},[_c('v-img',{attrs:{"contain":"","src":require("@/assets/img/no_image.jpg")}})],1)],1):_vm._e()]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.buyer != null)?_c('div',{staticClass:"d-flex justify-start text-uppercase font-weight-medium"},[_vm._v(" "+_vm._s(item.buyer.first_name)+" "+_vm._s(item.buyer.last_name)+" ")]):_vm._e()]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.buyer != null)?_c('div',{staticClass:"d-flex justify-start"},[_vm._v(" "+_vm._s(item.buyer.email)+" ")]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"loading":_vm.loadingPrint,"color":"#F6B246","dark":"","block":""},on:{"click":function($event){return _vm.HandlerPrintInvoice(item)}}},[_vm._v(" "+_vm._s(_vm.tab == 1 ? "Reimprimir" : "Imprimir")+" Factura ")])]}}],null,true)})],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }