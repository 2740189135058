var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("Categorias")]),_c('v-card',{staticClass:"shadow-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dataCategory,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.sub_category",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.HandlerSubCategories(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Sub Categorias")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.action == '0')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4",attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.HandlerEdit(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Categoria")])]):_vm._e(),(!_vm.loadingFilePhoto)?_c('v-file-input',{staticClass:"mb-4",attrs:{"multiple":"","label":"File input","hide-input":"","prepend-icon":"mdi-image-album"},on:{"change":function($event){return _vm.HandlerUploadPhoto(item)}},model:{value:(_vm.filePhoto),callback:function ($$v) {_vm.filePhoto=$$v},expression:"filePhoto"}}):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-end mt-3"},[(Object.keys(_vm.paginate).length > 0)?_c('v-pagination',{attrs:{"length":_vm.paginate.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.categoryName != '')?_c('h4',[_vm._v(_vm._s(_vm.categoryName)+" ( Sub Categoria )")]):_c('h4',[_vm._v("Seleccione una Sub Categoria")]),_c('v-card',{staticClass:"shadow-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headersSub,"items":_vm.dataSubCategory,"items-per-page":-1,"hide-default-footer":"","no-data-text":"Selecciona una categoria","no-results-text":"No tiene sub categorias registradas"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.action == 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.isDark ? 'primary' : 'green',"dark":""},on:{"click":function($event){return _vm.HandlerSubCategoriesDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'primary' : 'green'}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Sub Categoria")])]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }