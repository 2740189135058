<template>
  <v-container fluid class="mt-5">
    <v-row>
      <v-col md="12" v-if="!showRegisterPublication">
        <v-row class="justify-between align-center mb-5">
          <v-col cols="12" md="3" class="py-0">
            <v-btn
              rounded
              dark
              block
              large
              @click="HandlerAddPublication"
              class="accent shadow-4 px-8"
            >
              Añadir Publicación
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="3">
            <v-select
              :items="data_stores"
              item-text="name"
              item-value="id"
              v-model="selectedStore"
              label="Tienda"
              filled
              rounded
              dense
              class="rounded-small"
              no-data-text="No hay datos para mostrar"
              @change="HandlerGetPublication(page)"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Sku"
              v-model="sku"
              filled
              rounded
              dense
              class="rounded-small"
              @keyup.enter="HandlerGetPublication(page)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card class="shadow-1 mt-5" v-if="!showRegisterPublication">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="dataPublication"
            hide-default-footer
            class="elevation-0"
          >
            <template v-slot:[`item.pvp`]="{ item }">
              {{ item.pvp_with_discount | currencyValue }}
            </template>
            <template v-slot:[`item.pvc`]="{ item }">
              {{ item.pvc | currencyValue }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerEdit(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar Publicación</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerStatus(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon
                      :color="isDark ? 'white' : '#6D6D6D'"
                      v-if="item.is_active == false"
                    >
                      mdi-thumb-up
                    </v-icon>
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'" v-else>
                      mdi-thumb-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ item.is_active ? "Desactivar" : "Activar" }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="12" v-if="showRegisterPublication">
        <section id="register">
          <register-publication
            :dataUpdate="dataUpdate"
            :action="action"
            :country="selectedStore"
            @dialog:change="HandlerResponse"
          />
        </section>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col
        cols="6"
        md="4"
        v-if="!showRegisterPublication && pagination.lastPage > 1"
      >
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDelete" max-width="600">
      <v-card :loading="loadingDelete">
        <v-card-text>
          <h3 class="pt-4">Deseas eliminar esta publicación</h3>
          <p>Al aceptar estas de acuerdo en eliminar la publicacion</p>
          <v-img
            v-if="publicationData == null"
            src="@/assets/img/no_image.jpg"
          ></v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loadingDelete"
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="showDelete = !showDelete"
            >
              Cancelar
            </v-btn>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loadingDelete"
              rounded
              class="mr-1"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              @click="HandlerDelete"
            >
              Continuar
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RegisterPublication from "./Utils/registerPublicationB2b";

export default {
  components: {
    "register-publication": RegisterPublication
  },
  data() {
    return {
      loading: false,
      country_id: "",
      name: "",
      symbol: "",
      code: "",
      dataUpdate: {},
      action: 0,
      headers: [
        { text: "Nombre", sortable: false, value: "keywords" },
        { text: "Producto", sortable: false, value: "product.name" },
        { text: "Sku", sortable: false, value: "product.products_erp[0].sku" },
        // { text: "Pais", sortable: false, value: "country.name" },
        {
          text: "Disponibilidad alta",
          sortable: false,
          value: "high_availability"
        },
        {
          text: "Disponibilidad baja",
          sortable: false,
          value: "low_availability"
        },
        {
          text: "PVP",
          sortable: false,
          value: "pvp"
        },
        { text: "Acciones", sortable: false, align: "right", value: "action" }
      ],
      showRegisterPublication: false,
      page: 1,

      showDelete: false,
      loadingDelete: false,
      publicationData: null,
      product_name: "",
      is_active: "Si",
      store_id: 5,
      data_stores: [
        { name: "B2B chile", id: 2 },
        { name: "B2B argentina ", id: 1 }
      ],
      selectedStore: 2,
      sku: "",
      order_by: "ASC"
    };
  },

  created() {
    // this.HandlerGetStore();
    const urlParams = new URLSearchParams(window.location.search);
    const store = urlParams.get("store");
    const sku = urlParams.get("sku");
    if (store) {
      this.selectedStore = Number(store);
    }
    this.sku = sku || "";
    this.HandlerGetPublication();
  },

  // mounted() {
  //   this.HandlerGetPublication();
  // },

  watch: {
    page: function(val) {
      this.HandlerGetPublication(val);
    }
  },

  filters: {
    currencyValue(value) {
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(value);
      return AMOUNT_FORMAT;
    }
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },

    dataPublication() {
      return this.$store.getters["b2bPublication/GET_PUBLICATIONS"];
    },

    pagination() {
      return this.$store.getters["b2bPublication/GET_PAGINATION"];
    }
  },

  methods: {
    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: ""
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data;

        this.data_stores = response.data.data.filter(index => {
          return index.b2b_active == true;
        });
        this.HandlerGetPublication();
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetPublication(page = 1) {
      try {
        this.loading = true;
        const request = {
          page: page,
          per_page: 10,
          paginate: true,
          sort_position: "asc",
          country_id: this.selectedStore,
          sku: this.sku,
          order_by: this.order_by
        };

        await this.$store.dispatch(
          "b2bPublication/GET_PUBLICATIONS_B2B",
          request
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerAddPublication() {
      this.showRegisterPublication = !this.showRegisterPublication;
      this.action = 0;
      this.dataUpdate = {};
    },

    HandlerEdit(item) {
      this.dataUpdate = { ...item };
      this.action = 1;
      this.showRegisterPublication = !this.showRegisterPublication;
    },

    HandlerShowDelete(publication) {
      this.showDelete = true;
      this.publicationData = publication;
    },

    async HandlerDelete() {
      try {
        this.loadingDelete = true;
        const request = { id: this.publicationData.id };
        await this.$store.dispatch(
          "b2bPublication/DELETE_PUBLICATION_B2B",
          request
        );
        this.HandlerGetPublication(this.page, true);
        this.$snotify.success("Se ha eliminado correctamente", "Exitos!");
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error", "Error!");
      } finally {
        this.showDelete = false;
        this.loadingDelete = false;
      }
    },

    HandlerResponse() {
      this.showRegisterPublication = false;
      // if (action === 0) {
      this.HandlerGetPublication(this.page, true);
      // }
    },

    async HandlerStatus(item) {
      try {
        const request = {
          publication_id: item.id,
          request: { is_active: !item.is_active }
        };
        await this.$store.dispatch("publications/ENABLE_PUBLICATION", request);
        this.$snotify.success("Has actualizado la publicacion", "Exitos!");
        this.HandlerGetPublication(1, false);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style></style>
