<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            v-if="status_card"
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({})"
          >
            Añadir Producto
          </v-btn>
          <register-component
            v-if="activeAddData"
            :active="activeAddData"
            :data="dataAdd"
            :dataBrand="dataBrand"
            :action="actionAdd"
            @dialog:change="HandlerResponse"
          />
          <erps-component
            v-if="activeErps"
            :active="activeAddData"
            :data="dataAdd"
            :dataBrand="dataBrand"
            :action="1"
            @dialog:change="HandlerResponse"
          />
        </v-col>
      </v-row>
      <v-row class="mt-5" v-if="status_card">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            filled
            rounded
            dense
            class="rounded-small"
            label="Nombre / Marca / Modelo / SKU"
            v-model="search"
            single-line
            hide-details
            @keyup.enter="HandlerGetProducts()"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-select
            filled
            rounded
            dense
            :items="items"
            item-text="name"
            item-value="id"
            v-model="active"
            label="Estatus"
            color="blue-grey"
            class="rounded-small"
            single-line
            hide-details
            @change="HandlerGetProducts()"
          ></v-select>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-3"
                fab
                small
                @click="HandlerSyncContabilium()"
              >
                <v-avatar tile size="30">
                  <v-img src="@/assets/flag/arg.svg" contain></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <span>Sincronizar Contabilium</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mr-3"
                fab
                small
                @click="HandlerSyncContabiliumCL()"
              >
                <v-avatar tile size="30">
                  <v-img src="@/assets/flag/chl.svg" contain></v-img>
                </v-avatar>
                <!-- <v-icon color="primary">mdi-sync</v-icon> -->
              </v-btn>
            </template>
            <span>Sincronizar Contabilium</span>
          </v-tooltip>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :loading="loadingBtn"
                fab
                small
                color="primary"
                class="mr-3"
                @click="HandlerAddProductContabilium"
              >
                <v-avatar tile size="30">
                  <v-img
                    src="../../assets/img/isologo-white-comtabilium.svg"
                    contain
                  ></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <span>Contabilium</span>
          </v-tooltip> -->
          <v-btn
            :loading="loadingBtn"
            fab
            small
            class="mr-3"
            @click="showLoader = true"
          >
            <v-icon color="accent">mdi-file-excel</v-icon>
          </v-btn>
          <v-btn
            :loading="loadingBtn"
            fab
            small
            color="primary"
            class="mr-3"
            @click="saveImport = 'save'"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>

          <v-btn fab small @click="HandlerReset()">
            <v-icon color="primary">mdi-backup-restore</v-icon>
          </v-btn>
          <v-btn
            :loading="exportingProducts"
            fab
            small
            class="ml-3"
            @click="exportProducts()"
          >
            <v-icon color="primary">mdi-download</v-icon>
          </v-btn>

          <input
            v-show="false"
            ref="inputUpload"
            type="file"
            @change="HandlerImportFile"
          />
        </v-col>
      </v-row>
    </v-container>

    <register-contabilium-product
      v-if="registerProductContabilium"
      @dialog:change="HandlerResponseRegister"
    />

    <div v-if="status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="getProducts"
          hide-default-footer
          disable-pagination
          fixed-header
          height="530"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.has_null_specification`]="{ item }">
            <v-tooltip top v-if="item.has_null_specification">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#f44"
                      d="M20 17h2v-2h-2v2m0-10v6h2V7M4 2c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2V8l-6-6m-1 1.5L16.5 9H11Z"
                    />
                  </svg>
                </div>
              </template>
              <span>Actualización requerida</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex" style="align-items:center">
              <v-icon class="mr-2" @click="HandlerEditItem(item)">
                mdi-pencil
              </v-icon>
              <v-file-input
                v-if="!loadingFIleManual"
                class="mb-4"
                multiple
                label="File input"
                hide-input
                prepend-icon="mdi-text-box-plus-outline"
                @change="HandlerUploadManul(item)"
                v-model="fileManual"
              ></v-file-input>
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon
                class="mr-2"
                @click="HandlerGoToErps(item)"
                v-if="userIsAbleToSeeErps"
              >
                mdi-currency-usd
              </v-icon>
            </div>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <span v-if="item.name != null">
              {{ item.name }}
            </span>
          </template>

          <template v-slot:[`item.sku`]="{ item }">
            <div v-if="item.products_erp.length > 0">
              <div v-for="(item, i) of item.products_erp" :key="i">
                {{ item.sku }}
              </div>
            </div>
            <div v-else>
              {{ item.sku }}
            </div>
          </template>

          <template v-slot:[`item.model`]="{ item }">
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title
                  :class="item.brand === null ? 'red--text' : 'text-capitalize'"
                >
                  <span v-if="item.brand === null"
                    >No tiene marca registrada</span
                  >
                  <span v-else>{{ item.brand.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle
                  >Modelo:
                  <span
                    v-if="
                      item.pattern_name == null || item.pattern_name == 'null'
                    "
                  >
                    No tiene modelo registrada
                  </span>
                  <span v-else>
                    {{ item.pattern_name }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <div v-if="item.products_erp.length > 0">
              <div v-for="(product, i) of item.products_erp" :key="i">
                <v-chip
                  v-if="loadingStatus == false"
                  color="transparent"
                  small
                  @click="HandlerChangeStatus(item, product.country_id)"
                >
                  <v-icon
                    left
                    small
                    :color="product.active_b2c === true ? 'success' : 'error'"
                    >mdi-radiobox-marked</v-icon
                  >
                  {{ product.active_b2c === true ? "Activo" : "Inactivo" }}
                  ({{ product.country.name }})
                </v-chip>
              </div>
            </div>
            <div v-else>Por cargar</div>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ dateFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="space-between" class="mt-4">
        <v-col cols="6" md="3">
          <v-text-field
            @keyup.enter="HandlerGetData"
            type="number"
            ref="cantidad de pagina"
            filled
            rounded
            dense
            v-model="itemsPerPage"
            label="Cantidad"
            single-line
            hide-details
          >
            <template v-slot:append>
              <v-icon @click="HandlerGetData">
                mdi-book-open-page-variant
              </v-icon>
            </template></v-text-field
          >
        </v-col>
        <v-col cols="6" md="4">
          <v-pagination
            v-model="page"
            :length="getPaginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
      <v-dialog v-model="showLoader" persistent scrollable max-width="500">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card :loading="uploadFile">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-title class="pa-0 shadow-1">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
                <v-card-title class="font-weight-medium primary--text">
                  Cargar archivo
                </v-card-title>
              </v-img>
            </v-card-title>

            <vuescroll
              :ops="{
                bar: { background: '#bacfcec0', keepShow: true }
              }"
            >
              <v-card-text style="max-height: 350px">
                <ValidationProvider name="archivo" v-slot="{ errors }">
                  <v-file-input
                    v-model="file"
                    filled
                    rounded
                    class="rounded-small"
                    dense
                    label="Elige tu archivo"
                    prepend-icon
                    prepend-inner-icon="mdi-file-excel"
                    :error-messages="errors"
                  ></v-file-input>
                </ValidationProvider>
              </v-card-text>
            </vuescroll>

            <v-divider></v-divider>

            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="showLoader = false"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="uploadFile"
                  :disabled="uploadFile"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerImportFile)"
                  >Guardar</v-btn
                >
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-dialog>
      <loader-component
        v-if="showLoadingSync"
        :showLoader="showLoadingSync"
        color="primary"
      />
    </div>
  </section>
</template>

<script>
import vuescroll from "vuescroll";
import Register from "./Register";
import Erps from "./ProductErps.vue";
import ContabiliumProduct from "./Utils/RegisterContabiliumProduct";
import LoaderSync from "@/components/Utils/loader_component";
export default {
  components: {
    vuescroll,
    "register-component": Register,
    "erps-component": Erps,
    "register-contabilium-product": ContabiliumProduct,
    "loader-component": LoaderSync
  },
  data() {
    return {
      isAdmin: false,
      userIsAbleToSeeErps: false,
      loadingRentability: null,
      loading: false,
      exportingProducts: false,
      showLoadingSync: false,
      registerProductContabilium: false,
      saveImport: "",
      showLoader: false,
      uploadFile: false,
      loadingData: false,
      loadingTable: false,
      activeAddData: false,
      activeErps: false,
      status_card: true,
      actionAdd: 0,
      search: "",
      page: 1,
      pageCount: 0,
      selectedProductId: null,
      itemsPerPage: 50,
      loadingStatus: false,
      loadingBtn: false,
      name: "",
      sku: "",
      sku_contabilium: "",
      active: true,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text"
        },
        {
          text: "Sku",
          value: "sku",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Código de barras",
          value: "barcode",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Marca",
          value: "model",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Estatus",
          value: "active",
          sortable: false,
          class: "primary--text",
          width: "200"
        },
        {
          text: "Categoría",
          value: "category.name",
          sortable: false,
          class: "primary--text",
          width: "180"
        },
        {
          text: "",
          value: "has_null_specification",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          class: "primary--text"
        }
      ],
      data: [],
      items: [
        {
          id: true,
          name: "Activo"
        },
        {
          id: false,
          name: "Inactivo"
        },
        {
          id: "attention_required",
          name: "Requiere atención"
        },
        {
          id: 3,
          name: "Todos"
        }
      ],
      dataBrand: [],
      file: [],
      fileManual: [],
      loadingFIleManual: false,
      product_name: ""
    };
  },

  created() {
    const user = this.$store.getters["auth/GET_USER"];

    this.userIsAbleToSeeErps = user.permissions.some(
      p => p.name === "Productos SKUs"
    );
    this.isAdmin =
      this.getCurrentUser.roles.filter(
        rol => rol.name === "root" || rol.name === "admin"
      ).length > 0;
    this.HandlerGetProducts();
    this.HandlerGetBrand();
  },

  watch: {
    page(val) {
      this.HandlerGetProducts(val);
    }
  },

  computed: {
    getProducts() {
      return this.$store.getters["products/GET_PRODUCTS"];
    },

    getPaginate() {
      return this.$store.getters["products/GET_PAGINATE"];
    },
    getCurrentUser() {
      return this.$store.getters["auth/GET_USER"];
    }
  },

  methods: {
    async exportProducts() {
      this.exportingProducts = true;
      await this.$store.dispatch("products/EXPORT_PRODUCTS");
      this.exportingProducts = false;
    },
    handleGoBack() {
      this.activeErps = false;
      this.status_card = true;
    },
    async HandlerGetProducts(page) {
      try {
        this.loadingBtn = true;
        this.loadingTable = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: this.itemsPerPage,
          active: this.active,
          search: this.search.toLowerCase(),
          paginate: true,
          name: this.product_name
        };
        if (this.active == "attention_required") {
          request.attention_required = true;
          request.active = true;
        }
        await this.$store.dispatch("products/GET_PRODUCTS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
        this.loadingTable = false;
      }
    },

    async HandlerChangeStatus(item, country_id) {
      try {
        this.loadingStatus = true;
        const request = {
          // ...item,
          id: item.id,
          erps: country_id == 1 ? ["contabilium"] : ["contabilium_cl"],
          country_id: country_id
        };
        await this.$store.dispatch("products/GET_STATUS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingStatus = false;
      }
    },

    async HandlerGetBrand() {
      try {
        const request = {
          has_publications: "",
          store_id: ""
        };
        const response = await this.$store.dispatch(
          "products/GET_BRAND",
          request
        );
        this.dataBrand = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingStatus = false;
      }
    },

    HandlerAddProductContabilium() {
      this.registerProductContabilium = !this.registerProductContabilium;
    },

    async HandlerReset() {
      try {
        this.active = true;
        this.search = "";
        this.HandlerGetProducts(1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
      }
    },

    async HandlerImportFile() {
      try {
        this.showLoader = true;
        this.uploadFile = true;
        const request = new FormData();
        request.append("file", this.file);
        await this.$store.dispatch("products/UPLOAD_PRODUCTS", request);
        this.HandlerGetProducts(1);
        this.$snotify.info("Has cargado exitosamente el archivo", "Exitos");
      } catch (error) {
        console.log(error);
      } finally {
        this.showLoader = false;
        this.uploadFile = false;
      }
    },

    HandlerGetData() {
      this.HandlerGetProducts(1);
      this.page = 1;
    },

    HandlerEditItem(item) {
      this.activeAddData = true;
      this.actionAdd = 1;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    HandlerGoToErps(item) {
      this.activeErps = true;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    HandlerAddData(item) {
      this.registerProductContabilium = false;
      this.activeAddData = true;
      this.actionAdd = 0;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    async HandlerSyncContabilium() {
      try {
        this.showLoadingSync = true;
        await this.$store.dispatch("products/SYNC_CONTABILIUM");
        this.$snotify.success("Se ha Realizado la consulta", "Exitos!");
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la consulta", "Error!");
      } finally {
        this.showLoadingSync = false;
      }
    },

    async HandlerSyncContabiliumCL() {
      try {
        this.showLoadingSync = true;
        await this.$store.dispatch("products/SYNC_CONTABILIUM_CL");
        this.$snotify.success("Se ha Realizado la consulta", "Exitos!");
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la consulta", "Error!");
      } finally {
        this.showLoadingSync = false;
      }
    },

    HandlerResponse(value) {
      this.activeAddData = value;
      this.activeErps = false;
      this.status_card = true;

      this.HandlerGetProducts(this.page);

      // this.HandlerGetProducts();
      // this.HandlerGetBrand();
    },

    HandlerResponseRegister() {
      this.registerProductContabilium = false;
    },

    async HandlerUploadManul(item) {
      try {
        this.loadingFIleManual = true;
        const request = new FormData();
        request.append("product_manual", this.fileManual[0]);
        const requestData = { product_id: item.id };
        await this.$store.dispatch("products/UPDATE_DOC_MANUAL", {
          data: request,
          id: requestData
        });
        this.$snotify.success("Cargado con exitos", "Exitos");
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFIleManual = false;
      }
    }
  }
};
</script>

<style></style>
