<template>
  <v-container fluid>
    <filters-component
      v-if="status_card"
      :paginate="paginate"
      :page="page"
      @filters:change="HandlerFilterResponse"
    />
    <v-row class="mt-10">
      <v-col cols="12" md="12">
        <v-row class="ml-0 mt-10 mb-0">
          <v-toolbar
            flat
            color="primary"
            dark
            style="max-width: 30%;display: flex;justify-content:center"
          >
            <v-toolbar-title>Estatus del envio</v-toolbar-title>
          </v-toolbar>
          <v-col cols="12" sm="4" md="3">
            <v-text-field
              @keyup.enter="handlerUpdateDispatchCode"
              :disabled="cannotEditDispatchCode() || updatingDispatchCode"
              v-model.trim="dispatchCode"
              filled
              rounded
              dense
              class="rounded-small"
              name="dispatchCode"
              label="Código de despacho"
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card :class="HandlerResponsive">
          <v-tabs v-model="tab" align-with-title class="my-3 ml-md-5">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              <v-badge
                v-if="currentTab == item"
                bordered
                bottom
                color="primary"
                :content="!loading && paginate.total"
                transition="slide-x-transition"
              >
                {{ item }}
              </v-badge>
              <span v-else>
                {{ item }}
              </span>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
        v-for="(item, index) in dataOrder"
        :key="index"
        v-show="!loading"
      >
        <v-card flat class="elevation-0">
          <v-card-text>
            <v-card flat class="elevation-0">
              <v-row>
                <!-- <h3 class="ml-3">Envio #{{ item.meli_shippings_id }}</h3> -->
                <p class="ml-3 mb-0 mt-2 title">
                  Venta #{{ item.order.meli_id }}
                </p>

                <p class="mr-3 mb-0 mt-2 title ml-auto">
                  <span v-if="item.position >= 1">
                    Pág {{ item.position }} / {{ item.position_total }}
                  </span>
                  <!-- <span v-else>
                    Pág 1/1
                  </span> -->
                </p>
              </v-row>
              <v-row
                style="align-items: center"
                class="py-3"
                v-if="item.errorMessage"
              >
                <p class="ml-3 mb-0 mt-2 title" style="color: red">
                  Error creando el envio:
                </p>

                <b>
                  <p class="ml-3 mb-0 mt-2 ">
                    {{ item.errorMessage }}
                  </p>
                </b>
              </v-row>
              <v-card-text>
                <table-order-item
                  :orderItem="item.order.order_item"
                  v-if="item.order_item == null"
                />
                <table-order-item
                  :orderItem="[item.order_item]"
                  v-else
                  :action="true"
                />
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" sm="3" md="3">
                    <buyer-information-component :shipping="item" />
                  </v-col>
                  <v-col cols="12" sm="3" md="4">
                    <shipping-buyer-component :order="item" />
                    <shipping-status-component :order="item" />
                  </v-col>

                  <v-col offset-md="2" cols="12" sm="3" md="3">
                    <ticket-ship-information-component :order="item" />

                    <div
                      v-if="
                        item.date_prepeared == null ||
                          item.date_delivered == null
                      "
                    >
                      <button-to-prepared-component
                        :order="item"
                        :statusShip="statusShip"
                      />

                      <button-prepared-component
                        :order="item"
                        :statusShip="statusShip"
                      />

                      <button-in-transit-component
                        :order="item"
                        :statusShip="statusShip"
                      />

                      <button-delivered-component
                        :order="item"
                        :statusShip="statusShip"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="12" class="mt-15">
        <v-row justify="center">
          <v-progress-circular
            :size="300"
            :width="7"
            indeterminate
            color="#7E82FA"
          ></v-progress-circular>
        </v-row>
      </v-col>
      <v-col
        v-show="dataOrder.length == 0 && !loading"
        cols="12"
        md="12"
        class="mt-15"
      >
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-alert
              outlined
              color="#625FA4"
              type="warning"
              prominent
              border="left"
            >
              <p class="text-center headline">No hay datos para mostrar</p>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- <v-row v-show="dataOrder.length == 0" class="mt-10">
      <v-col cols="12" md="12">
        <v-card flat class="elevation-0">
          <v-card-text>
            <v-alert outlined type="warning" text>
              <span :class="isDark ? 'white--text' : 'black--text'">
                No se encuentran productos con estos filtros
              </span>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
    <v-row justify="end">
      <v-col cols="12" md="4">
        <v-pagination
          v-if="paginate.lastPage > 0"
          v-model="myPage"
          :length="paginate.lastPage"
          :total-visible="$vuetify.breakpoint.name == 'xs' ? 5 : 7"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Filters from "./Utils/Filters/filter";
import TableOrderItem from "./Utils/table-order-items";
import BuyerInformation from "./Utils/Buyer/buyer-information";
import ShippingBuyerInformation from "./Utils/OrderShip/shipping-information";
import ShippingStatus from "./Utils/OrderShip/shipping-status-information";
import ShippingTicketInformation from "./Utils/ButtonsStatus/print-ticket-information";
import ButtonToPrepare from "./Utils/ButtonsStatus/to_prepare";
import ButtonPepared from "./Utils/ButtonsStatus/prepared";
import ButtonInTransit from "./Utils/ButtonsStatus/in_transit";
import ButtonDelivered from "./Utils/ButtonsStatus/delivered";

export default {
  components: {
    "filters-component": Filters,
    "table-order-item": TableOrderItem,
    "buyer-information-component": BuyerInformation,
    "shipping-buyer-component": ShippingBuyerInformation,
    "shipping-status-component": ShippingStatus,
    "ticket-ship-information-component": ShippingTicketInformation,
    "button-to-prepared-component": ButtonToPrepare,
    "button-prepared-component": ButtonPepared,
    "button-in-transit-component": ButtonInTransit,
    "button-delivered-component": ButtonDelivered
  },
  data() {
    return {
      updatingDispatchCode: false,
      loading: false,
      page: 1,
      status_card: true,
      tab: null,
      operation_number: "",
      client_name: "",
      product_name: "",
      seller: "",
      dataSeller: [],
      dataStore: [],
      store: "",
      date: null,
      menu_start: false,
      date_end: null,
      menu_end: false,
      dataTypeSend: [],
      typeSend: "",
      items: [
        "Para preparar",
        "Para preparar (Open box)",
        "Para despachar",
        "EN TRANSITO",
        "FINALIZADO"
      ],
      // dataOrder: [],
      // paginate: {},
      // FILTROS
      isFilter: false,
      currentTab: "",
      dispatchCode: null
    };
  },

  created() {
    this.HandlerShowShipping();
    this.getDispatchCode();
  },

  beforeDestroy() {
    clearInterval();
    this.$store.commit("orders/CLEAR_STATE");
  },

  watch: {
    page(val) {
      this.HandlerShowShipping(val);
    },
    tab(val) {
      switch (val) {
        case 0:
          this.currentTab = "Para preparar";
          break;
        case 1:
          this.currentTab = "Para preparar (Open box)";
          break;
        case 2:
          this.currentTab = "Para despachar";
          break;
        case 3:
          this.currentTab = "EN TRANSITO";
          break;
        case 4:
          this.currentTab = "FINALIZADO";
          break;
        default:
          break;
      }
      const searchQuery = { ...this.searchQuery };
      searchQuery.tabs_status = val <= 1 ? 1 : val;
      if (val === 1) {
        searchQuery.open_box = true;
        searchQuery.paginate = false;
      } else {
        searchQuery.open_box = false;
        searchQuery.paginate = true;
      }
      searchQuery.page = 1;
      this.$store.commit("orders/SET_SEARCH_QUERY", searchQuery);
      this.HandlerShowShipping();
    }
  },

  computed: {
    HandlerResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-start";
        case "sm":
          return "text-start";
        case "md":
          return "d-flex justify-center";
        case "lg":
          return "d-flex justify-center";
        case "xl":
          return "d-flex justify-center";
        default:
          return "d-flex justify-center";
      }
    },
    paginate() {
      return this.$store.getters["orders/GET_PAGINATE"];
    },
    dataOrder() {
      return this.$store.getters["orders/GET_DATA_ORDER"].filter(
        ({ buyer_id }) => buyer_id !== 31971
      );
    },
    statusShip() {
      return this.$store.getters["orders/GET_DATA_STATUSHIP"];
    },
    searchQuery() {
      return this.$store.getters["orders/GET_DATA_FILTER"];
    },

    myPage: {
      get() {
        const paginate = this.$store.getters["orders/GET_PAGINATE"];
        return paginate.page;
      },
      set(val) {
        this.$store.commit("orders/SET_PAGINATE", val);
        const query = { ...this.searchQuery };
        query.page = val;
        this.$store.commit("orders/SET_SEARCH_QUERY", query);
        this.HandlerShowShipping();
      }
    }
  },

  methods: {
    async HandlerShowShipping() {
      try {
        this.loading = true;
        const request = this.searchQuery;
        const requestData = {
          ...request,
          warehouse_id: 5,
          country_id: 1
        };
        await this.$store.dispatch("orders/GET_SHIP", requestData);
        // if (request.open_box) {
        //   this.tab = 1;
        // } else {
        //   const currentTab
        //   this.tab = response.data.data.status_tabs;
        // }
        // console.log(this.tab);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerSearchFilter(request) {
      try {
        const requestData = { ...request };
        await this.$store.dispatch("orders/GET_SHIP", requestData);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerFilterResponse(loading) {
      this.loading = loading;
      if (this.statusShip === 1) {
        this.tab = 0;
      } else {
        this.tab = this.statusShip;
      }
    },

    async getDispatchCode() {
      try {
        const payload = {
          accountId: 1
        };
        const response = await this.$store.dispatch(
          "ml/GET_DISPATCH_CODES",
          payload
        );
        const code = response.data[0].shippings_dispatch_code;
        console.log(response.data);
        this.dispatchCode = code;
        console.log(this.dispatchCode);
      } catch (error) {
        console.log(error);
      }
    },

    cannotEditDispatchCode() {
      const user = this.$store.getters["auth/GET_USER"];
      const permission = user.permissions.find(
        p => p.name === "Editar codigo de despacho"
      );
      return !permission;
    },

    updateDispatchCode(input) {
      console.log(input);
      // this.dispatchCode = input.replace(" ", "");
    },

    async handlerUpdateDispatchCode() {
      this.updatingDispatchCode = true;

      try {
        await this.$store.dispatch("ml/UPDATE_DISPATCH_CODES", {
          id: 1,
          shippings_dispatch_code: this.dispatchCode
        });
        this.$snotify.success(
          "El código de despacaho se ha actualizado exitosamente"
        );
      } catch (error) {
        this.$snotify.error("Ha ocurrido un error ", "¡Error!");
      } finally {
        this.updatingDispatchCode = false;
      }
    }
  }
};
</script>

<style></style>
