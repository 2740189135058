<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="12">
          <v-card>
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-title class="pa-0 shadow-1">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
                <v-card-title class="font-weight-medium primary--text">
                  <!-- {{ action == 0 ? "Nuevo" : "Editar" }}  -->
                  Registrar
                </v-card-title>
              </v-img>
            </v-card-title>

            <vuescroll
              :ops="{
                bar: { background: '#bacfcec0', keepShow: true },
              }"
            >
              <v-card-text>
                <SendProducts
                  @dialog:change="HandlerResponse"
                  :products="products"
                />
              </v-card-text>
            </vuescroll>

            <v-divider></v-divider>

            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="close"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="HandlerAction"
                >
                  Registrar
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import vuescroll from "vuescroll";
import SendProducts from "./Utils/SendProducts";
export default {
  components: {
    SendProducts,
    vuescroll,
  },
  data() {
    return {
      e1: 1,
      itemsPerPage: 6,
      loading: false,
      search: "",
      items_products: [],
      products: [],
      sendData: [],
    };
  },
  created() {
    // this.HandlerGetProducts();
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    async HandlerGetProducts(page) {
      try {
        this.loadingBtn = true;
        this.loadingTable = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: this.itemsPerPage,
          active: 1,
          search: this.search.toLowerCase(),
        };

        const response = await this.$store.dispatch(
          "products/GET_PRODUCTS_TRANSACTIONS",
          request
        );

        this.items_products = response.data.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
        this.loadingTable = false;
      }
    },

    HandlerArrayProduct(item, array_new, array_old) {
      try {
        this[array_new].push(item);
        const indexDelete = this[array_old].indexOf(item);
        if (indexDelete > -1) this[array_old].splice(indexDelete, 1);
      } catch (error) {
        console.log("error", error);
      }
    },

    close() {
      this.$emit("dialog:change", false);
    },

    HandlerAction() {
      console.log("Registro", this.sendData);
    },

    HandlerResponse(products) {
      this.sendData = [...products];
    },
  },
};
</script>
<style>
</style>