<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row style="justify-content: space-between; align-items: center;">
        <v-row class="mb-0">
          <v-col cols="12" sm="5" md="4">
            <v-select
              :items="dataCountry"
              item-text="name"
              item-value="code_iso"
              v-model="country_id"
              style="max-width:400px"
              menu-props="auto"
              filled
              rounded
              dense
              class="rounded-small"
              name="country"
              label="Pais"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <div v-if="!loadingQuestions">
      <v-card v-if="!getQuestions.length" class="mt-4">
        <div class="not-questions">
          No hay preguntas por responder en este momento
        </div>
      </v-card>
      <v-card
        v-for="(item, index) in getQuestions"
        :key="index"
        class="shadow-1 mt-5 py-5 px-5"
      >
        <div class="card-row-question">
          <div class="d-flex flex-row">
            <div class="mr-4">
              <v-img
                v-if="!item.image"
                contain
                src="@/assets/img/no_image.jpg"
                height="100"
                width="100"
              >
              </v-img>
              <v-img
                v-else
                contain
                height="100"
                width="100"
                :src="item.image"
                :lazy-src="item.image"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div>
              <div class="title-item mb-1">{{ item.title }}</div>
              <div class="d-flex flex-row align-baseline">
                <div class="price-text mr-1">${{ item.price }}</div>
                <div class="common-text">
                  x{{ item.available_quantity }} disponible
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="common-text">Publicación #{{ item.item_id }} |</div>
                <div class="common-text">SKU:{{ item.sku }}</div>
              </div>
            </div>
          </div>

          <!-- <div class="btn-technical">
                  <v-btn
                    class="accent shadow-4 px-4"
                    :disabled="loadingAnswer[item.id] || loadingSuggestion[item.id]"
                  >
                    Asignar a equipo técnico
                  </v-btn>
                </div> -->
        </div>
        <div class="row-question">
          <div class="question-row-box">
            <div>
              <v-icon large>mdi-comment-text-outline</v-icon>
            </div>
            <div class="title-item px-3 text-bold">{{ item.text }}</div>
          </div>

          <div class="d-flex flex-row align-center mt-2">
            <v-icon class="mr-1" small>mdi-clock-outline</v-icon>
            <div class="time-text">
              Hace {{ getQuestionTimeAgo(item.created_at) }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-row mb-3 input-container">
          <v-textarea
            solo
            name="input-7-4"
            label="Ingresá tu respuesta"
            class="input-answer"
            no-resize
            v-model="answers[item.id]"
          ></v-textarea>
          <v-btn
            class="input-btn"
            :disabled="loadingSuggestion[item.id] || !answers[item.id]"
            :loading="loadingAnswer[item.id]"
            @click="answerQuestion(item.id)"
          >
            <v-icon color="white">mdi-check-bold</v-icon>
          </v-btn>
        </div>
        <div class="center-btn-box">
          <v-btn
            class="accent shadow-4 px-4 center-btn"
            :disabled="loadingAnswer[item.id]"
            :loading="loadingSuggestion[item.id]"
            @click="getHelpAi(item)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              class="mr-1"
            >
              <path
                fill="white"
                d="M7.5 5.6L5 7l1.4-2.5L5 2l2.5 1.4L10 2L8.6 4.5L10 7L7.5 5.6m12 9.8L22 14l-1.4 2.5L22 19l-2.5-1.4L17 19l1.4-2.5L17 14l2.5 1.4M22 2l-1.4 2.5L22 7l-2.5-1.4L17 7l1.4-2.5L17 2l2.5 1.4L22 2m-8.66 10.78l2.44-2.44l-2.12-2.12l-2.44 2.44l2.12 2.12m1.03-5.49l2.34 2.34c.39.37.39 1.02 0 1.41L5.04 22.71c-.39.39-1.04.39-1.41 0l-2.34-2.34c-.39-.37-.39-1.02 0-1.41L12.96 7.29c.39-.39 1.04-.39 1.41 0Z"
              />
            </svg>
            Obtener ayuda de la IA
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 20 20"
            >
              <path fill="white" d="m15 8l-4.03 6L7 8h8z" />
            </svg>
          </v-btn>
        </div>
      </v-card>
      <v-row justify="end" class="mt-2 mb-4" v-if="getQuestions.length">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <div v-else class="d-flex justify-center">
      <v-progress-circular
        size="300"
        class="d-flex justify-center my-5"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </section>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      loadingQuestions: false,
      loadingAnswer: {},
      loadingSuggestion: {},
      isChangingPageInternally: false,
      dataCountry: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 12,
      country_id: "",
      answers: {},
    };
  },

  created() {
    this.HandlerGetCountry();
  },

  watch: {
    page: {
      handler(val) {
        if (!this.isChangingCountryIdInternally) {
          this.HandlerGetQuestions(val, this.country_id);
        }
      },
      deep: true,
    },

    country_id(val) {
      this.isChangingCountryIdInternally = true;
      this.page = 1;
      this.HandlerGetQuestions(1, val);
      this.$nextTick(() => {
        this.isChangingCountryIdInternally = false;
      });
    },
  },

  computed: {
    getQuestions() {
      return this.$store.getters["store/GET_QUESTIONS"];
    },
    pagination() {
      return this.$store.getters["store/GET_PAGINATE_QUESTIONS"];
    },
  },

  methods: {
    async HandlerGetQuestions(page = 1, country_id = "AR") {
      try {
        this.loadingQuestions = true;
        const request = {
          page: page,
          per_page: this.itemsPerPage,
          paginate: true,
          countryId: country_id,
          status: "UNANSWERED",
        };
        await this.$store.dispatch("store/GET_QUESTIONS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingQuestions = false;
      }
    },

    async HandlerGetCountry() {
      try {
        this.loadingQuestions = true;
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        const countries = response.data.data.filter((elem) => elem.id !== 4);
        this.country_id = countries[0].code_iso;
        this.dataCountry = countries;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingQuestions = false;
      }
    },

    async answerQuestion(id) {
      try {
        this.loadingAnswer[id] = true;
        this.$forceUpdate();
        const request = {
          id: id,
          answer: this.answers[id],
        };
        await this.$store.dispatch("store/ANSWER_QUESTION", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAnswer[id] = false;
        this.$forceUpdate();
      }
    },

    async getHelpAi(question) {
      try {
        this.loadingSuggestion[question.id] = true;
        this.$forceUpdate();
        const request = {
          question: `${question?.text} ${question?.brand} ${question?.title} `,
        };
        const response = await this.$store.dispatch(
          "store/GET_HELP_AI",
          request
        );
        const responseAi = response.data;
        this.answers[question.id] = responseAi;
        this.$forceUpdate();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingSuggestion[question.id] = false;
        this.$forceUpdate();
      }
    },

    getQuestionTimeAgo(time) {
      const questionTime = moment(time);
      const actualTime = moment();
      const minutesAgo = actualTime.diff(questionTime, "minutes");
      const hoursAgo = actualTime.diff(questionTime, "hours");
      const daysAgo = actualTime.diff(questionTime, "days");

      if (daysAgo >= 1) {
        return `${daysAgo} día${daysAgo === 1 ? "" : "s"}`;
      } else if (hoursAgo >= 1) {
        return `${hoursAgo} hora${hoursAgo === 1 ? "" : "s"}`;
      } else {
        return `${minutesAgo} minuto${minutesAgo === 1 ? "" : "s"}`;
      }
    },
  },
};
</script>

<style>
.not-questions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px;
}
.question-row-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
}
.title-item {
  font-weight: 500;
  font-size: 17px;
  color: rgba(0, 0, 0, 0.77);
  flex: 1;
}
.price-text {
  font-weight: 500;
  font-size: 17px;
  color: #625fa4 !important;
}
.common-text {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.77);
}
.time-text {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.57);
  line-height: 12px;
}
.text-bold {
  font-weight: 600;
}
.input-btn {
  height: 120px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  background-color: #625fa4 !important;
}
.input-answer {
  height: 120px !important;
}
.input-answer .v-input__control .v-input__slot {
  margin: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
  box-shadow: none !important;
}
.input-answer .v-input__control .v-text-field__slot {
  height: 120px !important;
}
.input-answer .v-text-field__details {
  display: none;
}
.input-container {
  border-radius: 4px !important;
  border: 2px solid #625fa4;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card-row-question {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.btn-technical {
  display: flex;
  align-self: flex-start;
  margin-top: 16px;
}
.row-question {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 8px 0;
}
.center-btn-box {
  display: flex;
}
@media only screen and (max-width: 1150px) {
  .card-row-question {
    flex-direction: column !important;
  }
  .btn-technical {
    margin-top: 6px;
    align-self: center;
  }
  .row-question {
    flex-direction: column;
  }
  .center-btn {
    display: flex;
    align-self: center;
  }
  .center-btn-box {
    align-items: center;
    flex-direction: column;
  }
}
</style>
