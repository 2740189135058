var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('alert',{attrs:{"data":_vm.errorData,"active":_vm.activeAlert,"colorAlert":_vm.colorAlert},on:{"dialog:change":_vm.eventdialog}}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"350"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Dias feriados ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
            bar: { background: '#bacfcec0', keepShow: true },
          }}},[_c('v-card-text',{staticStyle:{"max-height":"350px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-date-picker',{attrs:{"multiple":"","no-title":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":_vm.closeModal}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerAction)}}},[_vm._v("Añadir")])]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }