<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            v-if="status_card"
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({}, 0)"
            >Registrar Rol</v-btn
          >
        </v-col>
        <register-components
          v-if="showRegister"
          :active="showRegister"
          :data="dataAdd"
          :action="actionAdd"
          @dialog:change="HandlerResponse"
        />
      </v-row>
    </v-container>

    <div v-if="status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="items"
          :search="search"
          :expanded.sync="expanded"
          show-expand
          :items-per-page="itemsPerPage"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" @click="HandlerAddData(item, 1)">
              mdi-pencil
            </v-icon>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | date }}</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td
              v-if="item.permissions.length > 0"
              :colspan="item.permissions.length"
            >
              <v-row class="my-2">
                <v-col
                  cols="12"
                  sm="3"
                  v-for="(item2, index) in item.permissions"
                  :key="index"
                >
                  <v-chip>
                    {{ item2.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </td>
            <td v-else :colspan="headers.length">
              <p class="red--text mt-5">No tiene permisos asignados</p>
            </td>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="getPaginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import RegisterRoles from "./Utils/RegisterRoles";
export default {
  components: {
    "register-components": RegisterRoles,
  },
  data() {
    return {
      status_card: true,
      loading: false,
      expanded: [],
      dataAdd: {},
      actionAdd: 0,
      singleExpand: false,
      showRegister: false,
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Descripcion",
          align: "left",
          sortable: false,
          value: "description",
          class: "primary--text",
        },
        {
          text: "Creacion",
          align: "left",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Acciones",
          align: "left",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      items: [],
      search: "",
      itemsPerPage: 50,
      page: 1,
    };
  },
  created() {
    this.HandlerGetRoles();
  },
  watch: {
    page(val) {
      this.HandlerGetRoles(val);
    },
  },
  filters: {
    date(value) {
      return moment(value).locale("es").format("LL");
    },
  },
  computed: {
    getPaginate() {
      return this.$store.getters["usersIndex/GET_PAGINATE_ROLES"];
    },
    userInfo() {
      return this.$store.getters["auth/GET_USER"];
    },
  },
  methods: {
    async HandlerGetRoles(page) {
      try {
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: true,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_ROLES",
          request
        );

        const roleUser = this.userInfo.roles
          .map((val) => val.name)[0]
          .toString();

        if (roleUser == "root") {
          this.items = response.data.data.data;
        } else {
          this.items = response.data.data.data.filter(
            (val) => val.name != "root"
          );
        }
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ha ocurrido un error obteniendo los roles",
          "¡Error!"
        );
      }
    },

    HandlerAddData(data, action) {
      this.status_card = !this.status_card;
      this.dataAdd = { ...data };
      this.showRegister = true;
      this.actionAdd = action;
    },

    HandlerResponse(data, action) {
      console.log("update",data)
      this.status_card = !this.status_card;
      this.showRegister = false;
      if(data == null) {
        return;
      }
      if (action == 0) {
        this.items.push(data.data);
      } else if(action == 1) {
        const indexUpdate = this.items
          .map((element) => element.id)
          .indexOf(data.data.id);
        if (indexUpdate > -1) {
          this.items.splice(indexUpdate, 1, data.data);
        }
        this.$store.dispatch("auth/GET_AUTH_USER_AGAIN");
      }
    },
  },
};
</script>

<style>
</style>