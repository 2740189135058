var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('div',{staticClass:"font-weight-medium primary--text mb-5 ml-3",staticStyle:{"font-size":"26px"}},[_vm._v(" Crear / Editar Roles ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"mb-5",attrs:{"height":"auto"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}})],1),_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":"","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre del rol","name":"nombre","prepend-inner-icon":"mdi-account","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.name),callback:function ($$v) {_vm.$set(_vm.data, "name", $$v)},expression:"data.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Descripcion del rol","name":"nombre","prepend-inner-icon":"mdi-account","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"small-chips":"","multiple":"","label":"Permisos","items":_vm.userRouteList,"item-text":"title","item-value":"id","required":"","filled":"","rounded":"","dense":"","prepend-inner-icon":"mdi-account"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticClass:"mx-2"},[_vm._v(_vm._s(item.title))])],1)]}}],null,true),model:{value:(_vm.permissionRoutes),callback:function ($$v) {_vm.permissionRoutes=$$v},expression:"permissionRoutes"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){return _vm.closeModal(null, false)}}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerRegister)}}},[_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar" : "Añadir")+" ")])]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }