<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
      >
        Crear / Editar Roles
      </div>
      <v-col cols="12" md="12">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-title class="pa-0">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
              </v-img>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-row dense justify="start">
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="nombre"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Nombre del rol"
                        name="nombre"
                        prepend-inner-icon="mdi-account"
                        type="text"
                        color="blue-grey"
                        v-model="data.name"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="descripcion"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Descripcion del rol"
                        name="nombre"
                        prepend-inner-icon="mdi-account"
                        type="text"
                        color="blue-grey"
                        v-model="data.description"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-autocomplete
                      small-chips
                      multiple
                      label="Permisos"
                      :items="userRouteList"
                      item-text="title"
                      item-value="id"
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      v-model="permissionRoutes"
                      prepend-inner-icon="mdi-account"
                    >
                      <template v-slot:[`item`]="{ item }">
                        <div class="d-flex">
                          <v-icon>{{ item.icon }}</v-icon>
                          <span class="mx-2">{{ item.title }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal(null, false)"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerRegister)"
                >
                  {{ action == 1 ? "Editar" : "Añadir" }}
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { routesList } from "../../core/Utils/routeList";
export default {
  props: {
    routesList: {
      type: Array,
      default: () => [],
      required: false
    },
    action: {
      type: Number,
      required: false,
      default: 0
    },
    data: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      userRouteList: [],
      loading: false,
      permissionRoutes: [],
      name: "",
      description: ""
    };
  },

  created() {
    const routeListData = [...routesList];
    // let arrayRoutes = [];

    this.userRouteList = routeListData.filter(val => {
      if (val.dropdownBox) {
        return false;
      }
      return val.title != "Dashboard";
    });

    console.log(
      "afkajflak",
      this.userRouteList.map(({ title, id }) => ({ title, id }))
    );

    // const userList = routeListData.map((val) => {
    //   if (val.group != undefined) {
    //     arrayRoutes.push(
    //       val.routes.map((gro) => {
    //         return { ...gro };
    //       })
    //     );
    //   }
    //   return {
    //     ...val,
    //   };
    // });
    // const flatArray = arrayRoutes.flat();

    // const userPermissionList = [...userList, flatArray].flat();
    // this.userRouteList = [...userPermissionList];
    if (this.action == 1) {
      this.permissionRoutes = this.data.permissions.map(val => val.id);
    }
  },

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerRegister() {
      try {
        let request = {};
        if (this.action == 0) {
          request = {
            name: this.data.name,
            slug: this.data.name,
            description: this.data.description,
            permissions: this.permissionRoutes
          };
        } else {
          request = {
            data: {
              name: this.data.name,
              slug: this.data.name,
              description: this.data.description,
              permissions: this.permissionRoutes
            },
            id: this.data.id
          };
        }

        const query = this.action == 0 ? "REGISTER_ROLES" : "UPDATED_ROLES";

        const response = await this.$store.dispatch(
          `usersIndex/${query}`,
          request
        );

        this.$snotify.success(
          `Rol ${this.action == 0 ? "registrado" : "editado"}`,
          "¡Exitos!"
        );

        this.$emit("dialog:change", response.data, this.action);
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con el registro", "¡Error!");
      }
    }
  }
};
</script>

<style></style>
