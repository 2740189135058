var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.payment.shipping.status != 'cancelled' &&
      _vm.payment.shipping.logistic_type != 'fulfillment' &&
      _vm.payment.shipping.shipping_mode != 'to_be_agreed'
    )?_c('p',{staticClass:"mb-7 subtitle-1 font-weight-bold text-uppercase"},[_vm._v(" Mercadoenvíos tradicional ")]):(
      _vm.payment.shipping.status != 'cancelled' &&
      _vm.payment.shipping.logistic_type != 'fulfillment' &&
      _vm.payment.shipping.shipping_mode == 'to_be_agreed'
    )?_c('p',{staticClass:"mb-7 subtitle-1 font-weight-bold text-uppercase"},[_vm._v(" Envío a Coordinar ")]):(
      _vm.payment.shipping.status != 'cancelled' &&
      _vm.payment.shipping.logistic_type == 'fulfillment'
    )?_c('p',{staticClass:"mb-7 subtitle-1 font-weight-bold text-uppercase",staticStyle:{"color":"#2c9a8f","font-weight":"600"}},[_vm._v(" Mercadoenvíos FULL ")]):_c('p',{staticClass:"mb-7 subtitle-1 font-weight-bold text-uppercase",staticStyle:{"color":"#cc3e6c","font-weight":"600"}},[_vm._v(" Envío Cancelado ")])])}
var staticRenderFns = []

export { render, staticRenderFns }