<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            v-if="status_card"
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({})"
            >Registrar Usuario</v-btn
          >
          <register-user-component
            v-if="showRegister"
            :active="showRegister"
            :data="dataAdd"
            :action="actionAdd"
            :roles="roles"
            @dialog:change="HandlerResponse"
          />
        </v-col>
      </v-row>
    </v-container>

    <div v-if="status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :loading="false"
          :headers="headers"
          :items="items"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="row-actions" v-if="!loadingActions[item.id]">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="HandlerEditItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="CheckBlockUser(item)"
                  >
                    {{item.is_active ? "mdi-lock-open" : "mdi-lock"}}
                  </v-icon>
                </template>
                <span>{{item.is_active ? "Bloquear" : "Desbloquear"}}</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="CheckDeleteUser(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex justify-center">
              <v-progress-circular
                size="20"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-chip
              v-if="loadingStatus == false"
              color="transparent"
              small
              @click="HandlerChangeStatus(item)"
            >
              <v-icon
                left
                small
                :color="item.active === true ? 'success' : 'error'"
                >mdi-radiobox-marked</v-icon
              >
              {{ item.active === true ? "Activo" : "Inactivo" }}
            </v-chip>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at }}</span>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="getPaginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
    <custom-modal
      v-if="showDeleteConfirmModal"
      :active="showDeleteConfirmModal"
      :action="HandlerDeleteUser"
      :closeModal="HandlerCloseModal"
      confirmBtn
      :title="'Confirmar eliminación'"
      max-width="600"
      :withImageTitle="false"
      closeText="cancelar"
    >
      <div class="delete-message">
        {{ infoDeleteUser.message }}
      </div>
    </custom-modal>
    <custom-modal
      v-if="showBlockConfirmModal"
      :active="showBlockConfirmModal"
      :action="HandlerBlockUser"
      :closeModal="HandlerCloseModal"
      confirmBtn
      :title="infoBlockUser.title"
      max-width="600"
      :withImageTitle="false"
      closeText="cancelar"
    >
      <div class="delete-message">
        {{ infoBlockUser.message }}
      </div>
    </custom-modal>
  </section>
</template>

<script>
import RegisterUser from "./Utils/registerUser";
import CustomModal from "../CustomModal/customModal.vue";
export default {
  components: {
    "register-user-component": RegisterUser,
    "custom-modal": CustomModal,
  },
  data() {
    return {
      status_card: true,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      showRegister: false,
      dataAdd: [],
      actionAdd: 0,
      headers: [
        {
          text: "Usuario",
          align: "left",
          sortable: false,
          value: "username",
          class: "primary--text",
        },
        {
          text: "Correo",
          value: "email",
          class: "primary--text",
        },
        {
          text: "IP",
          value: "ip",
          class: "primary--text",
        },
        {
          text: "Ultimo ingreso",
          value: "last_login",
          class: "primary--text",
        },
        {
          text: "Creado",
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "primary--text",
        },
      ],
      items: [],
      roles: [],
      loadingActions: {},
      showDeleteConfirmModal: false,
      infoDeleteUser: { item: null, message: "" },
      showBlockConfirmModal: false,
      infoBlockUser: { item: null, message: "" },
    };
  },

  created() {
    this.HandlerGetUsers();
    this.HandlerGetRoles();
  },

  watch: {
    page(val) {
      this.HandlerGetUsers(val);
    },
  },

  computed: {
    getPaginate() {
      return this.$store.getters["usersIndex/GET_PAGINATE"];
    },
  },

  methods: {
    async HandlerGetRoles(page) {
      try {
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: false,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_ROLES",
          request
        );
        this.roles = response.data.data;
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ha ocurrido un error obteniendo los roles",
          "¡Error!"
        );
      }
    },

    async HandlerGetUsers(page) {
      try {
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 10,
          paginate: true,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_USERS",
          request
        );
        this.items = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async CheckDeleteUser(item) {
        this.loadingActions[item.id] = true;
        this.$forceUpdate();
        this.showDeleteConfirmModal = true;
        this.infoDeleteUser = {
          item: item,
          message: `¿Seguro que desea eliminar a ${item.username}?`,
        };
    },

    async CheckBlockUser(item) {
        this.loadingActions[item.id] = true;
        this.$forceUpdate();
        this.showBlockConfirmModal = true;
        this.infoBlockUser = {
          item: item,
          message: `¿Seguro que desea ${item.is_active ? "bloquear" : "desbloquear" } a ${item.username}?`,
          title:  `Confirmar ${item.is_active ? "bloqueo" : "desbloqueo" }`
        };
    },

    async HandlerBlockUser() {
      try {
        this.loadingActions[this.infoBlockUser?.item?.id] = true;
        this.$forceUpdate();
        const request = {
          id: this.infoBlockUser?.item?.id,
        };
        await this.$store.dispatch(
          "usersIndex/BLOCK_USER",
          request
        );
        await this.HandlerGetUsers(this.page);
        this.$snotify.success(`Usuario ${this.infoBlockUser?.item?.is_active ? "bloqueado" : "desbloqueado"} exitosamente.`, "¡Hecho!");
      } catch (error) {
        console.log(error);
        this.$snotify.error(`Error al ${this.infoBlockUser?.item?.is_active ? "bloquear" : "desbloquear"} usuario.`, "¡Error!")
      } finally{
        this.loadingActions[this.infoBlockUser?.item?.id] = false;
        this.$forceUpdate();
      }
      this.HandlerCloseModal();
    },

    async HandlerDeleteUser() {
      try {
        this.loadingActions[this.infoDeleteUser?.item?.id] = true;
        this.$forceUpdate();
        const request = {
          id: this.infoDeleteUser?.item?.id,
        };
        await this.$store.dispatch(
          "usersIndex/DELETE_USER",
          request
        );
        await this.HandlerGetUsers(this.page);
        this.$snotify.success(`Usuario eliminado exitosamente.`, "¡Hecho!");
      } catch (error) {
        console.log(error);
        this.$snotify.error(`Error al eliminar usuario.`, "¡Error!")
      } finally{
        this.loadingActions[this.infoDeleteUser?.item?.id] = false;
        this.$forceUpdate();
      }
      this.HandlerCloseModal();
    },


    HandlerAddData(item) {
      this.status_card = !this.status_card;
      this.showRegister = true;
      this.dataAdd = { ...item };
      this.actionAdd = 0;
    },

    HandlerEditItem(item) {
      this.status_card = !this.status_card;
      this.showRegister = true;
      this.dataAdd = { ...item };
      this.actionAdd = 1;
    },

    HandlerResponse(data, action) {
      this.status_card = !this.status_card;
      this.showRegister = false;
      if (action == 1) {
        this.items.push(data.data);
      } else if (action == 2) {
        const indexUpdate = this.items
          .map((element) => element.id)
          .indexOf(data.data.id);
        if (indexUpdate > -1) {
          this.items.splice(indexUpdate, 1, data.data);
        }
      }
    },

    HandlerCloseModal() {
      if(this.showDeleteConfirmModal){
        this.loadingActions[this.infoDeleteUser?.item?.id] = false;
        this.$forceUpdate();
        this.showDeleteConfirmModal = false;
        this.infoDeleteUser = { item: null, message: "" };
      } else {
        this.loadingActions[this.infoBlockUser?.item?.id] = false;
        this.$forceUpdate();
        this.showBlockConfirmModal = false;
        this.infoBlockUser = { item: null, message: "" };
      }
    },
  },
};
</script>

<style>
  .row-actions{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
</style>