<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row justify="end">
        <v-btn small fab color="primary" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-row>
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <div style="word-break: normal" class="mb-5">
            <span
              :class="[
                isMobile && isDark
                  ? 'white--text text-light mb-5 title-text text-capitalize'
                  : 'mb-5 title-text text-light text-capitalize',
              ]"
            >
              {{ getDepositName }}
            </span>
            <small>Deposito donde salen los envios</small>
          </div>
          <v-btn
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({})"
            >Añadir duración</v-btn
          >
          <register-component
            v-if="activeAddData"
            :active="activeAddData"
            :data="dataAdd"
            :action="actionAdd"
            :country="dataCountry"
            @dialog:change="HandlerResponse"
          />
        </v-col>

        <v-col cols="auto" class="ml-auto">
          <v-text-field
            filled
            rounded
            dense
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <v-card class="shadow-1 mt-5">
      <v-data-table
        :loading="false"
        :headers="headers"
        :items="getDeposits"
        :search="search"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="HandlerEditItem(item)">
            mdi-pencil
          </v-icon>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:[`item.active`]="{ item }">
          <v-chip
            v-if="loadingStatus == false"
            color="transparent"
            small
            @click="HandlerChangeStatus(item)"
          >
            <v-icon
              left
              small
              :color="item.active === true ? 'success' : 'error'"
              >mdi-radiobox-marked</v-icon
            >
            {{ item.active === true ? "Habilitado" : "Inactivo" }}
          </v-chip>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <span>{{ dateFormat(item.created_at) }}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-row justify="end" class="mt-4">
      <v-col cols="12" md="4">
        <v-pagination
          v-model="page"
          :length="getPaginate.lastPage"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import Register from "./Register";

export default {
  components: {
    "register-component": Register,
  },
  data() {
    return {
      loadingData: false,
      activeAddData: false,
      activeAddDataDate: false,
      actionAdd: 0,
      actionAddDate: 0,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      loadingStatus: false,
      headers: [
        {
          text: "Deposito",
          align: "left",
          sortable: false,
          value: "warehouse_end.name",
          class: "primary--text",
        },
        // {
        //   text: "Observacion",
        //   value: "observations",
        //   class: "primary--text",
        // },
        {
          text: "Dirección",
          value: "warehouse_end.address",
          class: "primary--text",
        },
        {
          text: "Duración (hrs)",
          value: "duration",
          class: "primary--text",
        },
        {
          text: "Telefono",
          value: "warehouse_end.phone_1",
          class: "primary--text",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "primary--text",
        },
      ],
      data: [],
      dataCountry: [],
    };
  },

  created() {
    // console.log('this.$router.params',this.$route.params.id)

    this.HandlerGetWarehouseTime();
    // this.HandlerGetDeposits();
    // this.HandlerGetCountry();
  },

  watch: {
    page(val) {
      this.HandlerGetWarehouseTime(val);
    },
  },

  computed: {
    getDeposits() {
      return this.$store.getters["deposits/GET_DEPOSIT_TIME"];
    },
    getPaginate() {
      return this.$store.getters["deposits/GET_PAGINATE_DEPOSIT"];
    },
    getDepositName() {
      return this.$store.getters["deposits/GET_DEPOSIT_NAME"];
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    async HandlerGetWarehouseTime(page) {
      try {
        this.loadingData = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          warehouse_start_id: this.$route.params.id,
        };
        await this.$store.dispatch("deposits/GET_DEPOSITS_TIME", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
      }
    },

    HandlerEditItem(item) {
      this.activeAddData = true;
      this.actionAdd = 1;
      this.dataAdd = { ...item };
    },

    HandlerAddData() {
      this.activeAddData = true;
      this.actionAdd = 0;
      this.dataAdd = {};
    },

    HandlerResponse(value) {
      this.activeAddData = value;
    },
  },
};
</script>

<style>
</style>