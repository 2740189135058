<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <register-component
            v-if="activeAddData"
            :active="activeAddData"
            :data="dataAdd"
            :action="actionAdd"
            :data_stores="data_stores"
            :data_store_id="store_id"
            @dialog:change="HandlerResponse"
          />
        </v-col>
      </v-row>
      <v-row class="mt-5" v-if="status_card">
        <v-col cols="4">
          <v-select
            label="Tienda"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="store_id"
            :items="data_stores"
            item-text="name"
            item-value="id"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            label="Estado"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="registrationStatus"
            :items="statusOptions"
            item-text="label"
            item-value="value"
          ></v-select>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            :loading="loadingBtn"
            fab
            small
            color="primary"
            class="mr-3"
            @click="HandlerGetClients()"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn fab small @click="HandlerReset()">
            <v-icon color="primary">mdi-backup-restore</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :loading="loadingClients"
          :headers="headers"
          :items="getClients"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.name`]="{ item }">
            {{ item.buyer_store[0].company_name || item.first_name }}
          </template>
          <template v-slot:[`item.b2b_registration_status`]="{ item }">
            <span
              :style="{
                color:
                  statusMap[item.buyer_store[0].b2b_registration_status] &&
                  statusMap[item.buyer_store[0].b2b_registration_status].color,
              }"
            >
              {{
                statusMap[item.buyer_store[0].b2b_registration_status] &&
                  statusMap[item.buyer_store[0].b2b_registration_status].text
              }}
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div v-if="!loadingStatus[item.id]">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="HandlerEditItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      item.buyer_store[0].b2b_registration_status !== 'APPROVED'
                    "
                    class="mr-2"
                    @click="HandlerChangeStatus(item, 'APPROVED')"
                  >
                    mdi-thumb-up-outline
                  </v-icon>
                </template>
                <span>Aprobar solicitud</span>
              </v-tooltip>
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    v-if="
                      item.buyer_store[0].b2b_registration_status !== 'REJECTED'
                    "
                    @click="HandlerChangeStatus(item, 'REJECTED')"
                  >
                    mdi-thumb-down-outline
                  </v-icon>
                </template>
                <span>Rechazar solicitud</span>
              </v-tooltip>
            </div>
            <v-progress-circular
              size="20"
              class="d-flex justify-center"
              indeterminate
              color="primary"
              v-else
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="paginate.page"
            :length="paginate.lastPage"
            circle
            @input="HandlerGetClients(paginate.page)"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Register from "./Register";
export default {
  components: {
    "register-component": Register,
  },
  data() {
    return {
      loadingData: false,
      activeAddData: false,
      activeAddDataDate: false,
      actionAdd: 0,
      actionAddDate: 0,
      status_card: true,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      country_id: "",
      state_id: "",
      location_id: "",
      loadingBtn: false,
      loadingState: false,
      loadingLocation: false,
      stateData: [],
      locationData: [],
      statusMap: {
        APPROVED: {
          text: "Aprobado",
          color: "#58b153",
        },
        REJECTED: {
          text: "Rechazado",
          color: "rgb(255, 68, 68)",
        },
        PENDING_REVIEW: {
          text: "Revision pendiente",
          color: "blue",
        },
      },
      headers: [
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Direccion",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "b2b_registration_status",
          class: "primary--text",
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      data: [],
      dataCountry: [],
      getClients: [],
      paginate: {},
      data_stores: [],
      store_id: 5,
      registrationStatus: "",
      statusOptions: [
        { label: "Aprobado", value: "APPROVED" },
        { label: "Rechazado", value: "REJECTED" },
        { label: "Revisión pendiente", value: "PENDING_REVIEW" },
        { label: "Todos", value: "" },
      ],
      loadingClients: false,
      loadingStatus: {},
    };
  },

  created() {
    this.HandlerGetClients();
    this.HandlerGetCountry();
    this.HandlerGetStore();
  },

  methods: {
    async HandlerGetClients(page = 1) {
      try {
        this.loadingClients = true;
        const request = {
          buyer_store: 1,
          store_id: this.store_id,
          page: page,
          b2b_status: true,
          perPage: 10,
          per_page: 10,
          registration_status: this.registrationStatus,
        };
        const response = await this.$store.dispatch(
          "usersIndex/INDEX_CLIENTS_B2B",
          request
        );
        this.getClients = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingClients = false;
      }
    },

    async HandlerReset() {
      try {
        this.country_id = 5;
        this.state_id = 0;
        this.location_id = 0;
        this.HandlerGetClients(1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetStore() {
      try {
        const request = {
          paginate: false,
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data.filter((index) => {
          return index.b2b_active == true;
        });
        console.log(this.data_stores);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerEditItem(item) {
      this.activeAddData = true;
      this.actionAdd = 1;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    async HandlerChangeStatus(item, status) {
      try {
        this.loadingStatus[item.id] = true;
        this.$forceUpdate();
        console.log(item);
        const request = {
          buyer_store_id: item.buyer_store[0].id,
          data: {
            new_status: status,
          },
        };
        await this.$store.dispatch(
          "usersIndex/UPDATE_STATUS_CLIENT_B2B",
          request
        );
        await this.HandlerGetClients();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingStatus[item.id] = false;
        this.$forceUpdate();
      }
    },

    HandlerAddData(item) {
      this.activeAddData = true;
      this.actionAdd = 0;
      this.status_card = false;
      this.dataAdd = { ...item, billing_zip_code: "0001" };
    },

    HandlerResponse(value, action) {
      this.activeAddData = value;
      this.status_card = true;
      if (action == true) {
        this.HandlerGetClients();
      }
    },

    HandlerDate(item) {
      this.activeAddDataDate = true;
      this.actionAddDate = 1;
      this.dataAddDate = { ...item };
    },

    HandlerAddDataDate(item) {
      this.activeAddDataDate = true;
      this.actionAddDate = 0;
      this.dataAddDate = { ...item };
    },

    HandlerResponseDate(value) {
      this.activeAddDataDate = value;
    },

    HandlerDepositTime(item) {
      this.$router.push({
        name: "Duración entre depositos",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style></style>
