var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[(_vm.action == 0)?_c('div',{staticClass:"font-weight-medium primary--text mb-5 ml-3",staticStyle:{"font-size":"26px"}},[_vm._v(" Crear Usuarios ")]):_c('div',{staticClass:"font-weight-medium primary--text mb-5 ml-3",staticStyle:{"font-size":"26px"}},[_vm._v(" Editar Usuarios ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"mb-5",attrs:{"height":"auto"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}})],1),_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":"","justify":_vm.action == 0 ? 'center' : 'start'}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre de usuario","name":"nombre","prepend-inner-icon":"mdi-account","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.username),callback:function ($$v) {_vm.$set(_vm.data, "username", $$v)},expression:"data.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Correo","name":"email","prepend-inner-icon":"mdi-email","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"IP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"IP","name":"ip","prepend-inner-icon":"mdi-map-marker","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.ip),callback:function ($$v) {_vm.$set(_vm.data, "ip", $$v)},expression:"data.ip"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"rol","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.roles,"item-text":"name","item-value":"id","required":"","filled":"","rounded":"","dense":"","label":"Roles","name":"rol","prepend-inner-icon":"mdi-account-multiple","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.rolesID),callback:function ($$v) {_vm.rolesID=$$v},expression:"rolesID"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"password","rules":_vm.action === 0
                        ? 'required|confirmed:confirmation'
                        : 'confirmed:confirmation'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Contraseña","name":"contraseña","prepend-inner-icon":"mdi-key","type":"password","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.password),callback:function ($$v) {_vm.$set(_vm.data, "password", $$v)},expression:"data.password"}})]}}],null,true)})],1),(_vm.action === 0 || _vm.data.password)?_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Confirmar Contraseña","name":"confirmar contraseña","prepend-inner-icon":"mdi-key","type":"password","color":"blue-grey","error-messages":errors},model:{value:(_vm.data.confirm_password),callback:function ($$v) {_vm.$set(_vm.data, "confirm_password", $$v)},expression:"data.confirm_password"}})]}}],null,true)})],1):_vm._e()],1)],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey',"disabled":_vm.loading},on:{"click":function($event){return _vm.closeModal(null, false)}}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loading,"disabled":_vm.loading,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerAction)}}},[_vm._v(_vm._s(_vm.action == 1 ? "Editar" : "Añadir"))])]}}],null,true)})],1)],1)]}}])})],1)],1),(_vm.showConfirmModal)?_c('custom-modal',{attrs:{"active":_vm.showConfirmModal,"action":_vm.HandlerUpdate,"closeModal":_vm.HandlerCloseModal,"confirmBtn":"","title":'Confirmar cambio de contraseña',"max-width":"600","withImageTitle":false,"closeText":"cancelar","loader":_vm.loading}},[_c('div',{staticClass:"delete-message"},[_vm._v(" ¿Seguro que desea editar la contraseña del usuario? ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }