<template>
  <v-container>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <v-card :class="HandlerResponsive">
          <v-toolbar flat color="#2a9600" dark class="px-md-10 mb-1">
            <v-toolbar-title>Estatus del retiro</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab" align-with-title class="mt-3">
            <v-tabs-slider color="#2a9600"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              <v-badge
                v-if="currentTab == item"
                bordered
                bottom
                color="#2a9600"
                :content="paginate.total"
                transition="slide-x-transition"
              >
                {{ item }}
              </v-badge>
              <span v-else>
                {{ item }}
              </span>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col
        col="12"
        md="12"
        v-for="(item, index) in data"
        :key="index"
        v-show="!loadingTable"
      >
        <v-card>
          <v-row class="mb-3">
            <v-col cols="12" md="6">
              <div class="px-5 py-5">
                <div class="d-flex" v-if="item.buyer != null">
                  <h3 class="my-0 text-capitalize">
                    {{ item.buyer.first_name }}
                    {{ item.buyer.last_name }}
                  </h3>
                </div>
                <v-divider inset></v-divider>
                <div class="mt-3">
                  <span class="font-weight-bold mr-2">Teléfono:</span>
                  <span>{{ item.buyer.phone }}</span>
                </div>
                <div>
                  <span class="font-weight-bold mr-2">E-mail:</span>
                  <span>{{ item.buyer.email }}</span>
                </div>
                <h3 class="my-0 text-capitalize mt-2">Datos asociados</h3>
                <v-divider inset></v-divider>
                <div
                  v-if="
                    item.buyer != null &&
                    item.buyer.buyer_store != null &&
                    item.buyer.buyer_store.address != null
                  "
                >
                  <div class="mt-3">
                    <span class="font-weight-bold mr-2">Provincia:</span>
                    <span>
                      {{ item.buyer.buyer_store.address.location }}
                    </span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2">Localidad:</span>
                    <span>{{ item.buyer.buyer_store.address.location }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2"> Código Postal: </span>
                    <span>{{ item.buyer.buyer_store.address.zipcode }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2">Calle:</span>
                    <span>{{ item.buyer.buyer_store.address.street }}</span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2">
                      Número de Calle:
                    </span>
                    <span>
                      {{ item.buyer.buyer_store.address.street_number }}
                    </span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2">Piso:</span>
                    <span>
                      {{ item.buyer.buyer_store.address.floor_number }}
                    </span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2">Departamento:</span>
                    <span>
                      {{ item.buyer.buyer_store.address.department_number }}
                    </span>
                  </div>
                  <div>
                    <span class="font-weight-bold mr-2"> Observaciones: </span>
                    <span>
                      {{ item.buyer.buyer_store.address.observations }}
                    </span>
                  </div>
                </div>
                <div v-else>
                  <h5 style="color: #2a9600">Sin información</h5>
                </div>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <h3 class="mt-5 mb-0">Venta: #{{ item.meli_id }}</h3>
              <v-divider></v-divider>
              <div class="py-0 px-5">
                <v-data-table
                  :headers="headers"
                  :items="item.order_item"
                  :items-per-page="5"
                  hide-default-footer
                  class="elevation-0"
                >
                  <!-- headers -->
                  <template v-slot:[`header.name`]="{ header }">
                    <span style="font-size: 1.2em; color: #2a9600">
                      {{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.quantity`]="{ header }">
                    <span style="font-size: 1.2em; color: #2a9600">
                      {{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <template v-slot:[`header.barcode`]="{ header }">
                    <span style="font-size: 1.2em; color: #2a9600">
                      {{ header.text.toUpperCase() }}
                    </span>
                  </template>
                  <!-- ITEMS -->
                  <template v-slot:[`item.name`]="{ item }">
                    <span style="font-size: 1.6em">
                      {{ item.product.name }}
                    </span>
                  </template>
                  <template v-slot:[`item.quantity`]="{ item }">
                    <span style="font-size: 1.6em">
                      {{ item.quantity }}
                    </span>
                  </template>
                  <template v-slot:[`item.barcode`]="{ item }">
                    <span style="font-size: 1.6em">
                      {{ item.product.barcode }}
                    </span>
                  </template>
                </v-data-table>
              </div>
              <div v-if="tab == 0" style="width: 30%" class="mx-auto">
                <h4 class="text-center text-uppercase" style="color: #2a9600">
                  Este paquete sera preparado antes de:
                </h4>
                <h5 class="text-center">
                  <!-- {{ item.date_created | currentDay }} -->
                  {{ getDate(item.date_created) }}
                </h5>
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  class="mr-5 white--text"
                  color="#2a9600"
                  @click="HandlerPrintInvoice(item)"
                >
                  Retirados
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col col="12" md="12" v-show="loadingTable">
        <v-progress-linear
          indeterminate
          color="yellow darken-2"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      tab: 0,
      currentTab: "",
      page: 1,
      loadingTable: false,
      headers: [
        {
          text: "Nombre Producto",
          align: "start",
          sortable: false,
          value: "name",
          width: "50%",
        },
        { text: "Cantidad", value: "quantity", sortable: false },
        // { text: "Precio", value: "price", sortable: false },
        { text: "EAN", value: "barcode", sortable: false },
      ],
      data: [],
      items: ["Por Retirar", "Retirados"],
      downloaded: false,
      loadingPrint: false,
      // PAGINADO
      pagination: {},
    };
  },

  created() {
    this.HandlerGetSales();
  },

  filters: {
    currentDay(val) {
      return moment(val).format("DD-MM-YYYY HH:ss");
    },
  },

  watch: {
    tab(val) {
      if (val == 1) {
        this.downloaded = true;
        this.HandlerGetSales(this.downloaded);
      } else {
        this.downloaded = false;
        this.HandlerGetSales(this.downloaded);
      }
    },

    page(val) {
      this.HandlerGetSales(this.downloaded, val);
    },
  },

  computed: {
    HandlerResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-start";
        case "sm":
          return "text-start";
        case "md":
          return "d-flex justify-center";
        case "lg":
          return "d-flex justify-center";
        case "xl":
          return "d-flex justify-center";
        default:
          return "d-flex justify-center";
      }
    },
  },

  methods: {
    async HandlerGetSales(dowload = false, page = 1) {
      try {
        this.loadingTable = true;
        const request = {
          downloaded: dowload,
          page: page,
          per_page: 10,
          paginate: true,
          warehouse_id: 10,
          country_id: 1,
        };
        const response = await this.$store.dispatch(
          "salesRetirement/GET_SALES_RETIREMENT",
          request
        );
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.data = response.data.data.data;
        this.page = this.pagination.page;
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    async HandlerPrintInvoice(item) {
      try {
        console.log(item);
        this.loadingPrint = true;
        const request = {
          order_id: item.id,
          url:
            item.invoice_path_label == null
              ? item.order_invoice[0].invoice_path_label
              : item.invoice_path_label,
        };

        await this.$store.dispatch(
          "salesRetirement/PRINT_SALES_RETIREMENT",
          request
        );

        this.HandlerGetSales(this.downloaded, this.page);
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ocurrio un error con la consulta intente mas tarde",
          "Error!"
        );
      } finally {
        this.loadingPrint = false;
      }
    },


    getDate(date) {
      const dateParse = moment(date).format("YYYY-MM-DD HH:mm:ss");
      const numberDay = moment(dateParse).day();
      const dateHour = moment(dateParse).format("hh:mm:ss");

      // FORMATO 24HRS
      // const hour = moment(dateParse).format("HH:mm");
      // const dateHour = moment(hour, ["h:mm A"]).format("HH:mm:ss");

      if (numberDay <= 5) {
        const format = "hh:mm:ss";
        const time = moment(dateHour, format);
        const firstTime = moment("00:00:00", format);
        const secondTime = moment("08:59:00", format);

        const firstTime2 = moment("09:00:00", format);
        const secondTime2 = moment("16:30:00", format);

        if (time.isBetween(firstTime, secondTime)) {
          return moment(dateParse).format("YYYY-MM-DD") + " 9:30";
        } else if (time.isBetween(firstTime2, secondTime2)) {
          const add30Min = moment(time).add(30, "m").format("hh:mm");
          return moment(dateParse).format("YYYY-MM-DD") + " " + add30Min;
        } else {
          const currentDay = moment(dateParse).format("YYYY-MM-DD");
          const addNextDay = moment(currentDay)
            .add(1, "d")
            .format("YYYY-MM-DD");
          return addNextDay + " " + "09:30";
        }
      } else {
        const currentDay = moment(dateParse).format("YYYY-MM-DD");
        const addNextDay = moment(currentDay).add(1, "d").format("YYYY-MM-DD");
        return addNextDay + " " + "09:30";
      }
    },
  },
};
</script>

<style>
</style>