<template>
    <v-dialog v-model="active" persistent max-width="600">
        <v-card>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            rounded
            height="6"
          ></v-progress-linear>
          <v-card-title class="pa-0 shadow-1">
            <v-img
              :src="require('@/assets/illustrations/bg_modal_card.svg')"
              cover
              position="left bottom"
              class="align-center"
              height="65px"
            >
              <v-card-title class="font-weight-medium primary--text">
                Eliminar costo
              </v-card-title>
            </v-img>
          </v-card-title>
        
          <v-card-text>
            <div>¿Esta segur@ que desea eliminar el costo "{{description}}""</div>
          </v-card-text>
        
          <v-divider></v-divider>
        
          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                text
                rounded
                class="mr-1"
                :color="hover ? 'error' : 'grey'"
                @click="()=>closeModal(false)"
                >Cancelar</v-btn
              >
            </v-hover>
        
            <v-hover v-slot:default="{ hover }">
              <v-btn
                rounded
                :loading="loading"
                :disabled="loading"
                color="success"
                :class="hover ? 'shadow-2' : 'shadow-4'"
                class="px-8"
                @click="HandlerAction"
                >Aceptar</v-btn
              >
            </v-hover>
          </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Function,
      required: false,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: false,
      default: () => {},
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      description: "",
      id:"",
      loading: false,
    };
  },

  created() {
    if (this.data?.id) {
      this.description = this.data.description;
      this.id = this.data.id;
    }
  },

  methods: {
    HandlerAction() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.action({description:this.description, id:this.id})
            this.$snotify.success("Costo eliminado con exito","Exitos!");
        }, 2000);
    },
  },
};
</script>