<template>
  <v-container ref="container">
    <v-row>
      <v-col>
        <v-select
          label="Pais"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="countryId"
          :items="countrys"
          item-text="name"
          item-value="id"
          @change="getInventoryRotation"
          :disabled="loading"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          label="Marca"
          filled
          rounded
          dense
          class="rounded-small"
          @blur="onSelectBrand"
          v-model="brands"
          :disabled="loading"
          multiple
          :items="data.brands.map(b => ({ name: b.name, id: b.name }))"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>
    <v-row :style="{ gap: '15px', marginBottom: '20px', padding: '0 12px' }">
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["30days"] | currencyValue }}
          </b>
          <br /><span>30 D</span>
        </v-card-subtitle>
        <!-- <v-card-text>30 D</v-card-text> -->
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["60days"] | currencyValue }}
          </b>
          <br />
          <span>60 D</span>
        </v-card-subtitle>
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["90days"] | currencyValue }}
          </b>
          <br />
          <span>90 D</span>
        </v-card-subtitle>
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["120days"] | currencyValue }}
          </b>
          <br />
          <span>120 D</span>
        </v-card-subtitle>
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["150days"] | currencyValue }}
          </b>
          <br />
          <span>150 D</span>
        </v-card-subtitle>
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["180days"] | currencyValue }}
          </b>
          <br />
          <span>180 D</span>
        </v-card-subtitle>
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["rest"] | currencyValue }}
          </b>
          <br />
          <span>+180 D</span>
        </v-card-subtitle>
      </v-card>
      <v-card max-height="70">
        <v-card-subtitle>
          <b>
            {{ data.totals["noProyectableTotalVal"] | currencyValue }}
          </b>
          <br />
          <span>Stock no proyectable</span>
        </v-card-subtitle>
      </v-card>
    </v-row>

    <v-row
      :style="{
        gap: '20px',
        marginBottom: '0px',
        padding: '0 12px',
        alignItems: 'center'
      }"
    >
      <v-switch
        :label="`Proyectar stock on transit`"
        v-model="filters.proyectStockOnTransit"
        :disabled="loading"
        @change="getInventoryRotation"
      ></v-switch>
      <v-switch
        :label="`Proyectar stock on hand`"
        v-if="countryId === 3"
        :disabled="loading"
        v-model="filters.proyectStockOnHand"
        @change="getInventoryRotation"
      ></v-switch>

      <v-btn
        :loading="exportingProyection"
        fab
        x-small
        class="ml-3"
        @click="exportProyections()"
      >
        <v-icon color="primary">mdi-download</v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="data.products"
      :loading="loading"
      fixed-header
      height="530"
      class="elevation-1 small-table-body fixed-colums-table"
    >
      <template v-slot:item="i">
        <tr>
          <td
            v-for="{ value, render, slotClass } in headers"
            :key="value"
            :class="slotClass ? slotClass(i.item) : ''"
          >
            {{ render ? render(i.item[value]) : i.item[value] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      totalItems: 0,
      dialog: false,
      exportingProyection: false,
      loading: false,
      page: 1,
      openFrom: false,
      openTo: false,
      data: { brands: [], products: [], totals: {} },
      brands: [],
      itemsPerPage: 10,
      countryId: null,
      filters: { proyectStockOnTransit: true, proyectStockOnHand: true },

      countrys: [],
      headerMex: {
        text: "Stock ML FULL",
        class: "small-table-header ",
        align: "right",
        sortable: false,
        value: "mlCurrentStock"
      },

      headers: [
        {
          text: "Modelo",
          class: "small-table-header  fixed-col ",
          align: "right",
          slotClass: () => "maxWidthCol",
          sortable: false,
          value: "productName"
        },
        {
          text: "SKU",
          class: "small-table-header  ",
          align: "right",
          sortable: false,
          value: "sku"
        },
        {
          text: "Marca",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "brand"
        },
        {
          text: "Cantidad 120 D",
          class: "small-table-header ",
          slotClass: () => "gray-background",
          align: "right",
          sortable: false,
          value: "oneHundredDaysAgoDataQuantity"
        },
        {
          text: "Pvp neto 120 D",
          class: "small-table-header ",
          slotClass: () => "gray-background",
          align: "right",
          sortable: false,
          value: "oneHundredDaysAgoDataPVP",
          render: value => this.currencyValue(value)
        },
        {
          text: "Cantidad 90 D",
          class: "small-table-header ",
          slotClass: () => "yellow-background",
          align: "right",
          sortable: false,
          value: "ninetyDaysAgoDataQuantity"
        },
        {
          text: "Pvp neto 90 D",
          class: "small-table-header ",
          slotClass: () => "yellow-background",
          align: "right",
          sortable: false,
          value: "ninetyDaysAgoDataPVP",
          render: value => this.currencyValue(value)
        },
        {
          text: "Cantidad 60 D",
          class: "small-table-header ",
          slotClass: () => "orange-background",
          align: "right",
          sortable: false,
          value: "sixtyDaysAgoDataQuantity"
        },
        {
          text: "Pvp neto 60 D",
          class: "small-table-header ",
          slotClass: () => "orange-background",
          align: "right",
          sortable: false,
          value: "sixtyDaysAgoDataPVP",
          render: value => this.currencyValue(value)
        },
        {
          text: "Cantidad 30 D",
          class: "small-table-header ",
          slotClass: () => "green-background",
          align: "right",
          sortable: false,
          value: "thirtyDaysAgoDataQuantity"
        },
        {
          text: "Pvp neto 30 D",
          class: "small-table-header ",
          slotClass: () => "green-background",
          align: "right",
          sortable: false,
          value: "thirtyDaysAgoDataPVP",
          render: value => this.currencyValue(value)
        },
        {
          text: "Cantidad prom",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "averageQuantity"
        },
        {
          text: "Pvp neto prom",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "averagePVP",
          render: value => this.currencyValue(value)
        },
        {
          text: "Tendencia",
          class: "small-table-header ",
          slotClass: item => {
            return `${item.trend < 1 ? "red-background" : "green-background"}`;
          },

          align: "right",
          sortable: false,
          value: "trend"
        },
        {
          text: "Stock On Hand",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "currentStock"
        },
        {
          text: "Stock on transfer",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "pendingStock"
        },
        {
          text: "Stock on transit",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "currentStockInTransitDeposit"
        },
        {
          text: "Meses de venta",
          class: "small-table-header ",
          align: "right",
          sortable: true,
          value: "salesMonthsEstimation"
        },
        {
          text: "CP 30",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `green-background ${
              inTransitMonthsProyections.includes("30days") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "30daysQuantity"
        },
        {
          text: "Valor 30",
          class: "small-table-header ",
          slotClass: () => "green-background",
          align: "right",
          sortable: false,
          value: "30daysValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "CP 60",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `orange-background ${
              inTransitMonthsProyections.includes("60days") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "60daysQuantity"
        },
        {
          text: "Valor 60",
          class: "small-table-header ",
          slotClass: () => "orange-background",
          align: "right",
          sortable: false,
          value: "60daysValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "CP 90",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `yellow-background ${
              inTransitMonthsProyections.includes("90days") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "90daysQuantity"
        },
        {
          text: "Valor 90",
          class: "small-table-header ",
          slotClass: () => "yellow-background",
          align: "right",
          sortable: false,
          value: "90daysValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "CP 120",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `gray-background ${
              inTransitMonthsProyections.includes("120days") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "120daysQuantity"
        },
        {
          text: "Valor 120",
          class: "small-table-header ",
          slotClass: () => "gray-background",
          align: "right",
          sortable: false,
          value: "120daysValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "CP 150",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `celest-background ${
              inTransitMonthsProyections.includes("150days") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "150daysQuantity"
        },
        {
          text: "Valor 150",
          class: "small-table-header ",
          slotClass: () => "celest-background",
          align: "right",
          sortable: false,
          value: "150daysValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "CP 180",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `pink-background ${
              inTransitMonthsProyections.includes("180days") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "180daysQuantity"
        },
        {
          text: "Valor 180",
          class: "small-table-header ",
          slotClass: () => "pink-background",
          align: "right",
          sortable: false,
          value: "180daysValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "CP Resto",
          class: "small-table-header ",
          slotClass: ({ inTransitMonthsProyections }) => {
            return `red-background ${
              inTransitMonthsProyections.includes("rest") ? "highlight" : ""
            }`;
          },
          align: "right",
          sortable: false,
          value: "restQuantity"
        },
        {
          text: "Valor Resto",
          class: "small-table-header ",
          slotClass: () => "red-background ",
          align: "right",
          sortable: false,
          value: "restValue",
          render: value => this.currencyValue(value)
        },
        {
          text: "Ultima actualizacion",
          class: "small-table-header ",
          align: "right",
          sortable: false,
          value: "recentStockUpdate"
        }
      ]
    };
  },
  async created() {
    const permsDict = {
      "Rotación México": {
        name: "Mexico",
        id: 3
      },
      "Rotación Argentina": {
        name: "Argentina",
        id: 1
      },
      "Rotación Chile": {
        name: "Chile",
        id: 2
      }
    };

    const user = this.$store.getters["auth/GET_USER"];
    const permissions = user.permissions.filter(p =>
      ["Rotación México", "Rotación Chile", "Rotación Argentina"].includes(
        p.name
      )
    );
    this.countrys = permissions.map(p => permsDict[p.name]);

    this.countryId = this.countrys[0]?.id;

    this.getInventoryRotation();
  },

  methods: {
    currencyValue(value) {
      if (value === undefined) {
        return;
      }
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(Number(value).toFixed(2));
      return AMOUNT_FORMAT;
    },
    async getInventoryRotation() {
      this.loading = true;
      try {
        const payload = {
          country: this.countryId,
          brands: this.brands,
          ...this.filters
        };
        const data = await this.$store.dispatch(
          "stocks/GET_STOCK_PROYECTION",
          payload
        );
        this.data = data.data.data;
        this.data.products = this.data.products.map(
          ({
            oneHundredDaysAgoData,
            ninetyDaysAgoData,
            sixtyDaysAgoData,
            thirtyDaysAgoData,
            average,
            salesEstimation,
            ...rest
          }) => ({
            oneHundredDaysAgoDataQuantity:
              oneHundredDaysAgoData.productQuantity,
            oneHundredDaysAgoDataPVP: oneHundredDaysAgoData.productNetPVP,
            ninetyDaysAgoDataQuantity: ninetyDaysAgoData.productQuantity,
            ninetyDaysAgoDataPVP: ninetyDaysAgoData.productNetPVP,
            sixtyDaysAgoDataQuantity: sixtyDaysAgoData.productQuantity,
            sixtyDaysAgoDataPVP: sixtyDaysAgoData.productNetPVP,
            thirtyDaysAgoDataQuantity: thirtyDaysAgoData.productQuantity,
            thirtyDaysAgoDataPVP: thirtyDaysAgoData.productNetPVP,
            averageQuantity: average.productQuantityAverage,
            averagePVP: average.productNetPVPAverage,
            "30daysQuantity": salesEstimation["30days"].quantity,
            "30daysValue": salesEstimation["30days"].value,
            "60daysQuantity": salesEstimation["60days"].quantity,
            "60daysValue": salesEstimation["60days"].value,
            "90daysQuantity": salesEstimation["90days"].quantity,
            "90daysValue": salesEstimation["90days"].value,
            "120daysQuantity": salesEstimation["120days"].quantity,
            "120daysValue": salesEstimation["120days"].value,
            "150daysQuantity": salesEstimation["150days"].quantity,
            "150daysValue": salesEstimation["150days"].value,
            "180daysQuantity": salesEstimation["180days"].quantity,
            "180daysValue": salesEstimation["180days"].value,
            restQuantity: salesEstimation["rest"].quantity,
            restValue: salesEstimation["rest"].value,
            inTransitMonthsProyections:
              salesEstimation.inTransitMonthsProyections,
            ...rest
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async exportProyections() {
      const payload = {
        country: this.countryId,
        brands: this.brands,
        ...this.filters
      };
      this.exportingProyection = true;
      await this.$store.dispatch("stocks/EXPORT_PROYECTIONS", payload);
      this.exportingProyection = false;
    },
    async onSelectBrand() {
      this.getInventoryRotation();
    },

    getHighlight(item, key) {
      return {
        color:
          item.salesEstimation["inTransitMonthsProyections"].includes(key) > 0
            ? "green"
            : "black",
        fontWeight: item.salesEstimation["inTransitMonthsProyections"].includes(
          key
        )
          ? 700
          : 400
      };
    }
  },

  watch: {
    page: {
      handler() {
        this.getNotifications(true);
      }
    },
    countryId: {
      handler() {
        if (this.countryId !== 3) {
          if (this.headers[14].text === "Stock ML FULL") {
            this.headers.splice(14, 1);
          }
        } else {
          if (this.headers[14].text !== "Stock ML FULL") {
            this.headers.splice(14, 0, this.headerMex);
          }
        }
      }
    },
    itemsPerPage: {
      handler() {
        this.getNotifications(false);
      }
    }
  },

  filters: {
    currencyValue(value) {
      if (value === undefined) {
        return;
      }
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(Number(value).toFixed(2));
      return AMOUNT_FORMAT;
    },
    catalogStatus(value) {
      const statusMap = {
        winning: "Ganando",
        not_listed: "Sin listar",
        listed: "Perdiendo",
        competing: "Perdiendo",
        sharing_first_place: "Compitiendo"
      };

      return statusMap[value] || value;
    }
  },

  computed: {
    notifications() {
      const data = this.$store.getters["publications/GET_NOTIFICATIONS"];

      return {
        data: data.data,
        total: Number(data.total)
      };
    }
  }
};
</script>
