<template>
    <section>
      <v-container fluid class="mt-5">
        <v-row class="justify-between align-center">
          <v-col cols="auto">
            <v-btn
              class="accent shadow-4 px-8"
              large
              rounded
              @click.stop="HandlerOpenModal(true)"
            >
              Agregar costo
            </v-btn>
            <cost-modal
                v-if="activeModal"
                :active="activeModal"
                :data="dataCost"
                :action="ActionCost"
                :closeModal="HandlerOpenModal"
            />
            <delete-modal
                v-if="activeDeleteModal"
                :active="activeDeleteModal"
                :data="dataCost"
                :action="DeleteCost"
                :closeModal="HandlerOpenDeleteModal"
            />
          </v-col>
        </v-row>
      </v-container>
  
      <div>
        <v-card class="shadow-1 mt-5">
          <v-col cols="12" sm="12" md="12" class="px-0 py-0 mt-8 rounded-small">
            <v-data-table
              :key="tableItems.length"
              :loading="false"
              :headers="headers"
              :items="tableItems"
              :search="search"
              :items-per-page="itemsPerPage"
              hide-default-footer
              :page.sync="page"
              @page-count="pageCount = $event"
            >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon class="mr-2" @click="HandlerDeleteItem(item)">
                        mdi-delete
                    </v-icon>
                    <v-icon class="mr-2" @click="HandlerEditItem(item)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
          </v-col>
        </v-card>
      </div>
    </section>
  </template>
  
  <script>

  import CostModal from './costModal.vue';
  import DeleteModal from './deleteModal.vue';

  export default {
    components: {
        "cost-modal": CostModal,
        "delete-modal": DeleteModal,
    },
    data() {
      return {
        loadingData: false,
        activeModal: false,
        activeDeleteModal: false,
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        search: "",
        dataCost: {},
        headers: [
          {
            text: "Descripción",
            sortable: false,
            value: "description",
            align: "center",
            class: "primary--text",
          },
          {
            text: "Acciones",
            sortable: false,
            value: "actions",
            align: "center",
            class: "primary--text",
          },
        ],
        tableItems: [
            {description: "Descripción 1", id:"1"},
            {description: "Descripción 2", id:"2"}
        ],
        data: [],
        dataCountry: [],
      };
    },
  
    created() {
    },
  
    watch: {
    },
  
    computed: {
    },
  
    methods: {  
      HandlerOpenModal(value) {
        this.activeModal = value;
        if(!value){
            this.dataCost = {}
        }
      },

      HandlerOpenDeleteModal(value){
        this.activeDeleteModal = value;
        if(!value){
            this.dataCost = {}
        }
      },

      ActionCost(item) {
        this.activeModal = false;
        const index = this.tableItems.findIndex(el => el.id === item.id);
        if (index !== -1) {
          this.$set(this.tableItems, index, item);
        } else {
          this.tableItems.push(item);
        }
        this.dataCost = {}
      },

      DeleteCost(item) {
        this.activeDeleteModal = false;
        const index = this.tableItems.findIndex(el => el.id === item.id);
        if (index !== -1) {
            this.tableItems.splice(index, 1);
        }
        this.dataCost = {}
      },

      HandlerDeleteItem(item){
        this.activeDeleteModal = true;
        this.dataCost = { ...item };
      },

      HandlerEditItem(item){
        this.activeModal = true;
        this.dataCost = { ...item };
      }
    },
  };
  </script>
  
  <style></style>
  