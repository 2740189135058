<template>
  <v-container>
    <v-row class="mt-5 mb-5">
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="dataCountry"
          item-text="name"
          item-value="id"
          filled
          rounded
          dense
          class="rounded-small"
          label="Pais"
          v-model="country_id"
          @change="HandlerGetStockDay()"
        ></v-select>
      </v-col>
      <!-- <v-col cols="12" sm="4" md="3">
        <v-select
          :items="['ASC', 'DESC']"
          filled
          rounded
          dense
          class="rounded-small"
          label="Dias de venta"
          v-model="days_sale"
          @change="HandlerGetStockDay()"
        ></v-select>
      </v-col> -->
      <v-col class="d-flex justify-end" cols="12" sm="4" md="6">
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetStockDay()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="shadow-1">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="-1"
            height="500"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.sale_per_day`]="{ item }">
              <span>{{ item.sale_per_day | formatfix }}</span>
            </template>

            <template v-slot:[`item.days_of_sale`]="{ item }">
              <span>{{ item.days_of_sale | formatInt }}</span>
            </template>

            <template v-slot:[`item.histories_month`]="{ item }">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left"
                        v-for="(sub_item, i) of item.histories_month"
                        :key="i"
                      >
                        <span class="text-capitalize" v-if="i < 3">
                          {{ sub_item.production_to_month | date }}
                        </span>
                        <span v-else> Stock Hoy </span>
                      </th>
                      <!-- <th>Ventas Por Dia</th>
                      <th>Dias de Venta</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        v-for="(sub_item, i) of item.histories_month"
                        :key="i"
                      >
                        {{ sub_item.total }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      // filters
      brand: "",
      products: "",
      loadingFilters: false,

      // Data
      loading: false,
      sku: "",
      name: "",
      days: 30,

      total: 0,

      dataCountry: [],
      country_id: 1,

      data: [],

      warehouses: [],
      result: 0,

      // Pagination
      page: 1,
      pagination: {},

      offset: 0,
      days_sale: "",

      headers: [
        {
          text: "Producto",
          align: "start",
          value: "name",
        },
        {
          text: "Ultimos Tres Meses Completos",
          sortable: false,
          value: "histories_month",
        },
        { text: "Ventas Por Dia", value: "sale_per_day" },
        { text: "Dias de Venta", value: "days_of_sale" },
      ],
    };
  },

  filters: {
    formatfix(value) {
      return value.toFixed(1);
    },
    formatInt(value) {
      return parseInt(value);
    },
    date(value) {
      switch (value) {
        case 1:
          return "Enero";
        case 2:
          return "Febrero";
        case 3:
          return "Marzo";
        case 4:
          return "Abril";
        case 5:
          return "Mayo";
        case 6:
          return "Junio";
        case 7:
          return "Julio";
        case 8:
          return "Agosto";
        case 9:
          return "Septiembre";
        case 10:
          return "Octubre";
        case 11:
          return "Noviembre";
        case 12:
          return "Diciembre";
      }
    },

    // dayOrder(products) {
    //   const sum_total = products.reduce((acc, out) => {
    //     return acc + out.total;
    //   }, 0);

    //   return (sum_total / 90).toFixed(2);
    // },
  },

  created() {
    this.HandlerGetStockDay();
    this.HandlerGetCountry();
  },

  // watch: {
  //   page(val) {
  //     this.HandlerGetStockDay(val);
  //   },
  // },

  methods: {
    dayOrder(products) {
      const month = parseInt(moment().format("MM"));
      // const month = 4;
      const sum_total = products.reduce((acc, out) => {
        if (out.production_to_month != month) {
          acc = acc + out.total;
        }
        return acc;
      }, 0);

      const result = (sum_total / 90).toFixed(1);

      return result;
    },

    totalOrderDary(products) {
      let total = 0;

      if (!products) {
        return total;
      }

      const month = parseInt(moment().format("MM"));

      const stock_now = products.find(
        (index) => index.production_to_month === month
      );

      const sum_total = products.reduce((acc, out) => {
        if (out.production_to_month != month) {
          acc = acc + out.total;
        }
        return acc;
      }, 0);

      const result = sum_total / 90;

      if (stock_now?.total > 0 && result > 0) {
        total = parseInt(stock_now.total / result);
      }

      return total;
    },

    HandlerReset() {
      this.country_id = 1;
      this.HandlerGetStockDay();
    },
    async HandlerGetStockDay() {
      try {
        this.loading = true;
        const request = {
          country_id: this.country_id,
          days_sale: this.days_sale,
        };
        const response = await this.$store.dispatch(
          "stocks/GET_STOCKS_DAYS",
          request
        );
        this.data = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>