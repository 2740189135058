<template>
  <section id="dashboard">
    <!-- {{ DashboardDayValues }} -->
    <!-- <hr /> -->
    <!-- {{ DashboardMonthValues }} -->

    <!-- Welcome Card -->
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12">
          <v-card class="shadow-5">
            <v-img
              :src="require('@/assets/illustrations/bg_dashboard_card.svg')"
              cover
              position="top"
              class="align-center"
              :max-height="welcomeCardHeight"
            >
              <v-card-title>
                <v-container fill-height fluid>
                  <v-row align="center" justify="space-between" dense>
                    <v-col cols="12" md="9">
                      <h2
                        :class="[
                          isMobile ? 'h3' : 'h2',
                          isMobile && isDark ? 'white--text' : 'primary--text'
                        ]"
                        style="word-break: normal"
                      >
                        ¡Hola, Máster01!
                        <!-- {{ userData.name }} -->
                      </h2>
                    </v-col>
                    <v-col cols="3" class="d-none d-md-block">
                      <v-img
                        contain
                        max-width="90%"
                        class="mx-auto"
                        :src="require('@/assets/illustrations/chart.svg')"
                        alt="Chart illustration"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>

      <!-- Graphs -->

      <!-- Slider minicard for Mobiles -->
    </v-container>
  </section>
</template>

<script>
export default {
  inject: ["theme"],

  data() {
    return {
      render: false,
      userData: {},
      DateItems: ["Día", "Mes"],
      DateSearch: "Día",
      // CONFIGURACIONES PARA GRÁFICAS
      optionsMiniCardsAreaIncoming: {
        chart: {
          sparkline: {
            enabled: true
          }
        },
        title: {
          text: 0,
          offsetX: 20,
          offsetY: 10,
          style: {
            fontSize: "24px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        subtitle: {
          text: "Ventas del dia",
          offsetX: 20,
          offsetY: 42,
          style: {
            fontSize: "13px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        colors: ["#00BCD4"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
        xaxis: {
          categories: []
        }
      },
      optionsMiniCardsAreaOutgoing: {
        chart: {
          sparkline: {
            enabled: true
          }
        },
        title: {
          text: 0,
          offsetX: 20,
          offsetY: 10,
          style: {
            fontSize: "24px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        subtitle: {
          text: "Ventas del mes",
          offsetX: 20,
          offsetY: 42,
          style: {
            fontSize: "13px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        colors: ["#2196F3"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
          }
        },
        xaxis: {
          categories: []
        }
      },

      optionsMiniCardsBarIncoming: {
        chart: {
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        title: {
          text: 0,
          offsetX: 20,
          offsetY: 10,
          style: {
            fontSize: "24px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        subtitle: {
          text: "Productos mas vendidos",
          offsetX: 20,
          offsetY: 42,
          style: {
            fontSize: "13px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        colors: ["#625FA4"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            inverseColors: false,
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 1,
            stops: [50, 100]
          }
        },
        xaxis: {
          categories: []
        }
      },
      optionsMiniCardsBarOutgoing: {
        chart: {
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%"
          }
        },
        title: {
          text: 0,
          offsetX: 20,
          offsetY: 10,
          style: {
            fontSize: "24px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        subtitle: {
          text: "Medios de pago preferidos",
          offsetX: 20,
          offsetY: 42,
          style: {
            fontSize: "13px",
            fontFamily: "Poppins",
            color: "#7f7cc2"
          }
        },
        colors: ["#AB47BC"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            inverseColors: false,
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 1,
            stops: [50, 100]
          }
        },
        xaxis: {
          categories: []
        }
      },

      optionsDonutCalls: {
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: "100%"
              }
            }
          }
        ],
        width: "100%",
        legend: {
          position: "bottom"
        },
        labels: ["Entrantes", "Salientes"]
      },

      optionsBarIncomingCalls: {
        xaxis: {
          categories: []
        },
        fill: {
          colors: ["#6D71F9"]
        }
      },
      optionsBarOutgoingCalls: {
        xaxis: {
          categories: []
        }
        // fill: {
        //     colors: ["#625FA4"]
        // },
      },

      optionsLine: {
        theme: {
          mode: "light"
        },
        stroke: {
          curve: "smooth"
        },

        xaxis: {
          categories: []
        }
      },

      // DATOS PARA GRÁFICAS
      serieDonut: [0, 0],

      serieLine: [
        {
          name: "Total",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: "Entrantes",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: "Salientes",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],

      barIncomingCalls: [
        {
          name: "series-1",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      barOutgoingCalls: [
        {
          name: "series-1",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],

      seriesMiniCardIncomingArea: [
        {
          name: "Llamadas entrantes",
          data: [0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      seriesMiniCardOutgoingArea: [
        {
          name: "Llamadas salientes",
          data: [0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],

      seriesMiniCardIncomingBar: [
        {
          name: "Llamadas entrantes por extensión",
          data: [0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      seriesMiniCardOutgoingBar: [
        {
          name: "Llamadas salientes por extensión",
          data: [0, 0, 0, 0, 0, 0, 0, 0]
        }
      ]
    };
  },

  computed: {
    // DashboardDayValues() {
    //     return this.$store.getters["dashboard/GET_DASHBOARD_DAY"];
    // },

    // DashboardMonthValues() {
    //     return this.$store.getters["dashboard/GET_DASHBOARD_MONTH"];
    // },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    isDark() {
      return this.$vuetify.theme.dark;
    },

    welcomeCardHeight() {
      if (this.$vuetify.breakpoint.mobile) {
        return "200";
      } else {
        return "150";
      }
    }
  },

  watch: {
    // isDark() {
    //     this.changeThemeChart();
    // }
  },

  created() {
    // this.requestDashboard();
    this.render = true;
  },

  mounted() {
    // this.userData = JSON.parse(localStorage.getItem("user"));
  },

  updated() {
    // this.changeThemeChart();
  },

  methods: {
    // changeThemeChart() {
    //     if (this.$refs.chart1 !== undefined) {
    //         if (this.isDark) {
    //             this.$refs.chart1.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart2.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart3.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart4.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart5.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart6.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart7.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //             this.$refs.chart8.updateOptions(
    //                 { theme: { mode: "dark" } },
    //                 true
    //             );
    //         } else {
    //             this.$refs.chart1.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart2.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart3.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart4.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart5.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart6.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart7.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //             this.$refs.chart8.updateOptions(
    //                 { theme: { mode: "light" } },
    //                 true
    //             );
    //         }
    //     }
    // }
  }
};
</script>
