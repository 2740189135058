<template>
  <v-col cols="12">
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-card height="auto" class="mb-5">
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="primary"
          rounded
          height="6"
        ></v-progress-linear>

        <v-card-title class="pa-0">
          <v-img
            :src="require('@/assets/illustrations/bg_modal_card.svg')"
            cover
            position="left bottom"
            class="align-center"
            height="65px"
          >
          </v-img>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="Permisos"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Permisos"
                    filled
                    rounded
                    dense
                    class="rounded-small"
                    v-model="data.permissions_b2b_main"
                    :items="data_permision"
                    item-text="name"
                    item-value="id"
                    multiple
                    small-chips
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="Marca"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    label="Marca"
                    filled
                    rounded
                    dense
                    class="rounded-small"
                    v-model="data.b2b_brands"
                    :items="brands"
                    item-text="name"
                    item-value="id"
                    multiple
                    small-chips
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="Grupos"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    label="Grupos"
                    filled
                    rounded
                    dense
                    class="rounded-small"
                    v-model="data.b2b_groups"
                    :items="groups"
                    item-text="name"
                    item-value="id"
                    multiple
                    small-chips
                    :error-messages="errors"
                  ></v-select>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="empresa"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Empresa"
                    v-model="data.buyer_store[0].company_name"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Nombre de la empresa"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  :name="docTypes[store_id].name"
                  :rules="{
                    required: true,
                    ...docTypes[store_id].validation,
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    :label="docTypes[store_id].name"
                    v-model="data.doc_number"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Ingresa tu cuit"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="Provincia"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-autocomplete
                    :disabled="store_id === null"
                    :items="dataState"
                    item-text="name"
                    item-value="id"
                    v-model="data.state_id"
                    label="Provincia"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Ingresa tu nombre"
                    :error-messages="errors"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="Provincia"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Localidad"
                    v-model="data.location"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Ingresa tu nombre"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="sitio web"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Sitio web"
                    v-model="data.buyer_store[0].website_url"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Apellido de contacto"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="correo"
                  rules="email|required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Correo"
                    autocomplete="off"
                    v-model="data.email"
                    class="mt-0 rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Ingresa tu correo electronico"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="denominacion"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Nombre"
                    v-model="data.first_name"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Nombre de contacto"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="denominacion"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Apellido"
                    v-model="data.last_name"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Apellido de contacto"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="mt-md-n5">
                <ValidationProvider
                  name="denominacion"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Teléfono"
                    v-model="data.buyer_store[0].contact_phone"
                    class="rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Apellido de contacto"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
                class="mt-md-n5"
                v-if="action === 0"
              >
                <ValidationProvider
                  name="contraseña"
                  :rules="{
                    required: action == 0 ? true : false,
                    confirmed: 'confirmation',
                  }"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Contraseña"
                    autocomplete="off"
                    v-model="data.password"
                    type="password"
                    class="mt-0 rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Ingresa tu contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="4"
                class="mt-md-n5"
                v-if="action === 0"
              >
                <ValidationProvider
                  name="confirmar contraseña"
                  :rules="{ required: action == 0 ? true : false }"
                  vid="confirmation"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    label="Confirmar Contraseña"
                    autocomplete="off"
                    v-model="data.confirm_password"
                    type="password"
                    class="mt-2 rounded-small"
                    color="black"
                    dense
                    filled
                    rounded
                    placeholder="Confirma tu nombre contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="3" class="d-flex justify-start">
                <div class="d-flex">
                  <v-switch
                    v-model="data.b2b_invoice"
                    label="¿Factura?"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="closeModal(false)"
            >
              Cancelar
            </v-btn>
          </v-hover>

          <v-hover v-slot:default="{ hover }">
            <v-btn
              rounded
              :loading="loading"
              :disabled="loading"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              class="px-8"
              @click="passes(HandlerAction)"
            >
              {{ action == 1 ? "Editar" : "Guardar" }}</v-btn
            >
          </v-hover>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-col>
</template>

<script>
export default {
  props: {
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    brands: {
      type: Array,
      required: true,
      default: () => [],
    },
    store_id: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data() {
    return {
      loading: false,
      data_permision: [
        {
          id: 1,
          name: "Principal",
        },
        {
          id: 2,
          name: "Novedades",
        },
        {
          id: 3,
          name: "Catálogo de Productos",
        },
        {
          id: 4,
          name: "Orden de Compra",
        },
      ],
      groups: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      dataState: [],
      docTypes: {
        6: { name: "RUT", validation: { rutValidation: true } },
        5: {
          name: "CUIT",
          validation: {
            cuitValidation: true,
          },
        },
        10: {
          name: "RFC",
          validation: { regex: /^[A-Za-z]{3,4}[0-9]{6}[0-9A-Za-z]{3}$/ },
        },
      },
    };
  },

  created() {
    console.log(this.data.state_id);
    // console.log(this.data.buyer_store[0].store.country_id);
    this.HandlerProvincia(this.store_id);
  },

  watch: {
    store_id(val) {
      this.HandlerProvincia(val);
    },
  },

  methods: {
    closeModal(val = false) {
      this.$emit("dialog:change", false, val);
    },

    HandlerAction() {
      this.HandlerRegister(this.action === 0 ? "register" : "update");
    },

    async HandlerRegister(action) {
      try {
        this.loading = true;
        const request = {
          billing_zip_code: 1000,
          first_name: `${this.data.first_name}`,
          last_name: `${this.data.last_name}`,
          store_id: this.store_id,
          state_id: this.data.state_id,
          email: this.data.email,
          permissions_b2b_main: this.data.permissions_b2b_main,
          doc_number: this.data.doc_number,
          doc_type: this.docTypes[this.store_id].name,
          location: this.data.location,
          password: this.data.password,
          b2b_groups: this.data.b2b_groups,
          b2b_brands: this.data.b2b_brands,
          b2b_invoice: this.data.b2b_invoice,
          company_name: this.data.buyer_store[0].company_name,
          website_url: this.data.buyer_store[0].website_url,
          contact_phone: this.data.buyer_store[0].contact_phone,
        };
        if (action === "register") {
          await this.$store.dispatch("auth/REGISTER_CLIENTE", request);
        } else {
          const requestData = { ...request, id: this.data.id };
          await this.$store.dispatch("auth/UPDATE_CLIENTE", requestData);
        }
        this.closeModal(true);
      } catch (error) {
        this.$snotify.error(error.response.data.error.err_message, "Error!");
      } finally {
        this.loading = false;
      }
    },

    async HandlerProvincia(id) {
      try {
        this.lodaingProvincia = true;
        const storeCountryMap = {
          5: 1,
          6: 2,
          10: 3,
        };
        const request = { country_id: storeCountryMap[id] };

        const response = await this.$store.dispatch(
          "country/GET_STATES",
          request
        );
        this.dataState = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.lodaingProvincia = false;
      }
    },
  },
};
</script>

<style></style>
