<template>
  <v-container class="mt-5">
    <BrandData
      @dialog:change="HandlerResponseData"
      v-if="showData"
      action="0"
    />
  </v-container>
</template>

<script>
import BrandData from "./Utils/BrandData";
export default {
  components: {
    BrandData,
  },
  data() {
    return {
      showData: true,
      dataCategory: [],
      dataUpdate: [],
      action: 0,
    };
  },

  methods: {
    HandlerShowData() {
      this.showData = !this.showData;
      this.dataUpdate = {
        sub_category: [],
      };
    },

    HandlerResponse(data, action) {
      this.showData = !this.showData;
      this.action = 0;
      if (action == 0) {
        this.dataCategory.push(data.data[0]);
      } else {
        const indexupdate = this.dataCategory
          .map((element) => element.id)
          .indexOf(data.data[0].id);
        if (indexupdate > -1) {
          this.dataCategory.splice(indexupdate, 1, data.data[0]);
        }
      }
    },

    HandlerResponseData(data) {
      this.showData = !this.showData;
      this.dataUpdate = { ...data };
      this.action = 1;
    },
  },
};
</script>

<style>
</style>