<template>
  <div v-if="order.date_delivered == null && statusShip == 1">
    <div v-if="order.date_prepeared == null">
      <div v-if="order.ticket_name != null">
        <!-- <v-btn @click="HandlerMoveToPrepared(order)" block dark color="#F39C12">
          Para despachar
        </v-btn> -->
      </div>
      <div v-else>
        <!-- <v-btn @click="HandlerMoveToPrepared(order)" block dark color="#F39C12">
          Para despachar
        </v-btn> -->
      </div>
    </div>
    <div v-else>
      <v-btn block dark color="yellow darken--4">
        Desmarcar como preparado
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
    statusShip: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  methods: {
    async HandlerMoveToPrepared(item) {
      try {
        const request = { shipping_id: item.id };
        await this.$store.dispatch("orders/MOVE_TO_PREPARED", request);
        this.$snotify.success("Se ha movido el envio", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>