var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5 mb-5"},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.dataReservation,"item-text":"name","item-value":"id","filled":"","rounded":"","dense":"","name":"ciudad","label":"Tiendas","single-line":"","hide-details":""},on:{"change":function($event){return _vm.HandlerGetUserNotificationProduct()}},model:{value:(_vm.store_id),callback:function ($$v) {_vm.store_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"store_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"items":[
          { text: 'Por Procesar', value: true },
          { text: 'Procesados', value: false } ],"item-text":"text","item-value":"value","filled":"","rounded":"","dense":"","name":"ciudad","label":"Tiendas","single-line":"","hide-details":""},on:{"change":function($event){return _vm.HandlerGetUserNotificationProduct()}},model:{value:(_vm.is_active),callback:function ($$v) {_vm.is_active=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"is_active"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"3"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"type":"number","filled":"","rounded":"","dense":"","name":"ciudad","label":"SKU","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetUserNotificationProduct()}},model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"sku"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"2"}},[_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.HandlerGetUserNotificationProduct()}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.HandlerReset()}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-backup-restore")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"shadow-1 mt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.dataCoupon,"disable-pagination":"","hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.full_unit_price",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.full_unit_price))+" ")])]}},{key:"item.amount_coupons",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("currency")(item.amount_coupons))+" ")])]}},{key:"item.percentage",fn:function(ref){
        var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.percentage(item))+"%")])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.HandlerUpdateUserNotificationProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v("mdi-thumb-up")])],1)]}}],null,true)},[_c('span',[_vm._v("Procesar")])]),(item.is_active)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.HandlerDeleteUserNotificationProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'white' : '#6D6D6D'}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()]}}],null,true)})],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }