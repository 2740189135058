<template>
    <div class="text-center ma-2">
        <v-snackbar v-model="active" :timeout="timeout" top :color="color">
            {{ data }}
            <v-btn color="pink" text @click="closeAlert">Close</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    props: ["active", "data", "color"],
    data() {
        return {
            timeout: -1
        };
    },
    methods: {
        closeAlert() {
            this.$emit("dialog:change", "cerrar");
        }
    }
};
</script>

<style></style>
