<template>
  <span>
    <p class="my-0">
      <span>
        <span class="subtitle-2 font-weight-bold mr-1">Estado:</span>
        <span v-if="order.status == 'to_be_agreed'"> A Convenir </span>
        <span v-else-if="order.status == 'pending'">
          Pendiente
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'cost_exceeded'">
              <span class="subtitle-2 font-weight-bold mr-1">
                Sub-Estado:
              </span>
              Costo excedido
            </span>
            <span v-if="order.substatus == 'under_review'">
              <span class="subtitle-2 font-weight-bold mr-1">
                Sub-Estado:
              </span>
              En revisión (ej: fraude)
            </span>
            <span v-if="order.substatus == 'reviewed'">
              <span class="subtitle-2 font-weight-bold mr-1">
                Sub-Estado:
              </span>
              Revisado
            </span>
            <span v-if="order.substatus == 'fraudulent'">
              <span class="subtitle-2 font-weight-bold mr-1">
                Sub-Estado:
              </span>
              Fraudulento
            </span>
            <span v-if="order.substatus == 'waiting_for_payment'">
              <span class="subtitle-2 font-weight-bold mr-1">
                Sub-Estado:
              </span>
              Esperando a que se acredite el pago del envío
            </span>
            <span v-if="order.substatus == 'shipment_paid'">
              <span class="subtitle-2 font-weight-bold mr-1">
                Sub-Estado:
              </span>
              El costo de envío ha sido pagado
            </span>
          </span>
          <span v-else>{{ order.substatus }}</span>
        </span>
        <span v-else-if="order.status == 'handling'">
          En proceso
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'regenerating'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Regenerando
            </span>
            <span
              v-else-if="
                order.substatus == 'waiting_for_label_generation'
              "
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Esperando la generación de etiquetas
            </span>
            <span v-else-if="order.substatus == 'invoice_pending'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Factura Pendiente
            </span>
            <span
              v-else-if="
                order.substatus == 'waiting_for_return_confirmation'
              "
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Esperando confirmación de devolución
            </span>
            <span v-else-if="order.substatus == 'return_confirmed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Devolución Confirmada
            </span>
            <span v-else-if="order.substatus == 'manufacturing'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En fabricación
            </span>
          </span>
          <span v-else>{{ order.substatus }} </span>
        </span>
        <span v-else-if="order.status == 'ready_to_ship'">
          Listo para despachar
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'ready_to_print'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Listo para imprimir
            </span>
            <span v-else-if="order.substatus == 'invoice_pending'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Factura pendiente
            </span>
            <span v-else-if="order.substatus == 'printed'">
              <br />
              <span v-if="order.downloaded_by != null">
                <br />
                <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
                Etiqueta impresa
              </span>
              <span v-else class="subtitle-2 font-weight-bold">
                Sub-Estado:
              </span>
              Etiqueta sin imprimir
            </span>
            <span v-else-if="order.substatus == 'in_pickup_list'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En lista de recogida
            </span>
            <span
              v-else-if="order.substatus == 'ready_for_pkl_creation'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Listo para la creación PKL
            </span>
            <span v-else-if="order.substatus == 'ready_for_pickup'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Listo para recojer
            </span>
            <span v-else-if="order.substatus == 'ready_for_dropoff'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Listo para despachar
            </span>
            <span v-else-if="order.substatus == 'picked_up'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Recogido
            </span>
            <span v-else-if="order.substatus == 'stale'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío atrasado listo para enviar
            </span>
            <span v-else-if="order.substatus == 'dropped_off'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Despachado en Melipoint
            </span>
            <span v-else-if="order.substatus == 'in_hub'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En centro de almacenaje
            </span>
            <span v-else-if="order.substatus == 'measures_ready'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Medidas y peso listo
            </span>
            <span
              v-else-if="
                order.substatus ==
                'waiting_for_last_mile_authorization'
              "
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Esperando la autorización de última milla
            </span>
            <span
              v-else-if="
                order.substatus == 'waiting_for_carrier_authorization'
              "
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Esperando autorización del transportista
            </span>
            <span
              v-else-if="order.substatus == 'authorized_by_carrier'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Autorizado por el transportista
            </span>
            <span v-else-if="order.substatus == 'in_packing_list'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En la lista de embalaje
            </span>
            <span v-else-if="order.substatus == 'in_plp'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En PLP
            </span>
            <span v-else-if="order.substatus == 'in_warehouse'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En Almacén
            </span>
            <span v-else-if="order.substatus == 'ready_to_pack'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Listo para empacar
            </span>
          </span>
          <span v-else>{{ order.substatus }} </span>
        </span>
        <span v-else-if="order.status == 'shipped'">
          <span style="color: #2c9a8f">Enviado</span>
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'delayed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Retrasado
            </span>
            <span
              v-else-if="order.substatus == 'waiting_for_withdrawal'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En espera para ser retirado
            </span>
            <span
              v-else-if="
                order.substatus == 'contact_with_carrier_required'
              "
            >
              <br /><span class="subtitle-2 font-weight-bold">
                Sub-Estado:
              </span>
              Se requiere contactar al transportista
            </span>
            <span v-else-if="order.substatus == 'receiver_absent'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Receptor ausente
            </span>
            <span v-else-if="order.substatus == 'reclaimed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Reclamado
            </span>
            <span v-else-if="order.substatus == 'not_localized'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Sin localizar
            </span>
            <span v-else-if="order.substatus == 'forwarded_to_third'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Remitido a un tercero
            </span>
            <span v-else-if="order.substatus == 'soon_deliver'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Entrega temprana
            </span>
            <span v-else-if="order.substatus == 'refused_delivery'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío rechazado
            </span>
            <span v-else-if="order.substatus == 'bad_address'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              La dirección no se encontró
            </span>
            <span v-else-if="order.substatus == 'negative_feedback'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío atrasado enviado con comentarios negativos por el comprador
            </span>
            <span v-else-if="order.substatus == 'need_review'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Se necesita revisar el estado del transportista para comprender lo
              que sucedió
            </span>
            <span v-else-if="order.substatus == 'stale'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío atrasado enviado
            </span>
            <span
              v-else-if="order.substatus == 'operator_intervention'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Necesita intervención de operador
            </span>
            <span v-else-if="order.substatus == 'claimed_me'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío de perecedero que fue reclamado por el receptor
            </span>
            <span v-else-if="order.substatus == 'retained'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Retenido cuando el paquete estaba en camino
            </span>
            <span v-else-if="order.substatus == 'delivery_failed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              El envío falló
            </span>
            <span
              v-else-if="order.substatus == 'waiting_for_confirmation'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Esperando confirmación del comprador
            </span>
          </span>
          <span v-else>{{ order.substatus }} </span>
        </span>
        <span v-else-if="order.status == 'delivered'">
          <span style="color: #2c9a8f"> Entregado</span>
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'damaged'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Dañado
            </span>
            <span v-else-if="order.substatus == 'fulfilled_feedback'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Fullfilled por los comentarios del comprador
            </span>
            <span v-else-if="order.substatus == 'no_action_taken'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Ninguna acción tomada por el comprador
            </span>
            <span v-else-if="order.substatus == 'double_refund'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Doble reembolso
            </span>
            <span v-else-if="order.substatus == 'reclaimed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Reclamado
            </span>
            <span v-else-if="order.substatus == 'not_localized'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Sin localizar
            </span>
            <span v-else-if="order.substatus == 'forwarded_to_third'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Remitido a un tercero
            </span>
            <span v-else-if="order.substatus == 'soon_deliver'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Entrega temprana
            </span>
            <span v-else-if="order.substatus == 'refused_delivery'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío rechazado
            </span>
            <span v-else-if="order.substatus == 'bad_address'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              La dirección no se encontró
            </span>
            <span v-else-if="order.substatus == 'negative_feedback'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío atrasado enviado con comentarios negativos por el comprador
            </span>
            <span v-else-if="order.substatus == 'need_review'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Se necesita revisar el estado del transportista para comprender lo
              que sucedió
            </span>
            <span v-else-if="order.substatus == 'stale'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío atrasado enviado
            </span>
            <span
              v-else-if="order.substatus == 'operator_intervention'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Necesita intervención de operador
            </span>
            <span v-else-if="order.substatus == 'claimed_me'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío de perecedero que fue reclamado por el receptor
            </span>
            <span v-else-if="order.substatus == 'retained'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Retenido cuando el paquete estaba en camino
            </span>
            <span v-else-if="order.substatus == 'delivery_failed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              El envío falló
            </span>
            <span
              v-else-if="order.substatus == 'waiting_for_confirmation'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Esperando confirmación del comprador
            </span>
          </span>
          <span v-else>{{ order.substatus }} </span>
        </span>
        <span v-else-if="order.status == 'not_delivered'">
          No se pudo entregar el paquete
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'returning_to_sender'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Volviendo al remitente
            </span>
            <span v-else-if="order.substatus == 'retained'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Retenido
            </span>
            <span v-else-if="order.substatus == 'stolen'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Robado
            </span>
            <span v-else-if="order.substatus == 'returned'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Regresado
            </span>
            <span v-else-if="order.substatus == 'receiver_absent'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Receptor ausente
            </span>
            <span v-else-if="order.substatus == 'confiscated'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Confiscado
            </span>
            <span v-else-if="order.substatus == 'to_review'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío cerrado
            </span>
            <span v-else-if="order.substatus == 'destroyed'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Destruido
            </span>
            <span
              v-else-if="order.substatus == 'waiting_for_withdrawal'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              En espera de ser retirado
            </span>
            <span v-else-if="order.substatus == 'negative_feedback'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío atrasado forzado a no ser entregado debido a comentarios
              negativos del comprador
            </span>
            <span v-else-if="order.substatus == 'not_localized'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              No localizado
            </span>
            <span v-else-if="order.substatus == 'double_refund'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Doble reembolso
            </span>
            <span v-else-if="order.substatus == 'damaged'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Dañado
            </span>
            <span v-else-if="order.substatus == 'lost'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Paquete extraviado
            </span>
            <span
              v-else-if="
                order.substatus == 'cancelled_measurement_exceeded'
              "
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Envío cancelado por medición excedida
            </span>
            <span v-else-if="order.substatus == 'returned_to_hub'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Devuelto a la base
            </span>
            <span v-else-if="order.substatus == 'returned_to_agency'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Devuelto a la agencia
            </span>
            <span
              v-else-if="order.substatus == 'picked_up_for_return'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Regcogido para ser devuelto
            </span>
            <span v-else-if="order.substatus == 'claimed_me'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              No entregado que fue reclamado por el receptor
            </span>
            <span
              v-else-if="order.substatus == 'returning_to_warehouse'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Regresando al Almacén
            </span>
            <span
              v-else-if="order.substatus == 'returned_to_warehouse'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Regresado al almacén
            </span>
          </span>
          <span v-else>{{ order.substatus }} </span>
        </span>
        <span v-else-if="order.status == 'not_verified'">
          Sin Verificar
        </span>
        <span v-else-if="order.status == 'cancelled'">
          <span style="color: red">Cancelado</span>
          <span v-if="order.substatus != null">
            <span v-if="order.substatus == 'recovered'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Recuperado
            </span>
            <span v-else-if="order.substatus == 'Recuperado'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Etiqueta caducada
            </span>
            <span v-else-if="order.substatus == 'cancelled_manually'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Cancelado manualmente
            </span>
            <span v-else-if="order.substatus == 'fraudulent'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Cancelado Fraudulento
            </span>
            <span v-else-if="order.substatus == 'return_expired'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Devolución caducada
            </span>
            <span
              v-else-if="order.substatus == 'return_session_expired'"
            >
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              La sesión de regreso expiró
            </span>
            <span v-else-if="order.substatus == 'unfulfillable'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              Unfulfillable
            </span>
            <span v-else-if="order.substatus == 'closed_by_user'">
              <br />
              <span class="subtitle-2 font-weight-bold"> Sub-Estado: </span>
              El usuario cambio el tipo de envío y canceló el anterior.
            </span>
          </span>
          <span v-else>{{ order.substatus }} </span>
        </span>
        <span v-else-if="order.status == 'closed'">
          <span style="color: red">Cerrado</span>
        </span>
        <span v-else-if="order.status == 'error'"> Error </span>
        <span v-else-if="order.status == 'active'">
          Para preparar
        </span>
        <span v-else-if="order.status == 'not_specified'">
          Sin especificar
        </span>
        <span v-else-if="order.status == 'stale_ready_to_ship'">
          Envío atrasado, listo para el envío
        </span>
        <span v-else-if="order.status == 'stale_shipped'">
          Envío atrasado, enviado
        </span>
        <span v-else-if="order.status == 'delivery'">
          Finalizado
        </span>
        <span v-else-if="order.status == 'transit'">
          En tránsito
        </span>
        <span v-else-if="order.status == 'prepared'">
          Para despachar
        </span>
      </span>
    </p>
  </span>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
};
</script>

<style>
</style>