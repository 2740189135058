<template>
  <v-container>
    <v-row>
      <!-- {{ data.images }} -->
      <v-col cols="12">
        <v-btn icon @click="closeModal()" v-if="action !== 2">
          <v-icon color="primary">mdi-arrow-left-bold</v-icon>
        </v-btn>
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <alert
            :data="errorData"
            :active="activeAlert"
            :colorAlert="colorAlert"
            @dialog:change="eventdialog"
          />

          <v-card class="mt-5" :loading="loading" v-if="action !== 2">
            <v-card-text>
              <v-card-title class="font-weight-medium primary--text">
                <div class="mr-3" v-if="loading">
                  <v-progress-circular
                    color="deep-purple accent-4"
                    indeterminate
                    height="6"
                  ></v-progress-circular>
                </div>
                <div>
                  <span class="mr-3"> Localización </span>
                  <!-- <v-btn
                    :disabled="disabled"
                    small
                    rounded
                    class="mr-1"
                    color="primary"
                    @click="HandlerSyncErp('contabilium')"
                  >
                    Sincronizar ARG
                  </v-btn>
                  <v-btn
                    :disabled="disabled"
                    small
                    rounded
                    class="mr-1"
                    color="primary"
                    @click="HandlerSyncErp('contabilium_cl')"
                  >
                    Sincronizar CHI
                  </v-btn> -->
                </div>
              </v-card-title>
              <div>
                <v-row dense v-for="(item, i) in dataCountry" :key="i">
                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      readonly
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      :label="`SKU ${item.code_iso}`"
                      color="blue-grey"
                      v-model="item.sku"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Derecho"
                      color="blue-grey"
                      v-model="item.rights_percentage"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Estadísticas"
                      color="blue-grey"
                      v-model="item.statistics_percentage"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="TAX"
                      v-model="item.tax"
                      color="blue-grey"
                      hint="maximo 99"
                    ></v-text-field>
                  </v-col>

                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-select
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Activar B2B"
                      :items="[
                        { id: true, name: 'SI' },
                        { id: false, name: 'NO' }
                      ]"
                      item-text="name"
                      item-value="id"
                      color="blue-grey"
                      v-model="item.active_b2b"
                    ></v-select>
                  </v-col>
                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="FOB1"
                      v-model="item.fob1"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="FOB2"
                      v-model="item.fob2"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="item != null && item.id === 1"
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Factor CIF"
                      v-model="item.cif_factor_percentage"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="item != null && item.id === 1"
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Gastos Despacho"
                      v-model="item.dispatch_expenses_percentage"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="item != null && item.id === 1"
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Honorarios"
                      v-model="item.fees_percentage"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="item != null && item.id === 1"
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Impuesto país"
                      v-model="item.country_tax_percentage"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="item != null && item.id === 1"
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Otros gastos"
                      v-model="item.misc_costs_percentage"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="item != null && item.id === 1"
                    cols="12"
                    sm="6"
                    md="2"
                    style="display: none"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Secretaria"
                      v-model="item.secretary_percentage"
                      color="blue-grey"
                    ></v-text-field> </v-col
                  ><v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Ventas por mes"
                      v-model="item.stock_months_coverage_default"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col
                    v-if="item != null "
                    cols="12"
                    sm="6"
                    md="2"
                    style="visibility: hidden"
                  >
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Meses de venta esti"
                      v-model="item.secretary_percentage"
                      color="blue-grey"
                    ></v-text-field>
                  </v-col> -->

                  <!-- <v-col cols="12" sm="6" md="2">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Fecha disponibilidad"
                          rules=""
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            dense
                            class="rounded-small"
                            v-model="item.availability_date"
                            label="Fecha disponibilidad"
                            prepend-icon=""
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        locale="es-us"
                        no-title
                        v-model="data.availability_date"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col> -->

                  <v-col v-if="item != null" cols="12" sm="6" md="1">
                    <v-btn
                      :disabled="disabled"
                      small
                      rounded
                      class="mr-1 my-3"
                      color="primary"
                      @click="HandlerSyncErp(item.name)"
                    >
                      Sincronizar
                    </v-btn>
                  </v-col>

                  <!-- <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-select
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="Activar B2C"
                      :items="[
                        { id: true, name: 'SI' },
                        { id: false, name: 'NO' },
                      ]"
                      item-text="name"
                      item-value="id"
                      color="blue-grey"
                      v-model="item.active_b2c"
                    ></v-select>
                  </v-col> -->

                  <!-- <v-col v-if="item != null" cols="12" sm="6" md="2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small"
                      dense
                      label="moneda"
                      color="blue-grey"
                      v-model="item.value"
                    ></v-text-field>
                  </v-col> -->
                </v-row>
              </div>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="register_disabled"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerAction)"
                >
                  {{ action == 0 ? "Registrar" : "Editar" }}
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";

import alert from "../Utils/Alert";
export default {
  components: {
    alert
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    action: {
      type: Number,
      required: false,
      default: 0
    },
    data: {
      type: Object,
      required: true,
      default: () => {}
    },
    dataBrand: {
      type: Array,
      required: true,
      default: () => []
    },
    dataPublication: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      model: null,
      items: [
        {
          title: "SI",
          value: true
        },
        {
          title: "NO",
          value: false
        }
      ],
      stateData: [],
      locationData: [],
      loadingState: false,
      loadingLocation: false,
      loadingCategories: false,
      categoryData: this.initializeCategoryData(this.data?.category),
      time: null,
      time_open: null,
      menu_open: false,
      time_close: null,
      menu_close: false,
      showPassword: false,
      loading: false,
      errorData: "",
      activeAlert: false,
      colorAlert: "",
      dataModels: [],
      dataCountry: [],
      itemsGroup: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],

      disabled: true,

      menu2: false,
      date: null,
      register_disabled: false,
      updateImage: false
    };
  },

  created() {
    if (this.action == 0) {
      this.data.availability_date = moment().format("YYYY-MM-DD");
    }

    if (this.data.products_erp) {
      this.disabled = this.data.products_erp.length > 0 ? false : true;
    }

    this.HandlerGetCountry();

    this.HandlerGetCategories();

    this.data.volume =
      (this.data.length * this.data.width * this.data.height) / 1000000;
  },

  watch: {
    state_id(val) {
      this.HandlerGetLocations(val);
    }
  },

  computed: {
    getCategories() {
      return this.$store.getters["products/GET_CATEGORIES"];
    }
  },

  methods: {
    async HandlerGetCountry() {
      try {
        this.loading = true;

        const response = await this.$store.dispatch("country/GET_COUNTRIES");

        this.dataCountry = response.data.data;

        if (this.action == 1) {
          if (this.data.products_erp.length > 0) {
            this.dataCountry = this.dataCountry.map(index => {
              let product = this.data.products_erp.find(
                item => item.country_id == index.id
              );

              if (product !== undefined) {
                return {
                  ...index,
                  ...product,
                  id: index.id,
                  sku: product.sku,
                  // country_id: product.id,
                  // currency: product.currency.symbol,
                  base_price: product.base_price,
                  fob1: product.fob1,
                  fob2: product.fob2,
                  tax: product.tax,
                  active_b2b: product.active_b2b,
                  active_b2c: product.active_b2c
                };
              } else {
                return {
                  ...index,
                  sku: "",
                  // country_id: product.id,
                  // currency: product.currency.symbol,
                  base_price: 0,
                  fob1: 0,
                  fob2: 0,
                  tax: 0,
                  active_b2b: false,
                  active_b2c: false
                };
              }
            });
          } else {
            this.brand_id = this.data.brand_id;
            this.product_id = this.data.id;
          }
          await this.HandlerGetGenerateSku();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetModels() {
      try {
        const request = { brand_id: this.data.brand_id };
        const response = await this.$store.dispatch(
          "products/GET_MODELS",
          request
        );
        this.dataModels = response.data.data;
        console.log("modelos", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetGenerateSku() {
      try {
        this.loading = true;

        const request = {
          brand_id: this.data.brand_id,
          product_id: this.data?.id ? this.data.id : ""
        };
        const response = await this.$store.dispatch(
          "products/GET_GENERATE_SKU",
          request
        );

        this.dataCountry = this.dataCountry.map(index => {
          const data = response.data.data.find(item => {
            return item.country_id == index.id;
          });
          if (data !== undefined) {
            index.sku = data.sku;
          }
          return index;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    closeModal() {
      this.$emit("dialog:change", false);
    },

    HandlerAction() {
      if (this.action == 0) this.HandlerRegister();
      else this.HandlerUpdate();
    },

    async HandlerRegister() {
      try {
        this.loading = true;

        const products_erp = this.dataCountry.map(index => {
          return {
            sku: index.sku,
            country_id: index.id,
            // currency: index.currency.symbol,
            // base_price: index.base_price,
            tax: index.tax,
            active_b2b: index.active_b2b,
            // active_b2c: index.active_b2c,
            right: index.right,
            statistics: index.statistics
          };
        });

        const addRequestCategories = {
          id: this.categoryData.id,
          specifications: this.categoryData.specifications_values.map(spec => {
            return {
              id: spec.id,
              value: spec.value
            };
          })
        };

        const request = new FormData();

        if (this.data.images_publications !== undefined) {
          this.data.images_publications.forEach(file =>
            request.append("images_publications[]", file)
          );
        } else {
          request.append("images_publications[]", []);
        }
        request.append("products_erp", JSON.stringify(products_erp));
        // request.append("availability_date", this.data.availability_date);
        request.append("barcode", this.data.barcode);
        request.append("brand_id", this.data.brand_id);
        request.append("description", this.data.description);
        request.append("height", this.data.height);
        request.append("length", this.data.length);
        request.append("name", this.data.name);
        request.append("pattern_name", this.data.pattern_name);
        request.append("resume", this.data.resume);
        request.append("short_name", this.data.short_name);
        request.append(
          "sku",
          this.dataCountry.find(index => index.id == 1).sku
        );
        request.append("specs", this.data.specs);
        request.append("volume", this.data.volume);
        request.append("weight", this.data.weight);
        request.append("width", this.data.width);
        request.append("group", this.data.group);
        request.append("notes", this.data.notes);
        if (addRequestCategories?.id) {
          request.append("category", JSON.stringify(addRequestCategories));
        }

        // const request = {
        //   ...this.data,
        //   sku: this.dataCountry.find((index) => index.id == 1).sku,
        //   products_erp: this.dataCountry.map((index) => {
        //     return {
        //       sku: index.sku,
        //       country_id: index.id,
        //       currency: index.currency.symbol,
        //       base_price: index.base_price,
        //       tax: index.tax,
        //       active_b2b: index.active_b2b,
        //       active_b2c: index.active_b2c,
        //     };
        //   }),
        // };
        // console.log('request', request)
        const response = await this.$store.dispatch(
          "products/REGISTER_PRODUCTS",
          request
        );
        // console.log("response", response.data);
        this.data.id = response.data.data.id;
        this.$snotify.success("Productos creado con éxito.", "¡Hecho!");
        this.disabled = false;

        // this.closeModal();
      } catch (error) {
        console.log("error", error);
        // this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
        this.register_disabled = true;
      }
    },

    async HandlerUpdate() {
      try {
        this.loading = true;
        const products_erp = this.dataCountry.map(index => {
          const {
            rights_percentage,
            statistics_percentage,
            cif_factor_percentage,
            fees_percentage,
            dispatch_expenses_percentage,
            misc_costs_percentage,
            country_tax_percentage,
            stock_months_coverage_default
          } = index;
          return {
            sku: index.sku,
            country_id: index.id,
            tax: index.tax,
            active_b2b: index.active_b2b,
            right: index.right,
            statistics: index.statistics,
            fob1: index.fob1,
            fob2: index.fob2,
            rights_percentage,
            statistics_percentage,
            cif_factor_percentage,
            fees_percentage,
            country_tax_percentage,
            misc_costs_percentage,
            dispatch_expenses_percentage,
            stock_months_coverage_default
          };
        });

        const deleteCategoryId =
          this.data?.category?.id !== this.categoryData?.id
            ? this.data?.category?.id
            : null;

        const addRequestCategories =
          this.data?.category?.id == this.categoryData?.id
            ? null
            : {
                id: this.categoryData?.id,
                specifications: this.categoryData?.specifications_values?.map(
                  spec => {
                    return {
                      id: spec?.id,
                      value: spec?.value
                    };
                  }
                )
              };

        const valuesChangingSpecifications = () => {
          if (this.data?.category?.id !== this.categoryData?.id) {
            return null;
          }
          const differentValues = this.categoryData?.specifications_values?.filter(
            elem => {
              const specification = this.data?.category?.specifications_values?.find(
                el => el?.specification?.id == elem?.id
              );
              return elem?.value !== specification?.value;
            }
          );
          return differentValues;
        };

        const requestSpecificationsValues =
          valuesChangingSpecifications()?.length > 0
            ? valuesChangingSpecifications().map(item => {
                const newValues = { id: item?.valueId, value: item?.value };
                return newValues;
              })
            : [];

        const requestDeleteCategory = {
          product_id: this.data?.id,
          data: { category: deleteCategoryId }
        };

        const request = new FormData();

        if (this.data.images_publications !== undefined) {
          this.data.images_publications.forEach(file =>
            request.append("images_publications[]", file)
          );
        } else {
          request.append("images_publications[]", []);
        }
        request.append("products_erp", JSON.stringify(products_erp));
        // request.append("availability_date", this.data.availability_date);
        request.append("barcode", this.data.barcode);
        request.append("brand_id", this.data.brand_id);
        request.append("description", this.data.description);
        request.append("height", this.data.height);
        request.append("length", this.data.length);
        request.append("name", this.data.name);
        request.append("pattern_name", this.data.pattern_name);
        request.append("resume", this.data.resume);
        request.append("short_name", this.data.short_name);
        request.append(
          "sku",
          this.dataCountry.find(index => index.id == 1).sku
        );
        request.append("specs", this.data.specs);
        request.append("volume", this.data.volume);
        request.append("weight", this.data.weight);
        request.append("width", this.data.width);
        request.append("group", this.data.group);
        request.append("notes", this.data.notes);
        if (addRequestCategories?.id) {
          request.append(
            "category_to_add",
            JSON.stringify(addRequestCategories)
          );
        }
        if (requestSpecificationsValues?.length > 0) {
          request.append(
            "specifications_values",
            JSON.stringify(requestSpecificationsValues)
          );
        }

        const requestdata = {
          request: request,
          id: this.data.id
        };

        if (requestDeleteCategory?.data?.category) {
          await this.$store.dispatch(
            "products/DELETE_CATEGORY_TO_PRODUCT",
            requestDeleteCategory
          );
        }

        await this.$store.dispatch("products/UPDATE_PRODUCTS", requestdata);
        this.$snotify.success("Productos editado con éxito.", "¡Hecho!");
        this.disabled = false;
        this.closeModal();
      } catch (error) {
        console.log("error", error);
        // this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },

    async HandlerSyncErp(country) {
      try {
        let erps;
        if (country == "Argentina") {
          erps = "contabilium";
        } else {
          erps = "contabilium_cl";
        }

        this.loading = true;
        const request = {
          product_id: this.data.id,
          erps: [erps]
        };
        const response = await this.$store.dispatch(
          "products/SYNC_PRODUCTS",
          request
        );
        console.log("response", response);

        this.$snotify.success("Sincronizado con éxito.", "¡Hecho!");
        // this.closeModal();
      } catch (error) {
        this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },
    eventdialog() {
      this.activeAlert = false;
    },

    HandleVolumen() {
      this.data.volume =
        (this.data.length * this.data.width * this.data.height) / 1000000;
    },

    HandlerComercialDesignation() {
      console.log("**");
      const brand = this.dataBrand.find(
        index => index.id == this.data.brand_id
      );
      const text = `${brand.name} ${this.data.short_name}`;
      this.data.name = text.toUpperCase();
    },

    async addImagePublication() {
      try {
        this.loadingAdd = true;
        const request = new FormData();
        this.images_publications.forEach(file =>
          request.append("images_publications[]", file)
        );

        const response = await this.$store.dispatch(
          "products/ADD_PRODUCT_IMAGE",
          { data: request, id: this.dataUpdate.id }
        );

        const images = JSON.parse(response.data.data.images);

        const newImage = images[images.length - 1];

        this.dataUpdate.images.push(newImage);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAdd = false;
      }
    },

    async HandlerSaveImage(evt) {
      try {
        this.updateImage = true;
        const request = new FormData();
        request.append("images_publications[]", evt.target.files[0]);
        request.append("url_image", this.positionImage.url);
        request.append("publication_id", this.dataUpdate.id);

        const requestData = {
          publication_id: this.dataUpdate.id,
          data: request
        };
        const response = await this.$store.dispatch(
          "publications/UPLOAD_IMAGE",
          requestData
        );

        const newImage = JSON.parse(response.data.data.images)[
          this.positionImage.index
        ];

        this.dataUpdate.images.splice(this.positionImage.index, 1, newImage);

        this.$snotify.success("Imagen Cargada", "Exitos!");
      } catch (error) {
        console.log(error);
      } finally {
        this.updateImage = false;
      }
    },

    HandlerShowImage(item, index) {
      this.positionImage = { url: item, index: index };
      this.$refs.inputUpload.click();
    },

    async deleteImagePublication(image) {
      try {
        this.loadingAdd = true;
        const images = this.data.images;
        const deleteImage = images.indexOf(image);

        const request = {
          data: {
            url_image: image
          },
          id: this.data.id,
          position_delete: deleteImage,
          id_product: this.data.id
        };

        await this.$store.dispatch("products/DELETE_PRODUCT_IMAGE", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAdd = false;
      }
    },

    initializeCategoryData(categories) {
      if (categories) {
        const newCategory = {
          name: categories.name,
          id: categories.id,
          specifications_values: categories.specifications_values.map(el => {
            const specification = {
              id: el.specification.id,
              name: el.specification.name,
              measuring_unit: el.specification.measuring_unit,
              value: el.value,
              valueId: el.id
            };
            return specification;
          })
        };
        return newCategory;
      } else {
        return { name: "", id: null, specifications_values: [] };
      }
    },

    async HandlerGetCategories() {
      try {
        this.loadingCategories = true;
        const request = {
          paginate: false
        };
        await this.$store.dispatch("products/GET_CATEGORIES", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingCategories = false;
      }
    },

    showSwitch(index) {
      if (
        this.categoryData?.specifications_values[index]?.measuring_unit ==
        "SI/NO"
      ) {
        return true;
      }
      return false;
    },

    selectCategory(categoryId) {
      const newCategory = this.getCategories.find(
        elem => elem.id == categoryId
      );
      const newSpecificationsValues = newCategory.specifications.map(elem => {
        const specification = {
          id: elem.id,
          name: elem.name,
          measuring_unit: elem.measuring_unit,
          value: null,
          valueId: null
        };
        return specification;
      });

      this.categoryData.name = newCategory.name;
      this.categoryData.id = newCategory.id;
      this.categoryData.specifications_values = newSpecificationsValues;
    },

    addCategory() {
      this.categoryData = { name: "", id: null, specifications_values: [] };
    },

    deleteCategory() {
      this.categoryData = { name: "", id: null, specifications_values: [] };
    }
  }
};
</script>
<style>
.subtitle-specification {
  color: #706dac;
  font-size: 16px;
  line-height: 24px;
  margin-left: 12px;
}
.btn-add {
  color: #2196f3;
  text-decoration: underline;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
}
.btn-add-box {
  margin-top: -22px;
  margin-bottom: 14px;
  z-index: 2;
}
.delete-box {
  display: flex;
  align-items: start;
  justify-content: flex-start;
  padding-top: 13px !important;
}
</style>
