var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"small":"","fab":"","color":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('v-row',{staticClass:"justify-between align-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"mb-5",staticStyle:{"word-break":"normal"}},[_c('span',{class:[
              _vm.isMobile && _vm.isDark
                ? 'white--text text-light mb-5 title-text text-capitalize'
                : 'mb-5 title-text text-light text-capitalize' ]},[_vm._v(" "+_vm._s(_vm.getDepositName)+" ")]),_c('small',[_vm._v("Deposito donde salen los envios")])]),_c('v-btn',{staticClass:"accent shadow-4 px-8",attrs:{"large":"","rounded":""},on:{"click":function($event){$event.stopPropagation();return _vm.HandlerAddData({})}}},[_vm._v("Añadir duración")]),(_vm.activeAddData)?_c('register-component',{attrs:{"active":_vm.activeAddData,"data":_vm.dataAdd,"action":_vm.actionAdd,"country":_vm.dataCountry},on:{"dialog:change":_vm.HandlerResponse}}):_vm._e()],1),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card',{staticClass:"shadow-1 mt-5"},[_c('v-data-table',{attrs:{"loading":false,"headers":_vm.headers,"items":_vm.getDeposits,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.HandlerEditItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.name",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.active",fn:function(ref){
            var item = ref.item;
return [(_vm.loadingStatus == false)?_c('v-chip',{attrs:{"color":"transparent","small":""},on:{"click":function($event){return _vm.HandlerChangeStatus(item)}}},[_c('v-icon',{attrs:{"left":"","small":"","color":item.active === true ? 'success' : 'error'}},[_vm._v("mdi-radiobox-marked")]),_vm._v(" "+_vm._s(item.active === true ? "Habilitado" : "Inactivo")+" ")],1):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})]}},{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.created_at)))])]}}],null,true)})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-pagination',{attrs:{"length":_vm.getPaginate.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }