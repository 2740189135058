<template>
  <section>
    <v-row class="my-5 mb-5" v-if="!showEditProduct">
      <v-col cols="12" md="3">
        <v-select
          :items="dataCountry"
          item-text="name"
          item-value="id"
          v-model="country_id"
          menu-props="auto"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Pais"
          single-line
          hide-details
          @change="HandlerGetProduct()"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-autocomplete
          :items="dataBrand"
          item-text="name"
          item-value="id"
          v-model="brand_id"
          menu-props="auto"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Marca"
          single-line
          hide-details
          @change="HandlerGetProduct()"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="3">
        <v-select
          :items="groups"
          v-model="group"
          menu-props="auto"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Grupo"
          single-line
          hide-details
          @change="HandlerGetProduct()"
        ></v-select>
      </v-col>

      <v-col cols="12" md="3">
        <v-text-field
          v-model="search"
          label="Nombre / SKU"
          filled
          rounded
          dense
          class="rounded-small"
          single-line
          hide-details
          @keyup.enter="HandlerGetProduct()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="end" v-if="!showEditProduct">
      <v-col class="d-flex justify-end">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :loading="loadingSync"
              fab
              small
              color="primary"
              @click="handlerSync()"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-sync</v-icon>
            </v-btn>
          </template>
          <span>sincronizar stock</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <div class="mt-5" v-if="!showEditProduct">
      <v-card v-if="!loadingData" class="shadow-1 mt-5">
        <v-data-table
          class="mt-10"
          :loading="false"
          :headers="this.country_id !== 1 ? headers : argentinaHeaders"
          :items="productsData"
          :search="search"
          hide-default-footer
          disable-pagination
          fixed-header
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.products_erp[0].base_price`]="{ item }">
            <v-edit-dialog
              :return-value.sync="item.products_erp[0].base_price"
              @save="save(item)"
            >
              <span v-if="item.products_erp[0].base_price > 0">
                {{ item.products_erp[0].base_price | currencyValue }}
              </span>
              <span v-else> {{ 0 | currencyValue }} </span>
              <template v-slot:input>
                <!-- {{item.products_erp[0].base_price}} -->
                <v-text-field
                  type="number"
                  v-model="item.products_erp[0].base_price"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>

            <v-progress-circular
              v-if="loadingUpdate"
              size="300"
              class="d-flex justify-center my-5"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>

          <template
            v-slot:[`item.products_erp[0].availability_date`]="{ item }"
          >
            <span @click="showAvailability_date(item)" style="cursor: pointer">
              {{
                item.products_erp[0].availability_date !== null
                  ? item.products_erp[0].availability_date
                  : 0
              }}
            </span>

            <v-progress-circular
              v-if="loadingUpdate"
              size="300"
              class="d-flex justify-center my-5"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>

          <template v-slot:[`item.cost`]="{ item }">
            {{ item.cost | currencyValue }}
          </template>
          <template v-slot:[`item.products_erp[0].replacement_cost`]="{ item }">
            {{ item.products_erp[0].replacement_cost | currencyValue }}
          </template>

          <template v-slot:[`item.action`]="{ item }" v-if="country_id === 3">
            <v-btn
              fab
              @click="editProduct(item)"
              x-small
              color="primary"
              class="ml-1"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          size="300"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="paginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <v-card v-if="showEditProduct" color="transparent" class="elevation-0">
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="
            () => {
              showEditProduct = false;
            }
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          @click="HandlerUpdateProductCost()"
          :loading="loading"
          :disabled="!fieldsValidations()"
        >
          <p class="mb-0">Guardar</p>
        </v-btn>
      </div>
      <div class=" pt-3">
        <v-card class="px-3 py-3">
          <v-row>
            <v-col cols="12">
              <div class="font-weight-medium primary--text ml-3">
                Datos del producto
                <span class="text-uppercase">({{ dataClient.name }})</span>
              </div>
              <v-divider class="mb-2"></v-divider>
              <div class="d-flex">
                <v-col cols="2">
                  <v-text-field
                    label="SKU"
                    filled
                    rounded
                    dense
                    class="rounded-small lessSpace"
                    v-model="dataClient.products_erp[0].sku"
                    disabled
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Costo"
                    filled
                    rounded
                    dense
                    class="rounded-small lessSpace"
                    v-model="dataClient.products_erp[0].cost"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-card>

    <v-dialog
      v-if="activeSelectDays"
      v-model="activeSelectDays"
      persistent
      scrollable
      max-width="350"
    >
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-card>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            rounded
            height="6"
          ></v-progress-linear>

          <v-card-title class="pa-0 shadow-1">
            <v-img
              :src="require('@/assets/illustrations/bg_modal_card.svg')"
              cover
              position="left bottom"
              class="align-center"
              height="65px"
            >
              <v-card-title class="font-weight-medium primary--text">
                <!-- {{ action == 0 ? "Nuevo" : "Editar" }}  -->
                Seleccionar disponibilidad
              </v-card-title>
            </v-img>
          </v-card-title>

          <vuescroll
            :ops="{
              bar: { background: '#bacfcec0', keepShow: true }
            }"
          >
            <v-card-text style="max-height: 350px">
              <!-- {{data}} -->
              <v-col cols="12">
                <v-date-picker
                  v-model="dates"
                  no-title
                  locale="es-us"
                ></v-date-picker> </v-col
            ></v-card-text>
          </vuescroll>

          <v-divider></v-divider>

          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                text
                rounded
                class="mr-1"
                :color="hover ? 'error' : 'grey'"
                @click="activeSelectDays = false"
              >
                Cancelar
              </v-btn>
            </v-hover>

            <v-hover v-slot:default="{ hover }">
              <v-btn
                rounded
                :loading="loading"
                :disabled="loading && dates == null"
                color="success"
                :class="hover ? 'shadow-2' : 'shadow-4'"
                class="px-8"
                @click="passes(save)"
              >
                Actualizar
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </section>
</template>

<script>
import vuescroll from "vuescroll";
export default {
  components: {
    vuescroll
  },
  data() {
    return {
      menu2: false,
      loadingData: false,
      status_card: true,
      deposits: "",
      loadingBtn: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      productsData: [],
      paginate: {},
      headers: [
        {
          text: "Sku",
          value: "products_erp[0].sku",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Marca",
          value: "brand.name",
          sortable: false,
          align: "left",
          class: "primary--text"
        },
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text"
        },
        {
          text: "Grupo",
          align: "left",
          sortable: false,
          value: "group",
          class: "primary--text"
        },
        {
          text: "Costo",
          align: "right",
          sortable: false,
          value: "products_erp[0].cost",
          class: "primary--text"
        },

        {
          text: "PVP Base",
          align: "right",
          sortable: false,
          value: "products_erp[0].base_price",
          class: "primary--text"
        },
        {
          text: "Fecha disponibilidad",
          align: "right",
          sortable: false,
          value: "products_erp[0].availability_date",
          class: "primary--text"
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "action",
          class: "primary--text"
        }
      ],
      argentinaHeaders: [
        {
          text: "Sku",
          value: "products_erp[0].sku",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Marca",
          value: "brand.name",
          sortable: false,
          align: "left",
          class: "primary--text"
        },
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text"
        },
        {
          text: "Grupo",
          align: "left",
          sortable: false,
          value: "group",
          class: "primary--text"
        },
        {
          text: "Costo",
          align: "right",
          sortable: false,
          value: "products_erp[0].cost",
          class: "primary--text"
        },
        {
          text: "Costo de reposición",
          align: "right",
          sortable: false,
          value: "products_erp[0].replacement_cost",
          class: "primary--text"
        },
        {
          text: "PVP Base",
          align: "right",
          sortable: false,
          value: "products_erp[0].base_price",
          class: "primary--text"
        },
        {
          text: "Fecha disponibilidad",
          align: "right",
          sortable: false,
          value: "products_erp[0].availability_date",
          class: "primary--text"
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "action",
          class: "primary--text"
        }
      ],

      price: 0,
      loadingUpdate: false,
      dataCountry: [],
      groups: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      sku: "",
      country_id: 1,
      brand_id: "",
      group: "",
      dataBrand: [],
      availability_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      activeSelectDays: false,
      dates: null,
      loading: false,
      actualPrice: {},
      loadingSync: false,
      showEditProduct: false
    };
  },

  created() {
    this.HandlerGetProduct();
    this.HandlerGetCountry();
    this.HandlerGetBrand();
  },

  watch: {
    page(val) {
      this.HandlerGetProduct(val);
    }
  },

  filters: {
    currencyValue(value) {
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(value);
      return AMOUNT_FORMAT;
    }
  },

  methods: {
    async HandlerGetProduct(page = 1) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const request = {
          paginate: true,
          page: page,
          per_page: 50,
          country_id: this.country_id,
          search: this.search,
          group: this.group,
          brand_id: this.brand_id
        };
        // console.log("request", request);
        const response = await this.$store.dispatch(
          "products/PRODUCT_PRICE",
          request
        );
        // console.log("response***", response.data);
        this.productsData = response.data.data.data;
        // console.log("products", this.productsData);

        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    async HandlerGetBrand() {
      try {
        const request = {
          // page: page || 1,
          // per_page: 1000,
          // paginate: true,
          // name: "",
          store_id: ""
        };
        const response = await this.$store.dispatch(
          "products/GET_BRAND",
          request
        );
        this.dataBrand = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        this.dataCountry = response.data.data;
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async save(item = {}) {
      const { products_erp } = item;
      const { base_price, replacement_cost, cost } = products_erp[0];
      console.log(item);

      if (base_price < (replacement_cost || cost)) {
        this.$snotify.error("Precio base debe ser mayor al costo", "¡Error!");
        return;
      }
      try {
        this.loadingUpdate = true;
        const request = {
          product_id:
            Object.keys(item).length == 0 ? this.actualPrice.id : item.id,

          product_erp_id:
            Object.keys(item).length == 0
              ? this.actualPrice.products_erp[0].id
              : item.products_erp[0].id,
          base_price:
            this.price == 0
              ? Object.keys(item).length == 0
                ? this.actualPrice.products_erp[0].base_price
                : item.products_erp[0].base_price
              : this.price,
          availability_date:
            Object.keys(item).length == 0
              ? this.actualPrice.products_erp[0].availability_date
              : item.products_erp[0].availability_date
        };

        await this.$store.dispatch("products/UPDATE_PRICE_PRODUCT", request);

        const index = this.productsData.findIndex(
          val => val.id == this.actualPrice.id
        );
        if (index > -1) {
          if (this.group != "") {
            this.productsData[index].group = this.group;
          }
          this.productsData[
            index
          ].products_erp[0].availability_date = this.dates;
        }
        this.activeSelectDays = false;
        this.actualPrice = {};
        this.group = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingUpdate = false;
      }
    },

    showAvailability_date(item) {
      if (item.products_erp[0].availability_date != null) {
        this.dates = item.products_erp[0].availability_date;
      } else {
        this.dates = null;
      }
      this.actualPrice = { ...item };
      this.activeSelectDays = true;
    },

    selectGroup(item, val) {
      this.actualPrice = { ...item };
      this.group = val;
      this.save();
    },

    async handlerSync() {
      try {
        this.loadingSync = true;
        const response = await this.$store.dispatch(
          "products/SYNC_PRICE_PRODUCT"
        );
        this.$snotify.success("Se ha Realizado la consulta", "Exitos!");
        console.log(response);
      } catch (error) {
        this.$snotify.error("Ha ocurrido un error con la consulta", "Error!");
        console.log(error);
      } finally {
        this.loadingSync = false;
      }
    },

    editProduct(item) {
      this.showEditProduct = true;
      this.dataClient = { ...item };
    },

    fieldsValidations() {
      if (!this.dataClient.products_erp[0].cost) {
        return false;
      }

      return true;
    },

    async HandlerUpdateProductCost() {
      if (!this.fieldsValidations()) {
        return;
      }

      this.loading = true;
      try {
        const productErp = this.dataClient.products_erp[0];
        const request = {
          id: productErp.id,
          cost: productErp.cost
        };
        await this.$store.dispatch("products/UPDATE_PRODUCT_ERP", request);
        this.showEditProduct = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
