<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-select
          label="Estatus"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="status"
          :items="data_status"
          item-text="name"
          item-value="status"
          @change="HandlerGetShowRoom()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="12">
        <v-card class="shadow-5">
          <v-data-table
            :headers="headers"
            :items="dataShowroom"
            hide-default-footer
            :items-per-page="-1"
            class="elevation-0"
          >
            <template v-slot:[`item.first_name`]="{ item }">
              <span class="text-capitalize">
                {{ item.first_name }} {{ item.last_name }}
              </span>
            </template>
            <!-- <template v-slot:[`item.total_amount`]="{ item }">
              {{ item.total_amount | currency }}
            </template> -->
            <!-- <template v-slot:[`item.buyer.doc_number`]="{ item }">
              {{ item.buyer.doc_type }}: {{ item.buyer.doc_number }}
            </template> -->
            <template v-slot:[`item.date`]="{ item }">
              <span class="text-uppercase">{{ item.date | date }}</span>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div v-if="item.visited == null">
                <v-btn
                  @click="HandlerStatusChange(item, true)"
                  class="accent shadow-4"
                  rounded
                >
                  Visitado
                </v-btn>
                <v-btn @click="HandlerStatusChange(item, false)" rounded icon>
                  <v-icon>mdi-thumb-down</v-icon>
                </v-btn>
              </div>
              <div v-else>
                <span
                  v-if="item.visited"
                  class="green--text darken--text"
                  style="font-weight: 500"
                  >Visitado</span
                >
                <span v-else class="red--text darken--text">Cancelado</span>
              </div>
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex justify-end mt-3">
          <v-pagination
            v-if="Object.keys(paginate).length > 0"
            :length="paginate.lastPage"
            v-model="page"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data() {
    return {
      status: "",
      data_status: [
        {
          name: "Pendiente",
          status: "",
        },
        {
          name: "Visitado",
          status: true,
        },
        {
          name: "Cancelado",
          status: false,
        },
      ],
      page: 1,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "first_name",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Telefono",
          align: "start",
          sortable: false,
          value: "phone",
        },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "date",
        },

        { text: "Accion", value: "action" },
      ],

      categoryName: "",
      dataSubCategory: [],
      dataUpdate: [],
      dataShowroom: [],
      paginate: {},
      loadingFilePhoto: false,
      filePhoto: [],
      store_id: 7,
      data_stores: [],
      search_operation_number: "",
      search: "",
    };
  },

  watch: {
    page(val) {
      this.HandlerGetShowRoom(val);
    },
  },

  created() {
    this.HandlerGetShowRoom();
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  filters: {
    date(val) {
      return val;
    },
  },

  methods: {
    async HandlerGetShowRoom(page) {
      try {
        const request = {
          page: page || 1,
          per_page: 20,
          paginate: true,
          status: this.status,
          store_id: this.store_id,
          search_operation_number: this.search_operation_number,
          search: this.search,
          country_id: 1,
        };
        const response = await this.$store.dispatch(
          "orders/GET_SHOWROOM",
          request
        );

        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataShowroom = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerStatusChange(item, status) {
      try {
        this.loadingStatus = true;

        const request = {
          id: item.id,
          visited: status,
        };
        await this.$store.dispatch("orders/UPDATE_SHOWROOM", request);
        this.$snotify.success("Cargado con exitos", "Exitos");

        this.HandlerGetShowRoom();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingStatus = false;
      }
    },

    async HandlerShowVoucher(item) {
      window.open(item.payment[0].voucher_url);
    },
  },
};
</script>

<style></style>
