<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto" v-if="!showRegister">
          <v-btn
            @click="
              () => {
                showRegister = true;
                actionData = {};
                actionData = 0;
                dataUpdate = {};
              }
            "
            class="accent shadow-4 px-8"
            large
            rounded
          >
            Agregar Stock a Deposito
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-4 justify-space-between">
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            label="Producto / SKU"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="search_product"
            @keyup.enter="HandlerGetTransactionWP(page)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <div class="d-flex justify-end">
            <v-btn icon class="accent" @click="loadFile = true">
              <v-icon color="white">mdi-file-excel</v-icon>
            </v-btn>
            <v-btn icon class="primary ml-5" @click="HandlerDownload()">
              <v-icon color="white">mdi-download</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <Register
        @dialog:change="HandlerResponse"
        :dataUpdateShip="dataUpdate"
        :actionData="actionData"
        v-if="showRegister"
      />
    </v-container>
    <v-container v-if="showRegister == false">
      <v-row>
        <!-- <v-col cols="12">
          <h4>Mercaderia de mexico</h4>
        </v-col> -->
        <v-col cols="12">
          <v-card flat class="shadow-1 mt-5">
            <v-data-table
              :headers="headers"
              :items="dataTransaction"
              :items-per-page="-1"
              hide-default-footer
              disable-pagination
              class="elevation-1"
            >
              <template v-slot:[`item.price`]="{ item }">
                {{
                  item.products_erp[0].base_price == null
                    ? 0
                    : item.products_erp[0].base_price
                }}
              </template>
              <template v-slot:[`item.products_erp`]="{ item }">
                {{ item.products_erp[0].sku }}
              </template>
              <template v-slot:[`item.product_warehouse`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.product_warehouse[0].current_stock"
                  @save="save(item)"
                >
                  <span v-if="item.product_warehouse[0].current_stock > 0">
                    {{ item.product_warehouse[0].current_stock }}
                  </span>
                  <span v-else> {{ 0 }} </span>
                  <template v-slot:input>
                    <v-text-field
                      type="number"
                      v-model="item.product_warehouse[0].current_stock"
                      label="Edit"
                      single-line
                    ></v-text-field>
                  </template>
                </v-edit-dialog>

                <v-progress-circular
                  v-if="loadingUpdate"
                  size="300"
                  class="d-flex justify-center my-5"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-btn icon @click="edit(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="6" md="4">
          <div>
            <v-pagination
              v-model="page"
              :length="pagination.lastPage"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="loadFile" persistent scrollable max-width="500">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-card>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            rounded
            height="6"
          ></v-progress-linear>

          <v-card-title class="pa-0 shadow-1">
            <v-img
              :src="require('@/assets/illustrations/bg_modal_card.svg')"
              cover
              position="left bottom"
              class="align-center"
              height="65px"
            >
              <v-card-title class="font-weight-medium primary--text">
                Cargar archivo
              </v-card-title>
            </v-img>
          </v-card-title>
          <v-card-text style="max-height: 350px">
            <ValidationProvider name="archivo" v-slot="{ errors }">
              <v-file-input
                v-model="file"
                filled
                rounded
                class="rounded-small"
                dense
                label="Elige tu archivo"
                prepend-icon
                prepend-inner-icon="mdi-file-excel"
                :error-messages="errors"
              ></v-file-input>
            </ValidationProvider>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                text
                rounded
                class="mr-1"
                :color="hover ? 'error' : 'grey'"
                @click="loadFile = false"
              >
                Cancelar
              </v-btn>
            </v-hover>

            <v-hover v-slot:default="{ hover }">
              <v-btn
                rounded
                :loading="uploadFile"
                :disabled="uploadFile"
                color="success"
                :class="hover ? 'shadow-2' : 'shadow-4'"
                class="px-8"
                @click="passes(HandlerImportFile)"
              >
                Guardar
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </section>
</template>

<script>
import Register from "./Register";
import Swal from "sweetalert2";
// import moment from "moment";

export default {
  components: {
    Register,
  },
  data() {
    return {
      showRegister: false,
      dataUpdate: {},
      actionData: 0,

      headers: [
        {
          text: "Poducto",
          sortable: false,
          value: "name",
        },
        {
          text: "Precio",
          sortable: false,
          value: "price",
        },
        {
          text: "Sku",
          sortable: false,
          value: "products_erp",
        },
        // { text: "Proveedor", value: "provisioner.name" },
        // { text: "Deposito", value: "warehouse.name" },
        { text: "Stock Total", value: "product_warehouse" },
        { text: "Acciones", value: "action" },
      ],

      dataTransaction: [],
      page: 1,
      pagination: {
        lastPage: 1,
        page: 1,
        perPage: 10,
        total: 1,
      },

      loading: false,
      uploadFile: false,
      loadFile: false,
      file: [],
      search_product: "",
      partner_id: 3,
      warehouse_id: 22,
      loadingUpdate: false,
    };
  },

  created() {
    this.HandlerGetTransactionWP();
  },

  watch: {
    page(val) {
      this.HandlerGetTransactionWP(val);
    },
  },

  methods: {
    HandlerResponse(value) {
      switch (value) {
        case 1:
          this.dataUpdate = {};
          break;
        case 2:
          this.showRegister = false;
          break;
        case 0:
          this.showRegister = false;
          this.HandlerGetTransactionWP();
          break;
      }
    },

    async HandlerDownload() {
      try {
        const response = await fetch(
          "https://ventas.flextd.com/api/product_warehouse/export_xlsx",
          {
            method: "POST",
            headers: new Headers({
              Authorization: "Bearer " + localStorage.getItem('token'),
            }),
          }
        );

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "filename.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetTransactionWP(page = 1) {
      try {
        const request = {
          paginate: true,
          page: page,
          per_page: 10,
          pagination: true,
          search_product: this.search_product,
        };
        const response = await this.$store.dispatch(
          "products/GET_TRANSACTION_PROVISIONER_WAREHOUSE",
          request
        );
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataTransaction = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    edit(item) {
      console.log(item);
      this.showRegister = true;
      this.dataUpdate = { ...item };
      this.actionData = 1;
    },

    async HandlerImportFile() {
      try {
        this.showLoader = true;
        this.uploadFile = true;
        const request = new FormData();
        request.append("file", this.file);
        request.append("partner_id", this.partner_id);
        request.append("warehouse_id", this.warehouse_id);
        await this.$store.dispatch("products/UPLOAD_SHIP_DEPOSIT", request);
        this.HandlerGetTransactionWP();
        this.$snotify.info("Has cargado exitosamente el archivo", "Exitos");
      } catch (error) {
        /**
         * agrupar ERROR
         */
        const errors_array_group = error.response.data.data.rows.reduce(
          (acc, out) => {
            const key = out.errors[0].message;
            if (acc?.[key]) {
              acc[key] = [
                ...acc[key],
                { sku: out.row.sku, row: out.row_number },
              ];
            } else {
              acc[key] = [{ sku: out.row.sku, row: out.row_number }];
            }
            return acc;
          },
          {}
        );

        /**
         * Armar HTML
         */

        let message = `<ul style='font-size:14px'>`;

        for (const key in errors_array_group) {
          if (Object.hasOwnProperty.call(errors_array_group, key)) {
            message = `${message}<li>${key} <br> <table>
                      <tr>
                        <th width='30%'>Sku</th>
                        <th width='30%'>Fila</th>
                      </tr>`;

            for (const product of errors_array_group[key]) {
              message = `${message}<tr>
                <td>${product.sku} </td>
                <td>${product.row}</td>
              </tr>`;
            }

            message = `${message}</table></li>`;
          }
        }

        message = `${message}</ul>`;

        Swal.fire({
          title: "Oops...",
          icon: "error",
          html: message,
        });
      } finally {
        this.showLoader = false;
        this.uploadFile = false;
        this.loadFile = false;
      }
    },

    async save(item) {
      try {
        this.loadingUpdate = true;
        const request = {
          product_id: item.id,
          partner_id: this.partner_id,
          warehouse_id: this.warehouse_id,
          current_stock: item.product_warehouse[0].current_stock,
        };

        await this.$store.dispatch("products/UPDATE_CURRENT_STOCK", request);

        this.HandlerGetTransactionWP();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingUpdate = false;
      }
    },
  },
};
</script>

<style>
.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: left !important;
  word-wrap: break-word;
}
</style>