<template>
  <div v-if="statusShip == 4">

    <h1>statusShip</h1>
    {{ item.user_btn_prepeared }}
    {{ item.user_btn_delivered }}
    {{ item.user_btn_in_transit }}
    <move-toltip-component
      :order="order"
      v-if="
        item.user_btn_prepeared != null ||
        item.user_btn_delivered != null ||
        item.user_btn_in_transit != null
      "
    />
  </div>
</template>

<script>
import MoveToltip from "./Utils/moveToltip";
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
    statusShip: {
      type: Number,
      required: true,
      default: 4,
    },
  },
  components: {
    "move-toltip-component": MoveToltip,
  },
};
</script>

<style>
</style>