<template>
  <ValidationObserver ref="obs" v-slot="{}">
    <v-container class="mt-n0">
      <div
        class="font-weight-medium primary--text mb-5"
        style="font-size: 26px"
      >
        <!-- {{ actionData == 0 ? "Crear Tienda" : "Editar Tienda" }} -->
        Crear / Editar Publicacion
      </div>
      <v-card :loading="loading" class="mb-4">
        <v-card-title class="pa-0 shadow-1">
          <v-img
            :src="require('@/assets/illustrations/bg_modal_card.svg')"
            cover
            position="left bottom"
            class="align-center"
            height="65px"
          >
            <v-card-title class="font-weight-medium primary--text">
              Datos de la publicación
            </v-card-title>
          </v-img>
        </v-card-title>
        <v-card-text>
          <!-- {{dataUpdate}} -->
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider
                name="tienda"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :items="data_stores"
                  item-text="name"
                  item-value="id"
                  v-model="selectedStore"
                  label="Tienda"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  no-data-text="No hay datos para mostrar"
                  :error-messages="errors"
                >
                </v-select>
              </ValidationProvider>
            </v-col>
            <v-col cols="20" sm="6" md="9">
              <v-autocomplete
                :disabled="action == 1"
                :items="data_products"
                item-text="value.label"
                item-value="value"
                v-model="selectedProduct"
                label="Productos"
                no-filter
                clearable
                filled
                rounded
                dense
                class="rounded-small"
                :loading="searchProductLoading"
                :search-input.sync="searchProduct"
                no-data-text="Coloque dos caracteres (sku)"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <ValidationProvider
                name="titulo"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Titulo"
                  filled
                  dense
                  rounded
                  class="rounded-small"
                  v-model="title"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <ValidationProvider
                name="Pvp neto"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  readonly
                  label="Pvp neto"
                  v-model="pvpWithoutIva"
                  disabled
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider
                name="Costo"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Costo"
                  readonly
                  disabled
                  v-model="cost"
                  type="number"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <ValidationProvider
                name="Descuento sobre el PVP"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Descuento sobre el PVP"
                  v-model="pvpDiscount"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <ValidationProvider
                name="Variable PVC"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Variable PVC"
                  v-model="pvcVariable"
                  type="number"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="3">
              <ValidationProvider
                name="Disponibilidad Alta"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdate.high_availability"
                  type="number"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Disponibilidad Alta"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="3">
              <ValidationProvider
                name="Disponibilidad Baja"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdate.low_availability"
                  type="number"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Disponibilidad Baja"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-col>
            <v-row>
              <v-col>
                <v-row style="align-items: center">
                  <v-checkbox
                    v-model="calcWithCost"
                    label="Cálculo PVC a partir del costo"
                    class="mt-0"
                  ></v-checkbox>
                  <v-col
                    :style="{
                      opacity: calcWithCost ? 1 : 0
                    }"
                    class="ml-5"
                    cols="4"
                  >
                    <ValidationProvider
                      name="Margen"
                      :rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        v-model="sale_margin_percentage"
                        type="number"
                        filled
                        rounded
                        dense
                        class="rounded-small"
                        label="Margen"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                v-model="calcWithPvp"
                label="Cálculo PVC a partir del PVP"
                class="mt-0"
              ></v-checkbox>
            </v-row>
          </v-col>
          <v-row class="mt-2">
            <v-col cols="12" md="3">
              <template>
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <form @submit.prevent="agregarArray">
                    <ValidationProvider v-slot="{ errors }" name="Cantidad">
                      <v-text-field
                        v-model="quantity"
                        type="number"
                        filled
                        rounded
                        dense
                        :disabled="loading_new_step"
                        class="rounded-small"
                        :error-messages="errors"
                        label="Cantidad"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors }" name="Descuento">
                      <v-text-field
                        v-model="discount"
                        type="number"
                        filled
                        rounded
                        dense
                        class="rounded-small"
                        :disabled="loading_new_step"
                        :error-messages="errors"
                        label="Descuento"
                      ></v-text-field>
                    </ValidationProvider>
                    <v-spacer></v-spacer>
                    <v-hover v-slot:default="{ hover }">
                      <v-btn
                        type="submit"
                        :disabled="invalid"
                        :loading="loading_new_step"
                        rounded
                        class="mr-1"
                        color="success"
                        :class="hover ? 'shadow-2' : 'shadow-4'"
                      >
                        Agregar
                      </v-btn>
                    </v-hover>
                  </form>
                </validation-observer>
              </template>
            </v-col>
            <v-col cols="12" md="6">
              <template>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Cantidad</th>
                        <th class="text-left">Descuento</th>
                        <th class="text-left">PVC neto</th>
                        <!-- <th class="text-left">PVC Sin Iva</th> -->
                        <th class="text-left">Accion</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in data_array" :key="item.id">
                        <td>{{ item.quantity }}</td>
                        <td>{{ item.discount }}</td>
                        <td>{{ item.net_pvc | currency }}</td>
                        <!-- <td>{{ PVCWithOutIva(item) | currency }}</td> -->

                        <td>
                          <template>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  @click="removeArray(item.id)"
                                  icon
                                  color="white"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="#6D6D6D">
                                    mdi-delete-forever
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            text
            rounded
            class="mr-1"
            :color="hover ? 'error' : 'grey'"
            @click="closeModal({}, false)"
          >
            Cancelar
          </v-btn>
        </v-hover>
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :loading="loading"
            rounded
            class="mr-1"
            color="success"
            :class="hover ? 'shadow-2' : 'shadow-4'"
            @click="HandlerSendData"
          >
            Guardar
          </v-btn>
        </v-hover>
      </v-card-actions>
    </v-container>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    dataUpdate: {
      type: Object,
      default: () => {},
      required: false
    },
    action: {
      type: Number,
      default: 0,
      required: false
    },
    country: {
      type: Number,
      default: 2,
      required: false
    }
  },
  data() {
    return {
      calcWithPvp: true,
      calcWithCost: false,
      dataCountry: [],
      data_products: [],
      data_array: [],
      data_stores: [
        { name: "B2B chile", id: 2 },
        { name: "B2B argentina ", id: 1 }
      ],
      searchStore: "",
      quantity: "",
      title: "",
      discount: "",
      erpId: 0,
      pvp: 0,
      pvpWithoutIva: 0,
      cost: 0,
      sale_margin_percentage: 0,
      pvpDiscount: 0,
      pvcVariable: 0,
      pvc: "",
      iva: "",
      pvpBase: "",
      country_value: "",
      name: "",
      country_id: "",
      file: [],
      showRegister: false,
      page: 1,
      searchProduct: "",
      searchProductLoading: false,
      register: true,
      store_id: "",
      high_availability: 0,
      low_availability: 0,
      b2b_active: 0,
      selectedStore: this.country,
      selectedProduct: null,
      loading: false,
      loading_new_step: false
    };
  },

  computed: {
    GET_USER() {
      return this.$store.getters["auth/GET_USER"];
    },

    getPvp() {
      if (this.dataUpdate.pvp_percentage == undefined) {
        return 0;
      } else {
        return (
          this.pvpBase + this.pvpBase * (this.dataUpdate.pvp_percentage / 100)

          // parseFloat(this.pvpBase * this.dataUpdate.pvp_percentage) +
          // parseFloat(this.pvpBase)
        );
      }
    },

    getPvc() {
      if (
        this.pvpBase == undefined ||
        this.dataUpdate.iva == undefined ||
        this.dataUpdate.pvc_variable == undefined
      ) {
        return 0;
      } else {
        return parseFloat(
          parseFloat(this.pvpBase / (this.dataUpdate.iva / 100 + 1)) /
            this.dataUpdate.pvc_variable
        );
      }
    }
  },

  created() {
    if (this.action == 1) {
      // this.arrayImages = this.dataUpdate.images;
      const {
        product,
        purchase_options,
        keywords,
        pvp_discount_percentage,
        use_cost_for_base_price,
        cost,
        sale_margin_percentage
      } = this.dataUpdate;
      this.calcWithCost = use_cost_for_base_price;
      this.calcWithPvp = !use_cost_for_base_price;
      const product_erp = product.products_erp[0];
      this.pvcVariable = Number(this.dataUpdate.pvc_variable);
      this.selectedProduct = {
        ...product_erp,
        cost,
        productErpId: product_erp.id,
        id: product.id,
        label: product_erp.sku + " - " + product.name
      };
      this.data_products = [{ value: this.selectedProduct }];
      this.pvpDiscount = pvp_discount_percentage;
      this.cost = cost;
      this.sale_margin_percentage = sale_margin_percentage;
      this.data_array = purchase_options.map(
        ({ discount_percentage, quantity, id, net_pvc }) => ({
          id,
          discount: discount_percentage,
          quantity,
          net_pvc
        })
      );
      this.title = keywords;
      // this.data_array = this.dataUpdate.amount_discount;
    } else {
      this.HandlerGetProducts();
    }
  },

  watch: {
    calcWithCost: {
      handler(value) {
        if (value) {
          this.calcWithPvp = false;
          if (this.action === 1) {
            this.HandlerSendData();
          }
        }
      }
    },
    calcWithPvp: {
      handler(value) {
        if (value) {
          this.calcWithCost = false;
          if (this.action === 1) {
            this.HandlerSendData();
          }
        }
      }
    },
    searchProduct: {
      handler() {
        if (!this.selectedProduct) {
          this.HandlerGetProducts();
        }
      }
    },

    selectedStore: {
      handler() {
        this.data_products = [];
        this.selectedProduct = null;
        this.pvpBase = 0;
        this.pvpWithoutIva = 0;
        this.pvc = 0;
      }
    },

    pvcVariable: {
      handler(value) {
        this.pvc = Number(
          (this.pvpWithoutIva > 0
            ? this.pvpWithoutIva / (1 + Number(value) / 100)
            : 0
          ).toFixed(2)
        );
      }
    },

    selectedProduct: {
      handler() {
        const { base_price, tax, cost } = this.selectedProduct;
        const net_pvp = Number(((base_price * 100) / (tax + 100)).toFixed(2));

        this.pvpBase = base_price;
        this.pvpWithoutIva = net_pvp;
        this.cost = cost;
        this.pvc = Number(
          (this.pvpBase > 0
            ? net_pvp / (1 + Number(this.pvcVariable) / 100)
            : 0
          ).toFixed(2)
        );
      }
    }
  },

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    PVC(item) {
      return this.getPvc - (this.getPvc * item.discount) / 100;
    },

    PVCWithOutIva(item) {
      return this.PVC(item) / (this.dataUpdate.iva / 100 + 1);
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: ""
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data;

        this.data_stores = response.data.data
          .filter(index => {
            return index.b2b_active == true;
          })
          .map(({ id, country_id, name }) => ({
            id,
            value: { country_id, name }
          }));

        if (this.action == 1) {
          this.HandlerGetProducts();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;

        if (this.action == 1) {
          this.HandlerGetProducts();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async agregarArray() {
      if (!this.selectedProduct) {
        this.$snotify.error("Debes seleccionar un producto");
        return;
      }
      if (this.data_array.some(elem => elem.quantity == this.quantity)) {
        this.$snotify.error("Ya existe una descuento para esta cantidad.");
        return;
      }
      if (this.calcWithCost && this.sale_margin_percentage === undefined) {
        this.$snotify.error("Debes ingresar el margen sobre el costo.");
        return;
      }
      if (!this.discount || !this.quantity) {
        this.$snotify.error("Debes ingresar el descuento y la cantidad.");
        return;
      }
      let stepId;
      if (this.action === 1) {
        this.loading_new_step = true;
        const payload = {
          b2b_publication_id: this.dataUpdate.id,
          discount_percentage: Number(this.discount),
          quantity: Number(this.quantity)
        };
        const response = await this.$store.dispatch(
          "b2bPublication/CREATE_PURCHASE_OPTION",
          payload
        );

        this.loading_new_step = false;
        if (!response?.data?.id) {
          this.$snotify.error("Error inesperado");
          return;
        }
        stepId = response.data.id;
      }
      const { data } = await this.$store.dispatch(
        "b2bPublication/CALC_NET_PVC",
        {
          product_erp_id: this.selectedProduct.productErpId,
          iva: this.selectedProduct.tax,
          pvp_discount_percentage: this.pvpDiscount,
          pvc_variation: this.pvcVariable,
          option_discount_percentage: this.discount,
          use_cost_for_base_price: this.calcWithCost,
          sale_margin_percentage: this.sale_margin_percentage
        }
      );
      this.data_array.push({
        id: stepId || this.data_array.length + 1,
        quantity: this.quantity,
        discount: this.discount,
        net_pvc: data?.net_pvc
      });
      this.quantity = "";
      this.discount = "";
    },

    async removeArray(item) {
      let index = this.data_array.findIndex(el => el.id == item);
      this.data_array.splice(index, 1);
      if (this.action === 1) {
        await this.$store.dispatch(
          "b2bPublication/DELETE_PURCHASE_OPTION",
          item
        );
      }
    },

    // METODO PARA BUSCAR LOS PRODUCTOS
    async HandlerGetProducts() {
      if (
        (this.searchProduct !== null && this.searchProduct.length > 1) ||
        this.dataUpdate.product_id > 0
      ) {
        try {
          this.searchProductLoading = true;
          const request = {
            page: 1,
            per_page: 20,
            paginate: false,
            active: true,
            b2b_active: true,
            sku:
              this.searchProduct !== ""
                ? this.searchProduct
                : this.dataUpdate.product.name,

            countryId: this.selectedStore
          };

          if (this.dataUpdate.product_id > 0) {
            request.product_id = this.dataUpdate.product_id;
          }

          const response = await this.$store.dispatch(
            "traceability/GET_PRODUCT_TO_ENTRY",
            request
          );

          this.data_products = response.data.data.map(val => {
            const item = val.products_erp[0];
            return {
              value: {
                ...item,
                productErpId: item.id,
                id: val.id,
                label: item.sku + " - " + val.name
              }
            };
          });
        } catch (error) {
          console.log(error);
        } finally {
          this.searchProductLoading = false;
        }
      } else if (this.searchProduct.length == 0) {
        this.data_products = [];
      }
    },

    // METODO PARA CREAR/ ACTUALIZAR PUBLICACIONES
    async HandlerSendData() {
      try {
        if (await this.$refs.obs.validate()) {
          if (!this.data_array.length) {
            this.$snotify.error(
              "Debe agregar al menos una cantidad con su respectivo descuento"
            );
            return;
          }
          this.loading = true;
          // Json PARA crear/actualizar
          const purchase_options = this.data_array.map(
            ({ discount, quantity }) => ({
              discount_percentage: Number(discount),
              quantity: Number(quantity)
            })
          );
          const request = {
            product_id: this.selectedProduct.id,
            country_id: this.selectedStore,
            store_id: this.selectedStore === 2 ? 6 : 5,
            keywords: this.title,
            high_availability: this.dataUpdate.high_availability,
            low_availability: this.dataUpdate.low_availability,
            pvc_variable: this.pvcVariable,
            pvp_discount_percentage: this.pvpDiscount,
            use_cost_for_base_price: this.calcWithCost,
            sale_margin_percentage: this.sale_margin_percentage,
            purchase_options
          };

          let response = {};
          if (this.action == 0) {
            response = await this.$store.dispatch(
              "b2bPublication/REGISTER_PUBLICATION_B2B",
              { ...request, is_active: true }
            );
            this.$snotify.success("Publicacion Registrada.", "¡Exitos!");
            this.closeModal(response.data, 0);
          } else {
            const { purchase_options, ...payload } = request;
            console.log(purchase_options);

            response = await this.$store.dispatch(
              "b2bPublication/UPDATE_PUBLICATION_B2B",
              {
                request: payload,
                id: this.dataUpdate.id
              }
            );
            if (response.data) {
              this.data_array = response.data.purchase_options.map(
                ({ discount_percentage, quantity, id, net_pvc }) => ({
                  id,
                  discount: discount_percentage,
                  quantity,
                  net_pvc
                })
              );
            }
            this.$snotify.success("Publicacion Actualizada.", "¡Exitos!");
          }
        } else {
          this.showErrorCategories = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getSelectStore() {
      // console.log("getSelectStore", this.dataUpdate.store_id);
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
