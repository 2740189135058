<template>
  <v-container fluid class="mt-5">
    <v-row>
      <v-col md="12" v-if="!showRegisterPublication">
        <v-row class="justify-between align-center mb-5">
          <v-col cols="12" md="3" class="py-0">
            <v-btn
              rounded
              dark
              block
              large
              @click="HandlerAddPublication"
              class="accent shadow-4 px-8"
            >
              Añadir Publicación
            </v-btn>
          </v-col>
          <v-col cols="12" md="3" class="py-0">
            <v-btn
              rounded
              dark
              block
              large
              @click="HandlerOrderPublication"
              class="accent shadow-4 px-8"
            >
              Ordenar Publicaciones
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="!orderPublication">
          <v-col cols="12" sm="6" md="3">
            <v-select
              label="Tienda"
              filled
              rounded
              dense
              class="rounded-small"
              v-model="store_id"
              :items="data_stores"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Sku"
              filled
              rounded
              dense
              class="rounded-small"
              v-model="sku"
              @keyup.enter="HandlerGetPublication()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              label="Nombre"
              filled
              rounded
              dense
              class="rounded-small"
              v-model="product_name"
              @keyup.enter="HandlerGetPublication()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-select
              label="Activo"
              :items="['Si', 'No']"
              filled
              rounded
              dense
              class="rounded-small"
              v-model="is_active"
              @change="HandlerGetPublication()"
            ></v-select>
          </v-col>
        </v-row>
        <v-card
          class="shadow-1 mt-5"
          v-if="!showRegisterPublication && !orderPublication"
        >
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="dataPublication"
            disable-pagination
            hide-default-footer
            class="elevation-0"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerEdit(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar Publicación</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerShowDelete(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-delete-forever
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar publicación</span>
              </v-tooltip>

              <v-tooltip bottom v-if="!item.is_open_box">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="handlerCreateOpenBox(item.id)"
                    icon
                    color="green"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :loading="loadingCreateOpenBox === item.id"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-package-variant
                    </v-icon>
                  </v-btn>
                </template>
                <span>Crear publicación Open Box</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.is_active == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerStatus(item.id)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-thumb-up
                    </v-icon>
                  </v-btn>
                </template>
                <span>Activar</span>
              </v-tooltip>
              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerStatus(item.id)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-thumb-down
                    </v-icon>
                  </v-btn>
                </template>
                <span>Desactivar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.pvp`]="{ item }">
              {{ item.price.pvp | currencyValue }}
            </template>
            <template v-slot:[`item.pvp18`]="{ item }">
              {{ item.price.pvp_18_installments | currencyValue }}
            </template>
            <template v-slot:[`item.pvpTrans`]="{ item }">
              {{ item.price.pvp_transfer | currencyValue }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="12" v-if="showRegisterPublication">
        <section id="register">
          <register-publication
            :dataUpdate="dataUpdate"
            :action="action"
            @dialog:change="HandlerResponse"
          />
        </section>
      </v-col>
      <v-col cols="12" md="12">
        <v-card
          :loading="loading"
          v-if="!showRegisterPublication && orderPublication"
        >
          <v-card-text>
            <div class="font-weight-bold">
              Selecciona tu tienda para continuar
            </div>
            <div style="width: 50%" class="mt-2 d-flex">
              <v-select
                label="Tienda"
                filled
                rounded
                dense
                class="rounded-small"
                v-model="store_id"
                :items="data_stores"
                item-text="name"
                item-value="id"
              ></v-select>
              <v-btn
                @click="HandlerCloseOrder"
                class="ml-4 align-self-start"
                rounded
                color="primary"
              >
                Cancelar
              </v-btn>
            </div>

            <draggable :list="dataPublication" @change="HandlerNewOrder">
              <div v-for="(item, index) in dataPublication" :key="index">
                <v-row justify="center">
                  <v-col cols="8" class="d-flex justify-center">
                    <div
                      style="background-color: #f8f8f8; width: 100%"
                      class="d-flex"
                      v-if="item.images != null"
                    >
                      <v-avatar tile size="75" class="ml-5 my-3">
                        <v-img
                          contain
                          :src="item.images[0]"
                          :lazy-src="item.images[0]"
                        ></v-img>
                      </v-avatar>
                      <span
                        class="
                          align-self-center
                          ml-5
                          font-weight-bold
                          text-capitalize
                        "
                      >
                        {{ item.keywords }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4" v-if="!showRegisterPublication">
        <div>
          <v-pagination
            v-model="pagination.page"
            :length="pagination.lastPage"
            circle
            @input="HandlerGetPublication(pagination.page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDelete" max-width="600">
      <v-card :loading="loadingDelete">
        <v-card-text>
          <h3 class="pt-4">Deseas eliminar esta publicación</h3>
          <p>Al aceptar estas de acuerdo en eliminar la publicación</p>
          <v-img
            v-if="publicationData == null"
            src="@/assets/img/no_image.jpg"
          ></v-img>
          <v-img
            v-else
            height="200"
            widht="auto"
            contain
            :src="publicationData.images[0]"
            :lazy-src="publicationData.images[0]"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loadingDelete"
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="showDelete = !showDelete"
            >
              Cancelar
            </v-btn>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loadingDelete"
              rounded
              class="mr-1"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              @click="HandlerDelete"
            >
              Continuar
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RegisterPublication from "../Publications/Utils/registerPublications";
import draggable from "vuedraggable";
// import Loader_component from "../Utils/loader_component.vue";

export default {
  components: {
    "register-publication": RegisterPublication,
    draggable
    // "loader-component": Loader_component,
  },
  data() {
    return {
      loading: false,
      loadingCreateOpenBox: false,
      country_id: "",
      name: "",
      symbol: "",
      code: "",
      dataUpdate: {},
      action: 0,
      headers: [
        { text: "Tienda", sortable: false, value: "store.name" },
        {
          text: "Producto",
          sortable: false,
          align: "left",
          value: "product.name"
        },
        { text: "Nombre", sortable: false, align: "left", value: "keywords" },
        {
          text: "Sku",
          sortable: false,
          align: "left",
          value: "product.products_erp[0].sku"
        },
        { text: "PVP", sortable: false, align: "right", value: "pvp" },
        {
          text: "PVP 18 Cuotas",
          sortable: false,
          align: "right",
          value: "pvp18"
        },
        {
          text: "PVP Transfer",
          sortable: false,
          align: "right",
          value: "pvpTrans"
        },
        { text: "Acciones", sortable: false, align: "right", value: "action" }
      ],
      dataCountry: [],
      dataPublication: [],
      showRegisterPublication: false,
      page: 1,
      pagination: {},

      showDelete: false,
      loadingDelete: false,
      publicationData: null,
      orderPublication: false,
      store_id: 1,
      data_stores: [],

      myArray: [],

      sku: "",
      product_name: "",
      is_active: "Si"
    };
  },

  created() {
    if (this.GET_USER.email == "publicación@mail.com") {
      this.store_id = 1;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const store = urlParams.get("store");
    const sku = urlParams.get("sku");
    const active = urlParams.get("active");
    if (active !== null) {
      this.is_active = active === "true" ? "Si" : "No";
    }
    this.sku = sku || "";

    this.HandlerGetCountry();
    this.HandlerGetStore(store || 3);
    this.HandlerGetPublication(1);
  },

  watch: {
    store_id(val) {
      if (val != null) {
        this.HandlerGetPublication(this.page);
      }
    },
    orderPublication() {
      this.pagination = {};
      this.HandlerGetPublication(this.page);
    }
  },

  filters: {
    currencyValue(value) {
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency"
      }).format(value);
      return AMOUNT_FORMAT;
    }
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    GET_USER() {
      return this.$store.getters["auth/GET_USER"];
    }
  },

  methods: {
    async HandlerGetCountry() {
      try {
        this.loding = true;
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loding = false;
      }
    },

    async HandlerCopy(item) {
      await this.$copyText(item);
      this.$snotify.success(`Url copiado`, "Exitos");
    },

    async HandlerGetStore(defaultStore) {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: ""
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data;

        if (this.GET_USER.roles.filter(index => index.id == 10).length > 0) {
          this.data_stores = response.data.data.filter(index => {
            return index.id == 1;
          });
          this.store_id = 1;
        } else {
          this.data_stores = response.data.data
            .filter(index => {
              return index.b2b_active == false;
            })
            .filter(index => {
              return index.id !== 2;
            });
        }
        this.store_id = Number(defaultStore);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetPublication(page = 1) {
      try {
        this.loading = true;
        const request = {
          page: page,
          per_page: this.orderPublication ? 1000 : 20,
          name: "",
          paginate: true,
          store_id: this.store_id == null ? "" : this.store_id,
          sku: this.sku,
          sort_position: "asc",
          product_name: this.product_name,
          is_active: this.is_active == "Si" ? true : false
        };

        const response = await this.$store.dispatch(
          "publications/GET_PUBLICATIONS",
          request
        );

        this.dataPublication = response.data.data.data;
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerRegisterCountry() {
      try {
        const request = {
          country_id: this.country_id,
          name: this.name,
          symbol: this.symbol,
          code: this.code
        };

        const response = await this.$store.dispatch(
          "country/REGISTER_CURRENCY",
          request
        );

        const indexupdate = this.dataPublication
          .map(element => element.id)
          .indexOf(response.data.data[0].id);

        if (indexupdate > -1) {
          this.dataPublication.splice(indexupdate, 1, response.data.data[0]);
        }

        this.showRegisterPublication = !this.showRegisterPublication;
        this.$snotify.success("Se ha registrado correctamente", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },

    HandlerEdit(item) {
      this.dataUpdate = { ...item };
      this.action = 1;
      this.showRegisterPublication = !this.showRegisterPublication;
    },

    HandlerShowDelete(publication) {
      this.showDelete = true;
      this.publicationData = publication;
    },

    async HandlerDelete() {
      try {
        this.loadingDelete = true;
        const request = { id: this.publicationData.id };
        await this.$store.dispatch("publications/DELETE_PUBLICATION", request);
        this.HandlerGetPublication(this.page, true);
        this.$snotify.success("Se ha eliminado correctamente", "Exitos!");
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error", "Error!");
      } finally {
        this.showDelete = false;
        this.loadingDelete = false;
      }
    },

    async handlerCreateOpenBox(id) {
      try {
        this.loadingCreateOpenBox = id;
        await this.$store.dispatch("publications/CREATE_OPEN_BOX_PUB", id);
        this.$snotify.success(
          "La publicación se ha creado existosamente",
          "Exitos!"
        );
        this.loadingCreateOpenBox = false;
        this.loading = true;
        await this.HandlerGetPublication();
      } catch (error) {
        this.loadingCreateOpenBox = null;
        this.$snotify.error(error.response.data.message);
      } finally {
        this.loading = false;
      }
    },

    HandlerOrderPublication() {
      this.orderPublication = true;
      this.is_active = "Si";
      this.showRegisterPublication = false;
      this.dataPublication = [];
    },

    HandlerCloseOrder() {
      this.orderPublication = false;
      this.showRegisterPublication = false;
      this.store_id = null;
      this.HandlerGetPublication(this.page, true);
    },

    async HandlerNewOrder() {
      try {
        const idPublication = this.dataPublication.map(pb => pb.id);
        const request = {
          publication_ids: [...idPublication]
        };

        const response = await this.$store.dispatch(
          "publications/PUBLICATIONS_ORDER",
          request
        );
        console.log(response);
        this.$snotify.success("Publicaciones ordenadas", "Exitos");
      } catch (error) {
        console.log(error);
      }
    },

    test() {
      console.log("********** test");
    },

    HandlerAddPublication() {
      this.dataUpdate = {
        price: {
          percentage_18_installments: 1.12,
          percentage_transfer: 0.92
        },
        store: {}
      };
      this.showRegisterPublication = !this.showRegisterPublication;
      this.action = 0;
    },

    HandlerResponse() {
      // this.page = this.page;
      this.showRegisterPublication = false;
      // if (action === 0) {
      this.HandlerGetPublication(this.page, true);
      // }
    },

    async HandlerStatus(publication_id) {
      try {
        const request = { publication_id: publication_id };
        console.log("request", request);
        await this.$store.dispatch(
          "publications/UPDATED_STATUS_PUBLICATIONS",
          request
        );
        this.$snotify.success("Has actualizado la publicación", "Exitos!");
        this.HandlerGetPublication(1, false);
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style></style>
