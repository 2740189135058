<template>
  <section>
    <v-container fluid class="mt-5" v-if="!showTransferForm">
      <v-row style="justify-content: space-between; align-items: center;">
        <v-row class="mb-0">
          <v-col cols="12" sm="12" md="12">
            <v-row style="margin:0">
              <v-btn
                class="accent shadow-4 px-8 mr-4"
                large
                v-if="country_id === 3"
                rounded
                style="margin: 12px 0 12px 0;"
                @click.stop="HandlerOpenTransferForm(true, 'transfer')"
              >
                Transferir inventario
              </v-btn>
              <v-btn
                class="accent shadow-4 px-8"
                large
                rounded
                style="margin: 12px 0 12px 0;"
                @click.stop="HandlerOpenTransferForm(true, 'entry')"
              >
                Ingresar inventario
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-0">
          <v-col cols="12" sm="5" md="4">
            <v-select
              :items="dataCountry"
              item-text="name"
              item-value="id"
              v-model="country_id"
              style="max-width:400px"
              menu-props="auto"
              filled
              rounded
              dense
              class="rounded-small"
              name="country"
              label="Pais"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex flex-row" cols="12" sm="5" md="4">
            <v-select
              :items="getDeposits"
              item-text="name"
              :item-value="item => item"
              v-model="deposits"
              style="max-width:400px"
              menu-props="auto"
              filled
              rounded
              dense
              class="rounded-small"
              name="deposit"
              label="Deposito"
              single-line
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <div v-if="!status_card && !showTransferForm">
      <v-card v-if="!loadingProducts && !loadingDeposits" class="shadow-1 mt-5">
        <v-data-table
          :loading="false"
          :headers="headers"
          :items="productsData"
          :search="search"
          hide-default-footer
          disable-pagination
          fixed-header
          height="530"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.products_erp`]="{ item }">
            {{ item.products_erp[0].sku }}
          </template>
          <template
            v-slot:[`item.product_warehouse[0].mercadolibre_inventory_id`]="{
              item
            }"
          >
            <div
              v-if="isLoadingItem(item.product_warehouse[0].id)"
              class="center-loader"
            >
              <v-progress-circular
                size="25"
                class="d-flex justify-center my-5"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
            <v-edit-dialog
              :return-value.sync="
                item.product_warehouse[0].mercadolibre_inventory_id
              "
              @save="onChangeInventoryId(item)"
              v-else
            >
              <span v-if="item.product_warehouse[0].mercadolibre_inventory_id">
                {{ item.product_warehouse[0].mercadolibre_inventory_id }}
              </span>
              <div v-else class="add-id-btn">
                Asignar ID de inventario
              </div>
              <template v-slot:input>
                <v-text-field
                  v-model="item.product_warehouse[0].mercadolibre_inventory_id"
                  label="Edit"
                  single-line
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.product_warehouse`]="{ item }">
            {{ item.product_warehouse[0].current_stock }}
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at.split(" ")[0] }}
          </template>
        </v-data-table>
      </v-card>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          size="300"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="paginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <div
      class="d-flex justify-center mt-6"
      v-if="status_card && !showTransferForm"
    >
      <v-card width="700">
        <v-alert class="mb-0" outlined type="warning" prominent border="left">
          ¡No hay inventario en este depósito!
        </v-alert>
      </v-card>
    </div>

    <transfer-component
      v-if="showTransferForm"
      :active="showTransferForm"
      :closeModal="HandlerOpenTransferForm"
      :deposits="getDeposits.map(({ id, name }) => ({ id, name }))"
      :originDeposit="deposits"
      :isTransfer="isTransfer"
      :country="country_id"
    />
  </section>
</template>

<script>
import transferForm from "./transferForm.vue";

export default {
  components: {
    "transfer-component": transferForm
  },
  data() {
    return {
      getDeposits: [],
      loadingProducts: false,
      loadingDeposits: false,
      status_card: false,
      showTransferForm: false,
      deposits: null,
      search: "",
      depositType: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      country_id: "",
      dataCountry: [],
      allDeposits: [],
      isChangingPageInternally: false,
      productsData: [],
      paginate: {},
      isTransfer: true,
      loadingItem: null,
      headers: [],
      headersDefault: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text"
        },
        {
          text: "Sku",
          value: "products_erp",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Código de barras",
          value: "barcode",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Costo (USD)",
          align: "center",
          value: "cost",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Stock actual",
          align: "center",
          value: "product_warehouse",
          sortable: false,
          class: "primary--text"
        },
        {
          text: "Fechas de entrega",
          align: "center",
          value: "created_at",
          sortable: false,
          class: "primary--text"
        }
      ]
    };
  },

  created() {
    this.HandlerGetDeposits();
    this.HandlerGetCountry();
  },

  watch: {
    page: {
      handler(val) {
        if (!this.isChangingPageInternally) {
          this.HandlerGetProduct(val);
        }
      },
      deep: true
    },

    deposits(actualDeposits) {
      if (actualDeposits.id === 27) {
        this.headers = [
          ...this.headersDefault.slice(0, 3),
          {
            text: "ID de inventario ML",
            align: "center",
            value: "product_warehouse[0].mercadolibre_inventory_id",
            sortable: false,
            class: "primary--text"
          },
          ...this.headersDefault.slice(3)
        ];
      } else {
        this.headers = this.headersDefault;
      }
      this.HandlerGetProductID(actualDeposits);
    },
    country_id(val) {
      this.HandlerChangeDeposits(val);
    }
  },

  computed: {
    getPaginate() {
      return this.$store.getters["deposits/GET_PAGINATE"];
    }
  },

  methods: {
    HandlerOpenTransferForm(value, type) {
      if (type == "entry") {
        this.isTransfer = false;
        this.deposits = null;
      } else {
        this.isTransfer = true;
      }
      this.showTransferForm = value;
    },

    async onChangeInventoryId(item = {}) {
      this.loadingItem = item.product_warehouse[0].id;
      try {
        const request = {
          product_in_warehouse_id: item.product_warehouse[0].id,
          data: {
            mercadolibre_inventory_id:
              item.product_warehouse[0].mercadolibre_inventory_id
          }
        };
        await this.$store.dispatch(
          "deposits/UPDATE_PRODUCT_WAREHOUSE",
          request
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingItem = null;
      }
    },

    isLoadingItem(id) {
      return this.loadingItem === id;
    },

    async HandlerGetDeposits(page) {
      try {
        this.loadingDeposits = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: false,
          country_id: this.country_id == undefined ? "" : this.country_id,
          state_id: this.state_id == undefined ? "" : this.state_id,
          location_id: this.location_id == undefined ? "" : this.location_id
        };
        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS",
          request
        );
        const depositsFromMexico = response.data.data.filter(
          elem => elem.country_id === 3
        );

        this.allDeposits = response.data.data;
        this.getDeposits = depositsFromMexico;
        this.deposits = depositsFromMexico[0];
        this.country_id = 3;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingDeposits = false;
      }
    },

    HandlerChangeDeposits(countryId) {
      const currentDeposits = this.allDeposits.filter(
        elem => elem.country_id === countryId
      );
      this.getDeposits = currentDeposits;
      this.deposits = currentDeposits[0];
      this.isChangingPageInternally = true;
      this.page = 1;
      this.$nextTick(() => {
        this.isChangingPageInternally = false;
      });
    },

    async HandlerGetProduct(page = 1) {
      try {
        this.loadingProducts = true;
        const request = {
          warehouse_id: this.deposits.id == undefined ? 5 : this.deposits.id,
          page: page,
          per_page: 50
        };
        const response = await this.$store.dispatch(
          "deposits/GET_PRODUCT_DEPOSITS",
          request
        );
        this.productsData = response.data.data.data.map(data => ({
          ...data,
          cost: data.product_warehouse[0].product_cost
        }));
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingProducts = false;
      }
    },

    async HandlerGetProductID(value) {
      try {
        this.loadingProducts = true;
        this.status_card = false;
        const request = {
          warehouse_id: value == undefined ? 22 : value.id,
          page: 1,
          per_page: 50
        };
        const response = await this.$store.dispatch(
          "deposits/GET_PRODUCT_DEPOSITS",
          request
        );
        this.productsData = response.data.data.data.map(data => ({
          ...data,
          cost: data.product_warehouse[0].product_cost
        }));
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };

        if (this.productsData.length > 0) {
          this.status_card = false;
        } else {
          this.status_card = true;
        }
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la petición", "Error!");
      } finally {
        this.loadingProducts = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        const countries = response.data.data.filter(elem => elem.id !== 4);
        this.dataCountry = countries;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
.add-id-btn {
  border-radius: 20px;
  background-color: #58b153;
  padding: 7px 10px;
  display: flex;
  font-size: 0.82rem;
  color: white;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  line-height: 0.9rem;
  width: max-content;
}
.center-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
  margin-bottom: -4px;
}
</style>
