<template>
  <ValidationObserver ref="obs" v-slot="{}">
    <section>
      <v-container fluid class="mt-5">
        <v-row class="justify-between align-center">
          <v-col cols="12" v-if="actionData === 0">
            <v-card>
              <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                rounded
                height="6"
              ></v-progress-linear>

              <v-card-title class="pa-0 shadow-1">
                <v-img
                  :src="require('@/assets/illustrations/bg_modal_card.svg')"
                  cover
                  position="left bottom"
                  class="align-center"
                  height="65px"
                >
                  <v-card-title class="font-weight-medium primary--text">
                    <!-- {{ action == 0 ? "Nuevo" : "Editar" }}  -->
                    Registrar
                  </v-card-title>
                </v-img>
              </v-card-title>

              <vuescroll
                :ops="{
                  bar: { background: '#bacfcec0', keepShow: true },
                }"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Pais"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="dataCountry"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Pais"
                          v-model="dataUpdateShip.country_id"
                          :error-messages="errors"
                          @change="HandlerCountryId"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Proveedor"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :disabled="dataUpdateShip.country_id == null"
                          :items="dataProvisioner"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Proveedor"
                          v-model="dataUpdateShip.origin_id"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Deposito"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :disabled="dataUpdateShip.country_id == null"
                          :items="dataWarehouse"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Deposito que recibe"
                          v-model="dataUpdateShip.finality_id"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Producto"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          :disabled="
                            dataUpdateShip.country_id == null ||
                            actionData === 1
                          "
                          :items="dataProducts"
                          item-text="name"
                          item-value="id"
                          v-model="dataUpdateShip.product_id"
                          small-chips
                          label="Productos"
                          no-filter
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          :search-input.sync="searchProduct"
                          no-data-text="Coloque dos caracteres (sku)"
                          @keyup="HandlerGetProducts()"
                          @change="HandlerGetProducts()"
                          :error-messages="errors"
                        >
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Estatus"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="statusItems"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Estatus"
                          v-model="dataUpdateShip.status_id"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Cantidad"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="dataUpdateShip.count"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Cantidad"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="status_card">
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-for="(product, i) of productsCard"
                      :key="i"
                    >
                      <v-card
                        class="elevation-0"
                        :class="
                          isDark == true ? 'bg-card-dark' : 'bg-card-light'
                        "
                      >
                        <v-card-title class="py-1">
                          <div class="d-flex justify-space-around align-start">
                            <v-row>
                              <v-col cols="1">
                                <v-avatar>
                                  <img
                                    :src="product.images"
                                    alt="product_img"
                                  />
                                </v-avatar>
                              </v-col>
                              <v-col cols="col-9 ml-8">
                                <v-list-item two-line class="py-0 px-0">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="font-caption"
                                    ></v-list-item-title>
                                    <v-list-item-title
                                      class="font-caption text-uppercase"
                                      >{{ product.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-caption">
                                      <span class=""
                                        >SKU: {{ product.sku }}</span
                                      >
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle class="font-caption">
                                      <span class=""
                                        >Deposito:
                                        {{ product.warehouse.name }}</span
                                      >
                                    </v-list-item-subtitle>

                                    <!-- <v-list-item-subtitle class="font-caption">
                                    Código de barras: {{product.barcode }}
                                  </v-list-item-subtitle> -->
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <v-col cols="col-2">
                                <v-list-item two-line class="py-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="font-caption"
                                      >Marca: {{ product.brand_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-caption">
                                      Cantidad: {{ product.count }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </vuescroll>

              <v-divider></v-divider>

              <v-card-actions class="mx-2">
                <v-spacer></v-spacer>
                <v-hover v-slot:default="{ hover1 }">
                  <v-btn
                    text
                    rounded
                    class="mr-1"
                    :color="hover1 ? 'error' : 'grey'"
                    @click="closeModal(0)"
                  >
                    Cancelar
                  </v-btn>
                </v-hover>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    :loading="loading"
                    rounded
                    class="mr-1"
                    color="success"
                    :class="hover ? 'shadow-2' : 'shadow-4'"
                    @click="HandlerRegister"
                  >
                    Guardar
                  </v-btn>
                </v-hover>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" v-else>
            <v-card>
              <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                rounded
                height="6"
              ></v-progress-linear>

              <v-card-title class="pa-0 shadow-1">
                <v-img
                  :src="require('@/assets/illustrations/bg_modal_card.svg')"
                  cover
                  position="left bottom"
                  class="align-center"
                  height="65px"
                >
                  <v-card-title class="font-weight-medium primary--text">
                    <!-- {{ action == 0 ? "Nuevo" : "Editar" }}  -->
                    Editar {{ dataUpdateShip.name }}
                  </v-card-title>
                </v-img>
              </v-card-title>

              <vuescroll
                :ops="{
                  bar: { background: '#bacfcec0', keepShow: true },
                }"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        disabled
                        v-model="dataUpdateShip.products_erp[0].country.name"
                        filled
                        rounded
                        dense
                        class="rounded-small"
                        label="Pais"
                        :error-messages="errors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        disabled
                        v-model="dataUpdateShip.products_erp[0].base_price"
                        filled
                        rounded
                        dense
                        class="rounded-small"
                        label="Precio Base"
                        :error-messages="errors"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        disabled
                        v-model="dataUpdateShip.products_erp[0].sku"
                        filled
                        rounded
                        dense
                        class="rounded-small"
                        label="SKU"
                        :error-messages="errors"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </vuescroll>
            </v-card>
            <v-card class="mt-5">
              <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                rounded
                height="6"
              ></v-progress-linear>
              <v-card-title class="font-weight-medium primary--text">
                Transacciones
              </v-card-title>

              <vuescroll
                :ops="{
                  bar: { background: '#bacfcec0', keepShow: true },
                }"
              >
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-for="(product, i) in dataUpdateShip.transaction"
                      :key="i"
                    >
                      <v-card
                        class="elevation-0"
                        :class="
                          isDark == true ? 'bg-card-dark' : 'bg-card-light'
                        "
                      >
                        <v-card-title class="py-1">
                          <div class="d-flex justify-space-around align-start">
                            <v-row>
                              <v-col cols="10" sm="10" md="10">
                                <v-list-item two-line class="py-0 px-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="font-caption"
                                      >Deposito:
                                      <span class="font-weight-normal">
                                        {{ product.warehouse.name }}
                                      </span>
                                    </v-list-item-title>
                                    <v-list-item-title class="font-caption"
                                      >Proveedor:
                                      <span class="font-weight-normal">
                                        {{ product.provisioner.name }}
                                      </span>
                                    </v-list-item-title>
                                    <v-list-item-title class="font-caption"
                                      >Tipo:
                                      <span class="font-weight-normal">
                                        {{ product.type_transaction.name }}
                                      </span>
                                    </v-list-item-title>

                                    <v-list-item-subtitle class="font-caption">
                                      <span class=""
                                        >Cantidad: {{ product.count }}</span
                                      >
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="font-caption">
                                      <span class=""
                                        >Fecha:
                                        {{ product.created_at | date }}</span
                                      >
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <!-- <v-col cols="2" sm="2" md="2">
                                <v-btn
                                  icon
                                  color="primary"
                                  @click="HandlerEditTransaction(product)"
                                >
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                              </v-col> -->
                            </v-row>
                          </div>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </vuescroll>

              <v-card-actions class="mx-2">
                <v-spacer></v-spacer>
                <v-hover v-slot:default="{ hover1 }">
                  <v-btn
                    text
                    rounded
                    class="mr-1"
                    :color="hover1 ? 'error' : 'grey'"
                    @click="closeModal(0)"
                  >
                    Cancelar
                  </v-btn>
                </v-hover>
              </v-card-actions>
            </v-card>
            <!-- <v-card class="mt-5">
              <vuescroll
                :ops="{
                  bar: { background: '#bacfcec0', keepShow: true },
                }"
              >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Pais"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="dataCountry"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Pais"
                          v-model="transaction.country_id"
                          :error-messages="errors"
                          @change="HandlerCountryId"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Proveedor"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :disabled="transaction.country_id == null"
                          :items="dataProvisioner"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Proveedor"
                          v-model="transaction.origin_id"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Proveedor"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :disabled="transaction.country_id == null"
                          :items="dataWarehouse"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Deposito que recibe"
                          v-model="transaction.finality_id"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Producto"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-autocomplete
                          :disabled="
                            transaction.country_id == null || actionData === 1
                          "
                          :items="dataProducts"
                          item-text="name"
                          item-value="id"
                          v-model="transaction.product_id"
                          small-chips
                          label="Productos"
                          no-filter
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          :search-input.sync="searchProduct"
                          no-data-text="Coloque dos caracteres (sku)"
                          @keyup="HandlerGetProducts()"
                          @change="HandlerGetProducts()"
                          :error-messages="errors"
                        >
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Estatus"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="statusItems"
                          item-text="name"
                          item-value="id"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Estatus"
                          v-model="transaction.status_id"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="4">
                      <ValidationProvider
                        name="Cantidad"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="transaction.count"
                          filled
                          rounded
                          dense
                          class="rounded-small"
                          label="Cantidad"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row v-if="status_card">
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                      v-for="(product, i) of productsCard"
                      :key="i"
                    >
                      <v-card
                        class="elevation-0"
                        :class="
                          isDark == true ? 'bg-card-dark' : 'bg-card-light'
                        "
                      >
                        <v-card-title class="py-1">
                          <div class="d-flex justify-space-around align-start">
                            <v-row>
                              <v-col cols="1">
                                <v-avatar>
                                  <img
                                    :src="product.images"
                                    alt="product_img"
                                  />
                                </v-avatar>
                              </v-col>
                              <v-col cols="col-9 ml-8">
                                <v-list-item two-line class="py-0 px-0">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      class="font-caption"
                                    ></v-list-item-title>
                                    <v-list-item-title
                                      class="font-caption text-uppercase"
                                      >{{ product.name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-caption">
                                      <span class=""
                                        >SKU: {{ product.sku }}</span
                                      >
                                    </v-list-item-subtitle>

                                    <v-list-item-subtitle class="font-caption">
                                      <span class=""
                                        >Deposito:
                                        {{ product.warehouse.name }}</span
                                      >
                                    </v-list-item-subtitle>

                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                              <v-col cols="col-2">
                                <v-list-item two-line class="py-0">
                                  <v-list-item-content>
                                    <v-list-item-title class="font-caption"
                                      >Marca: {{ product.brand_name }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-caption">
                                      Cantidad: {{ product.count }}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </vuescroll>

              <v-divider></v-divider>

              <v-card-actions class="mx-2">
                <v-spacer></v-spacer>
                <v-hover v-slot:default="{ hover1 }">
                  <v-btn
                    text
                    rounded
                    class="mr-1"
                    :color="hover1 ? 'error' : 'grey'"
                    @click="closeModal(0)"
                  >
                    Cancelar
                  </v-btn>
                </v-hover>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    :loading="loading"
                    rounded
                    class="mr-1"
                    color="success"
                    :class="hover ? 'shadow-2' : 'shadow-4'"
                    @click="HandlerRegister"
                  >
                    Guardar
                  </v-btn>
                </v-hover>
              </v-card-actions>
            </v-card> -->
          </v-col>
        </v-row>
      </v-container>
    </section>
  </ValidationObserver>
</template>

<script>
import moment from "moment";
import vuescroll from "vuescroll";
export default {
  props: {
    dataUpdateShip: {
      type: Object,
      default: () => {},
      required: false,
    },
    actionData: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  components: {
    vuescroll,
  },
  data() {
    return {
      loading: false,
      status_card: false,
      product_card: {},

      dataCountry: [],
      dataProvisioner: [],
      dataWarehouse: [],
      dataProducts: [],
      statusItems: [],
      productsCard: [],
      searchProduct: "",
      transaction: {},
    };
  },

  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },

  created() {
    this.HandlerGetCountry();
    this.HandletGetStatus();
    // if (this.actionData === 1) {
    //   this.HandlerGetProducts();
    //   this.HandlerGetDeposits();
    //   this.dataUpdateShip.country_id =
    //     this.dataUpdateShip?.warehouse?.country_id;
    //   this.searchProduct = this.dataUpdateShip?.product?.sku;
    // }
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    closeModal(action) {
      this.$emit("dialog:change", action);
    },

    HandlerCountryId() {
      this.HandlerGetProvisioner();
      this.HandlerGetDeposits();
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;

        // if (this.actionData === 1) {
        //   this.HandlerGetProvisioner();
        // }
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetProvisioner() {
      try {
        const request = {
          country_id:
            this.transaction.country_id ?? this.dataUpdateShip.country_id,
        };
        const response = await this.$store.dispatch(
          "country/GET_PROVISIONER",
          request
        );
        this.dataProvisioner = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetDeposits() {
      try {
        const request = {
          paginate: false,
          country_id:
            this.transaction.country_id ?? this.dataUpdateShip.country_id,
        };
        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS",
          request
        );
        this.dataWarehouse = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetProducts() {
      if (
        (this.searchProduct !== null && this.searchProduct.length > 1) ||
        this.transaction.product_id > 0
      ) {
        try {
          const request = {
            paginate: false,
            active: true,
            sku:
              this.searchProduct !== ""
                ? this.searchProduct
                : this.dataUpdateShip.product.name,

            country_id:
              this.transaction.country_id ?? this.dataUpdateShip.country_id,
          };

          // if (this.dataUpdate.product_id > 0) {
          //   request.product_id = this.dataUpdate.product_id;
          // }

          const response = await this.$store.dispatch(
            "products/GET_PRODUCTS",
            request
          );

          this.dataProducts = response.data.data;
          this.product_card = this.dataProducts[0];
        } catch (error) {
          console.log(error);
        }
      } else if (this.searchProduct.length == 0) {
        this.data_products = [];
      }
    },

    async HandletGetStatus() {
      try {
        const response = await this.$store.dispatch("status/GET_STATUS");
        this.statusItems = response.data;
      } catch (error) {
        console.log(error);
      }
    },

    HandlerAddCard() {
      if (this.actionData === 0) {
        this.productsCard.push({
          images: this.product_card?.images[0],
          name: this.product_card.name,
          brand_name: this.product_card.brand.name,
          sku: this.product_card.products_erp[0].sku,
          product_id: this.dataUpdateShip.product_id,
          origin_id: this.dataUpdateShip.origin_id,
          finality_id: this.dataUpdateShip.finality_id,
          type_transaction_id: 2,
          status_id: this.dataUpdateShip.status_id,
          count: parseInt(this.dataUpdateShip.count),
          warehouse: this.dataWarehouse.find((index) => {
            return index.id == this.dataUpdateShip.finality_id;
          }),
        });
      }
      this.status_card = true;

      this.$emit("dialog:change", 1);
      this.product_card = {};
      this.$refs.obs.reset();
    },

    async HandlerRegister() {
      try {
        if (this.actionData === 0) {
          this.HandlerAddCard();
          const request = {
            data: this.productsCard,
          };
          await this.$store.dispatch(
            "products/REGISTER_TRANSACTION_PROVISIONER_WAREHOUSE",
            request
          );
        } else {
          // this.HandlerAddCard();
          const request = {
            id: this.transaction.id,
            data: {
              count: this.transaction.count,
            },
          };
          await this.$store.dispatch(
            "products/UPDATED_TRANSACTION_PROVISIONER_WAREHOUSE",
            request
          );
        }
        this.closeModal(0);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerEditTransaction(product) {
      this.transaction = {
        country_id: product.provisioner.country_id,
        ...product,
      };
      this.HandlerCountryId();
      this.HandlerGetProducts();
    },
  },
};
</script>

<style>
.font-weight-normal {
  font-weight: 400;
}
</style>