<template>
  <section>
    <v-container v-if="showIndex">
      <v-row class="justify-between align-center mb-5">
        <v-col cols="auto">
          <v-btn
            class="accent shadow-4 px-8"
            large
            rounded
            @click="showIndex = false"
            >Añadir Proveedor</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            filled
            rounded
            dense
            class="rounded-small"
            label="Nombre / Codigo / CUIT / DNI"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" md="3" class="d-flex justify-end">
          <v-btn fab small class="mr-3">
            <v-icon color="accent">mdi-file-excel</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="shadow-1 mt-5">
            <v-data-table
              :loading="false"
              :headers="headers"
              :items="items"
              hide-default-footer
              disable-pagination
              fixed-header
              height="530"
              :page.sync="page"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <registerProvider v-if="!showIndex" />
    </v-container>
  </section>
</template>

<script>
import registerProvider from "./registerProvider";
export default {
  components: {
    registerProvider,
  },
  data() {
    return {
      showIndex: true,
      headers: [
        {
          text: "Razon social",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Código",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Ubicación",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Acciones",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
      ],
      items: [],
      page: 1,
    };
  },
};
</script>

<style>
</style>