<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
      >
        {{ action == 1 ? "Editar" : "Añadir" }} Categoría
      </div>
      <v-col cols="12" md="12">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5 px-2 py-2">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>
            <v-card-text>
              <v-form>
                <v-row dense justify="start">
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="nombre"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Nombre de categoría"
                        name="nombre"
                        type="text"
                        color="blue-grey"
                        :error-messages="errors"
                        v-model="categoryData.name"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" class="subtitle-box">
                    <h5 class="subtitle-label">
                      Especificaciones
                    </h5>
                  </v-col>
                  <v-col cols="12">                  
                    <v-row
                      dense
                      v-for="(specification, index) in categoryData.specifications"
                      :key="specification.id"
                      style="margin:0px;"
                    >
                      <v-col cols="12" sm="5">
                        <ValidationProvider
                          :name="`Nombre de especificación ${index + 1}`"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            required
                            label="Nombre"
                            filled
                            rounded
                            dense
                            class="rounded-small"
                            :name="`Nombre de especificación ${index + 1}`"
                            :value="categoryData.specifications[index].name"
                            @input="(elem)=>updateUnit(index, elem)"
                            :items="availableOptions[index]"
                            item-text="name"
                            item-value="name"
                            color="blue-grey"
                            :error-messages="errors"
                            :search-input.sync="searchText[index]"
                          >
                          <template #append-item>
                            <div class="btn-box-options">
                              <v-btn
                                rounded
                                color="primary"
                                @click="HandlerOpenModal(index)"
                                class="ml-4"
                                v-if="!loadingSpecifications"
                              >
                                Añadir especificación
                              </v-btn>
                              <v-progress-circular
                                  size="20"
                                  class="d-flex justify-center my-5"
                                  indeterminate
                                  color="primary"
                                  v-else
                                ></v-progress-circular>
                            </div>
                          </template>
                          <template #no-data>
                            <div></div>
                          </template>
                          </v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="5">
                        <ValidationProvider
                          :name="`Unidad de especificación ${index + 1}`"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            required
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            disabled
                            label="Unidad"
                            :name="`Unidad de especificación ${index + 1}`"
                            type="text"
                            color="blue-grey"
                            :error-messages="errors"
                            v-model="categoryData.specifications[index].measuring_unit"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="1" class="delete-box">
                        <button @click.prevent="() => deleteSpecification(index)">
                          <v-icon large>
                            mdi-delete
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="btn-add-box">
                    <button 
                      @click.prevent="addSpecification" 
                      :class="`btn-add`"
                    >
                      Agregar expecificación
                    </button>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  :disabled="loader"
                  @click="closeModal(null, false)"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loader"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerRegister)"
                >
                  {{ action == 1 ? "Editar" : "Añadir" }}
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
    <custom-modal
      v-if="showAddSpecificationModal"
      :active="showAddSpecificationModal"
      :action="HandlerAddSpecification"
      :closeModal="HandlerCloseModal"
      :disabled="!activeModalBtn"
      confirmBtn
      :title="'Añadir especificación'"
      max-width="800"
      :loader="loadingAddSpecification"
    >
      <v-form class="mt-4">
        <v-row dense justify="start"> 
          <v-row dense style="margin:0px;">
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="`Nombre de especificación`"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Nombre"
                  :name="`Nombre de especificación`"
                  type="text"
                  color="blue-grey"
                  :error-messages="errors"
                  v-model="AddSpecificationData.name"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6">
              <ValidationProvider
                :name="`Unidad de especificación`"
                rules="required"
                v-slot="{ errors }"
              >
                <v-select
                  :items="specifications"
                  item-text="title"
                  item-value="value"
                  required
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Unidad"
                  :name="`Unidad de especificación`"
                  color="blue-grey"
                  v-model="AddSpecificationData.measuring_unit"
                  :error-messages="errors"
                ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-row>
      </v-form>
    </custom-modal>
  </v-container>
</template>

<script>

import CustomModal from "../../CustomModal/customModal.vue";
import { unitSelector } from '../../Products/Utils/common.ts';

export default {
  components: {
    "custom-modal": CustomModal,
  },
  props: {
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: ()=>{},
    },
    loader: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      loadingAddSpecification: false,
      loadingSpecifications: false,
      searchText: [],
      categoryData: {
        name: this.data?.name || "",
        id: this.data?.id || null,
        specifications: 
        this.data?.specifications ?  
            JSON.parse(JSON.stringify(this.data.specifications)) 
          : [
            {
              name: "",
              measuring_unit: "",
              id: null,
            },
          ]
      },
      AddSpecificationData: {name: '', measuring_unit:''},
      showAddSpecificationModal: false,
      specifications: unitSelector,
    };
  },

  created(){
    this.HandlerGetSpecifications()
  },

  computed: {
    activeModalBtn() {
      return !!(this.AddSpecificationData.name && this.AddSpecificationData.measuring_unit);
    },

    getSpecifications() {
      return this.$store.getters["products/GET_SPECIFICATIONS"];
    },

    availableOptions() {
      const test = this.categoryData.specifications.map((spec, indexOption) => {
        const selectedOptions = this.categoryData.specifications
          .filter((_, i) => i !== indexOption)
          .map(spec => spec.name);

        return this.getSpecifications.filter(spec => !selectedOptions.includes(spec.name));
      });
      return test;
    },
  },

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerGetSpecifications(){
      try{
        this.loadingSpecifications = true;
        const request={
          paginate: false,
        }
        await this.$store.dispatch("products/GET_SPECIFICATIONS", request);
      }catch(error){
        console.log(error);
      } finally {
        this.loadingSpecifications = false;
      }
    },

    addSpecification() {
      const newSpecification = {
        name: "",
        measuring_unit: "",
        id: null,
      };
      this.categoryData.specifications.push(newSpecification);
    },

    deleteSpecification(index) {
      this.categoryData.specifications.splice(index, 1);
    },

    updateUnit(index, elem) {
      this.categoryData.specifications[index].name = elem;
      this.categoryData.specifications[index].measuring_unit = this.getSpecifications.find((el)=>el.name== elem)?.measuring_unit;
      this.categoryData.specifications[index].id = this.getSpecifications.find((el)=>el.name== elem)?.id;
    },

    HandlerOpenModal(index) {
      this.AddSpecificationData.name = this.searchText[index];
      this.indexAddSpecification = index;
      this.showAddSpecificationModal = true;
    },

    HandlerCloseModal() {
      this.showAddSpecificationModal = false;
      this.AddSpecificationData = {name: '', measuring_unit:''};
    },

    async HandlerAddSpecification(){
      try{
        this.loadingAddSpecification = true;
        const newSpecification = await this.$store.dispatch(
          "products/REGISTER_SPECIFICATIONS", this.AddSpecificationData
        );
        this.categoryData.specifications[this.indexAddSpecification] = newSpecification;
        this.showAddSpecificationModal = false;
        this.AddSpecificationData = {name: '', measuring_unit:''};
        this.$snotify.success(
          `Especificación añadida`,
          "¡Exitos!"
        );
        this.HandlerGetSpecifications();
      }catch(error){
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con el registro", "¡Error!");
      } finally {
        this.loadingAddSpecification = false;
      }
    },

    async HandlerRegister() {
      this.$emit("dialog:change", this.categoryData, this.action, this.data);
    },
  },
};
</script>

<style>
  .subtitle-label {
    padding: 0;
    margin: 0;
    color: #706dac;
    font-weight: 500 !important;
  }
  .subtitle-box {
    margin-top: -18px !important;
    margin-bottom: 4px !important;
  }
  .btn-add {
    color: #2196f3;
    text-decoration: underline;
    margin-right: 6px;
    font-size: 12px;
    font-weight: 600;
  }
  .btn-disabled{
    color: rgba(0, 0, 0, 0.3) !important;
  }
  .btn-add-box {
    margin-top: -22px;
    margin-bottom: 14px;
    z-index: 2;
  }
  .delete-box {
    padding-top: 13px !important;
  }
  .btn-box-options{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 6px;
  }
</style>