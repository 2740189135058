var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-5",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.getDeposits,"item-text":"name","item-value":function (item) { return item; },"menu-props":"auto","filled":"","rounded":"","dense":"","name":"ciudad","label":"Deposito","single-line":"","hide-details":""},on:{"change":_vm.HandlerGetProductID},model:{value:(_vm.deposits),callback:function ($$v) {_vm.deposits=$$v},expression:"deposits"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"loading":_vm.loadingBtn,"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.HandlerSyncContabillium()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}])},[_c('span',[_vm._v("Sincronizar Contabillium")])])],1)],1)],1),(!_vm.status_card)?_c('div',[(!_vm.loadingData)?_c('v-card',{staticClass:"shadow-1 mt-5"},[_c('v-data-table',{attrs:{"loading":false,"headers":_vm.headers,"items":_vm.productsData,"search":_vm.search,"hide-default-footer":"","disable-pagination":"","fixed-header":"","height":"530","page":_vm.page},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.products_erp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.products_erp[0].sku)+" ")]}},{key:"item.product_warehouse",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product_warehouse[0].current_stock)+" ")]}}],null,true)})],1):_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{staticClass:"d-flex justify-center my-5",attrs:{"size":"300","indeterminate":"","color":"primary"}})],1),_c('v-row',{staticClass:"mt-4",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-pagination',{attrs:{"length":_vm.paginate.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1):_c('div',{staticClass:"d-flex justify-center mt-6"},[_c('v-card',{attrs:{"width":"700"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"outlined":"","type":"warning","prominent":"","border":"left"}},[_vm._v(" Debe sincronizar el deposito con contabillium para mostrar los productos! ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }