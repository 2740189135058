var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-between align-center"},[(!_vm.showRegister)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"accent shadow-4 px-8",attrs:{"large":"","rounded":""},on:{"click":function () {
              _vm.showRegister = true;
              _vm.actionData = {};
              _vm.actionData = 0;
              _vm.dataUpdate = {};
            }}},[_vm._v(" Agregar Stock a Deposito ")])],1):_vm._e()],1),_c('v-row',{staticClass:"mt-4 justify-space-between"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Producto / SKU","filled":"","rounded":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetTransactionWP(_vm.page)}},model:{value:(_vm.search_product),callback:function ($$v) {_vm.search_product=$$v},expression:"search_product"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"accent",attrs:{"icon":""},on:{"click":function($event){_vm.loadFile = true}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-file-excel")])],1),_c('v-btn',{staticClass:"primary ml-5",attrs:{"icon":""},on:{"click":function($event){return _vm.HandlerDownload()}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-download")])],1)],1)])],1),(_vm.showRegister)?_c('Register',{attrs:{"dataUpdateShip":_vm.dataUpdate,"actionData":_vm.actionData},on:{"dialog:change":_vm.HandlerResponse}}):_vm._e()],1),(_vm.showRegister == false)?_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadow-1 mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataTransaction,"items-per-page":-1,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.products_erp[0].base_price == null ? 0 : item.products_erp[0].base_price)+" ")]}},{key:"item.products_erp",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.products_erp[0].sku)+" ")]}},{key:"item.product_warehouse",fn:function(ref){
            var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.product_warehouse[0].current_stock},on:{"update:returnValue":function($event){return _vm.$set(item.product_warehouse[0], "current_stock", $event)},"update:return-value":function($event){return _vm.$set(item.product_warehouse[0], "current_stock", $event)},"save":function($event){return _vm.save(item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":""},model:{value:(item.product_warehouse[0].current_stock),callback:function ($$v) {_vm.$set(item.product_warehouse[0], "current_stock", $$v)},expression:"item.product_warehouse[0].current_stock"}})]},proxy:true}],null,true)},[(item.product_warehouse[0].current_stock > 0)?_c('span',[_vm._v(" "+_vm._s(item.product_warehouse[0].current_stock)+" ")]):_c('span',[_vm._v(" "+_vm._s(0)+" ")])]),(_vm.loadingUpdate)?_c('v-progress-circular',{staticClass:"d-flex justify-center my-5",attrs:{"size":"300","indeterminate":"","color":"primary"}}):_vm._e()]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)})],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pagination.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"500"},model:{value:(_vm.loadFile),callback:function ($$v) {_vm.loadFile=$$v},expression:"loadFile"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passes = ref.passes;
return [_c('v-card',[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Cargar archivo ")])],1)],1),_c('v-card-text',{staticStyle:{"max-height":"350px"}},[_c('ValidationProvider',{attrs:{"name":"archivo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-file-input',{staticClass:"rounded-small",attrs:{"filled":"","rounded":"","dense":"","label":"Elige tu archivo","prepend-icon":"","prepend-inner-icon":"mdi-file-excel","error-messages":errors},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})]}}],null,true)})],1),_c('v-divider'),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){_vm.loadFile = false}}},[_vm._v(" Cancelar ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.uploadFile,"disabled":_vm.uploadFile,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerImportFile)}}},[_vm._v(" Guardar ")])]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }