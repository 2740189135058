<template>
  <ValidationObserver ref="obs" v-slot="{}">
    <v-container class="mt-n0">
      <div
        class="font-weight-medium primary--text mb-5"
        style="font-size: 26px"
      >
        <!-- {{ actionData == 0 ? "Crear Tienda" : "Editar Tienda" }} -->
        Crear / Editar Caracteristica
      </div>
      <v-card :loading="loading" class="mb-4">
        <v-card-title class="pa-0 shadow-1">
          <v-img
            :src="require('@/assets/illustrations/bg_modal_card.svg')"
            cover
            position="left bottom"
            class="align-center"
            height="65px"
          >
            <!-- <v-card-title class="font-weight-medium primary--text">
              Datos Basicos
            </v-card-title> -->
          </v-img>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" sm="6" md="5">
              <ValidationProvider
                name="Nombre"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="dataUpdateStore.name"
                  filled
                  rounded
                  dense
                  class="rounded-small"
                  label="Nombre"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" sm="6" md="5">
              <ValidationProvider
                name="Categoria"
                rules="required"
                v-slot="{ errors }"
              >
                <v-autocomplete
                  :items="dataCategories"
                  item-text="name"
                  item-value="id"
                  v-model="categories_id"
                  chips
                  small-chips
                  filled
                  rounded
                  dense
                  multiple
                  class="rounded-small"
                  label="Categoria"
                  :error-messages="errors"
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="closeModal({}, false)"
            >
              Cancelar
            </v-btn>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loading"
              rounded
              class="mr-1"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              @click="HandlerSendData"
            >
              Guardar
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-container>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    dataUpdateStore: {
      type: Object,
      default: () => {},
      required: false,
    },
    actionData: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  components: {
    // "register-category": RegisterDataCategory,
  },
  data() {
    return {
      register: true,

      showErrorCategories: false,
      dataCategories: [],
      categories_id: [],
      loading: false,
    };
  },
  created() {
    if (this.actionData == 1) {
      // console.log(this.dataUpdateStore)
      this.categories_id = this.dataUpdateStore.categories.map((index) => index.id);
    }
    this.HandlerGetCategories();
  },
  // watch: {
  //   "dataUpdateStore.country_id": function (newVal) {
  //     const search = this.dataCurrency
  //       .map((value) => {
  //         if (value.id == newVal) {
  //           return value.currency;
  //         }
  //       })
  //       .filter((fil) => fil != undefined);
  //     this.dataCurrencySearch = search;
  //   },

  //   page(val) {
  //     this.HandlerGetCategories(val);
  //   },

  //   sub_categories() {
  //     if (this.sub_categories.length > 0) {
  //       this.showErrorCategories = false;
  //     } else {
  //       this.showErrorCategories = true;
  //     }
  //   },
  // },
  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    HandlerRegisterCategory() {
      this.showRegister = !this.showRegister;
      this.register = false;
      this.dataUpdate = {
        sub_category: [],
      };
    },

    HandlerResponse(item, action) {
      console.log("estoy actualizando index", item);
      this.showRegister = false;
      if (action === 0) {
        // const filterData = this.HandlerCreateCategoryCildren([item.data]);
        this.dataCategories.push(item.data);
      }
    },

    async HandlerSendData() {
      try {
        if (await this.$refs.obs.validate()) {
          this.loading = true;

          const request = {
            name: this.dataUpdateStore.name,
            category_ids: this.categories_id
          };
          let response;
          if (this.actionData == 0) {
            response = await this.$store.dispatch(
              "feature/REGISTER_FEATURES",
              request
            );
            console.log(response);
            this.$snotify.success("Caracteristica Registrada.", "¡Exitos!");
          } else {
            console.log("request", request);
            response = await this.$store.dispatch("feature/UPDATED_FEATURES", {
              data: request,
              id: this.dataUpdateStore.id,
            });
            this.$snotify.success("Caracteristica Actualizada.", "¡Exitos!");
          }
          this.closeModal(response.data, 0);
        } else {
          this.showErrorCategories = true;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetCategories() {
      try {
        const request = {
          paginate: false,
          store_id: "",
          name: "",
        };
        const response = await this.$store.dispatch(
          "category/GET_CATEGORIES",
          request
        );
        // this.paginateCategories = {
        //   lastPage: response.data.data.lastPage,
        //   page: response.data.data.page,
        //   perPage: response.data.data.perPage,
        //   total: response.data.data.total,
        // };
        // this.originalDataCategories = response.data.data.data;
        this.dataCategories = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>