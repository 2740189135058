<template>
  <v-row justify="center">
    <alert
      :data="errorData"
      :active="activeAlert"
      :colorAlert="colorAlert"
      @dialog:change="eventdialog"
    />
    <v-dialog v-model="active" persistent scrollable max-width="700">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-card>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            rounded
            height="6"
          ></v-progress-linear>

          <v-card-title class="pa-0 shadow-1">
            <v-img
              :src="require('@/assets/illustrations/bg_modal_card.svg')"
              cover
              position="left bottom"
              class="align-center"
              height="65px"
            >
              <v-card-title class="font-weight-medium primary--text">
                {{ action == 0 ? "Nueva" : "Editar" }} duración entre depositos
              </v-card-title>
            </v-img>
          </v-card-title>

          <vuescroll
            :ops="{
              bar: { background: '#bacfcec0', keepShow: true },
            }"
          >
            <v-card-text style="max-height: 350px">
              <v-form>
                <v-row dense>
                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="duracion"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Duración (horas)"
                        name="duracion"
                        prepend-inner-icon="mdi-clock"
                        type="text"
                        color="blue-grey"
                        v-model="data.duration"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" md="6">
                    <ValidationProvider
                      name="deposito de llegada"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :readonly="action == 1 ? true : false"
                        :items="getDepositsTime"
                        item-text="name"
                        item-value="id"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Deposito de llegada"
                        name="warehouse_end_id"
                        prepend-inner-icon="mdi-moped"
                        color="blue-grey"
                        v-model="data.warehouse_end_id"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </vuescroll>

          <v-divider></v-divider>

          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                text
                rounded
                class="mr-1"
                :color="hover ? 'error' : 'grey'"
                @click="closeModal"
                >Cancelar</v-btn
              >
            </v-hover>

            <v-hover v-slot:default="{ hover }">
              <v-btn
                rounded
                :loading="loading"
                :disabled="loading"
                color="success"
                :class="hover ? 'shadow-2' : 'shadow-4'"
                class="px-8"
                @click="passes(HandlerAction)"
                >Añadir</v-btn
              >
            </v-hover>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </v-row>
</template>

<script>
import vuescroll from "vuescroll";

import alert from "../../Utils/Alert";
export default {
  components: {
    alert,
    vuescroll,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      errorData: "",
      activeAlert: false,
      colorAlert: "",
      duration: "",
      warehouse_end_id: 0,
    };
  },

  created() {
    this.HandlerGetDeposit();
  },

  computed: {
    getDepositsTime() {
      const deposit = this.$store.getters["deposits/GET_DEPOSIT_ALL"];
      return deposit;
    },

    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },

    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    closeModal() {
      this.$emit("dialog:change", false);
    },

    HandlerAction() {
      this.HandlerRegister();
    },

    async HandlerRegister() {
      try {
        this.loading = true;
        const request = {
          warehouse_start_id: this.$route.params.id,
          warehouse_end_id: this.data.warehouse_end_id,
          duration: this.data.duration,
        };
        await this.$store.dispatch("deposits/REGISTER_DEPOSITS_TIME", request);
        this.$snotify.success("Duracion creado con éxito.", "¡Hecho!");
        this.closeModal();
      } catch (error) {
        this.$snotify.error(error.response, "¡Error!");
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetDeposit() {
      try {
        this.loading = true;
        const request = {
          paginate: false,
          warehouse_start_id: this.$route.params.id,
        };
        await this.$store.dispatch("deposits/GET_DEPOSITS_ALL", request);
      } catch (error) {
        console.log(error);
        this.$snotify.error(error.response, "¡Error!");
      } finally {
        this.loading = false;
      }
    },

    eventdialog() {
      this.activeAlert = false;
    },
  },
};
</script>
