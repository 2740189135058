<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
      >
        Crear / Editar Depositos
      </div>
      <v-col cols="12">
        <alert
          :data="errorData"
          :active="activeAlert"
          :colorAlert="colorAlert"
          @dialog:change="eventdialog"
        />
        <!-- <v-dialog v-model="active" persistent scrollable width="1000"> -->
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-title class="pa-0">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
              </v-img>
            </v-card-title>

            <v-card-text>
              <v-form>
                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="nombre"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Nombre"
                        name="nombre"
                        prepend-inner-icon="mdi-account"
                        type="text"
                        color="blue-grey"
                        v-model="data.name"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-menu
                      ref="menu_open"
                      v-model="menu_open"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="apertura"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            v-model="data.open_hour"
                            label="Apertura"
                            prepend-inner-icon="mdi-garage-open"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-time-picker
                        v-if="menu_open"
                        v-model="data.open_hour"
                        full-width
                        @click:minute="$refs.menu_open.save(data.open_hour)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <v-menu
                      ref="menu_close"
                      v-model="menu_close"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="close"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            v-model="data.close_hour"
                            label="Cierre"
                            prepend-inner-icon="mdi-garage"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-time-picker
                        v-if="menu_close"
                        v-model="data.close_hour"
                        full-width
                        @click:minute="$refs.menu_close.save(data.close_hour)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="delivery"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="items"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Permite Delivery"
                        name="delivery"
                        prepend-inner-icon="mdi-moped"
                        color="blue-grey"
                        v-model="data.can_deliver"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="Retiro en Deposito"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="items"
                        item-text="title"
                        item-value="value"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Retiro en Deposito"
                        name="entrega cliente"
                        prepend-inner-icon="mdi-car-lifted-pickup"
                        color="blue-grey"
                        v-model="data.can_pickup"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <ValidationProvider
                      name="telefono principal"
                      rules="required|numeric"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Telefono principal"
                        name="telefono principal"
                        prepend-inner-icon="mdi-cellphone"
                        color="blue-grey"
                        v-model="data.phone_1"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <!-- aaaaaaa{{ data.hasOwnProperty('email') }} -->
                    <ValidationProvider
                      name="email"
                      rules="email|required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="email"
                        name="email"
                        prepend-inner-icon="mdi-mail"
                        color="blue-grey"
                        v-model="data.email"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <ValidationProvider
                      name="pais"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <!-- {{country_id}} -->
                      <v-select
                        :items="country"
                        item-text="name"
                        item-value="id"
                        v-model="country_id"
                        filled
                        rounded
                        menu-props="auto"
                        class="rounded-small"
                        name="pais"
                        label="Pais"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <ValidationProvider
                      name="estado"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-if="loadingState == false"
                        :disabled="stateData.length == 0"
                        :items="stateData"
                        item-text="name"
                        item-value="id"
                        v-model="state_id"
                        menu-props="auto"
                        filled
                        rounded
                        class="rounded-small"
                        name="estado"
                        label="Estado"
                        :error-messages="errors"
                      ></v-autocomplete>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <ValidationProvider
                      name="direccion"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        required
                        v-model="data.address"
                        filled
                        rounded
                        class="rounded-small"
                        name="input-7-1"
                        label="Dirección"
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      name="observacion"
                      rules=""
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="observación"
                        name="usuario"
                        prepend-inner-icon="mdi-card-text"
                        color="blue-grey"
                        v-model="data.observations"
                        :error-messages="errors"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-card-title>
                      Localidades
                      <v-spacer></v-spacer>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <!-- <h5 class="grey--text text--darken-2">Localidades</h5> -->

                    <v-divider></v-divider>
                    <ValidationProvider
                      name="codigo postal"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <span
                        :class="errors.length > 0 ? 'error-class' : ''"
                      ></span>
                      <vuescroll
                        :ops="{
                          bar: {
                            background: '#bacfcec0',
                            keepShow: true,
                          },
                        }"
                      >
                        <v-card height="280" class="elevation-0">
                          <v-data-table
                            :disabled="locationData.length == 0"
                            :items="locationData"
                            v-model="location_id"
                            :headers="headers"
                            :single-select="singleSelect"
                            item-key="id"
                            show-select
                            dense
                            :search="search"
                            class="elevation-0"
                            :loading="loadingLocation"
                            no-data-text="Debe seleccionar un estado"
                            no-results-text="No hay localidades para mostrar"
                            :items-per-page="5"
                          >
                          </v-data-table>
                          <div style="color: #bf4744; font-size: 12px">
                            {{ errors[0] }}
                          </div>
                        </v-card>
                      </vuescroll>
                    </ValidationProvider>
                  </v-col>

                  <!-- <v-col cols="12" sm="6" md="6">
                    <ValidationProvider
                      name="estado"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-autocomplete
                        v-if="loadingState == false"
                        :disabled="stateData.length == 0"
                        :items="stateData"
                        item-text="name"
                        item-value="id"
                        v-model="state_id"
                        menu-props="auto"
                        filled
                        rounded
                        class="rounded-small"
                        name="ciudad"
                        label="Estado"
                        :error-messages="errors"
                      ></v-autocomplete>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </ValidationProvider>
                  </v-col> -->

                  <!-- <v-col cols="12">
                    <ValidationProvider
                      name="codigo postal"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        v-if="loadingLocation == false"
                        :disabled="locationData.length == 0"
                        :items="locationData"
                        item-text="zipcode"
                        menu-props="auto"
                        multiple
                        dense
                        item-value="id"
                        v-model="location_id"
                        small-chips
                        filled
                        rounded
                        class="rounded-small"
                        name="codigo_postal"
                        :label="
                          locationData.length == 0
                            ? 'Codigo Postal (No Data)'
                            : 'Codigo Postal'
                        "
                        :error-messages="errors"
                      ></v-select>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12">
                    <ValidationProvider
                      name="localidad"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        v-if="loadingLocation == false"
                        :readonly="locationData.length == 0"
                        :items="locationData"
                        item-text="name"
                        item-value="id"
                        v-model="location_id"
                        menu-props="auto"
                        small-chips
                        dense
                        multiple
                        filled
                        rounded
                        class="rounded-small"
                        name="ciudad"
                        :label="
                          locationData.length == 0
                            ? 'Localidad (No Data)'
                            : 'Localidad'
                        "
                        :error-messages="errors"
                      ></v-select>
                      <v-progress-circular
                        v-else
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </ValidationProvider>
                  </v-col> -->

                  <!-- <v-col cols="12" md="12">
                    <ValidationProvider
                      name="direccion"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        v-model="data.address"
                        filled
                        rounded
                        class="rounded-small"
                        name="input-7-1"
                        label="Dirección"
                        :error-messages="errors"
                      ></v-textarea>
                    </ValidationProvider>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerAction)"
                  >{{ action == 1 ? "Editar" : "Añadir" }}</v-btn
                >
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
        <!-- </v-dialog> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vuescroll from "vuescroll";

import alert from "../Utils/Alert";
export default {
  components: {
    alert,
    vuescroll,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    country: {
      type: Array,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      items: [
        {
          title: "SI",
          value: true,
        },
        {
          title: "NO",
          value: false,
        },
      ],
      country_id: "",
      state_id: "",
      location_id: [],
      stateData: [],
      locationData: [],
      loadingState: false,
      loadingLocation: false,

      time: null,
      time_open: null,
      menu_open: false,
      time_close: null,
      menu_close: false,
      showPassword: false,
      loading: false,
      errorData: "",
      activeAlert: false,
      colorAlert: "",

      singleSelect: false,
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          value: "name",
        },
        { text: "Codigo Postal", value: "zipcode" },
      ],
    };
  },

  created() {
    if (this.data?.location) {
      this.country_id = this.data.location[0].state.country.id;
      this.state_id = this.data.location[0].state.id;
      this.location_id = this.data.location;
    }
  },

  watch: {
    country_id(val) {
      this.HandlerGetState(val);
    },
    state_id(val) {
      this.HandlerGetLocations(val);
    },
  },

  methods: {
    closeModal() {
      this.$emit("dialog:change", false);
    },

    async HandlerGetState(item) {
      try {
        this.loadingState = true;
        const request = { country_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_STATE",
          request
        );
        this.stateData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingState = false;
      }
    },

    async HandlerGetLocations(item) {
      try {
        this.loadingLocation = true;
        const request = { state_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_LOCATIONS",
          request
        );
        this.locationData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingLocation = false;
      }
    },

    HandlerAction() {
      if (this.action == 0) this.HandlerRegister();
      else this.HandlerUpdate();
    },

    async HandlerRegister() {
      try {
        if (this.location_id) this.loading = true;
        const request = {
          erp_id: 1,
          warehouse_erp_id: 1,
          location_id: this.location_id.map((index) => parseInt(index.id)),
          user_id: 1,

          name: this.data.name,
          observations: this.data.observations,
          open_hour: this.data.open_hour,
          close_hour: this.data.close_hour,
          can_deliver: this.data.can_deliver,
          can_pickup: this.data.can_pickup,
          address: this.data.address,
          phone_1: this.data.phone_1,
          email: this.data.email,
        };
        await this.$store.dispatch("deposits/REGISTER_DEPOSITS", request);
        this.$snotify.success("Deposito creado con éxito.", "¡Hecho!");
        this.closeModal();
      } catch (error) {
        this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },

    async HandlerUpdate() {
      try {
        this.loading = true;
        const request = {
          data: {
            erp_id: 1,
            warehouse_erp_id: 1,
            location_id: this.location_id.map((index) => parseInt(index.id)),
            user_id: 1,

            name: this.data.name,
            observations: this.data.observations,
            open_hour: this.data.open_hour,
            close_hour: this.data.close_hour,
            can_deliver: this.data.can_deliver,
            can_pickup: this.data.can_pickup,
            address: this.data.address,
            phone_1: this.data.phone_1,
            email: this.data.email,
          },
          id: this.data.id,
        };
        await this.$store.dispatch("deposits/UPDATE_DEPOSITS", request);
        this.$snotify.success("Deposito creado con éxito.", "¡Hecho!");
        this.closeModal();
      } catch (error) {
        this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },
    eventdialog() {
      this.activeAlert = false;
    },
  },
};
</script>

<style>
.error-class {
  color: #ef5350;
  font-size: 12px;
}
</style>
