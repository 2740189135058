var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"font-weight-medium primary--text mb-5",staticStyle:{"font-size":"26px"}},[_vm._v(" "+_vm._s(_vm.action == 0 ? "Nuevo" : "Editar")+" Proveedor ")]),_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Datos personales ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
        bar: { background: '#bacfcec0', keepShow: true },
      }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Como identificarlo","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Razon Social","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre Fantasia","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Categoría Impositiva","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Personería","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Email","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Email facturazion","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","hint":"Si deseas ingresar más de 1 dirección, separa las direcciones mediante una coma.","persistent-hint":"","error-messages":errors}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Retenciones ")]),_c('v-card-subtitle',{staticClass:"font-weight-medium primary--text"},[_vm._v("Se muestran los campos correspondientes a retenciones. ")]),_c('vuescroll',{attrs:{"ops":{
        bar: { background: '#bacfcec0', keepShow: true },
      }}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-medium"},[_vm._v("Ingresos brutos")]),_c('small',[_vm._v("¿Es exento? (No se calculan retenciones)")]),_c('v-row',{staticClass:"ml-1"},[_c('v-checkbox',{attrs:{"label":"AGIP"},model:{value:(_vm.checkboxAGIP),callback:function ($$v) {_vm.checkboxAGIP=$$v},expression:"checkboxAGIP"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"ARBA"},model:{value:(_vm.checkboxARBA),callback:function ($$v) {_vm.checkboxARBA=$$v},expression:"checkboxARBA"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Ganancias ")]),_c('v-card-subtitle',{staticClass:"font-weight-medium primary--text"},[_vm._v("Se muestran los campos correspondientes a ganancias. ")]),_c('vuescroll',{attrs:{"ops":{
        bar: { background: '#bacfcec0', keepShow: true },
      }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Situación en el impuesto a las Ganancias","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Regimen de ganancias por defecto","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Percepciones ")]),_c('v-card-subtitle',{staticClass:"font-weight-medium primary--text"},[_vm._v("Se muestran los campos correspondientes a percepciones. ")]),_c('vuescroll',{attrs:{"ops":{
        bar: { background: '#bacfcec0', keepShow: true },
      }}},[_c('v-card-text',[_c('p',{staticClass:"font-weight-medium"},[_vm._v("Ingresos brutos")]),_c('small',[_vm._v("¿Es exento? (No se calculan retenciones)")]),_c('v-row',{staticClass:"ml-1"},[_c('v-checkbox',{attrs:{"label":"AGIP"},model:{value:(_vm.checkboxAGIP),callback:function ($$v) {_vm.checkboxAGIP=$$v},expression:"checkboxAGIP"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"label":"ARBA"},model:{value:(_vm.checkboxARBA),callback:function ($$v) {_vm.checkboxARBA=$$v},expression:"checkboxARBA"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Domicilio ")]),_c('v-card-subtitle',{staticClass:"font-weight-medium primary--text"},[_vm._v("Estos campos no son obligatorios. ")]),_c('vuescroll',{attrs:{"ops":{
        bar: { background: '#bacfcec0', keepShow: true },
      }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Pais","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Ciudad","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Localidad","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Domicilio","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Piso","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Codigo Postal","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Teléfono","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1)],1)],1)],1)],1),_c('v-divider',{staticClass:"mx-15 mb-6"}),_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Datos complementarios ")]),_c('v-card-subtitle',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Estos datos no son necesarios, pero podrían serte de utilidad ;-) ")]),_c('vuescroll',{attrs:{"ops":{
        bar: { background: '#bacfcec0', keepShow: true },
      }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Categorias","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Límite Descubierto","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('ValidationProvider',{attrs:{"name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-textarea',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Observaciones","name":"long","prepend-inner-icon":"mdi-card-text","color":"blue-grey","error-messages":errors}})]}}])})],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"end"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'}},[_vm._v("Cancelar")])]}}])}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-4 mb-2",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","color":"success"}},[_vm._v("Continuar")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }