<template>
  <v-row class="mt-5">
    <v-col cols="12" sm="4" md="3">
      <v-text-field
        @input="updateData"
        @keyup.enter="HandlerGetDataByFilter"
        @blur="handleBlur"
        v-model.trim="operation_number"
        filled
        rounded
        dense
        class="rounded-small"
        name="ciudad"
        label="Venta / Seguimiento"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-text-field
        @keyup.enter="HandlerGetDataByFilter"
        @blur="handleBlur"
        @input="clearSpaceEmail"
        v-model="client_name"
        filled
        rounded
        dense
        class="rounded-small"
        name="ciudad"
        label="Nombre / Email"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-text-field
        @keyup.enter="HandlerGetDataByFilter"
        @blur="handleBlur"
        @input="clearSpaceSKU"
        v-model="product_name"
        filled
        rounded
        dense
        class="rounded-small"
        name="ciudad"
        label="Producto / SKU"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-select
        @blur="handleBlur"
        :items="dataSeller"
        item-text="name"
        item-value="seller_id"
        v-model="seller"
        menu-props="auto"
        filled
        rounded
        dense
        class="rounded-small"
        name="ciudad"
        label="Vendedor"
        single-line
        hide-details
        clearable
      ></v-select>
    </v-col>

    <v-col cols="12" sm="4" md="3">
      <v-select
        @blur="handleBlur"
        :items="dataStore"
        item-text="name"
        item-value="id"
        v-model="store"
        filled
        rounded
        dense
        class="rounded-small"
        name="ciudad"
        label="Tienda"
        single-line
        hide-details
        clearable
      ></v-select>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-select
        :items="dataWarehouse"
        item-text="name"
        item-value="id"
        v-model="warehouse_id"
        filled
        rounded
        dense
        class="rounded-small"
        name="deposito"
        label="Deposito"
        single-line
        hide-details
      ></v-select>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-menu
        v-model="menu_start"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            @blur="handleBlur"
            @keyup.enter="HandlerGetDataByFilter"
            v-model="date"
            label="Desde"
            readonly
            filled
            rounded
            dense
            class="rounded-small"
            name="ciudad"
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          locale="es-us"
          v-model="date"
          @input="menu_start = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-menu
        v-model="menu_end"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            @blur="handleBlur"
            @keyup.enter="HandlerGetDataByFilter"
            v-model="date_end"
            label="Hasta"
            readonly
            filled
            rounded
            dense
            class="rounded-small"
            name="ciudad"
            single-line
            hide-details
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          no-title
          locale="es-us"
          v-model="date_end"
          @input="menu_end = false"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="12" sm="4" md="3">
      <v-select
        @blur="handleBlur"
        :items="dataTypeSend"
        item-text="name"
        item-value="value"
        v-model="typeSend"
        menu-props="auto"
        filled
        rounded
        dense
        class="rounded-small"
        name="ciudad"
        label="Modo de envío"
        single-line
        hide-details
        clearable
      ></v-select>
    </v-col>
    <v-row class="mx-2 mt-2" justify="space-between">
      <v-col cols="12" md="4" class="d-flex justify-start">
        <!-- <v-pagination
          v-if="paginate.lastPage > 0"
          v-model="myPage"
          :length="paginate.lastPage"
          :total-visible="$vuetify.breakpoint.name == 'xs' ? 5 : 7"
          circle
        ></v-pagination> -->
      </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetDataByFilter()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data() {
    return {
      loading: false,
      operation_number: "",
      client_name: "",
      product_name: "",
      seller: "",
      dataSeller: [],
      dataStore: [],
      store: null,
      date: null,
      menu_start: false,
      date_end: null,
      menu_end: false,
      // dataTypeSend: [
      //   { name: "Todos", value: "" },
      //   { name: "Retiro a una sucursal", value: "Retiro a una sucursal" },
      //   { name: "Rápido a domicilio", value: "Rápido a domicilio" },
      //   { name: "Normal a domicilio", value: "Normal a domicilio" },
      //   { name: "Estándar a domicilio", value: "Estándar a domicilio" },
      //   { name: "Estándar a sucursal de correo", value: "Estándar a sucursal de correo" },
      //   { name: "Express a domicilio", value: "Express a domicilio" },
      //   { name: "Express a sucursal de correo", value: "Express a sucursal de correo" },
      //   { name: "Chazki72", value: "Chazki72" },
      //   { name: "Logis24", value: "Logis24" },
      // ],
      dataTypeSend: [
        { name: "Todos", value: "" },
        { name: "Flex", value: "self_service" },
        { name: "Colecta", value: "cross_docking" },
        { name: "Places", value: "xd_drop_off" },
        // { name: "Chazki", value: "Chazki" },
        // { name: "Logis24", value: "Logis24" },
        // { name: "Retiro cliente", value: "Retiro cliente" },
        // { name: "Logis24", value: "Logis24" },
      ],
      // dataTypeSend: ["Estándar a sucursal de correo ", "Express a domicilio"],
      dataWarehouse: [],
      warehouse_id: 5,
      typeSend: null,
      pageUpdate: "",
      // myPage: 1,
    };
  },
  created() {
    this.HandlerGetSeller();
    this.HandlerGetStore();
    this.HandlerGetWarehouse();
  },
  computed: {
    tab() {
      return this.$store.getters["orders/GET_DATA_STATUSHIP"];
    },

    myPage: {
      get() {
        const paginate = this.$store.getters["orders/GET_PAGINATE"];
        return paginate.page;
      },
      set(val) {
        this.$store.commit("orders/SET_PAGINATE", val);
        this.HandlerGetDataByFilter(val);
      },
    },

    paginate() {
      const paginate = this.$store.getters["orders/GET_PAGINATE"];
      return paginate;
    },
  },
  methods: {
    updateData(input) {
      this.operation_number = input.replace(" ", "");
    },

    clearSpaceSKU(input) {
      this.product_name = input.replaceAll(" ", "");
    },

    clearSpaceEmail(input) {
      this.client_name = input.replaceAll(" ", "");
    },

    HandlerReset() {
      this.operation_number = "";
      this.client_name = "";
      this.product_name = "";
      this.date = null;
      this.date_end = null;
      this.seller = "";
      this.store = null;
      this.typeSend = null;
      this.warehouse_id = 5;
      this.HandlerGetDataByFilter();
    },

    async HandlerGetSeller() {
      try {
        const response = await this.$store.dispatch("orders/GET_SELLER");
        const filterData = response.data.data.filter((value) => {
          if (value.name != "TETE8158324") {
            return value;
          }
        });
        this.dataSeller = filterData;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          name: "",
          paginate: false,
          b2b_active: false,
        };
        const response = await this.$store.dispatch(
          "orders/GET_STORE",
          request
        );
        this.dataStore = response.data.data;
        this.dataStore.push({
          name: "Todos",
          id: "",
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetWarehouse() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          name: "",
          paginate: false,
        };
        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS",
          request
        );
        this.dataWarehouse = response.data.data;
        this.dataWarehouse = this.dataWarehouse.filter((index) => {
          return index.id == 3 || index.id == 5 || index.id == 10;
        });
        this.dataWarehouse.push({
          name: "Todos",
          id: "",
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetDataByFilter(page) {
      try {
        this.loading = true;
        this.$emit("filters:change", true);

        this.operation_number = this.operation_number.replaceAll(" ", "");

        const request = {
          page: page || this.paginate.page,
          per_page: this.paginate.perPage,
          paginate: true,
          shipping_mode: "",
          logistic_type: this.typeSend, //metodo de envio
          reqSeller: this.seller,
          reqStore: this.store,
          reqOrderItems: this.product_name,
          reqSearchQuery: this.operation_number,
          reqDateFrom: this.date == null ? "" : this.date,
          reqDateTo: this.date_end == null ? "" : this.date_end,
          reqSearchName: this.client_name.toLowerCase(),
          tabs_status: this.tab,
          warehouse_id: this.warehouse_id,
          country_id: 1
        };

        this.$store.commit("orders/SET_SEARCH_QUERY", request);

        await this.$store.dispatch("orders/GET_SHIP", request);

        this.$emit("filters:change", false);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.$emit("filters:change", false, false);
      }
    },

    handleBlur(input) {
      if (input.target.value != "") {
        this.HandlerGetDataByFilter();
      }
    },
  },
};
</script>

<style>
</style>