<template>
  <v-container fluid class="mt-5">
    <v-row class="mt-5 mb-5">
      <v-col cols="12" sm="4" md="3">
        <!-- {{ dataReservation }} -->
        <v-select
          @change="HandlerGetUserNotificationProduct()"
          v-model.trim="store_id"
          :items="dataReservation"
          item-text="name"
          item-value="id"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Tiendas"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-select
          @change="HandlerGetUserNotificationProduct()"
          v-model.trim="is_active"
          :items="[
            { text: 'Por Procesar', value: true },
            { text: 'Procesados', value: false },
          ]"
          item-text="text"
          item-value="value"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Tiendas"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-text-field
          type="number"
          @keyup.enter="HandlerGetUserNotificationProduct()"
          v-model.trim="sku"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="SKU"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetUserNotificationProduct()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12">
        <v-card class="shadow-1 mt-5">
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="dataCoupon"
            disable-pagination
            hide-default-footer
            class="elevation-0"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.full_unit_price`]="{ item }">
              <div>
                {{ item.full_unit_price | currency }}
              </div>
            </template>
            <template v-slot:[`item.amount_coupons`]="{ item }">
              <div>
                {{ item.amount_coupons | currency }}
              </div>
            </template>
            <template v-slot:[`item.percentage`]="{ item }">
              <div>{{ percentage(item) }}%</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerUpdateUserNotificationProduct(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'"
                      >mdi-thumb-up</v-icon
                    >
                  </v-btn>
                </template>
                <span>Procesar</span>
              </v-tooltip>
              <v-tooltip bottom v-if="item.is_active">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerDeleteUserNotificationProduct(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      is_active: true,
      store_id: "",
      headers: [
        { text: "Nombre", sortable: false, value: "name" },
        { text: "Email", sortable: false, value: "email" },
        { text: "Telefono", sortable: false, value: "phone" },
        { text: "Publicacion", sortable: false, value: "publication.keywords" },
        { text: "Accion", sortable: false, value: "action" },
      ],
      dataReservation: [],
      dataCoupon: [],
      page: 1,
      pagination: {},
      sku: "",
    };
  },

  created() {
    this.HandlerGetUserNotificationProduct();
    this.HandlerGetStore();
  },

  watch: {
    page(val) {
      this.HandlerGetUserNotificationProduct(val);
    },
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    updateData(input) {
      this.store_id = input.replace(" ", "");
    },

    async HandlerGetUserNotificationProduct(page = 1) {
      try {
        this.loading = true;
        const request = {
          store_id: this.store_id,
          is_active: this.is_active,
          sku: this.sku,
          page: page,
          per_page: 20,
          paginate: true,
        };

        const response = await this.$store.dispatch(
          "publications/GET_PRODUCT_NOTIFICATION",
          request
        );

        this.dataCoupon = response.data.data.data;
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.meli_id = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetStore() {
      try {
        this.loading = true;
        const request = {
          store_id: this.store_id,
          page: "",
          per_page: "",
          paginate: false,
        };

        const response = await this.$store.dispatch("store/GET_STORE", request);

        this.dataReservation = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerUpdateUserNotificationProduct(item) {
      try {
        this.loading = true;
        const request = {
          id: item.id,
          is_active: !item.is_active,
        };

        await this.$store.dispatch(
          "publications/UPDATE_PRODUCT_NOTIFICATION",
          request
        );

        this.HandlerGetUserNotificationProduct();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerDeleteUserNotificationProduct(item) {
      try {
        this.loading = true;
        const request = {
          id: item.id,
        };

        await this.$store.dispatch(
          "publications/DELETE_PRODUCT_NOTIFICATION",
          request
        );

        this.HandlerGetUserNotificationProduct();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    HandlerReset() {
      this.store_id = "";
      this.is_active = true;
      this.sku = "";
      this.HandlerGetUserNotificationProduct();
    },

    percentage(item) {
      const percentage =
        100 -
        ((item.unit_price - item.amount_coupons) * 100) / item.full_unit_price;
      return percentage.toFixed(2);
    },
  },
};
</script>

<style>
</style>