<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            v-if="status_card"
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData({})"
          >
            Crear Deposito
          </v-btn>
          <register-component
            v-if="activeAddData"
            :active="activeAddData"
            :data="dataAdd"
            :action="actionAdd"
            :country="dataCountry"
            @dialog:change="HandlerResponse"
          />
          <date-free-component
            v-if="activeAddDataDate"
            :active="activeAddDataDate"
            :data="dataAddDate"
            :action="actionAddDate"
            @dialog:change="HandlerResponseDate"
          />
        </v-col>
      </v-row>
      <v-row class="mt-5" v-if="status_card">
        <v-col cols="12" sm="4" md="3">
          <v-select
            :items="dataCountry"
            item-text="name"
            item-value="id"
            v-model="country_id"
            menu-props="auto"
            filled
            rounded
            dense
            class="rounded-small"
            name="ciudad"
            label="Pais"
            single-line
            hide-details
          ></v-select>
        </v-col>
        <!-- <v-col cols="12" sm="4" md="3">
          <v-select
            v-if="loadingState == false"
            :disabled="stateData.length == 0"
            :items="stateData"
            item-text="name"
            item-value="id"
            v-model="state_id"
            menu-props="auto"
            filled
            rounded
            dense
            class="rounded-small"
            name="ciudad"
            label="Estado"
            single-line
            hide-details
          ></v-select>
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-select
            v-if="loadingLocation == false"
            :disabled="locationData.length == 0"
            :items="locationData"
            item-text="name"
            item-value="id"
            v-model="location_id"
            menu-props="auto"
            filled
            rounded
            dense
            class="rounded-small"
            name="ciudad"
            single-line
            hide-details
            :label="locationData.length == 0 ? 'Provincia' : 'Provincia'"
          ></v-select>
        </v-col> -->
        <v-col class="d-flex justify-end">
          <v-btn
            :loading="loadingBtn"
            fab
            small
            color="primary"
            class="mr-3"
            @click="HandlerGetDeposits()"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn fab small @click="HandlerReset()">
            <v-icon color="primary">mdi-backup-restore</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="status_card">
      <v-card class="shadow-1 mt-5">
        <v-data-table
          :loading="false"
          :headers="headers"
          :items="getDeposits"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon class="mr-2" @click="HandlerEditItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon class="mr-2" @click="HandlerDate(item)">
              mdi-calendar
            </v-icon>
            <v-icon class="mr-2" @click="HandlerDepositTime(item)">
              mdi-warehouse
            </v-icon>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-chip
              v-if="loadingStatus == false"
              color="transparent"
              small
              @click="HandlerChangeStatus(item)"
            >
              <v-icon
                left
                small
                :color="item.active === true ? 'success' : 'error'"
                >mdi-radiobox-marked</v-icon
              >
              {{ item.active === true ? "Activo" : "Inactivo" }}
            </v-chip>
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ dateFormat(item.created_at) }}</span>
          </template>
        </v-data-table>
      </v-card>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="getPaginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import Register from "./Register";
import DaysFree from "./DaysFree";
export default {
  components: {
    "register-component": Register,
    "date-free-component": DaysFree,
  },
  data() {
    return {
      loadingData: false,
      activeAddData: false,
      activeAddDataDate: false,
      actionAdd: 0,
      actionAddDate: 0,
      status_card: true,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      loadingStatus: false,
      country_id: "",
      state_id: "",
      location_id: "",
      loadingBtn: false,
      loadingState: false,
      loadingLocation: false,
      stateData: [],
      locationData: [],
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "País",
          value: "country.name",
          class: "primary--text",
        },
        {
          text: "Apertura",
          value: "open_hour",
          class: "primary--text",
        },
        {
          text: "Cierre",
          value: "close_hour",
          class: "primary--text",
        },
        {
          text: "Estatus",
          value: "active",
          class: "primary--text",
        },
        // {
        //   text: "Delivery",
        //   value: "can_deliver",
        //   class: "primary--text",
        // },
        // {
        //   text: "Pickup",
        //   value: "can_pickup",
        //   class: "primary--text",
        // },
        // {
        //   text: "Direccion",
        //   value: "address",
        //   class: "primary--text",
        // },
        {
          text: "Telefono",
          value: "phone_1",
          class: "primary--text",
        },
        {
          text: "Acciones",
          value: "actions",
          class: "primary--text",
        },
      ],
      data: [],
      dataCountry: [],
    };
  },

  created() {
    this.HandlerGetDeposits();
    this.HandlerGetCountry();
  },

  watch: {
    page(val) {
      this.HandlerGetDeposits(val);
    },
    country_id(val) {
      this.HandlerGetState(val);
    },
    state_id(val) {
      this.HandlerGetLocations(val);
    },
  },

  computed: {
    getDeposits() {
      return this.$store.getters["deposits/GET_DEPOSITS"];
    },
    getPaginate() {
      return this.$store.getters["deposits/GET_PAGINATE"];
    },
  },

  methods: {
    async HandlerGetDeposits(page) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: true,
          country_id: this.country_id,
          state_id: this.state_id,
          location_id: this.location_id,
        };
        await this.$store.dispatch("deposits/GET_DEPOSITS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    async HandlerReset() {
      try {
        this.country_id = 0;
        this.state_id = 0;
        this.location_id = 0;
        this.HandlerGetDeposits(1);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("deposits/GET_COUNTRY");
        this.dataCountry = response.data.data;
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetState(item) {
      try {
        this.loadingState = true;
        const request = { country_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_STATE",
          request
        );
        this.stateData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingState = false;
      }
    },

    async HandlerGetLocations(item) {
      try {
        this.loadingLocation = true;
        const request = { state_id: item };
        const response = await this.$store.dispatch(
          "deposits/GET_LOCATIONS",
          request
        );
        this.locationData = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingLocation = false;
      }
    },

    async HandlerChangeStatus(item) {
      try {
        this.loadingStatus = true;
        const request = {
          ...item,
        };
        await this.$store.dispatch("deposits/GET_STATUS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingStatus = false;
      }
    },

    HandlerEditItem(item) {
      this.activeAddData = true;
      this.actionAdd = 1;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    HandlerAddData(item) {
      this.activeAddData = true;
      this.actionAdd = 0;
      this.status_card = false;
      this.dataAdd = { ...item };
    },

    HandlerResponse(value) {
      this.activeAddData = value;
      this.status_card = true;
    },

    HandlerDate(item) {
      this.activeAddDataDate = true;
      this.actionAddDate = 1;
      this.dataAddDate = { ...item };
    },

    HandlerAddDataDate(item) {
      this.activeAddDataDate = true;
      this.actionAddDate = 0;
      this.dataAddDate = { ...item };
    },

    HandlerResponseDate(value) {
      this.activeAddDataDate = value;
    },

    HandlerDepositTime(item) {
      this.$router.push({
        name: "Duración entre depositos",
        params: { id: item.id },
      });
    },
  },
};
</script>

<style>
</style>