<template>
  <div>
    <v-row>
      <v-col md="4" class="mb-5">
        <v-btn
          @click="
            () => {
              createCountry = true;
              action = 0;
              name = '';
              sku = '';
              code = '';
            }
          "
          rounded
          color="primary"
          class="white--text"
        >
          Crear
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingTable"
      :headers="headers"
      :items="data"
      hide-default-footer
      disable-pagination
      fixed-header
      :page.sync="page"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="HandlerEdit(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="createCountry" max-width="600">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-card>
          <div class="px-3 py-3">
            <div class="mb-3" style="font-size: 1.4em">
              Registrar nuevo país
            </div>
            <ValidationProvider
              name="nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                required
                filled
                rounded
                class="rounded-small"
                dense
                label="Nombre"
                name="nombre"
                type="text"
                v-model="name"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="Código ISO"
              rules=""
              v-slot="{ errors }"
            >
              <v-text-field
                required
                filled
                rounded
                class="rounded-small"
                label="Código ISO"
                dense
                v-model="code_iso"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              name="Prefijo SKU"
              rules="required|integer|max:2|min:2"
              v-slot="{ errors }"
            >
              <v-text-field
                required
                filled
                rounded
                class="rounded-small"
                label="Prefijo SKU"
                dense
                v-model="code"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="createCountry = false">Cerrar</v-btn>
            <v-btn color="primary" @click="passes(HandlerRegisterOrUpdate)">
              Continuar
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingTable: false,
      createCountry: false,
      action: 0,
      page: 1,
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Código ISO", value: "code_iso" },
        { text: "Prefijo SKU", value: "code" },
        { text: "Acciones", value: "actions" },
      ],
      data: [],
      dataUpdate: {},
      name: "",
      code: "",
      code_iso: "",
    };
  },

  created() {
    this.HandlerGetCountries();
  },

  methods: {
    async HandlerGetCountries() {
      try {
        this.loadingTable = true;
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.data = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    async HandlerRegisterOrUpdate() {
      try {
        this.loadingTable = true;

        const request = {
          name: this.name,
          code: this.code,
          code_iso: this.code_iso,
        };

        if (this.action == 0) {
          await this.$store.dispatch("country/REGISTER_COUNTRY", request);
        } else {
          const rquestData = {
            data: { ...request },
            id: this.dataUpdate.id,
          };
          await this.$store.dispatch("country/UPDATE_COUNTRY", rquestData);
        }

        this.HandlerGetCountries();
        this.createCountry = false;
        this.name = "";
        this.code = "";
        this.sku = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    HandlerEdit(item) {
      this.createCountry = true;
      this.action = 1;
      this.dataUpdate = { ...item };
      this.name = item.name;
      this.code = item.code;
      this.sku = item.currency != null ? item.currency.code : "";
    },
  },
};
</script>

<style>
</style>