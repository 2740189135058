<template>
  <v-container fluid class="mt-5">
    <v-row>
      <v-col md="12" v-if="!showRegisterImage">
        <v-row class="justify-between align-center mb-5">
          <v-col cols="12" md="3" class="py-0">
            <v-btn
              rounded
              dark
              block
              large
              @click="HandlerAddImage"
              class="accent shadow-4 px-8"
            >
              Añadir Imagen
            </v-btn>
          </v-col>
        </v-row>
        <v-card class="shadow-1 mt-5">
          <v-data-table
            v-if="!showRegisterImage"
            :loading="loading"
            :headers="headers"
            :items="dataImages"
            disable-pagination
            hide-default-footer
            class="elevation-0"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerEdit(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>Editar Imagen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerShowDelete(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-delete-forever
                    </v-icon>
                  </v-btn>
                </template>
                <span>Eliminar Imagen</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerCopy(item.image_url)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'">
                      mdi-content-copy
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copiar url</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col md="12" v-if="showRegisterImage">
        <section id="register">
          <!-- <v-card> -->
          <register-image
            :dataUpdate="dataUpdate"
            :action="action"
            @dialog:change="HandlerResponse"
          />
          <!-- </v-card> -->
        </section>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4" v-if="!showRegisterImage">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDelete" max-width="600">
      <v-card :loading="loadingDelete">
        <v-card-text>
          <h3 class="pt-4">Deseas eliminar esta imagen</h3>
          <p>Al aceptar estas de acuerdo en eliminar la imagen</p>
          <v-img
            v-if="imagesData == null"
            src="@/assets/img/no_image.jpg"
          ></v-img>
          <v-img
            v-else
            height="200"
            widht="auto"
            contain
            :src="imagesData.image_url"
            :lazy-src="imagesData.image_url"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="black lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loadingDelete"
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="showDelete = !showDelete"
            >
              Cancelar
            </v-btn>
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              :loading="loadingDelete"
              rounded
              class="mr-1"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              @click="HandlerDelete"
            >
              Continuar
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RegisterImage from "../ImageUpload/Utils/registerImage";

export default {
  components: {
    "register-image": RegisterImage,
  },
  data() {
    return {
      loading: false,
      country_id: "",
      name: "",
      symbol: "",
      code: "",
      dataUpdate: {},
      action: 0,
      headers: [
        { text: "Imagen", sortable: false, value: "name" },
        { text: "Acciones", sortable: false, value: "action" },
      ],
      dataCountry: [],
      dataImages: [],
      showRegisterImage: false,
      page: 1,
      pagination: {},

      showDelete: false,
      loadingDelete: false,
      publicationData: null,
      imagesData: "",
    };
  },

  created() {
    this.HandlerGetImages();
  },

  watch: {
    page(val) {
      this.HandlerGetImages(val);
    },
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    async HandlerCopy(item) {
      await this.$copyText(item);
      this.$snotify.success(`Url copiado`, "Exitos");
    },

    async HandlerGetImages(page) {
      try {
        this.loading = true;
        const request = {
          page: page || 1,
          per_page: 20,
          name: "",
          paginate: true,
        };
        const response = await this.$store.dispatch(
          "images/GET_PHOTOS",
          request
        );
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataImages = response.data.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerRegisterCountry() {
      try {
        const request = {
          country_id: this.country_id,
          name: this.name,
          symbol: this.symbol,
          code: this.code,
        };

        const response = await this.$store.dispatch(
          "country/REGISTER_CURRENCY",
          request
        );

        const indexupdate = this.dataImages
          .map((element) => element.id)
          .indexOf(response.data.data[0].id);

        if (indexupdate > -1) {
          this.dataImages.splice(indexupdate, 1, response.data.data[0]);
        }

        this.showRegisterImage = !this.showRegisterImage;
        this.$snotify.success("Se ha registrado correctamente", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },

    HandlerEdit(item) {
      this.dataUpdate = { ...item };
      this.action = 1;
      this.showRegisterImage = !this.showRegisterImage;
    },

    HandlerShowDelete(image) {
      this.showDelete = true;
      this.imagesData = image;
    },

    async HandlerDelete() {
      try {
        this.loadingDelete = true;
        const request = { id: this.imagesData.id };
        await this.$store.dispatch("images/DELETE_PHOTOS", request);
        this.HandlerGetImages();
        this.$snotify.success("Se ha eliminado correctamente", "Exitos!");
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error", "Error!");
      } finally {
        this.showDelete = false;
        this.loadingDelete = false;
      }
    },

    HandlerAddImage() {
      this.dataUpdate = {
        price: {},
        store: {},
      };
      this.showRegisterImage = !this.showRegisterImage;
      this.action = 0;
    },

    HandlerResponse() {
      this.page = 1;
      this.showRegisterImage = false;
      // if (action === 0) {
      this.HandlerGetImages();
      // }
    },
  },
};
</script>

<style>
</style>