<template>
  <v-container fluid>
    <filters-component
      v-if="status_card"
      :paginate="paginate"
      :page="page"
      @filters:change="HandlerFilterResponse"
    />
    <v-row class="mt-10">
      <v-col cols="12" md="12">
        <v-card :class="HandlerResponsive">
          <v-toolbar flat color="orange darken-3" dark class="px-md-10">
            <v-toolbar-title>Estatus del envio</v-toolbar-title>
          </v-toolbar>
          <v-tabs
            color="orange darken-3"
            v-model="tab"
            align-with-title
            class="my-3 ml-md-5"
          >
            <v-tabs-slider color="orange darken-3"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              <v-badge
                v-if="currentTab == item"
                bordered
                bottom
                color="orange darken-3"
                :content="paginate.total"
                transition="slide-x-transition"
              >
                {{ item }}
              </v-badge>
              <span v-else>
                {{ item }}
              </span>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="12"
        v-for="(item, index) in dataOrder"
        :key="index"
        v-show="!loading"
      >
        <v-card flat class="elevation-0">
          <v-card-text>
            <v-card flat class="elevation-0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title
                          class="font-weight-text text-capitalize"
                          >{{ item.order.buyer.first_name }}
                          {{ item.order.buyer.last_name }}</v-list-item-title
                        >
                        <v-divider></v-divider>
                        <v-list-item-subtitle>
                          <div class="mt-3">
                            <span class="font-weight-text mr-2">Teléfono:</span>
                            <span>{{ item.order.buyer.phone }}</span>
                          </div>
                          <div>
                            <span class="font-weight-text mr-2">E-mail:</span>
                            <span>{{ item.order.buyer.email }}</span>
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          <div class="d-flex mt-15">
                            <v-btn
                              v-if="tab == 0"
                              :loading="loadingPrepared"
                              @click="HandlerToPrepared(item)"
                              class="white--text"
                              color="#3DB3E3"
                            >
                              Para Despachar
                            </v-btn>

                            <v-btn
                              v-if="tab == 1"
                              :loading="loadingPrepared"
                              @click="HandlerMoveToTransist(item)"
                              class="white--text"
                              color="light-green darken-3"
                            >
                              En Tránsito
                            </v-btn>

                            <v-btn
                              v-if="tab == 2"
                              :loading="loadingPrepared"
                              @click="HandlerMoveFinal(item)"
                              class="white--text"
                              color="orange darken-4"
                            >
                              Finalizado
                            </v-btn>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-text"
                          >Datos de Envio</v-list-item-title
                        >
                        <v-divider></v-divider>
                        <v-list-item-subtitle class="text-capitalize">
                          <div
                            v-if="
                              item.order != null &&
                              item.order.buyer != null &&
                              item.order.buyer.buyer_store != null &&
                              item.order.buyer.buyer_store.address != null
                            "
                          >
                            <div class="mt-3">
                              <span class="font-weight-text mr-2"
                                >Provincia:</span
                              >
                              <span>
                                {{
                                  item.order.buyer.buyer_store.address.location
                                }}
                              </span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2"
                                >Localidad:</span
                              >
                              <span>{{
                                item.order.buyer.buyer_store.address.location
                              }}</span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">
                                Código Postal:
                              </span>
                              <span>{{
                                item.order.buyer.buyer_store.address.zipcode
                              }}</span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">Calle:</span>
                              <span>{{
                                item.order.buyer.buyer_store.address.street
                              }}</span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">
                                Número de Calle:
                              </span>
                              <span>
                                {{
                                  item.order.buyer.buyer_store.address
                                    .street_number
                                }}
                              </span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">Piso:</span>
                              <span>
                                {{
                                  item.order.buyer.buyer_store.address
                                    .floor_number
                                }}
                              </span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2"
                                >Departamento:</span
                              >
                              <span>
                                {{
                                  item.order.buyer.buyer_store.address
                                    .department_number
                                }}
                              </span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">
                                Observaciones:
                              </span>
                              <span>
                                {{
                                  item.order.buyer.buyer_store.address
                                    .observations
                                }}
                              </span>
                            </div>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-text">
                          <div
                            class="d-flex"
                            v-if="
                              item.order_item != null &&
                              item.order_item.product != null
                            "
                          >
                            <span class="my-0 text-capitalize">
                              {{ item.order_item.product.name }}
                            </span>
                          </div>
                        </v-list-item-title>
                        <v-divider></v-divider>
                        <v-list-item-subtitle>
                          <div
                            class="mt-3"
                            v-if="
                              item.order_item != null &&
                              item.order_item.product != null
                            "
                          >
                            <div>
                              <span class="font-weight-text"> EAN: </span>
                              {{ item.order_item.product.barcode }}
                            </div>
                            <div>
                              <span class="font-weight-text mr-2"
                                >Cantidad:</span
                              >
                              <span>{{ item.order_item.quantity }}</span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">Venta:</span>
                              <span>#{{ item.order.meli_id }}</span>
                            </div>
                            <div>
                              <span class="font-weight-text mr-2">Envio:</span>
                              <span>#{{ item.meli_shippings_id }}</span>
                            </div>
                          </div>
                        </v-list-item-subtitle>
                        <v-list-item-title class="mt-2">
                          <v-divider></v-divider>
                          <p
                            class="text-uppercase font-weight-text mt-2"
                            style="color: #00a650"
                          >
                            Este paquete sera preparado antes de:
                          </p>
                          <div class="font-weight-text">
                            <!-- {{ item.date_created | currentDay }} -->
                            {{ getDate(item.date_created) }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="loading" cols="12" md="12" class="mt-15">
        <v-row justify="center">
          <v-progress-circular
            :size="300"
            :width="7"
            indeterminate
            color="#7E82FA"
          ></v-progress-circular>
        </v-row>
      </v-col>
      <v-col
        v-show="dataOrder.length == 0 && !loading"
        cols="12"
        md="12"
        class="mt-15"
      >
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-alert
              outlined
              color="orange darken-3"
              type="warning"
              prominent
              border="left"
            >
              <p class="text-center headline">No hay datos para mostrar</p>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="12" md="4">
        <v-pagination
          v-if="paginate.lastPage > 0"
          v-model="myPage"
          :length="paginate.lastPage"
          :total-visible="$vuetify.breakpoint.name == 'xs' ? 5 : 7"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Filters from "./Utils/Filters/filter";
import moment from "moment";

export default {
  components: {
    "filters-component": Filters,
  },
  data() {
    return {
      loading: false,
      page: 1,
      status_card: true,
      tab: null,
      operation_number: "",
      client_name: "",
      product_name: "",
      seller: "",
      dataSeller: [],
      dataStore: [],
      store: "",
      date: null,
      menu_start: false,
      date_end: null,
      menu_end: false,
      dataTypeSend: [],
      typeSend: "",
      items: [
        "Para preparar",
        "Listo para despachar",
        "EN TRANSITO",
        "FINALIZADO",
      ],
      // FILTROS
      isFilter: false,
      currentTab: "",
      loadingPrepared: false,
    };
  },

  created() {
    this.HandlerShowShipping();
  },

  beforeDestroy() {
    clearInterval();
    this.$store.commit("orders/CLEAR_STATE");
  },

  watch: {
    page(val) {
      this.HandlerShowShipping(val);
    },
    tab(val) {
      switch (val) {
        case 0:
          this.currentTab = "Para preparar";
          break;
        case 1:
          this.currentTab = "Para despachar";
          break;
        case 2:
          this.currentTab = "EN TRANSITO";
          break;
        case 3:
          this.currentTab = "FINALIZADO";
          break;
        default:
          break;
      }
      const searchQuery = { ...this.searchQuery };
      searchQuery.tabs_status = val + 1;
      searchQuery.page = 1;
      this.$store.commit("orders/SET_SEARCH_QUERY", searchQuery);
      this.HandlerShowShipping();
    },
  },

  filters: {
    currentDay(val) {
      return moment(val).format("DD-MM-YYYY");
    },
  },

  computed: {
    HandlerResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-start";
        case "sm":
          return "text-start";
        case "md":
          return "d-flex justify-center";
        case "lg":
          return "d-flex justify-center";
        case "xl":
          return "d-flex justify-center";
        default:
          return "d-flex justify-center";
      }
    },
    paginate() {
      return this.$store.getters["orders/GET_PAGINATE"];
    },
    dataOrder() {
      return this.$store.getters["orders/GET_DATA_ORDER"];
    },
    statusShip() {
      return this.$store.getters["orders/GET_DATA_STATUSHIP"];
    },
    searchQuery() {
      return this.$store.getters["orders/GET_DATA_FILTER"];
    },

    myPage: {
      get() {
        const paginate = this.$store.getters["orders/GET_PAGINATE"];
        return paginate.page;
      },
      set(val) {
        this.$store.commit("orders/SET_PAGINATE", val);
        const query = { ...this.searchQuery };
        query.page = val;
        this.$store.commit("orders/SET_SEARCH_QUERY", query);
        this.HandlerShowShipping();
      },
    },
  },

  methods: {
    async HandlerShowShipping() {
      try {
        this.loading = true;
        const request = this.searchQuery;
        const requestData = {
          ...request,
          warehouse_id: 3,
          country_id: 1
        };
        const response = await this.$store.dispatch(
          "orders/GET_SHIP",
          requestData
        );
        this.tab = response.data.data.status_tabs - 1;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerSearchFilter(request) {
      try {
        const requestData = { ...request };
        await this.$store.dispatch("orders/GET_SHIP", requestData);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerFilterResponse(loading) {
      this.loading = loading;
      this.tab = this.statusShip - 1;
    },

    async HandlerToPrepared(item) {
      try {
        this.loadingPrepared = true;
        const request = {
          shipping_id: item.id,
        };
        await this.$store.dispatch("orders/TO_PREPARED_ROS", request);
        setTimeout(() => {
          this.HandlerShowShipping();
        }, 500);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPrepared = false;
      }
    },

    async HandlerMoveToTransist(item) {
      try {
        const request = { shipping_id: item.id };
        const response = await this.$store.dispatch(
          "orders/MOVE_TO_TRANSIT",
          request
        );
        setTimeout(() => {
          this.HandlerShowShipping();
        }, 500);
        this.$snotify.success("Se ha movido el envio", "Exitos!");
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerMoveFinal(item) {
      try {
        const request = { shipping_id: item.id };
        await this.$store.dispatch("orders/MOVE_TO_DELIVERED", request);
        setTimeout(() => {
          this.HandlerShowShipping();
        }, 500);
        this.$snotify.success("Se ha movido el envio", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerPrintBill(item) {
      try {
        const request = { shipping_id: item.id };
        const response = await this.$store.dispatch(
          "orders/PRINT_INVOICE_DES",
          request
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.pdf`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPrintBill = false;
      }
    },

    getDate(date) {
      const dateParse = moment(date).format("YYYY-MM-DD HH:mm:ss");
      const numberDay = moment(dateParse).day();
      const dateHour = moment(dateParse).format("HH:mm:ss");

      console.log("dateParse", dateParse);
      console.log("numberDay", numberDay);

      // FORMATO 24HRS
      // const hour = moment(dateParse).format("HH:mm");
      // const dateHour = moment(hour, ["h:mm A"]).format("HH:mm:ss");

      if (numberDay > 0 && numberDay <= 5) {
        const format = "HH:mm:ss";
        const time = moment(dateHour, format);
        const firstTime = moment("00:00:00", format);
        const secondTime = moment("08:59:00", format);

        const firstTime2 = moment("09:00:00", format);
        const secondTime2 = moment("16:30:00", format);

        // console.log('time', time)
        // console.log('firstTime', firstTime)
        // console.log('secondTime', secondTime)

        /**
         * 00:00:00 / 08:59:00
         */
        if (time.isBetween(firstTime, secondTime)) {
          // console.log('primero',date)
          return moment(dateParse).format("YYYY-MM-DD") + " 9:30";
        } else if (time.isBetween(firstTime2, secondTime2)) {
          /**
           * 09:00:00 / 16:30:00
           */
          // console.log('segundo', date)

          const add30Min = moment(time).add(30, "m").format("HH:mm");
          return moment(dateParse).format("YYYY-MM-DD") + " " + add30Min;
        } else {
          const currentDay = moment(dateParse).format("YYYY-MM-DD");
          const addNextDay = moment(currentDay)
            .add(1, "d")
            .format("YYYY-MM-DD");
          return addNextDay + " " + "09:30";
        }
      } else {
        console.log("numberDay", numberDay);
        const number_diff = numberDay == 0 ? 1 : 2;
        const currentDay = moment(dateParse).format("YYYY-MM-DD");
        const addNextDay = moment(currentDay)
          .add(number_diff, "d")
          .format("YYYY-MM-DD");
        return addNextDay + " " + "09:30";
      }
    },
  },
};
</script>

<style>
</style>