<template>
  <section id="profile-show">
    <v-row justify="center" class="mt-2">
      <v-col cols="12" md="8">
        <v-card flat>
          <v-tabs color="primary">
            <v-tab>Datos</v-tab>
            <v-tab>Contraseña</v-tab>

            <v-tab-item>
              <v-card-text>
                <ValidationObserver ref="obs" v-slot="{ passes }">
                  <v-row align="end" justify="end">
                    <v-col cols="12" md="6" class="py-0">
                      <p class="subtitle-1 font-weight-bold secondary--text">
                        Datos personales
                      </p>
                      <ValidationProvider
                        name="nombre y apellido"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          required
                          label="Nombre y apellido"
                          v-model.trim="userdata.name"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                      <ValidationProvider
                        name="correo"
                        rules="email|required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          required
                          label="Correo"
                          v-model="userdata.email"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-btn
                        large
                        block
                        rounded
                        color="accent shadow-4"
                        @click="passes(UpdateData)"
                        >Actualizar</v-btn
                      >
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </v-card-text>
            </v-tab-item>

            <v-tab-item>
              <v-card-text>
                <ValidationObserver ref="passwordForm" v-slot="{ passes }">
                  <v-row dense>
                    <v-col cols="12" class="py-0">
                      <p class="subtitle-1 font-weight-bold secondary--text">
                        Contraseña actual
                      </p>
                      <ValidationProvider
                        name="contraseña actual"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          required
                          label="Contraseña actual"
                          :append-icon="
                            showOldPassword
                              ? 'mdi-eye-outline'
                              : 'mdi-eye-off-outline'
                          "
                          :type="showOldPassword ? 'text' : 'password'"
                          v-model="old_password"
                          :error-messages="errors"
                          @click:append="showOldPassword = !showOldPassword"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row dense align="end" justify="end">
                    <v-col cols="12" md="6" class="py-0">
                      <p class="subtitle-1 font-weight-bold secondary--text">
                        Nueva contraseña
                      </p>
                      <ValidationProvider
                        name="nueva contraseña"
                        rules="confirmed:confirmation|required|min:8"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          required
                          label="Contraseña"
                          :append-icon="
                            showPassword
                              ? 'mdi-eye-outline'
                              : 'mdi-eye-off-outline'
                          "
                          :type="showPassword ? 'text' : 'password'"
                          v-model="password"
                          :error-messages="errors"
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                      <ValidationProvider
                        name="confirmar contraseña"
                        rules="required"
                        vid="confirmation"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          required
                          label="Confirmar contraseña"
                          name="password"
                          :append-icon="
                            showConfirmPassword
                              ? 'mdi-eye-outline'
                              : 'mdi-eye-off-outline'
                          "
                          :type="showConfirmPassword ? 'text' : 'password'"
                          v-model="confirm_password"
                          :error-messages="errors"
                          @click:append="
                            showConfirmPassword = !showConfirmPassword
                          "
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="4">
                      <v-btn
                        :disabled="
                          [old_password, password, confirm_password].includes(
                            ''
                          )
                        "
                        large
                        rounded
                        block
                        color="accent shadow-4"
                        @click="passes(UpdatePassword)"
                        >Actualizar</v-btn
                      >
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </v-card-text>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      //Paginación
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      //Data actual
      olduserdata: {
        name: "",
        email: "",
      },
      //Data auxiliar para actualizar
      userdata: {
        name: "",
        email: "",
      },
      old_password: "",
      password: "",
      confirm_password: "",
      showOldPassword: false,
      showPassword: false,
      showConfirmPassword: false,
      headers: [
        {
          text: "Base de datos",
          value: "name",

          align: "left",
          sortable: false,
        },
        {
          text: "Dirección IP",
          value: "ip",

          align: "right",
          sortable: false,
        },
      ],
      search: "",

      dialog: false,

      Clients: []
    };
  },

  computed: {},

  created() {},

  mounted() {},

  methods: {
    async GetProfileInfo() {
      try {
        let data = await this.$store.dispatch("passport/ACTIVE_USER");
        this.olduserdata = JSON.parse(JSON.stringify(data.data.data));
        this.userdata = JSON.parse(JSON.stringify(data.data.data));
      } catch (error) {
        console.log(error);
      }
    },

    async UpdateData() {
      try {
        const enviar = {
          name: this.userdata.name !== null ? this.userdata.name : false,
          email: this.userdata.email,
        };

        let info = {
          value: {
            ...enviar,
          },
          id: this.userdata.id,
        };

        await this.$store.dispatch("passport/UPDATE_USER", info);
        this.$snotify.success("Tus datos han sido actualizado.", "¡Genial!");
        let newData = this.$store.getters["passport/CURRENT_USER"];
        this.olduserdata = JSON.parse(JSON.stringify(newData));
        this.userdata = JSON.parse(JSON.stringify(newData));
      } catch (e) {
        console.error(e.response.data.error);

        this.$snotify.error(
          "Revise los campos e intente nuevamente.",
          "¡Error!"
        );
      }
    },

    async UpdatePassword() {
      try {
        const enviar = {
          old_password: this.old_password,
          password: this.password,
          password_confirmation: this.confirm_password,
          id: this.userdata.id,
        };

        let response = await this.$store.dispatch(
          "passport/UPDATE_PASSWORD",
          enviar
        );

        if (response.status == 200) {
          this.$snotify.success(
            "Contraseña cambiada. No la olvides.",
            "¡Genial!"
          );

          this.old_password = "";
          this.password = "";
          this.confirm_password = "";

          this.$nextTick(() => {
            this.$refs.passwordForm.reset();
          });
        }
      } catch (error) {
        this.$snotify.error(error.response.data.error, "Error!");
        console.error(error);
      }
    },

    /**
     * [selectClient description]
     * Nota: Actualizar cliente asociado al usuario
     * solo para superusurios
     * @param  {number} client_id [description]
     * @return {Promise<any>}           [description]
     */
    async selectClient(client_id) {
      try {
        this.dialog = true;
        // console.log(client_id);
        await this.$store.dispatch("passport/UPDATE_TOKEN_CLIENT", client_id);
        this.dialog = false;
        alert("Cambio de servidor de base de datos, completado");
      } catch (error) {
        this.dialog = false;
        console.log(error);
        alert("No se pudo cambiar de servidor de base de datos");
      }
    },
  },
};
</script>

<style></style>
