<template>
  <div>
    <!-- <pre>
      {{ orderItem }}
    </pre> -->
    <v-data-table
      :headers="headers"
      :items="orderItem"
      :items-per-page="5"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:[`item.unit_price`]="{ item }">
        {{ item.unit_price | currency }}
      </template>
      <template v-slot:[`item.warehouse`]="{ item }">
        {{ item.warehouse != null ? item.warehouse.name : "" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    orderItem: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Cantidad",
          align: "left",
          sortable: false,
          value: "quantity",
          class: "primary--text",
        },
        {
          text: "Código",
          value: "seller_sku",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Descripción",
          value: "product.name",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Depósito",
          value: "warehouse",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Código de barras",
          value: "product.barcode",
          class: "primary--text",
          sortable: false,
        },
        {
          text: "Precio unitario",
          value: "unit_price",
          class: "primary--text",
          sortable: false,
        },
      ],
      dataProducts: [],
    };
  },
  filters: {
    currency(value) {
      const AMOUNT_FORMAT = new Intl.NumberFormat("es-AR", {
        currency: "ARS",
        style: "currency",
      }).format(value);
      return AMOUNT_FORMAT;
    },
  },
};
</script>

<style>
</style>