<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <p>Deposito que envia</p>
        <v-autocomplete
          filled
          rounded
          dense
          class="rounded-small"
          :items="getDeposits"
          item-text="name"
          item-value="id"
          single-line
          hide-details
          v-model="origin_id"
          label="Seleccione"
          @change="HandlerFilterDeposit"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <p>Deposito que recibe</p>
        <v-autocomplete
          filled
          rounded
          dense
          class="rounded-small"
          :items="items_deposit"
          item-text="name"
          item-value="id"
          single-line
          hide-details
          v-model="finality_id"
          label="Seleccione"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-5">
        <div v-if="origin_id > 0" class="font-caption">
          Productos que tengo en el deposito
          <span class="text-bold primary--text text-capitalize">
            {{ HandlerNameWarehouse() }}
          </span>
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
        v-for="(item, index) in items_products"
        :key="index"
      >
        <v-card class="elevation-0">
          <v-img
            class="white--text align-end"
            height="100px"
            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
          >
          </v-img>
          <v-card
            class="elevation-0"
            outlined
            style="
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            "
          >
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-caption">{{
                    item.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="font-caption"
                    >SKU: {{ item.sku }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="mt-2">
                    <v-text-field
                      required
                      filled
                      rounded
                      class="rounded-small font-caption"
                      dense
                      label="Cantidad"
                      color="blue-grey"
                      v-model="item.count"
                      single-line
                      hide-details
                      :hint="item.error"
                      persistent-hint
                      type="number"
                      :disabled="item.status ? true : false"
                    ></v-text-field>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-fab-transition>
              <v-btn
                :color="!item.status ? 'accent' : 'primary'"
                fab
                dark
                x-small
                absolute
                top
                right
                :disabled="item.status ? true : false"
                @click="HandlerAddProducts(item)"
              >
                <v-icon v-if="!item.status">mdi-plus</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-5">
        <div v-if="origin_id > 0" class="font-caption">
          Productos de salida
          <span class="text-bold primary--text text-capitalize">
            {{ HandlerNameWarehouse() }}
          </span>
        </div>
        <v-divider></v-divider>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="2"
        v-for="(item, index) in items_add_products"
        :key="index"
      >
        <v-card flat>
          <v-img
            class="white--text align-end"
            height="100px"
            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
          >
          </v-img>
          <v-card outlined
            style="
              border-top-left-radius: 0px !important;
              border-top-right-radius: 0px !important;
            ">
            <v-list>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title class="font-caption">{{
                    item.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="font-caption"
                    >SKU: {{ item.sku }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="font-caption"
                    >Cantidad: {{ item.count }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-fab-transition>
              <v-btn
                :color="!item.status ? 'accent' : 'primary'"
                fab
                dark
                x-small
                absolute
                top
                right
                @click="HandlerSubProducts(item)"
              >
                <v-icon v-if="!item.status">mdi-plus</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    products: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      items_deposit: [],
      items_products: [],
      items_add_products: [],
      origin_id: "",
      finality_id: "",
    };
  },

  watch: {
    page(val) {
      this.HandlerGetProducts(val);
    },
    items_add_products(val) {
      this.$emit("dialog:change", val);
    }
  },

  created() {
    this.HandlerGetDeposits();
  },
  computed: {
    getDeposits() {
      return this.$store.getters["deposits/GET_DEPOSITS"];
    },

    getProducts() {
      return this.$store.getters["products/GET_PRODUCTS"];
    },

    getPaginate() {
      return this.$store.getters["products/GET_PAGINATE"];
    },
  },
  methods: {
    async HandlerGetDeposits(page) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: true,
          country_id: 0,
          state_id: 0,
          location_id: 0,
        };
        await this.$store.dispatch("deposits/GET_DEPOSITS", request);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    HandlerRegisterTransaction() {
      try {
        console.log("registrar");
      } catch (error) {
        console.log(error);
      }
    },

    HandlerNameWarehouse() {
      try {
        const date_name = this.getDeposits.filter(
          (index) => index.id == this.origin_id
        );

        return date_name[0].name;
      } catch (error) {
        console.log("error", error);
      }
    },

    async HandlerFilterDeposit(page) {
      try {
        const myPage = page || 1;

        const request = {
          page: myPage,
          per_page: 15,
          origin_id: this.origin_id,
        };

        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS_STOCK",
          request
        );

        this.items_deposit = this.getDeposits.filter(
          (index) => index.id !== this.origin_id
        );

        this.items_products = response.data.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },

    HandlerAddProducts(item_product) {
      try {
        let product = this.items_products.find(
          (product) => product.sku == item_product.sku
        );

        product.status = !product.status;
        const status_items = this.items_add_products.some(
          (element) => element.sku == item_product.sku
        );

        if(!item_product?.count) item_product.count = 1 

        if (status_items == false) this.items_add_products.push(item_product);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerSubProducts(item_product) {
      try {
        let product = this.items_products.find(
          (product) => product.sku == item_product.sku
        );
        product.status = !product.status;
        const indexDelete = this.items_add_products.indexOf(item_product);
        if (indexDelete > -1) this.items_add_products.splice(indexDelete, 1);
        // console.log('product',array_new)
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>