<template>
  <v-container fluid>
    <v-row v-if="!showData && !showEdit">
      <v-col cols="12">
        <div
          class="font-weight-medium primary--text mb-5"
          style="font-size: 26px"
        >
          Listados de clientes
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="store_id"
          :items="data_stores"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Nombre"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="name"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Email"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="email"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Numero de documento"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="doc_number"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Numero de sku"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="sku"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          label="Dias de creacion carrito"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="days_shopping_cart"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row
      class="mx-2 my-1"
      justify="space-between"
      v-if="!showData && !showEdit"
    >
      <v-col cols="12" md="4" class="d-flex justify-start"> </v-col>
      <v-col class="d-flex justify-end" cols="12" md="2">
        <v-spacer></v-spacer>
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetClients()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!showData && !showEdit && !loadingTable">
      <v-col cols="12" md="12">
        <v-data-table
          :loading="loadingTable"
          :headers="headers"
          :items="getProducts"
          hide-default-footer
          disable-pagination
          fixed-header
          :page.sync="page"
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.fullname`]="{ item }">
            <span class="text-capitalize">
              {{ item.denominations.fullname }}
            </span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span>{{ item.denominations.email }}</span>
          </template>
          <template v-slot:[`item.province`]="{ item }">
            <span class="text-uppercase">{{ item.denominations.state }}</span>
          </template>
          <template v-slot:[`item.city`]="{ item }">
            <span class="text-uppercase">{{
              item.denominations.location
            }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn fab @click="moreData(item)" x-small color="primary">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              fab
              @click="editClient(item)"
              x-small
              color="primary"
              class="ml-1"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end" v-if="!showData && !showEdit && !loadingTable">
      <v-col cols="6" md="4">
        <v-pagination
          v-model="page"
          :length="paginate.lastPage"
          circle
        ></v-pagination>
      </v-col>
    </v-row>
    <v-card v-if="showEdit" color="transparent" class="elevation-0">
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="
            () => {
              showEdit = false;
            }
          "
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn
          class="ml-2"
          color="primary"
          @click="HandlerUpdateClient()"
          :loading="loading"
          :disabled="!fieldsValidations()"
        >
          <p class="mb-0">Guardar</p>
        </v-btn>
      </div>
      <div class=" pt-3">
        <v-card class="px-3 py-3">
          <div id="denomination">
            <v-row>
              <v-col cols="12">
                <div class="font-weight-medium primary--text ml-3">
                  Datos del cliente
                  <span class="text-uppercase">({{ dataClient.store }})</span>
                </div>
                <v-divider class="mb-2"></v-divider>
                <div class="d-flex justify-space-between">
                  <v-col cols="4">
                    <v-text-field
                      label="Denominación"
                      filled
                      rounded
                      dense
                      class="rounded-small lessSpace"
                      v-model="dataClient.fullname"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Teléfono"
                      filled
                      rounded
                      dense
                      type="number"
                      class="rounded-small lessSpace"
                      disabled
                      v-model="dataClient.contact_phone"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Email"
                      filled
                      rounded
                      dense
                      required
                      class="rounded-small lessSpace"
                      v-model="dataClient.email"
                      :rules="validEmail"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="font-weight-medium primary--text ml-3">
                  Datos fiscales
                </div>
                <v-divider class="mb-2"></v-divider>
                <v-col cols="4">
                  <v-text-field
                    :label="countryDNI()"
                    filled
                    rounded
                    dense
                    :type="countryDNI() === 'RUT' ? 'text' : 'number'"
                    class="rounded-small lessSpace"
                    v-model="dataClient.doc_number"
                    :rules="countryDNI() === 'RUT' ? docNumberRules : []"
                    maxlength="10"
                  />
                </v-col>
              </v-col>
              <v-col cols="12">
                <div class="font-weight-medium primary--text ml-3">
                  Dirección
                </div>
                <v-divider class="mb-2"></v-divider>
                <div class="d-flex justify-space-between">
                  <v-col cols="4">
                    <v-text-field
                      label="Codigo postal"
                      filled
                      rounded
                      dense
                      type="number"
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.zipcode"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Provincia"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.state"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Localidad"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.location"
                    />
                  </v-col>
                </div>
                <div class="d-flex justify-space-between">
                  <v-col cols="4">
                    <v-text-field
                      label="# Departamento"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.department_number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Piso"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.floor_number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Calle"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.street"
                    />
                  </v-col>
                </div>
                <div class="d-flex justify-space-between">
                  <v-col cols="4">
                    <v-text-field
                      label="# Calle"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.street_number"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Entre calles"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.between_streets"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Observaciones"
                      filled
                      rounded
                      dense
                      disabled
                      class="rounded-small lessSpace"
                      v-model="dataClient.observations"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </div>
    </v-card>
    <div v-if="loadingTable" class="d-flex justify-center">
      <v-progress-circular
        size="300"
        class="d-flex justify-center my-5"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-card v-if="showData" color="transparent" class="elevation-0">
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="backToList()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div class="px-2 py-2">
        <v-card class="px-2 py-2">
          <div id="denomination">
            <v-row>
              <v-col cols="12">
                <div class="font-weight-medium primary--text">
                  Datos del cliente
                  <span class="text-uppercase">({{ dataClient.store }})</span>
                </div>
                <v-divider class="mb-2"></v-divider>
                <div class="d-flex justify-space-between">
                  <span>
                    <div class="font-weight-medium">Denominación</div>
                    <div>{{ dataClient.fullname }}</div>
                  </span>

                  <span>
                    <div class="font-weight-medium">Última conexión</div>
                    <div>{{ dataClient.last_enter }}</div>
                  </span>

                  <span>
                    <div class="font-weight-medium">Telefono</div>
                    <div>{{ dataClient.contact_phone }}</div>
                  </span>

                  <span>
                    <div class="font-weight-medium">Email</div>
                    <div>{{ dataClient.email }}</div>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div class="font-weight-medium primary--text">
                  Datos fiscales
                </div>
                <v-divider class="mb-2"></v-divider>

                <span>
                  <span class="font-weight-medium">{{ countryDNI() }}</span>
                  {{ dataClient.doc_number }}</span
                >
              </v-col>
              <v-col cols="12">
                <div class="font-weight-medium primary--text">Dirección</div>
                <v-divider class="mb-2"></v-divider>
                <div class="d-flex">
                  <span>
                    <div class="font-weight-medium">Codigo postal</div>
                    <div>{{ dataClient.zipcode }}</div>
                  </span>

                  <span class="mx-15">
                    <div class="font-weight-medium">Provincia</div>
                    <div>{{ dataClient.state }}</div>
                  </span>

                  <span>
                    <div class="font-weight-medium">Localidad</div>
                    <div>{{ dataClient.location }}</div>
                  </span>
                </div>

                <div class="d-flex my-5">
                  <span>
                    <div class="font-weight-medium"># Departamento</div>
                    <div>{{ dataClient.department_number }}</div>
                  </span>

                  <span class="mx-15">
                    <div class="font-weight-medium">Piso</div>
                    <div>{{ dataClient.floor_number }}</div>
                  </span>

                  <span>
                    <div class="font-weight-medium">Calle</div>
                    <div>{{ dataClient.street }}</div>
                  </span>

                  <span class="mx-15">
                    <div class="font-weight-medium"># Calle</div>
                    <div>{{ dataClient.street_number }}</div>
                  </span>
                </div>

                <div class="d-flex mt-2">
                  <span>
                    <div class="font-weight-medium">Entre calles</div>
                    <div>{{ dataClient.between_streets }}</div>
                  </span>

                  <span class="ml-10">
                    <div class="font-weight-medium">Observaciones</div>
                    <div>{{ dataClient.observations }}</div>
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3">
          <div id="tracking">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium primary--text">
                  Visualización de publicaciones
                </div>
                <v-divider></v-divider>
                <v-data-table
                  class="mt-5"
                  :headers="headersTracking"
                  :items="publicationViews"
                  :items-per-page="5"
                ></v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3">
          <div id="products">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium primary--text">Contactos</div>
                <v-divider></v-divider>
                <v-data-table
                  class="mt-5"
                  :headers="headersContacts"
                  :items="contacts"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.channel`]="{}">
                    Email
                  </template>
                  <template v-slot:[`item.body`]="{ item }">
                    <button @click="OpenBodyModal(item)" class="btn-link">
                      Ver
                    </button>
                  </template>
                  <template v-slot:[`item.files`]="{ item }">
                    <button @click="OpenFilesModal(item)" class="btn-link">
                      Ver
                    </button>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3">
          <div id="products">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium primary--text">
                  Carritos de compra
                </div>
                <v-divider></v-divider>
                <v-data-table
                  class="mt-5"
                  :headers="headersShoppingCart"
                  :items="shoppingCart"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.is_sold`]="{ item }">
                    <span>{{ item.is_sold ? "Si" : "No" }}</span>
                  </template>
                  <template v-slot:[`item.products`]="{ item }">
                    <button @click="OpenProductsModal(item)" class="btn-link">
                      Ver
                    </button>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="px-2 py-2 mt-3" v-if="store_id === 1">
          <div id="products">
            <v-row>
              <v-col cols="12" md="12">
                <div class="font-weight-medium primary--text">
                  Subscripciones
                </div>
                <v-divider></v-divider>
                <v-data-table
                  class="mt-5"
                  :headers="headersSubscriptions"
                  :items="subscriptions"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.sent`]="{ item }">
                    <span>{{ item.sent ? "Si" : "No" }}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <custom-modal
          v-if="showBodyModal"
          :active="showBodyModal"
          :closeModal="HandlerCloseModal"
          :title="'Cuerpo del correo'"
          max-width="800"
        >
          <div v-if="loadingItemData" class="d-flex justify-center">
            <v-progress-circular
              size="150"
              class="d-flex justify-center my-5"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
          <div v-else>
            <embed :src="urlEmailBody" width="100%" height="600px" />
          </div>
        </custom-modal>
        <custom-modal
          v-if="showFilesModal"
          :active="showFilesModal"
          :closeModal="HandlerCloseModal"
          :title="'Archivos adjuntos'"
        >
          <div class="content-modal" v-if="itemSelected.attachments[0]">
            <div
              class="cost-row"
              v-for="file in itemSelected.attachments"
              :key="file"
            >
              <div class="modal-text">{{ file }}</div>
              <div v-if="isLoadingItem(file)" class="d-flex justify-center">
                <v-progress-circular
                  size="22"
                  class="d-flex justify-center"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
              <span
                @click="() => HandlerShowFile(file)"
                class="link"
                role="button"
                v-else
              >
                <v-icon>
                  mdi-eye
                </v-icon>
              </span>
            </div>
          </div>
          <div class="content-modal" v-if="!itemSelected.attachments[0]">
            <div class="modal-text">No hay archivos subidos.</div>
          </div>
        </custom-modal>
      </div>
    </v-card>
    <custom-modal
      v-if="showProductsModal"
      :active="showProductsModal"
      :closeModal="HandlerCloseShoppingModal"
      :title="'Productos'"
      max-width="800"
    >
      <v-data-table
        class="mt-5"
        :headers="headersProductsShoppingCart"
        :items="productsSelected"
        hide-default-footer
      >
        <template v-slot:[`item.value`]="{ item }">
          <div>${{ item.quantity * item.pvp_unit }}</div>
        </template>
      </v-data-table>
    </custom-modal>
  </v-container>
</template>

<script>
import CustomModal from "../CustomModal/customModal.vue";
export default {
  components: {
    "custom-modal": CustomModal,
  },
  data() {
    return {
      showBodyModal: false,
      showFilesModal: false,
      itemSelected: {},
      loadingItemData: false,
      loadingTable: false,
      loadingItem: null,
      urlEmailBody: null,
      showProductsModal: false,
      productsSelected: [],
      headers: [
        {
          text: "Denominación",
          align: "start",
          sortable: false,
          value: "fullname",
          class: "primary--text",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        // {
        //   text: "Status",
        //   align: "start",
        //   sortable: false,
        //   value: "verify",
        //   class: "primary--text",
        // },
        {
          text: "Ultimo Contacto",
          align: "start",
          sortable: false,
          value: "denominations.last_enter",
          class: "primary--text",
        },
        {
          text: "Provincia",
          align: "start",
          sortable: false,
          value: "province",
          class: "primary--text",
        },
        {
          text: "Ciudad",
          align: "start",
          sortable: false,
          value: "city",
          class: "primary--text",
        },
        // {
        //   text: "Código Postal",
        //   align: "start",
        //   sortable: false,
        //   value: "denominations.zipcode",
        //   class: "primary--text",
        // },
        {
          text: "Accion",
          align: "start",
          sortable: false,
          value: "action",
          class: "primary--text",
        },
      ],
      headersTracking: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Publicación",
          align: "start",
          sortable: false,
          value: "publication.keywords",
          class: "primary--text",
        },
      ],
      headersCart: [
        {
          text: "Marca Modelo",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Cantidad",
          align: "start",
          sortable: false,
          value: "quantity",
          class: "primary--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "status",
          class: "primary--text",
        },
      ],
      headersContacts: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Cuerpo",
          align: "center",
          sortable: false,
          value: "body",
          class: "primary--text",
        },
        {
          text: "Canal",
          align: "center",
          sortable: false,
          value: "channel",
          class: "primary--text",
        },
        {
          text: "Archivos adjuntos",
          align: "center",
          sortable: false,
          value: "files",
          class: "primary--text",
        },
      ],
      contacts: [],
      headersShoppingCart: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Vendido",
          align: "center",
          sortable: false,
          value: "is_sold",
          class: "primary--text",
        },
        {
          text: "Productos",
          align: "center",
          sortable: false,
          value: "products",
          class: "primary--text",
        },
      ],
      headersProductsShoppingCart: [
        {
          text: "Nombre",
          sortable: false,
          value: "product.name",
          align: "left",
          class: "primary--text",
        },
        {
          text: "SKU",
          sortable: false,
          value: "product.sku",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "quantity",
          align: "center",
          class: "primary--text",
        },
        {
          text: "Valor",
          sortable: false,
          value: "value",
          align: "center",
          class: "primary--text",
        },
      ],
      headersSubscriptions: [
        {
          text: "Fecha",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "ID de publicación",
          align: "center",
          sortable: false,
          value: "publication_id",
          class: "primary--text",
        },
        {
          text: "Correo",
          align: "center",
          sortable: false,
          value: "email",
          class: "primary--text",
        },
        {
          text: "Nombre",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Teléfono",
          align: "center",
          sortable: false,
          value: "phone",
          class: "primary--text",
        },
        {
          text: "Notificación enviada",
          align: "center",
          sortable: false,
          value: "sent",
          class: "primary--text",
        },
      ],
      shoppingCart: [],
      subscriptions: [],
      getProducts: [],
      data_stores: [],
      publicationViews: [],
      store_id: 1,
      paginate: {},
      page: 1,
      per_page: 20,
      doc_number: "",
      name: "",
      email: "",
      sku: "",
      days_shopping_cart: "",
      showData: false,
      showEdit: false,
      dataClient: {},
      loading: false,
      docNumberRules: [(v) => /^\d{8,9}(k)?$|^\d{9,10}$/.test(v)],
      validEmail: [
        // eslint-disable-next-line no-useless-escape
        (v) =>
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/.test(
            v
          ),
      ],
    };
  },

  created() {
    this.HandlerGetClients();
    this.HandlerGetStore();
    const clientEmailParam = this.$route.params.clientId;
    const storeIdParam = this.$route.params.store_id;
    if(clientEmailParam){
      this.HandlerGetClientData(clientEmailParam, storeIdParam);
    }
  },

  watch: {
    page() {
      this.HandlerGetClients();
    },
    store_id() {
      this.HandlerGetClients();
    },
  },

  filters: {
    city(val) {
      switch (val) {
        case "1":
          return "Argentina";
        case "2":
          return "Chile";
        case "3":
          return "Mexico";
        default:
          break;
      }
    },

    origin(val, data_stores) {
      const getStore = data_stores.find((st) => st.id === val);
      if (getStore != undefined) {
        return getStore.name;
      }
    },
  },

  methods: {
    async HandlerGetClients() {
      try {
        this.loadingTable = true;
        const request = {
          b2b_status: false,
          buyer_store: 1,
          store_id: this.store_id,
          page: this.page,
          per_page: this.per_page,
          doc_number: this.doc_number,
          name: this.name,
          email: this.email,
          sku: this.sku,
          days_shopping_cart: this.days_shopping_cart,
        };
        const response = await this.$store.dispatch(
          "usersIndex/INDEX_CLIENTS",
          request
        );
        this.getProducts = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
      } catch (error) {
        console.log(error);
      } finally{
        this.loadingTable = false;
      }
    },

    async HandlerGetClientData(clientEmailParam, storeIdParam){
      try {
        const request = {
          b2b_status: false,
          buyer_store: 1,
          store_id: storeIdParam,
          page: this.page,
          per_page: this.per_page,
          doc_number: this.doc_number,
          name: this.name,
          email: clientEmailParam,
          sku: this.sku,
          days_shopping_cart: this.days_shopping_cart,
        };
        const response = await this.$store.dispatch(
          "usersIndex/INDEX_CLIENTS",
          request
        );
        const UserData = response?.data?.data?.data[0];
        if(UserData){
          this.moreData(UserData)
        }
      } catch (error) {
        console.log(error);
      }

    },

    isLoadingItem(id) {
      return this.loadingItem === id;
    },

    async GetEmailsClients(item) {
      try {
        const request = {
          buyer_store_id: item.buyer_store_id,
          page: 1,
          perPage: 10,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_EMAIL_LIST",
          request
        );
        this.contacts = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerShowBody() {
      this.loadingItemData = true;
      try {
        const request = {
          email_history_id: this.itemSelected.id,
          file_name: "email_body.pdf",
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_URL_EMAIL_FILE",
          request
        );
        const url = response.data.signedURL;
        this.urlEmailBody = url;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingItemData = false;
      }
    },

    async HandlerShowFile(file) {
      this.loadingItem = file;
      try {
        const request = {
          email_history_id: this.itemSelected.id,
          file_name: file,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_URL_EMAIL_FILE",
          request
        );
        const url = response.data.signedURL;
        window.open(url, "_blank");
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingItem = null;
      }
    },

    OpenBodyModal(item) {
      this.itemSelected = { ...item };
      this.showBodyModal = true;
      this.HandlerShowBody();
    },

    OpenFilesModal(item) {
      this.itemSelected = { ...item };
      this.showFilesModal = true;
    },

    HandlerCloseModal() {
      this.showBodyModal = false;
      this.showFilesModal = false;
      this.itemSelected = {};
      this.urlEmailBody = null;
    },

    async GetShoppingCart(item) {
      try {
        const request = {
          buyer_store_id: item.buyer_store_id,
          page: 1,
          perPage: 10,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_BUYER_SHOPPING_CART",
          request
        );
        this.shoppingCart = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async GetSubscriptions(item) {
      try {
        const request = {
          buyer_store_id: item.buyer_store_id,
        };
        const response = await this.$store.dispatch(
          "usersIndex/GET_BUYER_SUBSCRIPTIONS",
          request
        );
        this.subscriptions = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    countryDNI() {
      if (this.dataClient.doc_type) {
        return this.dataClient.doc_type;
      } else if (this.dataClient?.store?.includes("Chile")) {
        return "RUT";
      } else if (this.dataClient?.store?.includes("Mexico")) {
        return "CI";
      } else {
        return "CUID";
      }
    },

    fieldsValidations() {
      switch (true) {
        case !this.validEmail[0](this.dataClient.email):
          return false;
        case this.countryDNI() !== "RUT":
          return true;
        case !this.dataClient.doc_number:
          return true;
        case !this.docNumberRules[0](this.dataClient.doc_number):
          return false;
        default:
          return true;
      }
    },

    async HandlerUpdateClient() {
      if (this.fieldsValidations()) {
        this.loading = true;
        try {
          const request = {
            id: this.dataClient.id,
            email: this.dataClient.email,
            doc_number: this.dataClient.doc_number,
            store_id: this.store_id,
            fullname: this.dataClient.fullname,
          };
          await this.$store.dispatch("usersIndex/UPDATE_CLIENTS", request);
          this.showEdit = false;
          this.HandlerGetClients();
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      }
    },

    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 10,
          paginate: false,
          name: "",
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.data_stores = response.data.data.filter((index) => {
          return index.b2b_active == false;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerCopy(item) {
      const email = item.email;
      await this.$copyText(email);
      this.$snotify.success(`Correo copiado`, "Exitos");
    },

    HandlerReset() {
      this.doc_number = "";
      this.name = "";
      this.email = "";
      this.store_id = 1;
      this.sku = "";
      this.HandlerGetClients();
    },

    getAddress(address, value) {
      const principal = address.buyer_store.find((val) => {
        if (val.address != null && val.address.status == true) {
          return val;
        }
      });

      if (principal === undefined && value == "store_id") {
        return address.buyer_store[0].store_id;
      }

      if (principal != undefined && principal.address != null) {
        return principal.address[value];
      } else {
        return "";
      }
    },

    async getPublicationViews(buyerId) {
      try {
        const request = {
          buyer_store_id: buyerId,
          page: 1,
          perPage: 10,
        };
        const response = await this.$store.dispatch(
          "publications/GET_PUBLICATION_VIEWS",
          request
        );
        this.publicationViews = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    moreData(item) {
      this.showData = true;
      this.dataClient = { ...item.denominations };
      this.getPublicationViews(item.denominations.buyer_store_id);
      this.GetEmailsClients(item.denominations);
      this.GetShoppingCart(item.denominations);
      this.GetSubscriptions(item.denominations);
    },

    backToList(){
      this.showData = false;
      this.publicationViews = [];
      this.contacts = [];
      this.shoppingCart = [];
      this.subscriptions = [];
    },

    editClient(item) {
      this.showEdit = true;
      this.dataClient = { ...item.denominations };
    },

    OpenProductsModal(item) {
      this.productsSelected = item.shopping_cart_items;
      this.showProductsModal = true;
    },

    HandlerCloseShoppingModal() {
      this.showProductsModal = false;
      this.productsSelected = [];
    },
  },
};
</script>

<style>
.btn-link {
  color: #2196f3;
  text-decoration: underline;
}
.content-modal {
  margin: 14px 0 -14px 0;
}
.cost-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.modal-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 500;
}
</style>
