<template>
  <v-container>
    <v-row>
      <register-component
        v-if="activeAddData"
        :active="activeAddData"
        :data="dataAdd"
        :dataBrand="dataBrand"
        :action="actionAdd"
        :dataPublication="dataPublication"
        @dialog:change="HandlerResponse"
      />
    </v-row>
    <div v-if="actionAdd == 0">
      <v-row>
        <!-- <v-col cols="3">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Ultimo 60 dias"
                filled
                rounded
                dense
                class="rounded-small"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              range
              v-model="date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-col> -->
        <v-col cols="3">
          <v-select
            label="Pais"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="country_id"
            :items="data_countries"
            item-text="name"
            item-value="id"
            @change="HandlerGetPublicationML"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            label="Todas las cuentas"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="seller_id"
            :items="data_stores_items"
            item-text="name"
            :item-value="item => item"
            @change="HandlerGetPublicationML"
          ></v-select>
        </v-col>
        <!-- <v-col>
          <v-btn color="success" @click="initSocketProduct()"
            >socket arriba</v-btn
          >
          <v-btn color="white" @click="close()">socket cerrado</v-btn>
        </v-col> -->
        <!-- <v-col cols="3">
          <v-select
            :disabled="seller_id === null"
            label="Todas las tiendas"
            filled
            rounded
            dense
            class="rounded-small"
            v-model="seller_id"
            :items="data_meli_brand"
            item-text="name"
            item-value="seller_id"
          ></v-select>
        </v-col> -->
      </v-row>
      <!-- <v-row>
        <v-col>
          <div class="text-center d-flex pb-4">
            <v-btn @click="all"> all </v-btn>
            <div>{{ panel }}</div>
            <v-btn @click="none"> none </v-btn>
          </div>
        </v-col>
      </v-row> -->
      <div v-if="statusLoading">
        <v-row>
          <v-col cols="12">
            <v-progress-linear
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col col="12">
            <v-card
              flat
              class="shadow-1 mt-5"
              v-for="(publication, i) in data_publication_ml"
              :key="i"
            >
              <v-card-title>
                <!-- {{ publication.product_id }} -->
                <v-avatar
                  tile
                  size="80"
                  style="cursor: pointer"
                  @click="HandlerLinkPublication(publication.link)"
                >
                  <img
                    :src="publication.picture"
                    :lazy-src="publication.picture"
                    :alt="publication.title"
                  />
                </v-avatar>
                <div class="ml-2">
                  <p class="mb-0">
                    {{ publication.title }}
                  </p>
                  <p class="font-weight-light">
                    {{ publication.item_id }}
                  </p>
                </div>
              </v-card-title>
              <v-card-text>
                <v-expansion-panels multiple focusable>
                  <v-progress-linear
                    v-if="loadingAnswer"
                    indeterminate
                  ></v-progress-linear>
                  <v-expansion-panel
                    v-for="(question, j) in publication.questions"
                    :key="j"
                    @click="HandlerShowItem(publication, question)"
                  >
                    <v-expansion-panel-header hide-actions>
                      <div>
                        <v-avatar
                          color="indigo"
                          size="10"
                          v-if="question.answer == null"
                        >
                        </v-avatar>
                        {{ question.text }}
                        <small>({{ question.nickname_meli_user }})</small>
                      </div>
                    </v-expansion-panel-header>

                    <!-- <v-expansion-panel-content class="mt-3">
                    <div v-if="question.answer !== null">
                      {{ question.answer.text }}
                    </div>
                    <div v-else class="d-flex">
                      <v-btn dark color="#FF6F00" rounded class="mt-3">
                        Re Asignar
                      </v-btn>
                    </div>
                  </v-expansion-panel-content> -->
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="6" md="4">
            <div>
              <v-pagination
                v-model="page"
                :length="pagination.lastPage"
                circle
              ></v-pagination>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import Register from "./Utils/register";
// import Ws from "@adonisjs/websocket-client";

export default {
  components: {
    "register-component": Register
  },
  data() {
    return {
      data_stores_ml: [],
      data_countries: [],
      data_publication_ml: [],
      data_stores_items: [],
      country_id: null,
      item_id: "",
      seller_id: null,
      answer: "",

      panel: [],
      items: 5,
      pagination: {},
      page: 1,

      activeAddData: false,
      actionAdd: 0,
      dataAdd: {},
      dataPublication: {},
      dataBrand: {},
      data_meli_brand: [],

      showAlertRole: false,
      loadingAnswer: false,
      date: [],
      menu2: false,
      role: "",
      statusLoading: false,
      auth_status: false,
      cont: 0
    };
  },

  watch: {
    page() {
      this.HandlerGetPublicationML();
    }
  },

  created() {
    this.init();
    this.HandlerGetMlAccounts();
    this.HandlerGetCountries();
    this.HandlerGetPublicationML();
  },

  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },

    // async initSocketProduct() {
    //   const inputToken = localStorage.getItem("token");

    //   /**
    //    * Si no esta loqgueado
    //    */
    //   if (inputToken == null) return;

    //   const ws = Ws("wss://productos.flextd.com", { reconnection: false });
    //   // const ws = Ws("wss://productos.flextd.com");

    //   // ws.connect()

    //   ws.withApiToken(inputToken).connect(); // token string
    //   window.client_token = inputToken;

    //   window.ws = ws;

    //   ws.on("error", (er) => {
    //     console.log("errrr", er);
    //   });

    //   // console.log("++++++++", ws);

    //   // const chat = ws.subscribe("chat")

    //   // connect to a non-dynamic channel,
    //   // you will receive notifications from all users,
    //   // it must be blocked from the backend
    //   // const notifications = ws.subscribe('notifications') // no

    //   // join the channel, and register a topic for the current user,
    //   // it remains to define how to uniquely identify the channels
    //   // const notify_key = "key_" + this.random(0, 500); // key_userId

    //   ws.on("open", () => {
    //     console.log("open socket");
    //   });

    //   ws.on("close", () => {
    //     console.log("close socket");
    //   });

    //   const notify = ws.subscribe("notify");
    //   window.notifyws = notify;

    //   notify.on("message", async (message) => {
    //     console.log("notify: receive message", message);

    //     // if (message.data.example == "question_new") {
    //     //   this.$snotify.info(message.message, "Exitos!", {
    //     //     position: "rightTop",
    //     //   });

    //     //   // this.$snotify.info(message.message, "Exitos!");
    //     // }
    //   });

    //   notify.on("ready", () => {
    //     console.log("notify: subscribe ready");
    //     // notifications.emit('message', 'hello')
    //   });

    //   notify.on("error", (error) => {
    //     console.log("notify: subscribe error");
    //     console.log(error);
    //   });

    //   notify.on("close", () => {
    //     console.log("notify: subscribe closed");
    //   });
    // },

    async init() {
      const notify = window.notifyws;

      notify.on("message", async message => {
        if (message.data.example == "question_new") {
          await this.HandlerGetPublicationML();
          this.$snotify.info(message.message, "Exitos!", {
            position: "rightTop"
          });

          // this.$snotify.info(message.message, "Exitos!");
        }

        if (message.data.example == "assign_question") {
          await this.HandlerGetPublicationML();
          this.$snotify.success(message.message, "Exitos!");
        }
      });

      notify.on("ready", () => {
        console.log("notify: subscribe ready");
        // notifications.emit('message', 'hello')
      });

      notify.on("error", error => {
        console.log("notify: subscribe error");
        console.log(error);
      });

      notify.on("close", () => {
        console.log("notify: subscribe closed");
      });
    },

    close() {
      // window.adwsProduct.close();
      window.notifyws.close();
    },

    // Create an array the length of our items
    // with all values as true
    all() {
      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panel = [];
    },

    async HandlerGetMlAccounts() {
      try {
        const response = await this.$store.dispatch("ml/GET_ML_ACCOUNT");
        this.data_stores_ml = response.data.data.filter(index => {
          return index.id !== 3;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetMlBrand() {
      try {
        const response = await this.$store.dispatch("ml/GET_MELI_BRAND", {
          id: this.seller_id.id
        });
        this.data_meli_brand = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerGetCountries() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.data_countries = response.data.data;
        console.log(this.data_countries);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },
    async HandlerGetPublicationML() {
      try {
        this.statusLoading = true;
        const request = {
          page: this.page,
          per_page: 10,
          paginate: true,
          country_id: this.country_id ?? "",
          seller_id: this.seller_id,
          auth_status: this.auth_status
        };

        if (this.country_id > 0) {
          this.data_stores_items = this.data_stores_ml.filter(index => {
            return index.country_id == this.country_id;
          });
        }

        const response = await this.$store.dispatch(
          "products/GET_PUBLICATION_ML",
          request
        );
        this.data_publication_ml = response.data.data.data;

        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.statusLoading = false;
      }
    },

    async HandlerShowItem(item, question) {
      try {
        this.loadingAnswer = true;
        const request = {
          question_id: question.id,
          type: "assign"
        };

        const response = await this.$store.dispatch(
          "products/REGISTER_ASSIGN_QUESTION",
          request
        );

        this.activeAddData = true;
        this.actionAdd = 2;
        this.dataAdd = { ...item.product };
        this.dataPublication = {
          title: item.title,
          item_id: item.item_id,
          picture: item.picture,
          sku: item.sku,
          question: question,
          logistic_type: item.logistic_type,
          re_asign: response.data.re_asign,
          country: item.country.name,
          country_id: item.country.id,
          brand: item.brand,
          cbt: question.cbt,
          user_registration: question.user_registration,
          user_total: question.user_total,
          price: item.price,
          available_quantity: item.available_quantity,
          catalog_listing: item.catalog_listing,
          mercado_libre: item.mercado_libre.name
        };

        this.auth_status = true;
        // await this.HandlerGetPublicationML();
      } catch (error) {
        const errorResponse = error.response?.data?.error?.err_message;
        if (
          errorResponse ===
          "Con el rol que posee no puede asignar la pregunta asi mismo."
        ) {
          this.showAlertRole = true;
        }
      } finally {
        this.loadingAnswer = false;
      }
    },

    HandlerLinkPublication(url) {
      window.open(url, "_blank");
    },

    HandlerResponse() {
      this.activeAddData = false;
      this.actionAdd = 0;
      this.dataAdd = {};
      this.dataPublication = {};
      this.HandlerGetPublicationML();
    }
  }
};
</script>

<style></style>
