<template>
  <ValidationObserver ref="obs" v-slot="{ passes }">
    <div class="font-weight-medium primary--text mb-5" style="font-size: 26px">
      Crear / Editar Categorias
    </div>
    <v-card flat class="shadow-5">
      <v-card-title class="pa-0 shadow-1">
        <v-img
          v-if="$route.name == 'categoria'"
          :src="require('@/assets/illustrations/bg_modal_card.svg')"
          cover
          position="left bottom"
          class="align-center"
          height="65px"
        >
          <v-card-title class="font-weight-medium primary--text">
            {{ Title }}
          </v-card-title>
        </v-img>
        <v-card-title
          v-if="$route.name == 'tienda'"
          class="font-weight-medium primary--text"
        >
          Registrar Tienda
        </v-card-title>
      </v-card-title>
      <v-card-text>
        <v-row v-if="checkbox" justify="start">
          <v-col md="6" class="mt-5">
            <ValidationProvider
              name="nombre"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="dataUpdate.name"
                filled
                rounded
                class="rounded-small"
                dense
                label="Nombre"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
            <div>
              <ValidationProvider
                name="nombre sub categoria"
                v-slot="{ errors }"
              >
                <v-text-field
                  @keyup.enter="HandlerAddData"
                  persistent-hint
                  hint="Este valor es opcional"
                  v-model="subCategoryName"
                  filled
                  rounded
                  class="rounded-small"
                  dense
                  label="Nombre Sub Categoria"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
              <v-row justify="end">
                <v-btn
                  text
                  rounded
                  :disabled="subCategoryName == ''"
                  @click="HandlerAddData"
                  class="shadow-2"
                  color="primary"
                >
                  Agregar
                </v-btn>
              </v-row>
            </div>
          </v-col>
          <v-col md="6" v-if="checkbox">
            <div>
              <v-card
                class="mt-2"
                flat
                v-if="dataUpdate.sub_category.length > 0"
              >
                <v-card-subtitle>Sub Categorias Agregadas</v-card-subtitle>
                <v-card-text>
                  <v-list-item
                    v-for="(item, index) in dataUpdate.sub_category"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-divider></v-divider>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-btn icon @click="HandlerDeleteSubCategory(item)">
                        <v-icon>mdi-delete-forever</v-icon>
                      </v-btn>
                    </v-list-item-icon>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row justify="end" class="mr-3 mb-3">
          <v-hover v-slot:default="{ hover }">
            <v-btn
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="closeModal({}, false)"
              >Cancelar</v-btn
            >
          </v-hover>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              rounded
              color="success"
              :class="hover ? 'shadow-2 mr-3' : 'shadow-4 mr-3'"
              class="mr-1"
              @click="passes(HandlerAction)"
              >Guardar</v-btn
            >
          </v-hover>
        </v-row>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
export default {
  props: {
    dataUpdate: {
      type: Object,
      required: false,
      defalut: () => {},
    },
    dataCategory: {
      type: Array,
      required: false,
      defalut: () => [],
    },
    action: {
      type: Number,
      required: false,
      defalut: 0,
    },
  },
  data() {
    return {
      checkbox: true,
      checkbox1: false,
      category_name: "",
      subCategories: [],
      subCategoryName: "",
      showInputSubCategory: false,
      updateSubCategory: {},
    };
  },
  created() {
    console.log(this.action);
    if (this.action == 1) {
      if (this.dataUpdate.sub_category.length > 0) {
        this.checkbox = true;
      }
    }
  },

  computed: {
    Title() {
      let title;
      if (this.action == "0") {
        title = "Nueva Categoria";
      } else {
        title = "Editar Categoria";
      }
      return title;
    },
  },

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerAddData() {
      if (this.dataUpdate.sub_category.length == 0) {
        this.dataUpdate.sub_category.push({ name: this.subCategoryName });
        this.subCategoryName = "";
      } else {
        const parse = this.dataUpdate.sub_category.map((val) => val.name);
        if (parse.includes(this.subCategoryName) == false) {
          this.dataUpdate.sub_category.push({ name: this.subCategoryName });
          this.subCategoryName = "";
        } else {
          this.$snotify.warning("Ya se encuentra registrado", "Alerta!");
        }
      }
    },

    HandlerDeleteSubCategory(item) {
      const indexDelete = this.dataUpdate.sub_category.indexOf(item);
      this.dataUpdate.sub_category.splice(indexDelete, 1);
    },

    HandlerAction() {
      if (this.action == "0") this.HandlerRegister();
      else this.HandlerUpdated();
    },

    async HandlerRegister() {
      try {
        if (this.subCategoryName != "") {
          this.subCategories.push({ name: this.subCategoryName });
          this.subCategoryName = "";
        }
        const request = {
          name: this.dataUpdate.name,
          subcategories: [...this.dataUpdate.sub_category],
        };

        const response = await this.$store.dispatch(
          "category/REGISTER_CATEGORIES",
          request
        );

        this.subCategories = [];
        this.$snotify.success("Registro Exitoso", "Exitos!");
        this.closeModal(response.data, 0);
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error", "Error!");
      }
    },

    async HandlerUpdated() {
      try {
        if (this.subCategoryName != "") {
          this.subCategories.push({ name: this.subCategoryName });
          this.subCategoryName = "";
        }

        const request = {
          data: {
            name: this.dataUpdate.name,
            subcategories: [...this.dataUpdate.sub_category],
          },
          id: this.dataUpdate.id,
        };

        console.log('request', request)

        const response = await this.$store.dispatch(
          "category/UPDATED_CATEGORIES",
          request
        );

        this.subCategories = [];
        this.$snotify.success("Registro Exitoso", "Exitos!");
        this.closeModal(response.data, 1);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>