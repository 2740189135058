<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" md="6">
        <h4>Categorias</h4>
        <v-card class="shadow-5">
          <v-data-table
            :headers="headers"
            :items="dataCategory"
            hide-default-footer
            :items-per-page="-1"
            class="elevation-0"
          >
            <template v-slot:[`item.sub_category`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerSubCategories(item)"
                    icon
                    color="white"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'"
                      >mdi-eye</v-icon
                    >
                  </v-btn>
                </template>
                <span>Ver Sub Categorias</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="d-flex">
                <v-tooltip bottom v-if="action == '0'">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="HandlerEdit(item)"
                      icon
                      dark
                      class="mt-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon :color="isDark ? 'white' : '#6D6D6D'"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>Editar Categoria</span>
                </v-tooltip>
                <v-file-input
                  v-if="!loadingFilePhoto"
                  class="mb-4"
                  multiple
                  label="File input"
                  hide-input
                  prepend-icon="mdi-image-album"
                  @change="HandlerUploadPhoto(item)"
                  v-model="filePhoto"
                ></v-file-input>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>

              <!-- <v-tooltip bottom v-if="action == '0'">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerDelete(item)"
                    icon
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'white' : '#6D6D6D'"
                      >mdi-trash-can</v-icon
                    >
                  </v-btn>
                </template>
                <span>Eliminar Categoria</span>
              </v-tooltip> -->
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex justify-end mt-3">
          <v-pagination
            v-if="Object.keys(paginate).length > 0"
            :length="paginate.lastPage"
            v-model="page"
            circle
          ></v-pagination>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <h4 v-if="categoryName != ''">{{ categoryName }} ( Sub Categoria )</h4>
        <h4 v-else>Seleccione una Sub Categoria</h4>
        <v-card class="shadow-5">
          <v-data-table
            :headers="headersSub"
            :items="dataSubCategory"
            :items-per-page="-1"
            hide-default-footer
            no-data-text="Selecciona una categoria"
            no-results-text="No tiene sub categorias registradas"
            class="elevation-0"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom v-if="action == 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="HandlerSubCategoriesDelete(item)"
                    icon
                    :color="isDark ? 'primary' : 'green'"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon :color="isDark ? 'primary' : 'green'"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </template>
                <span>Eliminar Sub Categoria</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    action: {
      type: String,
      required: false,
      default: "0",
    },
  },
  data() {
    return {
      page: 1,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Sub Categoria", value: "sub_category" },
        { text: "Accion", value: "action" },
      ],
      headersSub: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Accion", value: "action" },
      ],

      categoryName: "",
      dataSubCategory: [],
      dataUpdate: [],
      dataCategory: [],
      paginate: {},
      loadingFilePhoto: false,
      filePhoto: [],
    };
  },

  watch: {
    page(val) {
      this.HandlerGetCategory(val);
    },
  },

  created() {
    this.HandlerGetCategory();
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    async HandlerGetCategory(page) {
      console.log("0");
      try {
        const request = {
          page: page || 1,
          per_page: 20,
          paginate: true,
          name: "",
          store_id: "",
        };
        const response = await this.$store.dispatch(
          "category/GET_CATEGORIES",
          request
        );
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataCategory = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerSubCategoriesDelete(sub_category) {
      // console.log(sub_category)
      try {
        const request = {
          id: sub_category.id,
        };
        console.log("request", request);
        const response = await this.$store.dispatch(
          "category/DELETE_SUB_CATEGORY",
          request
        );
        console.log("eliminado", response);
        this.dataSubCategory = [];
        await this.HandlerGetCategory(this.page);
      } catch (error) {
        console.log(error);
      }
    },

    HandlerSubCategories(item) {
      this.categoryName = item.name;
      this.dataSubCategory = item.sub_category;
      if (item.length == 0) {
        this.$snotify.warning("No tiene sub categorias", "Alerta!");
      }
    },

    HandlerEdit(item) {
      this.$emit("dialog:change", item, 1);
    },

    async HandlerDelete(item) {
      try {
        const request = { id: item.id };
        await this.$store.dispatch("category/DELETE_CATEGORY", request);
        this.HandlerGetCategory();
        this.$snotify.success("Categoria Eliminada", "Exitos!");
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerUploadPhoto(item) {
      try {
        this.loadingFilePhoto = true;
        const request = new FormData();
        request.append("image", this.filePhoto[0]);
        request.append("category_id", item.id);
        // const requestData = { category_id: item.id };
        await this.$store.dispatch("category/UPDATE_IMAGE", {
          data: request,
        });
        this.$snotify.success("Cargado con exitos", "Exitos");
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFilePhoto = false;
      }
    },
  },
};
</script>

<style>
</style>