<template>
  <div>
    <p class="mb-0 mt-2">
      <span class="subtitle-2 font-weight-bold mr-1">Envio:</span>
      <span>#{{ order.meli_shippings_id }}</span>
    </p>
    <div v-if="order.shipping_method_name != null">
      <p class="mb-0">
        <span class="subtitle-2 font-weight-bold mr-1">
            Método:
        </span>
        <span>{{ order.shipping_method_name }}</span>
      </p>
      <p class="mb-0">
        <span class="subtitle-2 font-weight-bold mr-1">
          Metodo seguimiento:
        </span>
        <span>{{ order.tracking_method }}</span>
      </p>
      <p class="mb-0">
        <span class="subtitle-2 font-weight-bold mr-1">
          Seguimiento:
        </span>
        <span>{{ order.tracking_number }}</span>
      </p>
    </div>
    <div v-if="order.shipping_mode == 'to_be_agreed'">
      <p class="font-weight-bold mb-0">Datos de Contacto</p>
      <p class="mb-0">
        <span class="subtitle-2 font-weight-medium mr-1">
          Recibe/ Retira:
        </span>
        <span> {{ order.order.buyer.first_name }} </span>
        <span> {{ order.order.buyer.last_name }} </span>
      </p>
      <p class="mb-0">
        <span class="font-weight-bold mr-1">Teléfono:</span>
        <span>{{ order.order.buyer.phone }}</span>
      </p>
      <p class="buyer-contact email mb-0">
        <span font-weight-bold mr-1>Email:</span>
        <span>{{ order.order.buyer.email }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style>
</style>