<template>
  <section>
    <v-container fluid class="mt-5" v-if="!showTransferForm">
      <v-row style="justify-content: space-between; align-items: center;">
        <v-btn
          class="accent shadow-4 px-8"
          large
          rounded
          @click.stop="HandlerOpenTransferForm(true, 'transfer')"
        >
          Transferir inventario
        </v-btn>
      </v-row>
    </v-container>

    <div v-if="!showTransferForm">
      <v-card v-if="!loadingListData" class="shadow-1 mt-5">
        <v-col cols="12" sm="12" md="12" class="px-0 py-0 mt-8 rounded-small">
          <v-data-table
            :key="tableItems.length"
            :loading="false"
            :headers="headers"
            :items="tableItems"
            :search="search"
            :items-per-page="100"
            hide-default-footer
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.shipping_date`]="{ item }">
              {{ getDate(item.shipping_date) }}
            </template>
            <template v-slot:[`item.estimated_delivery_date`]="{ item }">
              {{ getDate(item.estimated_delivery_date) }}
            </template>
            <template v-slot:[`item.delivery_date`]="{ item }">
              {{ getDate(item.delivery_date) }}
            </template>
            <template v-slot:[`item.origin_warehouse_id`]="{ item }">
              {{ getWarehouseName(item.origin_warehouse_id) }}
            </template>
            <template v-slot:[`item.destination_warehouse_id`]="{ item }">
              {{ getWarehouseName(item.destination_warehouse_id) }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <button
                @click="OpenStatusModal(item)"
                :class="statusStyle(item)"
                :disabled="item.status !== 'IN_TRANSIT'"
              >
                {{ getStatus(item.status) }}
              </button>
            </template>
            <template v-slot:[`item.costsTotal`]="{ item }">
              <button @click="OpenCostsModal(item)" class="btn-link">
                {{ item.costsTotal }} $
              </button>
            </template>
            <template v-slot:[`item.products`]="{ item }">
              <button @click="OpenProductsModal(item)" class="btn-link">
                Ver
              </button>
            </template>
            <template v-slot:[`item.receipts`]="{ item }">
              <button @click="OpenreceiptsModal(item)" class="btn-link">
                Ver
              </button>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div
                :style="{ display: 'flex', justifyContent: 'center' }"
                v-if="item.status === 'IN_TRANSIT'"
              >
                <v-col>
                  <v-btn
                    small
                    @click="onCancelTransfer(item)"
                    :loading="cancelingTransfer === item.id"
                    >Cancelar
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn small @click="onOpenFormForEdit(item)">Editar </v-btn>
                </v-col>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-card>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          size="300"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
    <custom-modal
      v-if="showStatusModal"
      :active="showStatusModal"
      :action="HandlerStatus"
      :closeModal="HandlerCloseModal"
      :confirmBtn="true"
      :title="'¿El transporte llegó al destino?'"
      :closeText="'Cancelar'"
      :loader="loadingBtn"
    >
      <div class="content-modal">
        <h6>
          Haga clic en confirmar, para cambiar el estado de la transferencia de
          inventario de "En transito" a "Entregado".
        </h6>
      </div>
    </custom-modal>
    <custom-modal
      v-if="showCostsModal"
      :active="showCostsModal"
      :closeModal="HandlerCloseModal"
      :title="'Gastos asociados'"
    >
      <div class="content-modal" v-if="!loadingItemData">
        <div class="cost-row" v-for="cost in itemSelected.costs" :key="cost.id">
          <div class="modal-text">{{ cost.description }}</div>
          <div class="light-text">{{ cost.amount }}$</div>
        </div>
      </div>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          size="150"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </custom-modal>
    <custom-modal
      v-if="showProductsModal"
      :active="showProductsModal"
      :closeModal="HandlerCloseModal"
      :title="'Detalles de productos'"
    >
      <v-data-table
        v-if="!loadingItemData"
        :key="itemSelected.length"
        :loading="false"
        :headers="productHeader"
        :items="itemSelected.products"
        :items-per-page="itemsPerPage"
        hide-default-footer
        :page.sync="page"
        @page-count="pageCount = $event"
      >
        <template v-slot:[`item.cost_on_departure`]="{ item }">
          {{ item.cost_on_departure }}$
        </template>
      </v-data-table>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          size="150"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </custom-modal>
    <custom-modal
      v-if="showReceiptsModal"
      :active="showReceiptsModal"
      :closeModal="HandlerCloseModal"
      :title="'Comprobantes'"
    >
      <div
        class="content-modal"
        v-if="!loadingItemData && itemSelected.receipts[0]"
      >
        <div
          class="cost-row"
          v-for="receipt in itemSelected.receipts"
          :key="receipt.name"
        >
          <div class="modal-text">{{ receipt.name }}</div>
          <span
            @click="() => HandlerDownloadReceipt(receipt)"
            class="link"
            role="button"
          >
            <v-icon>
              mdi-download
            </v-icon>
          </span>
        </div>
      </div>
      <div
        class="content-modal"
        v-if="!loadingItemData && !itemSelected.receipts[0]"
      >
        <div class="modal-text">No hay comprobantes subidos.</div>
      </div>
      <div v-if="loadingItemData" class="d-flex justify-center">
        <v-progress-circular
          size="150"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </custom-modal>
    <transfer-component
      v-if="showTransferForm"
      :active="showTransferForm"
      :closeModal="HandlerOpenTransferForm"
      :deposits="getDeposits.map(({ id, name }) => ({ id, name }))"
      :originDeposit="deposits"
      :isTransfer="isTransfer"
      :transferEditData="itemSelected"
    />
  </section>
</template>

<script>
import CustomModal from "./customModal.vue";
import transferForm from "../Inventory/transferForm.vue";

import moment from "moment";
export default {
  components: {
    "custom-modal": CustomModal,
    "transfer-component": transferForm
  },
  data() {
    return {
      transferData: null,
      loadingListData: false,
      loadingItemData: false,
      loadingBtn: false,
      showStatusModal: false,
      showCostsModal: false,
      showProductsModal: false,
      showReceiptsModal: false,
      cancelingTransfer: null,
      showTransferForm: false,
      isTransfer: true,
      status_card: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      search: "",
      deposits: "",
      depositType: "",
      getDeposits: [],
      productsData: [],
      paginate: {},
      itemSelected: null,
      headers: [
        {
          text: "ID",
          sortable: false,
          value: "id",
          align: "center",
          class: "primary--text min-box2"
        },
        {
          text: "Fecha de envío",
          sortable: false,
          value: "shipping_date",
          align: "center",
          class: "primary--text min-box2"
        },
        {
          text: "Depósito origen",
          sortable: false,
          value: "origin_warehouse_id",
          align: "center",
          class: "primary--text min-box2"
        },
        {
          text: "Fecha estimada de recepción",
          sortable: false,
          value: "estimated_delivery_date",
          align: "center",
          class: "primary--text min-box"
        },
        {
          text: "Fecha de recepción",
          sortable: false,
          value: "delivery_date",
          align: "center",
          class: "primary--text min-box"
        },
        {
          text: "Depósito destino",
          sortable: false,
          value: "destination_warehouse_id",
          align: "center",
          class: "primary--text min-box"
        },
        {
          text: "Estado",
          sortable: false,
          value: "status",
          align: "center",
          class: "primary--text"
        },
        {
          text: "ML shipping ID",
          sortable: false,
          value: "mercadolibre_shipping_id",
          align: "center",
          class: "primary--text"
        },
        {
          text: "Gastos asociados (USD)",
          sortable: false,
          value: "costsTotal",
          align: "center",
          class: "primary--text min-box"
        },
        {
          text: "Productos",
          sortable: false,
          value: "products",
          align: "center",
          class: "primary--text"
        },
        {
          text: "Comprobante",
          sortable: false,
          value: "receipts",
          align: "center",
          class: "primary--text"
        },
        {
          text: "Acciones",
          sortable: false,
          value: "actions",
          align: "center",
          class: "primary--text"
        }
      ],
      productHeader: [
        {
          text: "Nombre",
          sortable: false,
          value: "name",
          align: "left",
          class: "primary--text"
        },
        {
          text: "SKU",
          sortable: false,
          value: "sku",
          align: "center",
          class: "primary--text"
        },
        {
          text: "Cantidad",
          sortable: false,
          value: "quantity",
          align: "center",
          class: "primary--text"
        },
        {
          text: "Valor",
          sortable: false,
          value: "cost_on_departure",
          align: "center",
          class: "primary--text"
        }
      ],
      tableItems: []
    };
  },

  created() {
    this.HandlerGetDeposits();
    this.HandlerGetProductID();
    this.HandlerGetInventoryTransfers();
  },

  watch: {
    page(val) {
      this.HandlerGetProduct(val);
    }
  },

  computed: {
    getPaginate() {
      return this.$store.getters["deposits/GET_PAGINATE"];
    }
  },

  methods: {
    async onCancelTransfer(item) {
      this.cancelingTransfer = item.id;
      await this.$store.dispatch("traceability/CANCEL_TRANSFER", item.id);
      this.cancelingTransfer = null;
      this.HandlerGetInventoryTransfers();
    },

    async onOpenFormForEdit(item) {
      await this.HandlerGetInventoryTransfersId(item.id);
      this.showTransferForm = true;
    },
    HandlerOpenTransferForm(value, type) {
      if (!value) {
        this.HandlerGetInventoryTransfers();
        this.itemSelected = null;
      }
      if (type == "entry") {
        this.isTransfer = false;
      } else {
        this.getDeposits = this.getDeposits.filter(
          elem => elem.country_id === 3
        );
        this.isTransfer = true;
      }
      this.showTransferForm = value;
    },

    statusStyle(item) {
      if (item.status == "DELIVERED") {
        return "btn-delivered";
      }
      if (item.status == "CANCELED") {
        return "btn-delivered btn-cancel";
      }
      return "btn-delivered btn-transit";
    },

    getWarehouseName(id) {
      if (id) {
        return this.getDeposits?.filter(elem => elem.id == id)[0]?.name;
      } else {
        return "Ingreso inventario";
      }
    },

    getStatus(item) {
      const statusDic = {
        IN_TRANSIT: "En transito",
        DELIVERED: "Entregado",
        CANCELED: "Cancelado"
      };
      return statusDic[item];
    },

    getDate(item) {
      if (item) {
        return moment(item)
          .locale("es")
          .format("L");
      } else {
        return "";
      }
    },

    HandlerCloseModal() {
      this.showStatusModal = false;
      this.showTransferForm = false;
      this.showCostsModal = false;
      this.showProductsModal = false;
      this.showReceiptsModal = false;
      this.itemSelected = null;
    },

    async HandlerStatus() {
      try {
        this.loadingBtn = true;
        await this.$store.dispatch(
          "traceability/CONFIRM_RECEIPT",
          this.itemSelected.id
        );
        this.$set(this.itemSelected, "status", "DELIVERED");
        const index = this.tableItems.findIndex(
          el => el.id === this.itemSelected.id
        );
        if (index !== -1) {
          this.$set(this.tableItems, index, this.itemSelected);
        }
        this.$snotify.success("Cambio de estado exitoso", "Exitos!");
        this.showStatusModal = false;
        this.itemSelected = {};
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
      }
    },

    OpenStatusModal(item) {
      this.showStatusModal = true;
      this.itemSelected = { ...item };
    },

    OpenCostsModal(item) {
      this.itemSelected = { ...item };
      this.showCostsModal = true;
      this.HandlerGetInventoryTransfersId(item.id);
    },

    OpenProductsModal(item) {
      this.itemSelected = { ...item };
      this.showProductsModal = true;
      this.HandlerGetInventoryTransfersId(item.id);
    },

    OpenreceiptsModal(item) {
      this.itemSelected = { ...item };
      this.showReceiptsModal = true;
      this.HandlerGetInventoryTransfersId(item.id);
    },

    async HandlerGetInventoryTransfers() {
      try {
        this.loadingListData = true;
        const response = await this.$store.dispatch(
          "traceability/GET_LIST_INVENTORY_TRANSFERS"
        );
        this.tableItems = response.data.reverse();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingListData = false;
      }
    },

    async HandlerGetInventoryTransfersId(id) {
      const index = this.tableItems.findIndex(el => el.id === id);
      try {
        this.loadingItemData = true;
        const response = await this.$store.dispatch(
          "traceability/GET_INVENTORY_TRANSFERS",
          id
        );
        this.$set(this.tableItems, index, response.data);

        this.itemSelected = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingItemData = false;
      }
    },

    async HandlerDownloadReceipt(item) {
      try {
        this.loadingBtn = true;
        const data = await this.$store.dispatch(
          "traceability/GET_RECEIPT_URL",
          item.id
        );
        const response = await fetch(data.data.url[0]);
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = item.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingBtn = false;
      }
    },

    async HandlerGetDeposits(page) {
      try {
        this.loadingListData = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: false,
          country_id: this.country_id == undefined ? "" : this.country_id,
          state_id: this.state_id == undefined ? "" : this.state_id,
          location_id: this.location_id == undefined ? "" : this.location_id
        };
        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS",
          request
        );
        // const depositsFromMexico = response.data.data.filter(
        //   elem => elem.country_id === 3
        // );
        this.getDeposits = response.data.data;
        // this.deposits = depositsFromMexico.fidepositsFromMexicond(elem => elem.id == 22);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingListData = false;
      }
    },

    async HandlerGetProduct(page = 1) {
      try {
        this.loadingListData = true;
        const request = {
          warehouse_id: this.deposits.id == undefined ? 5 : this.deposits.id,
          page: page,
          per_page: 50
        };
        const response = await this.$store.dispatch(
          "deposits/GET_PRODUCT_DEPOSITS",
          request
        );
        this.productsData = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingListData = false;
      }
    },

    async HandlerGetProductID(value) {
      try {
        this.loadingListData = true;
        const request = {
          warehouse_id: value == undefined ? 5 : value.id,
          page: 1,
          per_page: 50
        };
        const response = await this.$store.dispatch(
          "deposits/GET_PRODUCT_DEPOSITS",
          request
        );
        this.productsData = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total
        };

        if (this.productsData.length > 0) {
          this.status_card = false;
        } else {
          this.status_card = true;
        }
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingListData = false;
      }
    }
  }
};
</script>

<style>
.btn-link {
  color: #2196f3;
  text-decoration: underline;
  margin-right: 6px;
}
.btn-delivered {
  min-width: 86px;
  height: 32px;
  background-color: #2e7d32;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 13px;
}
.btn-transit {
  background-color: #ed6c02;
}
.btn-cancel {
  background-color: rgb(215, 9, 9);
}
.min-box {
  min-width: 140px !important;
}
.min-box2 {
  min-width: 130px !important;
}
.cost-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.modal-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 500;
}
.light-text {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 400;
}
.content-modal {
  margin: 14px 0 -14px 0;
}
.link {
  text-decoration: none !important;
}
</style>
