<template>
  <div>
    <!-- <v-btn readonly class="blue lighten-1" block dark>Ver movimientos</v-btn> -->
    <div v-if="order.user_btn_prepeared != null" class="mb-md-n5 pb-0">
      <span class="font-weight-bold subtitle-2 mr-1 mb-0"> Marcado: </span>
      {{ order.date_prepeared }}
      <span class="font-weight-bold subtitle-2 mr-1">por:</span>
      <span v-if="order.user_prepared != null">
        {{ order.user_prepared.email }}
      </span>
    </div>
    <div v-if="order.user_btn_delivered != null" class="mb-0 pb-0">
      <br />
      <span class="font-weight-bold">Marcado entregado el</span>
      {{ order.date_delivered }} por:
      {{ order.user_btn_delivered }}
    </div>
    <div v-if="order.user_btn_in_transit != null" class="mb-0 pb-0">
      <br />
      <span class="font-weight-bold">Marcado en tránsito el</span>
      {{ order.date_in_transit }} por:
      {{ order.user_btn_in_transit }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style>
</style>