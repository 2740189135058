<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row justify="end" class="mt-5">
        <v-col class="d-flex justify-end" cols="12" sm="4" md="4">
          <v-select
            @change="HandlerGetProductID"
            :items="getDeposits"
            item-text="name"
            :item-value="(item) => item"
            v-model="deposits"
            menu-props="auto"
            filled
            rounded
            dense
            class="rounded-small"
            name="ciudad"
            label="Deposito"
            single-line
            hide-details
          ></v-select>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="loadingBtn"
                fab
                small
                v-bind="attrs"
                v-on="on"
                color="primary"
                class="ml-3"
                @click="HandlerSyncContabillium()"
              >
                <v-icon>mdi-backup-restore</v-icon>
              </v-btn>
            </template>
            <span>Sincronizar Contabillium</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <div v-if="!status_card">
      <v-card v-if="!loadingData" class="shadow-1 mt-5">
        <v-data-table
          :loading="false"
          :headers="headers"
          :items="productsData"
          :search="search"
          hide-default-footer
          disable-pagination
          fixed-header
          height="530"
          :page.sync="page"
          @page-count="pageCount = $event"
        >
        <template v-slot:[`item.products_erp`]="{ item }">
            {{ item.products_erp[0].sku }}
          </template>
          <template v-slot:[`item.product_warehouse`]="{ item }">
            {{ item.product_warehouse[0].current_stock }}
          </template>
        </v-data-table>
      </v-card>
      <div v-else class="d-flex justify-center">
        <v-progress-circular
          size="300"
          class="d-flex justify-center my-5"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <v-row justify="end" class="mt-4">
        <v-col cols="12" md="4">
          <v-pagination
            v-model="page"
            :length="paginate.lastPage"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <div class="d-flex justify-center mt-6" v-else>
      <v-card width="700">
        <v-alert class="mb-0" outlined type="warning" prominent border="left">
          Debe sincronizar el deposito con contabillium para mostrar los
          productos!
        </v-alert>
      </v-card>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      getDeposits: [],
      loadingData: false,
      status_card: true,
      deposits: "",
      loadingBtn: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      productsData: [],
      paginate: {},
      headers: [
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Sku",
          value: "products_erp",
          sortable: false,
          class: "primary--text",
        },
        {
          text: "Código de barras",
          value: "barcode",
          sortable: false,
          class: "primary--text",
        },
        {
          text: "Stock",
          value: "product_warehouse",
          sortable: false,
          class: "primary--text",
        },
        // {
        //   text: "Estatus",
        //   value: "active",
        //   sortable: false,
        //   class: "primary--text",
        // },
        // {
        //   text: "Acciones",
        //   value: "actions",
        //   sortable: false,
        //   class: "primary--text",
        // },
      ],
    };
  },

  created() {
    this.HandlerGetDeposits();
    this.HandlerGetProductID();
  },

  watch: {
    page(val) {
      this.HandlerGetProduct(val);
    },
  },

  computed: {
    // getDeposits() {
    //   const depositFilter = this.$store.getters["deposits/GET_DEPOSITS"];
    //   // const depositFilter = deposits.filter((deposit) => {
    //   //   if (deposit.contabilium_id != null) {
    //   //     return deposit;
    //   //   }
    //   // });
    //   return depositFilter;
    // },
    getPaginate() {
      return this.$store.getters["deposits/GET_PAGINATE"];
    },
  },

  methods: {
    async HandlerGetDeposits(page) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 15,
          paginate: false,
          country_id: this.country_id == undefined ? "" : this.country_id,
          state_id: this.state_id == undefined ? "" : this.state_id,
          location_id: this.location_id == undefined ? "" : this.location_id,
        };
        const response = await this.$store.dispatch(
          "deposits/GET_DEPOSITS",
          request
        );

        this.getDeposits = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    async HandlerSyncContabillium() {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const request = {
          only_deposits: [this.deposits.contabilium_id],
        };
        await this.$store.dispatch("deposits/SYNC_DEPOSITS", request);
        this.$snotify.success("Sincronizacion exitosa", "Exitos!");
        this.status_card = false;
        this.HandlerGetProduct();
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    async HandlerGetProduct(page = 1) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const request = {
          warehouse_id: this.deposits.id == undefined ? 5 : this.deposits.id,
          page: page,
          per_page: 50,
        };
        const response = await this.$store.dispatch(
          "deposits/GET_PRODUCT_DEPOSITS",
          request
        );
        this.productsData = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };

        // setTimeout(() => {
        //   this.$snotify.success(
        //     `Productos segun la tienda: ${this.deposits.name}`,
        //     "Exitos!"
        //   );
        // }, 2000);
        // this.status_card = !this.status_card;
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },

    async HandlerGetProductID(value) {
      try {
        this.loadingData = true;
        this.loadingBtn = true;
        const request = {
          warehouse_id: value == undefined ? 5 : value.id,
          page: 1,
          per_page: 50,
        };
        const response = await this.$store.dispatch(
          "deposits/GET_PRODUCT_DEPOSITS",
          request
        );
        this.productsData = response.data.data.data;
        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };

        if (this.productsData.length > 0) {
          this.status_card = false;
        } else {
          this.status_card = true;
        }
      } catch (error) {
        console.log(error);
        this.$snotify.error("Ha ocurrido un error con la peticion", "Error!");
      } finally {
        this.loadingData = false;
        this.loadingBtn = false;
      }
    },
  },
};
</script>

<style>
</style>