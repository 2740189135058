<template>
  <v-container>
    <v-row class="mt-5 mb-5">
      <v-col cols="12" sm="4" md="3">
        <v-select
          :items="dataCountry"
          item-text="name"
          item-value="id"
          filled
          rounded
          dense
          class="rounded-small"
          label="Pais"
          v-model="country_id"
          @change="HandlerGetSalesWeights()"
        ></v-select>
      </v-col>
      <v-col class="d-flex justify-end" cols="12" sm="4" md="6">
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetSalesWeights()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="shadow-1">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-data-table
            :headers="headers"
            :items="data"
            :items-per-page="-1"
            height="500"
            disable-pagination
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:[`item.transaction_amount`]="{ item }">
              <span>{{ item.transaction_amount | currency }}</span>
            </template>

            <template v-slot:[`item.total_amount`]="{ item }">
              <span>{{ item.total_amount | currency }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
// import moment from "moment";
export default {
  data() {
    return {
      // Data
      loading: false,
      total: 0,

      dataCountry: [],
      country_id: 1,

      data: [],

      headers: [
        {
          text: "Tienda",
          align: "start",
          value: "name",
        },
        {
          text: "Pais",
          sortable: false,
          value: "countries.name",
        },
        { text: "Monto total (pesos) order", value: "total_amount" },
        { text: "Monto total (pesos) payment", value: "transaction_amount" },
      ],
    };
  },

  filters: {},

  created() {
    this.HandlerGetSalesWeights();
    this.HandlerGetCountry();
  },

  // watch: {
  //   page(val) {
  //     this.HandlerGetSalesWeights(val);
  //   },
  // },

  methods: {
    HandlerReset() {
      this.country_id = 1;
      this.HandlerGetSalesWeights();
    },
    async HandlerGetSalesWeights() {
      try {
        this.loading = true;
        const request = {
          country_id: this.country_id,
          date_from: "2022-09-05",
          date_to: "2022-09-05",
          // days_sale: this.days_sale,
        };
        const response = await this.$store.dispatch(
          "stocks/GET_SALES_WEIGHTS",
          request
        );
        this.data = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
</style>