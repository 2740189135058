<template>
  <v-container>
    <v-row class="mt-5 mb-5">
      <v-col cols="12" sm="4" md="4">
        <v-select
          :items="dataCountry"
          item-text="name"
          item-value="id"
          filled
          rounded
          dense
          class="rounded-small"
          label="Pais"
          v-model="country_id"
          @change="HandlerGetStocks()"
        ></v-select>
      </v-col>
      <!-- <v-col cols="12" sm="4" md="4">
        <v-select
          v-model.trim="brand"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Marca"
          single-line
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="products"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Productos con Proyección"
          single-line
          hide-details
        ></v-select>
      </v-col> -->
      <!-- 
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          v-model="days"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Dias"
        ></v-text-field>
      </v-col> -->

      <!-- <v-col cols="12" sm="4" md="4">
        <v-select
          :items="['FWL01', 'ROS01', 'CBA01', 'MLFULL', 'TODOS']"
          v-model.trim="warehouses"
          filled
          rounded
          dense
          class="rounded-small"
          name="Deposito"
          label="Deposito"
        ></v-select>
      </v-col> -->

      <v-col cols="12"> Suma total : {{ total | currency }} </v-col>

      <v-col class="d-flex justify-end" cols="12" md="12">
        <v-btn
          fab
          small
          color="primary"
          class="mr-3"
          @click="HandlerGetStocks()"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn fab small @click="HandlerReset()">
          <v-icon color="primary">mdi-backup-restore</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <v-card class="shadow-1">
          <v-data-table
            :loading="loading"
            :headers="headersTable"
            :items="data"
            :items-per-page="20"
            class="elevation-0"
            disable-pagination
            hide-default-footer
            fixed-header
            height="500"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <!-- <template v-slot:[`item.fw01`]="{ item }">
              <span>{{
                warehouseValue(item, 5).current_stock 
              }}</span>
            </template>
            <template v-slot:[`item.cba01`]="{ item }">
              <span>{{
                warehouseValue(item, 3).current_stock 
              }}</span>
            </template>
            <template v-slot:[`item.mlfull`]="{ item }">
              <span>{{
                warehouseValue(item, 8).current_stock 
              }}</span>
            </template>
            <template v-slot:[`item.ros01`]="{ item }">
              <span>{{
                warehouseValue(item, 10).current_stock 
              }}</span>
            </template> -->
            <template v-slot:[`item.pvp`]="{ item }">
              <span>{{ item.pvp | currency }}</span>
            </template>
            <template v-slot:[`item.stock`]="{ item }">
              <span>{{ item.stock }}</span>
            </template>
            <template v-slot:[`item.price`]="{ item }">
              <span v-if="item.price == 0">{{ item.price }}</span>
              <span v-else>{{ item.price | currency }}</span>
            </template>
            <template v-slot:[`item.id`]="{ index }">
              <span>{{ index + 1 }}</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      // filters
      brand: "",
      products: "",
      loadingFilters: false,

      // Data
      loading: false,
      sku: "",
      name: "",
      days: 30,

      total: 0,

      dataCountry: [],
      country_id: 1,

      // Table
      headers: [
        {
          text: "Posicion",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "product.products_erp[0].sku",
          width: 50,
        },
        { text: "Nombre", value: "product.name", sortable: false },
        { text: "ML FULL", value: "MLFULL", sortable: false },
        { text: "FWL01", value: "FWL01", sortable: false },
        { text: "CBA01", value: "CBA01", sortable: false },
        { text: "ROS01", value: "ROS01", sortable: false },
        { text: "PRINCIPAL", value: "PRINCIPAL", sortable: false },
        { text: "ML_FULL", value: "ML_FULL", sortable: false },
        { text: "ML_FULL_CBT", value: "ML_FULL_CBT", sortable: false },
        { text: "RMA", value: "RMA", sortable: false },
        { text: "MEX_DEPO01", value: "MEX_DEPO01", sortable: false },
        { text: "TOTAL", value: "total" },
        { text: "PVP", value: "pvp" },
        { text: "Valor Total", value: "price" },
      ],

      data: [],

      warehouses: [],

      // Pagination
      page: 1,
      pagination: {},
    };
  },

  created() {
    this.HandlerGetStocks();
    this.HandlerGetCountry();
  },

  computed: {
    headersTable() {
      if (this.country_id === 1) {
        return this.headers.filter(
          (header) =>
            ![
              "PRINCIPAL",
              "MEX_DEPO01",
              "ML_FULL",
              "ML_FULL_CBT",
              "RMA",
            ].includes(header.text)
        );
      }
      if (this.country_id === 2) {
        return this.headers.filter(
          (header) =>
            !["ML FULL", "FWL01", "CBA01", "ROS01", "MEX_DEPO01"].includes(
              header.text
            )
        );
      }
      if (this.country_id === 3) {
        return this.headers.filter(
          (header) =>
            ![
              "ML FULL",
              "FWL01",
              "CBA01",
              "ROS01",
              "PRINCIPAL",
              "ML_FULL",
              "ML_FULL_CBT",
              "RMA",
            ].includes(header.text)
        );
      }
      return this.headers;
    },
  },

  watch: {
    page(val) {
      this.HandlerGetStocks(val);
    },
  },

  methods: {
    HandlerReset() {
      this.days = 30;
      (this.sku = ""), (this.name = "");
      this.HandlerGetStocks(this.page);
    },
    async HandlerGetStocks(page) {
      try {
        this.loading = true;
        const request = {
          page: page || 1,
          per_page: 20,
          sku: this.sku,
          name: this.name,
          days: this.days,
          country_id: this.country_id,
          warehouses: this.warehouses.toString(),
        };
        const response = await this.$store.dispatch(
          "stocks/GET_STOCKS_VALORIZED",
          request
        );

        console.log(response.data);
        this.data = response.data.data.data;
        this.total = response.data.data.total;
        this.pagination = {
          lastPage: response.data.data.data.lastPage,
          page: response.data.data.data.page,
          perPage: response.data.data.data.perPage,
          total: response.data.data.data.total,
        };
        // console.log(response.data.data.data);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetCountry() {
      try {
        const response = await this.$store.dispatch("country/GET_COUNTRIES");
        this.dataCountry = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    // warehouseValue(item, value) {
    //   console.log('lelelelle', item)
    //   const warehouse = item.product_warehouse.find(
    //     (warh) => warh.warehouse_id == value
    //   );
    //   if (warehouse != undefined) {
    //     return warehouse;
    //   } else return "";
    // },
  },
};
</script>

<style>
</style>