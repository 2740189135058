var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-container',{staticClass:"mt-n0"},[_c('div',{staticClass:"font-weight-medium primary--text mb-5",staticStyle:{"font-size":"26px"}},[_vm._v(" Crear / Editar Publicacion ")]),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.loading}},[_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Datos de la publicación ")])],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"tienda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.data_stores,"item-text":"name","item-value":"id","label":"Tienda","filled":"","rounded":"","dense":"","no-data-text":"No hay datos para mostrar","error-messages":errors},model:{value:(_vm.selectedStore),callback:function ($$v) {_vm.selectedStore=$$v},expression:"selectedStore"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"20","sm":"6","md":"9"}},[_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"disabled":_vm.action == 1,"items":_vm.data_products,"item-text":"value.label","item-value":"value","label":"Productos","no-filter":"","clearable":"","filled":"","rounded":"","dense":"","loading":_vm.searchProductLoading,"search-input":_vm.searchProduct,"no-data-text":"Coloque dos caracteres (sku)"},on:{"update:searchInput":function($event){_vm.searchProduct=$event},"update:search-input":function($event){_vm.searchProduct=$event}},model:{value:(_vm.selectedProduct),callback:function ($$v) {_vm.selectedProduct=$$v},expression:"selectedProduct"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"titulo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Titulo","filled":"","dense":"","rounded":"","error-messages":errors},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Pvp neto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"readonly":"","label":"Pvp neto","disabled":"","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.pvpWithoutIva),callback:function ($$v) {_vm.pvpWithoutIva=$$v},expression:"pvpWithoutIva"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Costo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Costo","readonly":"","disabled":"","type":"number","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.cost),callback:function ($$v) {_vm.cost=$$v},expression:"cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Descuento sobre el PVP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Descuento sobre el PVP","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.pvpDiscount),callback:function ($$v) {_vm.pvpDiscount=$$v},expression:"pvpDiscount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Variable PVC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Variable PVC","type":"number","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.pvcVariable),callback:function ($$v) {_vm.pvcVariable=$$v},expression:"pvcVariable"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Disponibilidad Alta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"type":"number","filled":"","rounded":"","dense":"","label":"Disponibilidad Alta","error-messages":errors},model:{value:(_vm.dataUpdate.high_availability),callback:function ($$v) {_vm.$set(_vm.dataUpdate, "high_availability", $$v)},expression:"dataUpdate.high_availability"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"Disponibilidad Baja","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"type":"number","filled":"","rounded":"","dense":"","label":"Disponibilidad Baja","error-messages":errors},model:{value:(_vm.dataUpdate.low_availability),callback:function ($$v) {_vm.$set(_vm.dataUpdate, "low_availability", $$v)},expression:"dataUpdate.low_availability"}})]}}],null,true)})],1)],1),_c('v-col',[_c('v-row',[_c('v-col',[_c('v-row',{staticStyle:{"align-items":"center"}},[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Cálculo PVC a partir del costo"},model:{value:(_vm.calcWithCost),callback:function ($$v) {_vm.calcWithCost=$$v},expression:"calcWithCost"}}),_c('v-col',{staticClass:"ml-5",style:({
                    opacity: _vm.calcWithCost ? 1 : 0
                  }),attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"Margen","rules":_vm.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"type":"number","filled":"","rounded":"","dense":"","label":"Margen","error-messages":errors},model:{value:(_vm.sale_margin_percentage),callback:function ($$v) {_vm.sale_margin_percentage=$$v},expression:"sale_margin_percentage"}})]}}],null,true)})],1)],1)],1)],1),_c('v-row',[_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"Cálculo PVC a partir del PVP"},model:{value:(_vm.calcWithPvp),callback:function ($$v) {_vm.calcWithPvp=$$v},expression:"calcWithPvp"}})],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.agregarArray($event)}}},[_c('ValidationProvider',{attrs:{"name":"Cantidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"type":"number","filled":"","rounded":"","dense":"","disabled":_vm.loading_new_step,"error-messages":errors,"label":"Cantidad"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Descuento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"type":"number","filled":"","rounded":"","dense":"","disabled":_vm.loading_new_step,"error-messages":errors,"label":"Descuento"},model:{value:(_vm.discount),callback:function ($$v) {_vm.discount=$$v},expression:"discount"}})]}}],null,true)}),_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"type":"submit","disabled":invalid,"loading":_vm.loading_new_step,"rounded":"","color":"success"}},[_vm._v(" Agregar ")])]}}],null,true)})],1)]}}],null,true)})]],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Cantidad")]),_c('th',{staticClass:"text-left"},[_vm._v("Descuento")]),_c('th',{staticClass:"text-left"},[_vm._v("PVC neto")]),_c('th',{staticClass:"text-left"},[_vm._v("Accion")])])]),_c('tbody',_vm._l((_vm.data_array),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.quantity))]),_c('td',[_vm._v(_vm._s(item.discount))]),_c('td',[_vm._v(_vm._s(_vm._f("currency")(item.net_pvc)))]),_c('td',[[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"white","dark":""},on:{"click":function($event){return _vm.removeArray(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#6D6D6D"}},[_vm._v(" mdi-delete-forever ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]],2)])}),0)]},proxy:true}],null,true)})]],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey'},on:{"click":function($event){return _vm.closeModal({}, false)}}},[_vm._v(" Cancelar ")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"loading":_vm.loading,"rounded":"","color":"success"},on:{"click":_vm.HandlerSendData}},[_vm._v(" Guardar ")])]}}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }