<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            @click="showRegister = true"
            class="accent shadow-4 px-8"
            large
            rounded
            >Crear un movimiento</v-btn
          >
        </v-col>
      </v-row>
      <register @dialog:change="HandlerResponse" v-if="showRegister" />
    </v-container>
  </section>
</template>

<script>
import Register from "./Register";
export default {
  components: { Register },
  data() {
    return {
      loadingData: false,
      activeAddData: false,
      activeAddDataDate: false,
      actionAdd: 0,
      actionAddDate: 0,
      showRegister: false,
    };
  },

  created() {},

  watch: {},

  computed: {},

  methods: {
    HandlerResponse() {
      this.showRegister = false;
    },
  },
};
</script>

<style>
</style>