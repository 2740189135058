var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{ref:"container"},[_c('v-row',[_c('v-col',{staticStyle:{"flex-grow":"0"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getNotifications(false)}}},[_vm._v(" Filtrar ")])],1),_c('v-col',{staticStyle:{"flex-grow":"0"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v(" Reiniciar ")])],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Evento","filled":"","rounded":"","dense":"","items":Object.entries(_vm.notificationsTypes).map(function (ref) {
    var key = ref[0];
    var value = ref[1];

    return ({
            name: value,
            id: key
          });
}),"item-text":"name","item-value":"id"},model:{value:(_vm.filters.event),callback:function ($$v) {_vm.$set(_vm.filters, "event", $$v)},expression:"filters.event"}})],1),_c('v-col',[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Pais","filled":"","rounded":"","dense":"","items":_vm.countrys,"item-text":"name","item-value":"id"},model:{value:(_vm.filters.countryId),callback:function ($$v) {_vm.$set(_vm.filters, "countryId", $$v)},expression:"filters.countryId"}})],1),_c('v-col',[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Tienda","filled":"","rounded":"","dense":"","items":_vm.stores,"item-text":"name","item-value":"id"},model:{value:(_vm.filters.storeId),callback:function ($$v) {_vm.$set(_vm.filters, "storeId", $$v)},expression:"filters.storeId"}})],1)],1),_c('v-row',{staticClass:"mb-5 ",staticStyle:{"margin-top":"-25px"}},[_c('v-col',[_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"ID","filled":"","rounded":"","dense":"","name":"id","hide-details":""},model:{value:(_vm.filters.meli_id),callback:function ($$v) {_vm.$set(_vm.filters, "meli_id", $$v)},expression:"filters.meli_id"}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-small",attrs:{"label":"Desde","readonly":"","filled":"","rounded":"","dense":"","name":"ciudad","hide-details":""},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.openFrom),callback:function ($$v) {_vm.openFrom=$$v},expression:"openFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-us"},on:{"input":function($event){_vm.openFrom = false}},model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-small",attrs:{"label":"Hasta","readonly":"","filled":"","rounded":"","dense":"","name":"ciudad","hide-details":""},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.openTo),callback:function ($$v) {_vm.openTo=$$v},expression:"openTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es-us"},on:{"input":function($event){_vm.openTo = false}},model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 small-table-body",attrs:{"headers":_vm.headers,"items":_vm.notifications.data,"loading":_vm.loading,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.notifications.total,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50]
    }},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.context.item_id || item.context.id)+" ")])]}},{key:"item.storeName",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s((item.context.store_name && item.context.store_name.name) || item.context.store_name)+" ")])]}},{key:"item.topic",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.notificationsTypes[item.topic])+" ")])]}},{key:"item.prevPvp",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyValue")(item.context.pvp && item.context.pvp.prev))+" ")])]}},{key:"item.currPvp",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("currencyValue")(item.context.pvp && item.context.pvp.curr))+" ")])]}},{key:"item.prevRent",fn:function(ref){
    var item = ref.item;
return [_c('span',{style:({
          color: _vm.getRentabilityStatusColor(
            item.context.rentStatus && item.context.rentStatus.prev
          ),
          'font-weight': 500
        })},[_vm._v(" "+_vm._s(item.context.rentStatus && item.context.rentStatus.prev)+" ")])]}},{key:"item.currRent",fn:function(ref){
        var item = ref.item;
return [_c('span',{style:({
          color: _vm.getRentabilityStatusColor(
            item.context.rentStatus && item.context.rentStatus.curr
          ),
          'font-weight': 500
        })},[_vm._v(" "+_vm._s(item.context.rentStatus && item.context.rentStatus.curr)+" ")])]}},{key:"item.catalogPrev",fn:function(ref){
        var item = ref.item;
return [_c('span',{style:({
          color: _vm.getCatalogStatusColor(
            item.context.catalog && item.context.catalog.prev
          ),
          'font-weight': 500
        })},[_vm._v(" "+_vm._s(_vm._f("catalogStatus")(item.context.catalog && item.context.catalog.prev))+" ")])]}},{key:"item.catalogCurr",fn:function(ref){
        var item = ref.item;
return [_c('span',{style:({
          color: _vm.getCatalogStatusColor(
            item.context.catalog && item.context.catalog.curr
          ),
          'font-weight': 500
        })},[_vm._v(" "+_vm._s(_vm._f("catalogStatus")(item.context.catalog && item.context.catalog.curr))+" ")])]}},{key:"item.title",fn:function(ref){
        var item = ref.item;
return [(item.context.statusByMethod)?_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"color":"blue","text-decoration":"underline"}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.context.title)+" ")])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-data-table',{attrs:{"headers":_vm.paymentMethodsHeaders,"items":item.context.statusByMethod,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.prevRent",fn:function(ref){
        var item = ref.item;
return [_c('span',{style:({
                color: _vm.getRentabilityStatusColor(item.prev.status),
                'font-weight': 500
              })},[_vm._v(" "+_vm._s(item.prev.status)+" ")])]}},{key:"item.currRent",fn:function(ref){
              var item = ref.item;
return [_c('span',{style:({
                color: _vm.getRentabilityStatusColor(item.curr.status),
                'font-weight': 500
              })},[_vm._v(" "+_vm._s(item.curr.status)+" ")])]}}],null,true)})],1):_c('span',[_vm._v(" "+_vm._s(item.context.title)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }