var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-5",attrs:{"fluid":""}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[(!_vm.showRegister)?_c('v-row',{staticClass:"justify-between align-center mb-5"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"accent shadow-4 px-8",attrs:{"rounded":"","dark":"","block":"","large":""},on:{"click":function($event){return _vm.HandlerUpdateFill()}}},[_vm._v(" Añadir Imagen ")])],1)],1):_vm._e()],1)],1),(!_vm.showRegister)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Tienda","filled":"","rounded":"","dense":"","items":_vm.data_stores,"item-text":"name","item-value":"id"},model:{value:(_vm.store_id),callback:function ($$v) {_vm.store_id=$$v},expression:"store_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Sección","item-text":"label","item-value":"value","filled":"","rounded":"","dense":"","items":_vm.typePromotionItems},model:{value:(_vm.typePromotion),callback:function ($$v) {_vm.typePromotion=$$v},expression:"typePromotion"}})],1)],1):_vm._e(),(_vm.showRegister)?_c('div',{staticClass:"font-weight-medium primary--text mb-5",staticStyle:{"font-size":"26px"}},[_vm._v(" "+_vm._s(_vm.update ? "Editar" : "Crear")+" Imagen ")]):_vm._e(),_c('v-card',{staticClass:"mb-5"},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('vuescroll',{attrs:{"ops":{
          bar: { background: '#bacfcec0', keepShow: true },
        }}},[_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":""}},[(!_vm.showRegister)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card-title',{staticClass:"font-weight-light primary--text"},[_vm._v(" Imagenes Cargadas ")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dataImages,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image_url",fn:function(ref){
        var item = ref.item;
return [_c('v-avatar',{attrs:{"tile":"","size":"50"}},[_c('v-img',{attrs:{"contain":"","src":item.image_url}})],1)]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.HandlerUpdate(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.HandlerDelete(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1):_vm._e(),(_vm.showRegister)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"tienda","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.data_stores,"item-text":"name","item-value":"id","small-chips":"","label":"Tienda","filled":"","rounded":"","dense":"","no-data-text":"No hay datos para mostrar","clearable":"","error-messages":errors},model:{value:(_vm.store_id),callback:function ($$v) {_vm.store_id=$$v},expression:"store_id"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"section","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typePromotionItems,"item-text":"label","item-value":"value","filled":"","rounded":"","dense":"","label":"Sección","error-messages":errors},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"displayDevice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":['Desktop', 'Mobile'],"filled":"","rounded":"","dense":"","label":"Dispositivo","error-messages":errors},model:{value:(_vm.device),callback:function ($$v) {_vm.device=$$v},expression:"device"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"imagen","rules":_vm.update ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-camera-image","label":"Cargar Imagen","filled":"","rounded":"","dense":"","error-messages":errors},on:{"change":_vm.onFileChange},model:{value:(_vm.image_promotion),callback:function ($$v) {_vm.image_promotion=$$v},expression:"image_promotion"}})]}}],null,true)})],1),(_vm.type == 'Categorias' && _vm.store_id != undefined)?_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"imagen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.data_categories,"item-text":"name","item-value":"id","filled":"","rounded":"","dense":"","label":"Categorias","error-messages":errors},model:{value:(_vm.categories),callback:function ($$v) {_vm.categories=$$v},expression:"categories"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"posicion","rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","label":"Posicion","error-messages":errors},model:{value:(_vm.position),callback:function ($$v) {_vm.position=$$v},expression:"position"}})]}}],null,true)})],1),(_vm.type == 'Carrusel')?_c('v-col',{staticClass:"pb-0 d-flex",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"features"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","label":"Caracteristicas","error-messages":errors},model:{value:(_vm.features),callback:function ($$v) {_vm.features=$$v},expression:"features"}})]}}],null,true)})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.HandlerAddFeatures}},[_vm._v(" agregar caracteristicas ")])],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[(_vm.itemsFeatures.length == 4)?_c('v-alert',{attrs:{"dense":"","border":"left","type":"info"}},[_vm._v(" Maximo 4 caracteristicas ")]):_vm._e(),_vm._l((_vm.itemsFeatures),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.HandlerSubFeatures(index)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)],1)],1)})],2),(_vm.type != 'Categorias')?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"filled":"","rounded":"","dense":"","label":"Url","error-messages":errors},model:{value:(_vm.url_promotions),callback:function ($$v) {_vm.url_promotions=$$v},expression:"url_promotions"}})]}}],null,true)})],1):_vm._e()],1),(_vm.showRegister && !_vm.update)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return passes(_vm.HandlerRegister)}}},[_vm._v(" Cargar ")]):_vm._e(),(_vm.showRegister && _vm.update)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return passes(_vm.HandlerUpdatedUploadImage)}}},[_vm._v(" Actualizar ")]):_vm._e(),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"secondary"},on:{"click":function () {
                      _vm.showRegister = !_vm.showRegister;
                      _vm.update = !_vm.update;
                    }}},[_vm._v(" Cancelar ")])],1):_vm._e(),(_vm.showRegister)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"4","sm":"12","md":"4"}},[(_vm.url)?_c('v-card',{staticClass:"mx-start mb-2 elevation-0",attrs:{"loading":_vm.loading,"max-width":"374","height":"auto"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('v-img',{attrs:{"height":"auto","src":_vm.url}})],2):_vm._e()],1):_vm._e()],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }