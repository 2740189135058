<template>
  <div>
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <v-card :loading="loading" class="shadow-1 mt-5">
        <v-card-title class="pa-0 shadow-1">
          <v-img
            :src="require('@/assets/illustrations/bg_modal_card.svg')"
            cover
            position="left bottom"
            class="align-center"
            height="65px"
          >
            <v-card-title class="font-weight-medium primary--text">
              Registrar Producto Contabilium
            </v-card-title>
          </v-img>
        </v-card-title>
        <v-card-text>
          <v-col cols="12" md="4">
            <ValidationProvider
              name="SKU"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <v-text-field
                @keyup.enter="passes(HandlerRegisterProductContabilium)"
                filled
                rounded
                dense
                class="rounded-small"
                label="SKU"
                v-model="sku_contabilium"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              text
              rounded
              class="mr-1"
              :color="hover ? 'error' : 'grey'"
              @click="close"
              >Cancelar</v-btn
            >
          </v-hover>

          <v-hover v-slot:default="{ hover }">
            <v-btn
              rounded
              :loading="loading"
              :disabled="loading"
              color="success"
              :class="hover ? 'shadow-2' : 'shadow-4'"
              class="px-8"
              @click="passes(HandlerRegisterProductContabilium)"
            >
              Registrar
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      sku_contabilium: "",
    };
  },
  methods: {
    close() {
      this.$emit("dialog:change", false);
    },
    async HandlerRegisterProductContabilium() {
      try {
        this.loading = true;
        const request = { sku: this.sku_contabilium };
        await this.$store.dispatch(
          "products/REGISTER_PRODUCT_CONTABILIUM",
          request
        );
        this.$snotify.success("Producto Registrado.", "¡Genial!");
        this.$emit("dialog:change", false);
      } catch (error) {
        if (error.response.status == 422) {
          this.$snotify.error(error.response.data.errors[0].message, "Error!");
        } else {
          this.$snotify.error(error.response.data.error.message, "Error!");
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>