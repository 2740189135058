<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div
          class="font-weight-medium primary--text mb-5 ml-3"
          style="font-size: 26px"
        >
          Crear / Editar Usuario (B2B)
        </div>
      </v-col>
      <v-col cols="3">
        <v-select
          label="Tienda"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="store_id"
          :items="data_stores"
          item-text="name"
          item-value="id"
        ></v-select>
      </v-col>
      <register-card-component
        :action="action"
        :data="data"
        :brands="dataBrand"
        :store_id="store_id"
        @dialog:change="registerCard"
      />
    </v-row>
  </v-container>
</template>

<script>
import RegisterCard from "./RegisterCard.vue";
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    data_stores: {
      type: Array,
      required: false,
      default: () => {},
    },
    data_store_id: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  components: {
    "register-card-component": RegisterCard,
  },
  data() {
    return {
      items: [
        {
          title: "SI",
          value: true,
        },
        {
          title: "NO",
          value: false,
        },
      ],
      country_id: "",
      location_id: [],
      stateData: [],
      locationData: [],
      loadingState: false,
      loadingLocation: false,
      store_id: 0,
      dataBrand: [],
    };
  },

  created() {
    if (this.action === 1) {
      this.data.permissions_b2b_main = JSON.parse(
        this.data.buyer_store[0].permissions_b2b_main
      );
      this.data.zipcode = this.data.buyer_store[0].zipcode;
      this.data.location = this.data.billing_location;
      this.store_id = this.data_store_id;
      this.data.state_id = this.data.states_id;
      this.data.b2b_groups = JSON.parse(this.data.buyer_store[0].b2b_groups);
      this.data.b2b_brands = JSON.parse(this.data.buyer_store[0].b2b_brands);
      this.data.b2b_invoice = this.data.buyer_store[0].b2b_invoice;
      // this.HandlerProvincia();
    }
    this.HandlerGetBrand();
  },

  methods: {
    async HandlerGetBrand() {
      try {
        const request = {
          has_publications_b2b: true,
          store_id: "",
        };
        const response = await this.$store.dispatch(
          "products/GET_BRAND",
          request
        );
        this.dataBrand = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    registerCard(val, action) {
      this.$emit("dialog:change", val, action);
    },
  },
};
</script>

<style>
.error-class {
  color: #ef5350;
  font-size: 12px;
}
</style>
