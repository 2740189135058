<template>
    <v-dialog v-model="active" persistent max-width="600">
        <ValidationObserver ref="obs" v-slot="{ passes }">
            <v-card>
              <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                rounded
                height="6"
              ></v-progress-linear>
              <v-card-title class="pa-0 shadow-1">
                <v-img
                  :src="require('@/assets/illustrations/bg_modal_card.svg')"
                  cover
                  position="left bottom"
                  class="align-center"
                  height="65px"
                >
                  <v-card-title class="font-weight-medium primary--text">
                    {{btnMessage}} costos
                  </v-card-title>
                </v-img>
              </v-card-title>
            
              <v-card-text>
                <ValidationProvider
                  name=" "
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    required
                    filled
                    rounded
                    class="rounded-small"
                    dense
                    name="description"
                    placeholder ="Descripción"
                    color="blue-grey"
                    v-model="description"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </v-card-text>
          
              <v-divider></v-divider>
          
              <v-card-actions class="mx-2">
                <v-spacer></v-spacer>
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    text
                    rounded
                    class="mr-1"
                    :color="hover ? 'error' : 'grey'"
                    @click="()=>closeModal(false)"
                    >Cancelar</v-btn
                  >
                </v-hover>
            
                <v-hover v-slot:default="{ hover }">
                  <v-btn
                    rounded
                    :loading="loading"
                    :disabled="loading"
                    color="success"
                    :class="hover ? 'shadow-2' : 'shadow-4'"
                    class="px-8"
                    @click="passes(HandlerAction)"
                    >{{btnMessage}}</v-btn
                  >
                </v-hover>
              </v-card-actions>
            </v-card>
        </ValidationObserver>
    </v-dialog>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Function,
      required: false,
      default: () => {},
    },
    closeModal: {
      type: Function,
      required: false,
      default: () => {},
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      description: "",
      id: Math.floor(Math.random() * 1000) / 100,
      btnMessage:"Añadir",
      loading: false,
    };
  },

  created() {
    if (this.data?.id) {
      this.description = this.data.description;
      this.id = this.data.id;
      this.btnMessage = "Editar"
    }
  },

  methods: {
    HandlerAction() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
            this.action({description:this.description, id:this.id})
            this.$snotify.success("Costo agregado con exito","Exitos!");
        }, 2000);
    },
  },
};
</script>