<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
      >
        {{ action == 1 ? "Editar" : "Añadir" }} Especificación
      </div>
      <v-col cols="12" md="12">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5 px-2 py-2">
            <v-progress-linear
              v-if="loader"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>
            <v-card-text>
              <v-form>
                <v-row dense justify="start">
                  <v-row dense style="margin:0px;">
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="`Nombre de especificación`"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Nombre"
                          :name="`Nombre de especificación`"
                          type="text"
                          color="blue-grey"
                          :error-messages="errors"
                          v-model="specificationData.name"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <ValidationProvider
                        :name="`Unidad de especificación`"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          :items="specifications"
                          item-text="title"
                          item-value="value"
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Unidad"
                          :name="`Unidad de especificación`"
                          color="blue-grey"
                          v-model="specificationData.measuring_unit"
                          :error-messages="errors"
                        ></v-select>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal(null, false)"
                  :disabled="loader"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loader"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerRegister)"
                >
                  {{ action == 1 ? "Editar" : "Añadir" }}
                </v-btn>
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { unitSelector } from '../../Products/Utils/common.ts';

export default {
  props: {
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    loader: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      specificationData: {
        name: this.data?.name || "",
        id: this.data?.id || "",
        measuring_unit: this.data?.measuring_unit || "",
      },
      searchItems: [""],
      searchLoadings: [false],
      specifications: unitSelector,
    };
  },

  created(){},

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerRegister() {
      this.$emit("dialog:change", this.specificationData, this.action);
    },
  },
};
</script>

<style>
  .subtitle-label {
    padding: 0;
    margin: 0;
    color: #706dac;
    font-weight: 500 !important;
  }
  .subtitle-box {
    margin-top: -18px !important;
    margin-bottom: 4px !important;
  }
  .btn-add {
    color: #2196f3;
    text-decoration: underline;
    margin-right: 6px;
    font-size: 12px;
    font-weight: 600;
  }
  .btn-add-box {
    margin-top: -22px;
    margin-bottom: 14px;
    z-index: 2;
  }
</style>