<template>
  <v-container>
    <!-- <show-product-component
      v-if="activeAddData"
      :active="activeAddData"
      :data="data"
      :dataBrand="dataBrand"
      :action="actionAdd"
      :dataPublication="dataPublication"
    /> -->
    <!-- @dialog:change="HandlerResponse" -->
    <v-row>
      <v-col>
        <v-card class="mb-5">
          <v-card-text class="pt-0">
            <v-card-title class="font-weight-medium primary--text">
              <div>Datos de la Publicacion</div>
              <div class="ml-auto">
                <v-chip label class="ma-2">
                  <v-avatar left tile>
                    <v-img
                      v-if="dataPublication.country_id == 1"
                      src="@/assets/flag/arg.svg"
                    ></v-img>
                    <v-img
                      v-if="dataPublication.country_id == 2"
                      src="@/assets/flag/chl.svg"
                      contain
                    ></v-img>
                    <v-img
                      v-if="dataPublication.country_id == 3"
                      src="@/assets/flag/mex.svg"
                    ></v-img>
                  </v-avatar>
                  {{ dataPublication.country }}
                </v-chip>
                <v-chip class="ma-2" label color="#BBDEFB">
                  {{ dataPublication.brand }}
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="#F48FB1"
                  label
                  v-if="dataPublication.catalog_listing"
                >
                  Catálogo
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="#80CBC4"
                  label
                  v-if="dataPublication.logistic_type == 'fulfillment'"
                >
                  FULL
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="#80CBC4"
                  label
                  v-if="dataPublication.logistic_type !== 'fulfillment'"
                >
                  FLEX
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="#FFF176"
                  label
                  v-if="dataPublication.cbt"
                >
                  CBT
                </v-chip>
                <v-chip
                  class="ma-2"
                  color="#FFF176"
                  label
                  v-if="!dataPublication.cbt"
                >
                  {{ dataPublication.mercado_libre }}
                </v-chip>
              </div>
            </v-card-title>
            <v-row dense>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="d-flex">
                <div class="d-flex align-center">
                  <v-avatar
                    tile
                    size="60"
                    style="cursor: pointer"
                    @click="HandlerLinkPublication(dataPublication.link)"
                  >
                    <img
                      :src="dataPublication.picture"
                      :alt="dataPublication.title"
                    />
                  </v-avatar>
                  <div class="ml-2">
                    <div>
                      <p class="mb-1 text-title font-weight-bold">
                        {{ dataPublication.title }}
                      </p>
                      <span class="mb-1 text-title">
                        {{ dataPublication.item_id }}
                        <span class="ml-5 text-title-price">{{
                          dataPublication.price | currency
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div>
                  <p class="mb-0">
                    <span class="green--text">{{
                      dataPublication.price | currency
                    }}</span>
                    x {{ dataPublication.available_quantity }} disponibles
                  </p>
                </div>
                <div class="d-flex">
                  <p class="mb-0">Publicacion #{{ dataPublication.item_id }}</p>
                  <p class="mx-1 mb-0">|</p>
                  <p class="mb-0">&nbsp; SKU: {{ dataPublication.sku }}</p>
                </div>
                <div>
                  <p>
                    <span class="text-uppercase">
                      {{ dataPublication.question.nickname_meli_user }}
                    </span>
                    ({{ dataPublication.user_total }} puntos) | Miembro desde:
                    {{ dataPublication.user_registration | date }}
                  </p>
                </div>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <div class="d-flex mt-4">
                  <p class="pt-1">
                    <span class="text-uppercase">
                      {{ dataPublication.question.nickname_meli_user }}
                    </span>
                    ({{ dataPublication.user_total }} puntos) | Miembro desde:
                    {{ dataPublication.user_registration | date }}
                  </p>
                  <v-btn class="text-capitalize ml-5" small
                    >Usuario sin Compras Previas</v-btn
                  >
                </div>
                <v-divider></v-divider>
              </v-col>
              <!-- <v-col cols="12" sm="12" md="4">
                <div>
                  <p>
                    <span class="text-title"> Puede retirar: </span>

                    {{
                      dataPublication.logistic_type !== "fulfillment"
                        ? "SI"
                        : "NO"
                    }}
                  </p>
                  <p>
                    <span class="text-title">Tipo de Envio: </span>
                    {{
                      dataPublication.logistic_type == "fulfillment"
                        ? "FULL"
                        : "FLEX"
                    }}
                  </p>
                  <v-btn> Listado de compras anteriores </v-btn>
                </div>
              </v-col> -->
              <v-col
                cols="8"
                class="mt-2"
                v-if="dataPublication.question.answer == null"
              >
                <div>
                  <v-icon class="mr-2">mdi-message-bulleted</v-icon>
                  <label style="font-weight: 600; font-size: 1.2em">
                    {{ dataPublication.question.text }}
                  </label>
                </div>
                <!-- {{dataPublication.re_asign}} -->
                <v-textarea
                  :disabled="dataPublication.re_asign"
                  placeholder="Ingresa tu respuesta"
                  filled
                  rounded
                  dense
                  class="rounded-small mt-3"
                  rows="7"
                  v-model="answerDefault"
                ></v-textarea>
                <div>
                  Saludos Tienda Oficial
                  {{
                    dataPublication.country_id !== 3 ? dataPublication.brand : ""
                  }}
                  {{ dataPublication.country }}
                </div>
              </v-col>
              <v-col cols="12" class="mt-2" v-else>
                <div>
                  <v-icon class="mr-2">mdi-message-bulleted</v-icon>
                  <label style="font-weight: 600; font-size: 1.2em">
                    {{ dataPublication.question.text }}
                  </label>
                </div>
                {{ dataPublication.question.answer.text }}
              </v-col>
              <v-col cols="4">
                <div style="font-size: 16px; font-weight: 500">
                  Respuestas rápidas
                </div>
                <hr class="hr-primary" />
                <br />
                <v-btn
                  class="text-capitalize"
                  block
                  @click="HandlerButtons()"
                  outlined
                  color="primary"
                >
                  <v-icon size="20">mdi-pencil</v-icon>
                  Administrar
                </v-btn>
                <div v-for="(item, index) in responseTag" :key="index">
                  <v-btn
                    block
                    class="my-2"
                    :color="item.color"
                    dark
                    @click="answerDefault = item.text"
                  >
                    {{ item.name }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="loadingAnswer"
              v-if="
                dataPublication.question.answer == null &&
                dataPublication.re_asign == false
              "
              color="primary"
              rounded
              class="mr-1"
              @click="HandlerSaveAnswer"
            >
              Responder
            </v-btn>

            <v-btn
              :loading="loadingAnswer"
              color="secondary"
              rounded
              class="mr-1"
              @click="HandlerUserList"
            >
              Re Asignar
            </v-btn>

            <v-btn dark color="accent" rounded class="mr-1" @click="closeModal">
              Regresar
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showHandlerButtons" max-width="80%">
      <v-card>
        <v-card-text>
          <h3 class="pt-3 mb-5">Repuestas Rápidas</h3>
          <v-row>
            <v-col cols="12" md="2">
              <div
                v-if="!changeName"
                :style="`background-color: ${buttonColor}`"
                class="button"
                dark
                @click="changeName = true"
              >
                {{ tagName }}
              </div>
              <v-text-field
                v-model="buttonName"
                v-if="changeName"
                label="Nombre Etiqueta"
                append-icon="mdi-close"
                @keyup.enter="
                  () => {
                    tagName = buttonName;
                    changeName = false;
                  }
                "
                @click:append="
                  () => {
                    tagName = buttonName;
                    changeName = false;
                  }
                "
              ></v-text-field>
              <div class="d-flex">
                <div
                  class="mt-3 mr-2"
                  v-for="(item, index) in colors"
                  :key="index"
                  @click="buttonColor = item"
                  :style="`background-color: ${item}; width: 25px; height: 25px; cursor: pointer`"
                ></div>
              </div>
            </v-col>
            <v-col cols="12" md="8">
              <v-textarea
                v-model="bodyText"
                placeholder="Ingresa una nueva respuesta rapida"
                filled
                rounded
                dense
                class="rounded-small"
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn color="#9CCC65" dark @click="saveAnswerDefault"
                >Agregar</v-btn
              >
            </v-col>
          </v-row>
          <draggable :list="responseTag" @change="HandlerNewOrder">
            <v-row v-for="(item, index) in responseTag" :key="index">
              <v-col cols="12">
                <v-card class="d-flex" color="#ECEFF1" flat>
                  <v-col cols="12" md="2">
                    <v-btn
                      :color="item.color"
                      small
                      dark
                      class="text-uppercase"
                    >
                      {{ item.name }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="8">
                    <p class="text-center">{{ item.text }}</p>
                  </v-col>
                  <v-col cols="12" md="2">
                    <div class="d-flex">
                      <v-btn icon @click="editDefaultAnswer(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        class="mx-2"
                        @click="deleteDefaultAnswer(item)"
                      >
                        <v-icon>mdi-delete-forever</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </draggable>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUser" width="450">
      <v-card max-width="450" class="mx-auto">
        <v-toolbar color="cyan" dark flat>
          <v-toolbar-title>Usuarios</v-toolbar-title>
        </v-toolbar>

        <v-list>
          <template v-for="(item, index) in dataUsers">
            <v-divider v-if="index > 0" :key="index"></v-divider>

            <v-list-item :key="item.title">
              <v-list-item-content>
                <v-list-item-title v-html="item.username"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @click="HandlerUserAssing(item.id)"
                    >
                      <v-icon color="grey lighten-1">mdi-account</v-icon>
                    </v-btn>
                  </template>
                  <span>Asignar</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import draggable from "vuedraggable";
// import vuescroll from "vuescroll";
// import Register from "../../Products/Register";

export default {
  // components: {
  //   // vuescroll,
  //   "show-product-component": Register,
  // },

  components: {
    draggable,
  },

  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    // dataBrand: {
    //   type: Array,
    //   required: true,
    //   default: () => [],
    // },
    dataPublication: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      loadingAnswer: false,
      activeAddData: true,
      actionAdd: 2,

      dataButtons: [1, 2, 3, 4, 5, 6],
      showHandlerButtons: false,
      showUser: false,
      colors: [
        "#90CAF9",
        "#F48FB1",
        "#80CBC4",
        "#D4E157",
        "#BA68C8",
        "#FFB74D",
      ],
      buttonColor: "#80CBC4",
      changeName: false,
      tagName: "Etiqueta",
      buttonName: "",
      responseTag: [],
      bodyText: "",
      editAnswer: false,
      updateAnswerId: null,
      answerDefault: "",

      dataUsers: [],
    };
  },

  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },

  created() {
    // this.HandlerGetBrand();
    this.defaultAnswer();
  },

  methods: {
    closeModal() {
      this.$emit("dialog:change");
    },

    // async HandlerGetBrand() {
    //   try {
    //     const request = {
    //       has_publications: "",
    //       store_id: "",
    //     };
    //     const response = await this.$store.dispatch(
    //       "products/GET_BRAND",
    //       request
    //     );
    //     this.dataBrand = response.data.data;
    //   } catch (error) {
    //     console.log(error);
    //   } finally {
    //     this.loadingStatus = false;
    //   }
    // },

    async HandlerSaveAnswer() {
      try {
        this.loadingAnswer = true;
        const request = {
          question_id: this.dataPublication.question.id,
          text: `${this.answerDefault}.
           Saludos Tienda Oficial ${this.dataPublication.brand} ${this.dataPublication.country}`,
        };
        await this.$store.dispatch("products/SAVE_ANSWER", request);
        this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAnswer = false;
      }
    },

    async HandlerUserList() {
      this.showUser = true;
      try {
        this.loadingAnswer = true;
        const response = await this.$store.dispatch("auth/GET_ROLE_USER");
        this.dataUsers = response.data.data;
        // this.closeModal();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAnswer = false;
      }
    },

    async HandlerUserAssing(user_id) {
      try {
        const request = {
          user_id: user_id,
          question_id: this.dataPublication.question.id,
        };

        await this.$store.dispatch(
          "products/REGISTER_ASSIGN_QUESTION",
          request
        );
      } catch (error) {
        console.log("error", error);
      } finally {
        this.showUser = false;
        this.loadingAnswer = false;
        this.closeModal();
      }
    },

    async defaultAnswer() {
      try {
        const request = {
          page: 1,
          paginate: false,
          per_page: 15,
        };
        const response = await this.$store.dispatch(
          "ml/GET_DEFAULT_ANSWER",
          request
        );
        this.responseTag = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async saveAnswerDefault() {
      try {
        const request = {
          id: this.updateAnswerId,
          color: this.buttonColor,
          name: this.buttonName,
          text: this.bodyText,
        };
        if (!this.editAnswer) {
          const response = await this.$store.dispatch(
            "ml/SAVE_DEFAULT_ANSWER",
            request
          );
          this.$snotify.success("Respuesta Guardada", "¡Genial!");
          this.responseTag.push({ ...response.data.data });
          this.cleanData();
        } else {
          const response = await this.$store.dispatch(
            "ml/UPDATE_DEFAULT_ANSWER",
            request
          );
          this.$snotify.success("Respuesta Editada", "¡Genial!");
          const searchId = this.searchId(this.updateAnswerId);
          this.responseTag.splice(searchId, 1, response.data.data);
          this.cleanData();
        }
      } catch (error) {
        console.log(error);
      }
    },

    editDefaultAnswer(item) {
      this.buttonColor = item.color;
      this.tagName = item.name;
      this.buttonName = item.name;
      this.bodyText = item.text;
      this.updateAnswerId = item.id;
      this.editAnswer = true;
    },

    async deleteDefaultAnswer(answ) {
      try {
        const request = { id: answ.id };
        await this.$store.dispatch("ml/DELETE_DEFAULT_ANSWER", request);
        const searchId = this.searchId(answ.id);
        this.responseTag.splice(searchId, 1);
        this.cleanData();
      } catch (error) {
        console.log(error);
      }
    },

    cleanData() {
      this.buttonColor = "#80CBC4";
      this.tagName = "Etiqueta";
      this.buttonName = "";
      this.bodyText = "";
      this.updateAnswerId = null;
      this.editAnswer = false;
    },

    searchId(id) {
      return this.responseTag.findIndex((answ) => answ.id === id);
    },

    getDefaultDataAnswer(item) {
      this.dataPublication.question.meli_answer = item.text;
    },

    HandlerButtons() {
      this.showHandlerButtons = true;
    },

    async HandlerNewOrder() {
      try {
        const request = { answers: this.responseTag };
        await this.$store.dispatch("ml/ORDER_DEFAULT_ANSWER", request);
        this.cleanData();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.text-title {
  font-weight: 500;
}
.text-title-price {
  font-weight: 600;
}
.button {
  border-radius: 5px;
  color: white;
  font-size: 0.9em;
  padding: 2px;
  padding-left: 5px;
}
.hr-primary {
  position: relative;
  top: 5px;
  border: none;
  height: 4px;
  background: #625fa4;
  margin-bottom: 50px;
}
</style>