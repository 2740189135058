<template>
  <v-container>
    <div
      class="font-weight-medium primary--text mb-5 ml-3"
      style="font-size: 26px"
    >
      Ventas Landing
    </div>
    <v-row class="mt-5 mb-5">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          @input="updateData"
          @keyup.enter="HandlerGetSales"
          @blur="handleBlur"
          v-model.trim="operation_number"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Venta / Operación MP"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          @keyup.enter="HandlerGetSales"
          @blur="handleBlur"
          @input="clearSpaceEmail"
          v-model="client_name"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Nombre / Email"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          @keyup.enter="HandlerGetSales"
          @blur="handleBlur"
          @input="clearSpaceSKU"
          v-model="product_name"
          filled
          rounded
          dense
          class="rounded-small"
          name="ciudad"
          label="Producto / SKU"
          single-line
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="4" md="3">
        <v-menu
          v-model="menu_start"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @blur="handleBlur"
              @keyup.enter="HandlerGetSales"
              v-model="date"
              label="Desde"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="date"
            @input="menu_start = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-menu
          v-model="menu_end"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              @blur="handleBlur"
              @keyup.enter="HandlerGetSales"
              v-model="date_end"
              label="Hasta"
              readonly
              filled
              rounded
              dense
              class="rounded-small"
              name="ciudad"
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            no-title
            locale="es-us"
            v-model="date_end"
            @input="menu_end = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-row class="mx-2 mt-2" justify="space-between">
        <v-col cols="12" md="4" class="d-flex justify-start">
          <!-- <v-pagination
          v-if="paginate.lastPage > 0"
          v-model="myPage"
          :length="paginate.lastPage"
          :total-visible="$vuetify.breakpoint.name == 'xs' ? 5 : 7"
          circle
        ></v-pagination> -->
        </v-col>
        <v-col class="d-flex justify-end" cols="12" md="2">
          <v-spacer></v-spacer>
          <v-btn
            fab
            small
            color="primary"
            class="mr-3"
            @click="HandlerGetSales()"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn fab small @click="HandlerReset()">
            <v-icon color="primary">mdi-backup-restore</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col col="12" sm="12" md="12" class="mb-5">
        <v-card :class="HandlerResponsive">
          <v-toolbar flat color="primary" dark class="px-md-10 mb-1">
            <v-toolbar-title>Estatus de la venta</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab" align-with-title class="mt-3">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              <v-badge
                v-if="currentTab == item"
                bordered
                bottom
                color="primary"
                :content="pagination.total"
                transition="slide-x-transition"
              >
                {{ item }}
              </v-badge>
              <span v-else>
                {{ item }}
              </span>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-card
      width="100%"
      v-show="!loadingTable"
      class="mt-2"
      v-for="(item, index) in data"
      :key="index"
    >
      <v-row justify="space-around">
        <v-col cols="12" sm="6" md="6">
          <v-card
            height="auto"
            class="mx-2 elevation-0"
            v-if="item.buyer != null"
          >
            <div style="font-size: 20px" class="ml-4 pt-2">
              Datos del Cliente
            </div>
            <v-divider color="#625FA4" inset></v-divider>
            <v-card-text class="my-0 py-2">
              <span class="font-weight-bold mr-1">Nombre:</span>
              {{ `${item.buyer.first_name} ${item.buyer.last_name}` }}
              <br />
              <span class="font-weight-bold mr-1">Documento:</span>
              {{ `${item.buyer.doc_type}/${item.buyer.doc_number}` }}
              <br />
              <span class="font-weight-bold mr-1">E-mail:</span>
              {{ `${item.buyer.email}` }}
              <br />
              <span class="font-weight-bold mr-1">Teléfono: </span>
              {{ `${item.buyer.phone}` }}
            </v-card-text>
            <div style="font-size: 20px" class="ml-4">
              Datos de la Dirección
            </div>
            <v-divider color="#625FA4" inset></v-divider>
            <v-card-text v-if="item.buyer != null">
              <span class="font-weight-bold mr-1">Provincia: </span>
              {{
                `${item.buyer.state.name}` == undefined
                  ? ""
                  : `${item.buyer.state.name}`
              }}
              <br />
              <span class="font-weight-bold mr-1">Localidad: </span>
              {{ `${item.buyer.billing_location}` }}

              <br />
              <span class="font-weight-bold mr-1">Código Postal: </span>
              {{ `${item.buyer.billing_zip_code}` }}
              <br />
              <span class="font-weight-bold mr-1">Calle: </span>
              {{ `${item.buyer.billing_departament}` }}
              <br />
              <span class="font-weight-bold mr-1 mb-15">Número de Calle: </span>
              {{ `${item.buyer.billing_department_number}` }}
              <br />
              <span class="font-weight-bold mr-1 mb-15">Piso: </span>
              {{ `${item.buyer.floors == null ? "" : item.buyer.floors}` }}
              <br />
              <span class="font-weight-bold mr-1 mb-15">Departamento: </span>
              {{
                `${item.buyer.department == null ? "" : item.buyer.department}`
              }}
              <br />
              <span class="font-weight-bold mr-1 mb-15">Observaciones: </span>
              {{
                `${
                  item.buyer.observations == null ? "" : item.buyer.observations
                }`
              }}
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="6">
          <v-card height="auto" class="mx-2 elevation-0">
            <div style="font-size: 20px" class="ml-4 pt-2">
              Detalles de la Operación {{ HandlerShippingOperator(item) }}
            </div>
            <v-divider color="#625fa4" inset></v-divider>
            <v-card-text class="my-0 py-2">
              <v-row justify="center">
                <v-col cols="12" sm="6" md="6" class="mt-7">
                  <div
                    class="d-flex justify-center mb-5"
                    v-if="item.order_item.length > 0"
                  >
                    <v-img
                      contain
                      v-if="
                        item.order_item[0].product.publication.images != null
                      "
                      width="25%"
                      height="200"
                      :src="item.order_item[0].product.publication.images[0]"
                      :lazy-src="
                        item.order_item[0].product.publication.images[0]
                      "
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="black lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-img
                      v-else
                      width="25%"
                      height="auto"
                      src="../../assets/img/no_image.jpg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="my-auto"
                  v-if="item.order_item != null"
                >
                  <span class="font-weight-bold mr-1">Venta:</span>
                  #{{ item.meli_id }}
                  <br />
                  <span class="font-weight-bold mr-1">Producto:</span>
                  <span class="text-uppercase">
                    {{ item.order_item[0].product.name }}
                  </span>
                  <br />
                  <span class="font-weight-bold mr-1">Cantidad:</span>
                  <span class="text-uppercase">
                    {{ item.order_item[0].quantity }}
                  </span>
                  <br />
                  <span class="font-weight-bold mr-1">SKU:</span>
                  {{ item.order_item[0].product.sku }}
                  <br />
                  <span class="font-weight-bold mr-1">Precio:</span>
                  {{ item.total_amount | currency }}
                  <br />
                  <span class="font-weight-bold mr-1">Fecha:</span>
                  <span
                    v-if="
                      item.mp_transaction != null &&
                      item.mp_transaction.created_at != null
                    "
                  >
                    {{ item.date_created | dateHour }}
                  </span>
                  <br />
                  <span class="font-weight-bold mr-1"> Operación MP: </span>
                  <span
                    v-if="
                      item.mp_transaction != null &&
                      item.mp_transaction.payment_id != null
                    "
                  >
                    #{{ item.mp_transaction.payment_id }}
                  </span>
                  <br />
                  <span class="font-weight-bold mr-1"> Número Factura: </span>
                  <v-hover v-slot="{ hover }">
                    <a
                      @click.prevent="HandlerPrintInvoice(item, 'invoice')"
                      v-if="hover"
                    >
                      #{{ item.bill_number }}
                    </a>
                    <span v-else>#{{ item.bill_number }}</span>
                  </v-hover>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :md="$vuetify.breakpoint.width < 1100 ? '12' : '6'"
                >
                  <!-- <div v-if="tab == 0"> -->
                  <v-btn
                    class="mb-1"
                    v-if="tab != 4"
                    :loading="loadingFile"
                    :small="$vuetify.breakpoint.width < 1200 ? true : false"
                    block
                    color="#F6B246"
                    dark
                    @click="HandlerUploadData(item, index)"
                  >
                    Nueva Etiqueta
                  </v-btn>
                  <input
                    v-show="false"
                    ref="inputUpload"
                    type="file"
                    @change="HandlerUploadFile"
                  />
                  <!-- </div> -->
                  <!-- <div v-else> -->
                  <v-btn
                    v-if="item.path_label != null"
                    :small="$vuetify.breakpoint.width < 1200 ? true : false"
                    class="text-uppercase"
                    :loading="loadingFile"
                    block
                    color="primary"
                    dark
                    @click="HandlerPrintBill(item, 'ticket')"
                  >
                    Descargar Etiqueta
                  </v-btn>
                  <v-btn
                    v-if="tab == 3"
                    :small="$vuetify.breakpoint.width < 1200 ? true : false"
                    class="text-uppercase"
                    :loading="loadingFile"
                    block
                    color="primary"
                    dark
                    @click="HandlerUploadBill(item, index)"
                  >
                    Subir Factura
                  </v-btn>
                  <input
                    v-show="false"
                    ref="inputUploadBill"
                    type="file"
                    @change="HandlerUploadFileBill"
                  />
                  <!-- </div> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  :md="$vuetify.breakpoint.width < 1100 ? '12' : '6'"
                >
                  <!-- <v-btn
                    v-if="tab != 3"
                    :small="$vuetify.breakpoint.width < 1200 ? true : false"
                    class="text-uppercase"
                    :loading="loadingEmail"
                    @click="HandlerSendEmail(item)"
                    dark
                    color="#65B760"
                    v-show="item.path_label != null"
                    block
                  >
                    {{ tab == 1 ? "reenviar" : "Enviar" }} E-mail
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="mb-8"></div>
    </v-card>

    <v-row justify="center">
      <v-progress-circular
        v-show="loadingTable == true"
        size="250"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>

    <v-row justify="center" v-if="data.length == 0">
      <v-col cols="6" md="6">
        <v-card>
          <v-alert class="mb-0" outlined type="warning" prominent border="left">
            No hay datos para mostrar
          </v-alert>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="end" v-if="pagination.lastPage > 0">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      loadingTable: false,
      data: [],
      downloaded: false,

      //pagination
      page: 1,
      pagination: {},

      //tab
      tab: 0,
      items: ["CABA", "Nuevas", "Procesadas", "Sin factura", "Rechazados"],
      currentTab: "CABA",

      //file
      file: [],
      loadingFile: false,
      loadingEmail: false,
      selectData: {},
      selectDataBill: {},

      // filters
      operation_number: "",
      client_name: "",
      product_name: "",
      menu_start: false,
      date: null,
      menu_end: "",
      date_end: "",
      status: 4,
      loadingFilters: false,
    };
  },

  created() {
    this.HandlerGetSales(false, 1, 4);
  },

  filters: {
    dateParse(val) {
      return moment(val).locale("es").format("DD-MM-YYYY");
    },

    dateHour(val) {
      return moment(val).locale("es").format("DD-MM-YYYY HH:mm");
    },
  },

  watch: {
    tab(val) {
      if (val == 1) {
        this.currentTab = "Nuevas";
        // this.downloaded = true;
        this.status = 0;
        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } else if (val == 2) {
        this.currentTab = "Procesadas";
        this.downloaded = false;
        this.status = 1;
        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } else if (val == 3) {
        this.currentTab = "Sin factura";
        this.downloaded = false;
        this.status = 2;
        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } else if (val == 4) {
        this.currentTab = "Rechazados";
        this.downloaded = false;
        this.status = 3;
        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } else {
        this.currentTab = "CABA";
        this.downloaded = false;
        this.status = 4;
        this.HandlerGetSales(this.downloaded, this.page, this.status);
      }
    },

    page(val) {
      this.HandlerGetSales(this.downloaded, val, this.status);
    },
  },

  computed: {
    HandlerResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-start";
        case "sm":
          return "text-start";
        case "md":
          return "d-flex justify-center";
        case "lg":
          return "d-flex justify-center";
        case "xl":
          return "d-flex justify-center";
        default:
          return "d-flex justify-center";
      }
    },
  },

  methods: {
    async HandlerGetSales(dowload = false, page = 1, status) {
      try {
        this.loadingTable = true;
        const request = {
          downloaded: dowload != Boolean ? false : dowload, // ESTE VALOR NO SE ENVIA
          page: page,
          per_page: 10,
          paginate: true,
          status: status == undefined ? this.status : status,
          meli_id: this.operation_number,
          product_sku: this.product_name.toLowerCase(),
          buyer_name: this.client_name.toLowerCase(),
          date_ini: this.date == null ? "" : this.date,
          date_end: this.date_end == null ? "" : this.date_end,
        };

        const response = await this.$store.dispatch(
          "salesRetirement/GET_SALES_RETIREMENT_CLIENT",
          request
        );

        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };

        this.data = response.data.data.data;

        console.log(this.data);
        this.page = this.pagination.page;
        // this.tab = response.data.status == 5 ? this.tab : response.data.status;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    HandlerUploadData(item, index) {
      this.selectData = { ...item };
      this.$refs.inputUpload[index].click();
    },

    HandlerUploadBill(item, index) {
      this.selectDataBill = { ...item };
      this.$refs.inputUploadBill[index].click();
    },

    async HandlerUploadFile(evt) {
      try {
        this.loadingFile = true;
        const request = new FormData();
        request.append("tags_order", evt.target.files[0]);
        request.append("order_id", this.selectData.id);

        await this.$store.dispatch("salesRetirement/UPLOAD_FILE", request);

        this.$snotify.success("Etiqueta cargada con exitos", "Exitos!");

        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFile = false;
      }
    },

    async HandlerUploadFileBill(evt) {
      try {
        this.loadingFile = true;
        const request = new FormData();
        request.append("invoice_order", evt.target.files[0]);
        request.append("order_id", this.selectDataBill.id);

        await this.$store.dispatch("salesRetirement/UPLOAD_FILE_BILL", request);

        this.$snotify.success("Factura cargada con exitos", "Exitos!");

        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFile = false;
      }
    },

    async HandlerSendEmail(item) {
      try {
        this.loadingEmail = true;
        const request = { order_id: item.id };
        await this.$store.dispatch("salesRetirement/SEND_EMAIL", request);
        this.$snotify.success("Email enviado con exito", "Exitos!");
        this.HandlerGetSales(this.downloaded, this.page, this.status);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingEmail = false;
      }
    },

    async HandlerPrintBill(order, type) {
      try {
        this.loadingFile = true;
        const id = order.id;
        const path = order.path_label;
        const indexDelete = path.search("/");
        const name = path.slice(indexDelete + 1);
        const request = {
          url: path,
          id: id,
          type: type,
        };
        const response = await this.$store.dispatch(
          "salesRetirement/PRINT_LABEL",
          request
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFile = false;
      }
    },

    async HandlerPrintInvoice(order, type) {
      try {
        this.loadingFile = true;
        // const id = order.id;
        const path = order.invoice_path_label;
        const indexDelete = path.search("/");
        const name = path.slice(indexDelete + 1);
        const request = {
          url: path,
          type: type,
        };
        const response = await this.$store.dispatch(
          "salesRetirement/PRINT_INVOICE",
          request
        );
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingFile = false;
      }
    },

    updateData(input) {
      this.operation_number = input.replace(" ", "");
    },

    handleBlur(input) {
      if (input.target.value != "") {
        this.HandlerGetSales();
      }
    },

    clearSpaceEmail(input) {
      this.client_name = input.replaceAll(" ", "");
    },

    clearSpaceSKU(input) {
      this.product_name = input.replaceAll(" ", "");
    },

    // FILTROS

    HandlerReset() {
      this.operation_number = "";
      this.client_name = "";
      this.product_name = "";
      this.date = null;
      this.date_end = null;
      this.HandlerGetSales();
    },

    HandlerShippingOperator(item) {
      console.log("item", item);
      if (item.shipping !== null) {
        if (item.shipping.logistic_type == "Logis24") {
          return "(CABA)";
        } else if (item.shipping.logistic_type == "chazki") {
          return "(CHAZKI)";
        }
      } else {
        return;
      }
    },
  },
};
</script>

<style>
</style>