<template>
  <v-container>
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <div
        class="font-weight-medium primary--text mb-5"
        style="font-size: 26px"
      >
        Crear / Editar Imagen
      </div>
      <v-row>
        <v-col md="12">
          <v-card class="mb-5">
            <v-card-title class="pa-0 shadow-1">
              <v-img
                :src="require('@/assets/illustrations/bg_modal_card.svg')"
                cover
                position="left bottom"
                class="align-center"
                height="65px"
              >
                <v-card-title class="font-weight-medium primary--text">
                  Imagenes / Videos
                </v-card-title>
              </v-img>
            </v-card-title>

            <vuescroll
              :ops="{
                bar: { background: '#bacfcec0', keepShow: true },
              }"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="imagenes / videos"
                      :rules="action == 0 ? 'required' : ''"
                      v-slot="{ errors }"
                    >
                      <v-file-input
                        prepend-icon
                        prepend-inner-icon="mdi-camera-image"
                        label="Imagenes / videos"
                        v-model="images"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-file-input>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <ValidationProvider
                      name="nombre"
                      :rules="action == 0 ? 'required' : ''"
                      v-slot="{ errors }"
                    >
                      <v-text-field
                        label="Nombre del archivo"
                        v-model="name"
                        filled
                        rounded
                        dense
                        :error-messages="errors"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="3">
                    <v-btn
                      :loading="loadingPublication"
                      text
                      rounded
                      class="mr-1"
                      @click="closeModal({}, false)"
                    >
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="primary"
                      @click="
                        () =>
                          action == 0
                            ? passes(HandlerRegisterImages)
                            : HandlerUpdateImages(dataUpdate)
                      "
                    >
                      Guardar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </vuescroll>
            <!-- <v-card-actions class="mb-4">
              <v-spacer></v-spacer>
              <v-btn class="accent shadow-4 px-8 mr-1">
                Agregar / Modificar
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </ValidationObserver>
    <v-dialog v-model="activeModal">
      <v-card>
        <v-progress-linear
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import draggable from "vuedraggable";
import vuescroll from "vuescroll";
export default {
  props: {
    dataUpdate: {
      type: Object,
      required: false,
      default: () => {},
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  components: {
    vuescroll,
    // draggable,
  },
  data() {
    return {
      activeModal: false,
      model: null,
      images: [],
      name: "",

      loading: false,
      page: 1,
      productsData: [],
      paginate: {},
      selected: [],
      loadingPublication: false,
      updateImage: false,
    };
  },

  created() {
    this.name = this.dataUpdate.name;
  },

  methods: {
    closeModal(data, action) {
      this.$emit("dialog:change", data, action);
    },

    async HandlerRegisterImages() {
      try {
        this.activeModal = true;
        const request = new FormData();

        request.append("name", this.name);
        request.append("image", this.images);

        const response = await this.$store.dispatch(
          "images/REGISTER_PHOTOS",
          request
        );

        this.closeModal(response, false);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPublication = false;
        this.activeModal = false;
      }
    },

    async HandlerUpdateImages(item) {
      try {
        this.activeModal = true;
        const request = new FormData();

        request.append("photo_id", item.id);
        request.append("name", this.name);
        request.append("image", this.images);

        const response = await this.$store.dispatch(
          "images/UPDATED_PHOTOS",
          request
        );

        this.closeModal(response, false);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingPublication = false;
        this.activeModal = false;
      }
    },
  },
};
</script>

<style>
</style>