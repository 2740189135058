<template>
  <section>
    <v-container fluid class="mt-5">
      <v-row class="justify-between align-center">
        <v-col cols="auto">
          <v-btn
            v-if="!registerStore"
            class="accent shadow-4 px-8"
            large
            rounded
            @click.stop="HandlerAddData()"
            >Añadir Tienda</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <register-store
      v-if="registerStore"
      :dataUpdateStore="dataUpdate"
      :actionData="actionData"
      @dialog:change="HandlerResponse"
    />
    <v-container v-if="registerStore == false">
      <v-row>
        <v-col cols="12">
          <v-card flat class="shadow-1 mt-5">
            <v-data-table
              :headers="headers"
              :items="dataStore"
              :items-per-page="-1"
              hide-default-footer
              disable-pagination
              no-data-text="Selecciona una categoria"
              no-results-text="No tiene sub categorias registradas"
              class="elevation-0"
            >
              <template v-slot:[`item.name`]="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <img :src="item.url" alt="logo" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="text-capitalize"
                      v-html="item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="HandlerEditShop(item)"
                      icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="end">
        <v-col cols="6" md="4">
          <div>
            <v-pagination
              v-model="page"
              :length="pagination.lastPage"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import registerStore from "./Utils/registerStore.vue";
export default {
  components: {
    "register-store": registerStore,
  },
  data() {
    return {
      registerStore: false,
      dataUpdate: {},
      actionData: 0,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
        },
        // { text: "Logo", value: "url" },
        { text: "Accion", value: "action" },
      ],
      dataStore: [],
      // PAGINATION
      page: 1,
      pagination: {},
    };
  },
  created() {
    this.HandlerGetStore();
  },
  methods: {
    HandlerAddData() {
      this.registerStore = true;
      this.dataUpdate = {
        b2b_active: false
      };
      this.actionData = 0;
    },
    async HandlerGetStore() {
      try {
        const request = {
          page: 1,
          per_page: 20,
          paginate: true,
        };
        const response = await this.$store.dispatch("store/GET_STORE", request);
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataStore = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    HandlerEditShop(item) {
      this.registerStore = true;
      this.actionData = 1;
      this.dataUpdate = { ...item };
    },

    HandlerResponse() {
      this.registerStore = false;
      this.HandlerGetStore();
      // if (action === 0) {
      //   this.dataStore.push(item.data);
      // }
    },
  },
};
</script>

<style>
</style>