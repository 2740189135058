<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          label="Venta"
          filled
          rounded
          dense
          class="rounded-small"
          v-model="meli_id"
          @keyup.enter="HandlerGetSales()"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12" sm="12" md="12">
        <v-card :class="HandlerResponsive">
          <v-toolbar flat color="primary" dark class="px-md-10 mb-1">
            <v-toolbar-title>Estatus del envio</v-toolbar-title>
          </v-toolbar>
          <v-tabs v-model="tab" align-with-title class="mt-3">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              <v-badge
                v-if="currentTab == item"
                bordered
                bottom
                color="primary"
                :content="paginate.total"
                transition="slide-x-transition"
              >
                {{ item }}
              </v-badge>
              <span v-else>
                {{ item }}
              </span>
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col col="12" md="12">
        <v-card flat class="shadow-1 mt-5">
          <v-data-table
            :loading="loadingTable"
            :headers="headers"
            :items="data"
            hide-default-footer
            disable-pagination
            fixed-header
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.order`]="{ item }">
              <div
                v-for="(value, index) in item.order_item"
                :key="index"
                col="12"
                sm="12"
                md="12"
              >
                <div v-if="value.product != null || value.product != undefined">
                  <div>
                    <span class="font-weight-medium">Nombre: </span>
                    <span class="text-uppercase">
                      {{ value.product.name }}
                    </span>
                  </div>
                  <div>
                    <span class="font-weight-medium">SKU:</span>
                    {{ value.product.sku }}
                  </div>
                  <div>
                    <span class="font-weight-medium">Codigo de barras:</span>
                    {{ value.product.barcode }}
                  </div>
                  <div>
                    <span class="font-weight-medium">Cantidad:</span>
                    {{ value.quantity }}
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <div
                class="d-flex justify-start"
                v-if="item.order_item.length > 0"
              >
                <v-avatar
                  v-if="item.order_item[0].product.pictures == null"
                  size="100"
                  tile
                >
                  <v-img contain src="@/assets/img/no_image.jpg"></v-img>
                </v-avatar>
                <v-avatar v-else size="100" tile>
                  <v-img
                    :src="JSON.parse(item.order_item[0].product.pictures)[0]"
                    :lazy-src="
                      JSON.parse(item.order_item[0].product.pictures)[0]
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="black lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
              </div>
              <div v-if="item.order_item.length == 0">
                <v-avatar size="100" tile>
                  <v-img contain src="@/assets/img/no_image.jpg"></v-img>
                </v-avatar>
              </div>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div
                v-if="item.buyer != null"
                class="d-flex justify-start text-uppercase font-weight-medium"
              >
                {{ item.buyer.first_name }} {{ item.buyer.last_name }}
              </div>
            </template>
            <template v-slot:[`item.email`]="{ item }">
              <div v-if="item.buyer != null" class="d-flex justify-start">
                {{ item.buyer.email }}
              </div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-btn
                :loading="loadingPrint"
                color="#F6B246"
                dark
                block
                class="text-capitalize"
                @click="HandlerPrintInvoice(item)"
              >
                {{ tab == 1 ? "Reimprimir" : "Imprimir" }} Factura
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="6" md="4">
        <div>
          <v-pagination
            v-model="page"
            :length="pagination.lastPage"
            circle
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      currentTab: "",
      page: 1,
      loadingTable: false,
      headers: [
        { text: "Venta #", value: "meli_id" },
        {
          text: "Producto",
          align: "start",
          sortable: false,
          value: "order",
          width: "25%",
        },
        // { text: "Logo", value: "url" },
        // { text: "Imagen Producto", value: "image" },
        { text: "Nombre Usuario", value: "name" },
        { text: "Correo", value: "email" },
        { text: "Acciones", value: "action" },
      ],
      data: [],
      items: ["Por Retirar", "Retirados"],
      downloaded: false,
      loadingPrint: false,
      meli_id: "",
      // PAGINADO
      pagination: {},
    };
  },

  created() {
    this.HandlerGetSales();
  },

  watch: {
    tab(val) {
      if (val == 1) {
        this.downloaded = true;
        this.HandlerGetSales(this.downloaded);
      } else {
        this.downloaded = false;
        this.HandlerGetSales(this.downloaded);
      }
    },

    page(val) {
      this.HandlerGetSales(this.downloaded, val);
    },
  },

  computed: {
    HandlerResponsive() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-start";
        case "sm":
          return "text-start";
        case "md":
          return "d-flex justify-center";
        case "lg":
          return "d-flex justify-center";
        case "xl":
          return "d-flex justify-center";
        default:
          return "d-flex justify-center";
      }
    },
  },

  methods: {
    async HandlerGetSales(dowload = false, page = 1) {
      console.log('dowload',dowload)

      try {
        this.loadingTable = true;
        const request = {
          downloaded: this.downloaded,
          page: page,
          per_page: 10,
          paginate: true,
          warehouse_id: "",
          country_id: 2,
          meli_id: this.meli_id,
        };
        const response = await this.$store.dispatch(
          "salesRetirement/GET_SALES_RETIREMENT",
          request
        );
        this.pagination = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.data = response.data.data.data;
        this.page = this.pagination.page;
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingTable = false;
      }
    },

    async HandlerPrintInvoice(item) {
      try {
        console.log(item);
        this.loadingPrint = true;
        const request = {
          order_id: item.id,
          url:
            item.invoice_path_label == null
              ? item.order_invoice[0].invoice_path_label
              : item.invoice_path_label,
        };

        const response = await this.$store.dispatch(
          "salesRetirement/PRINT_SALES_RETIREMENT",
          request
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${item.meli_id}.pdf`);
        document.body.appendChild(link);
        link.click();

        this.$snotify.success(
          "Su factura se ha impreso correctamente",
          "Exitos!"
        );

        this.HandlerGetSales(this.downloaded, this.page);
      } catch (error) {
        console.log(error);
        this.$snotify.error(
          "Ocurrio un error con la consulta intente mas tarde",
          "Error!"
        );
      } finally {
        this.loadingPrint = false;
      }
    },
  },
};
</script>

<style>
</style>