var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('div',{staticClass:"font-weight-medium primary--text mb-5 ml-3",staticStyle:{"font-size":"26px"}},[_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar" : "Añadir")+" Categoría ")]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"mb-5 px-2 py-2",attrs:{"height":"auto"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary","rounded":"","height":"6"}}):_vm._e(),_c('v-card-text',[_c('v-form',[_c('v-row',{attrs:{"dense":"","justify":"start"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre de categoría","name":"nombre","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.categoryData.name),callback:function ($$v) {_vm.$set(_vm.categoryData, "name", $$v)},expression:"categoryData.name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"subtitle-box",attrs:{"cols":"12"}},[_c('h5',{staticClass:"subtitle-label"},[_vm._v(" Especificaciones ")])]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.categoryData.specifications),function(specification,index){return _c('v-row',{key:specification.id,staticStyle:{"margin":"0px"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":("Nombre de especificación " + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"rounded-small",attrs:{"required":"","label":"Nombre","filled":"","rounded":"","dense":"","name":("Nombre de especificación " + (index + 1)),"value":_vm.categoryData.specifications[index].name,"items":_vm.availableOptions[index],"item-text":"name","item-value":"name","color":"blue-grey","error-messages":errors,"search-input":_vm.searchText[index]},on:{"input":function (elem){ return _vm.updateUnit(index, elem); },"update:searchInput":function($event){return _vm.$set(_vm.searchText, index, $event)},"update:search-input":function($event){return _vm.$set(_vm.searchText, index, $event)}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{staticClass:"btn-box-options"},[(!_vm.loadingSpecifications)?_c('v-btn',{staticClass:"ml-4",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.HandlerOpenModal(index)}}},[_vm._v(" Añadir especificación ")]):_c('v-progress-circular',{staticClass:"d-flex justify-center my-5",attrs:{"size":"20","indeterminate":"","color":"primary"}})],1)]},proxy:true},{key:"no-data",fn:function(){return [_c('div')]},proxy:true}],null,true)})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('ValidationProvider',{attrs:{"name":("Unidad de especificación " + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","disabled":"","label":"Unidad","name":("Unidad de especificación " + (index + 1)),"type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.categoryData.specifications[index].measuring_unit),callback:function ($$v) {_vm.$set(_vm.categoryData.specifications[index], "measuring_unit", $$v)},expression:"categoryData.specifications[index].measuring_unit"}})]}}],null,true)})],1),_c('v-col',{staticClass:"delete-box",attrs:{"cols":"1"}},[_c('button',{on:{"click":function($event){$event.preventDefault();return (function () { return _vm.deleteSpecification(index); })($event)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-delete ")])],1)])],1)}),1),_c('v-col',{staticClass:"btn-add-box",attrs:{"cols":"12"}},[_c('button',{class:"btn-add",on:{"click":function($event){$event.preventDefault();return _vm.addSpecification($event)}}},[_vm._v(" Agregar expecificación ")])])],1)],1)],1),_c('v-card-actions',{staticClass:"mx-2"},[_c('v-spacer'),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"text":"","rounded":"","color":hover ? 'error' : 'grey',"disabled":_vm.loader},on:{"click":function($event){return _vm.closeModal(null, false)}}},[_vm._v("Cancelar")])]}}],null,true)}),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"px-8",class:hover ? 'shadow-2' : 'shadow-4',attrs:{"rounded":"","loading":_vm.loader,"color":"success"},on:{"click":function($event){return passes(_vm.HandlerRegister)}}},[_vm._v(" "+_vm._s(_vm.action == 1 ? "Editar" : "Añadir")+" ")])]}}],null,true)})],1)],1)]}}])})],1)],1),(_vm.showAddSpecificationModal)?_c('custom-modal',{attrs:{"active":_vm.showAddSpecificationModal,"action":_vm.HandlerAddSpecification,"closeModal":_vm.HandlerCloseModal,"disabled":!_vm.activeModalBtn,"confirmBtn":"","title":'Añadir especificación',"max-width":"800","loader":_vm.loadingAddSpecification}},[_c('v-form',{staticClass:"mt-4"},[_c('v-row',{attrs:{"dense":"","justify":"start"}},[_c('v-row',{staticStyle:{"margin":"0px"},attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre de especificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"rounded-small",attrs:{"required":"","filled":"","rounded":"","dense":"","label":"Nombre","name":"Nombre de especificación","type":"text","color":"blue-grey","error-messages":errors},model:{value:(_vm.AddSpecificationData.name),callback:function ($$v) {_vm.$set(_vm.AddSpecificationData, "name", $$v)},expression:"AddSpecificationData.name"}})]}}],null,false,2472491361)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Unidad de especificación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"rounded-small",attrs:{"items":_vm.specifications,"item-text":"title","item-value":"value","required":"","filled":"","rounded":"","dense":"","label":"Unidad","name":"Unidad de especificación","color":"blue-grey","error-messages":errors},model:{value:(_vm.AddSpecificationData.measuring_unit),callback:function ($$v) {_vm.$set(_vm.AddSpecificationData, "measuring_unit", $$v)},expression:"AddSpecificationData.measuring_unit"}})]}}],null,false,3533646504)})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }