var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Nombre / Documento","filled":"","rounded":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetTransfer()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"rounded-small",attrs:{"label":"Venta","filled":"","rounded":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.HandlerGetTransfer()}},model:{value:(_vm.search_operation_number),callback:function ($$v) {_vm.search_operation_number=$$v},expression:"search_operation_number"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-select',{staticClass:"rounded-small",attrs:{"label":"Estatus","filled":"","rounded":"","dense":"","items":_vm.data_status,"item-text":"text","item-value":"value"},on:{"change":function($event){return _vm.HandlerGetTransfer()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadow-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.dataTransfer,"hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.buyer.first_name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(item.buyer.first_name)+" "+_vm._s(item.buyer.last_name)+" ")])]}},{key:"item.total_amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_amount))+" ")]}},{key:"item.shipping_cost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.shipping_cost))+" ")]}},{key:"item.total_amount_with_shipping",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_amount_with_shipping))+" ")]}},{key:"item.buyer.doc_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.buyer.doc_type)+": "+_vm._s(item.buyer.doc_number)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.payment[0].status == 'pending')?_c('v-btn',{staticClass:"accent shadow-4",attrs:{"rounded":"","disabled":item.payment[0].voucher_url ? false : true},on:{"click":function($event){return _vm.HandlerStatusChange(item, 'approved')}}},[_vm._v(" Aprobar ")]):_vm._e(),(item.payment[0].status == 'pending')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.HandlerStatusChange(item, 'rejected')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-thumb-down")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechazar")])]):_vm._e(),(item.payment[0].voucher_url)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":_vm.isDark ? 'primary' : 'green',"dark":""},on:{"click":function($event){return _vm.HandlerShowVoucher(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.isDark ? 'primary' : 'green'}},[_vm._v("mdi-text-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver comprobante de pago")])]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"d-flex justify-end mt-3"},[(Object.keys(_vm.paginate).length > 0)?_c('v-pagination',{attrs:{"length":_vm.paginate.lastPage,"circle":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }