<template>
  <section>
    <v-container fluid class="mt-5 mb-5">
      <filters-component
        v-if="status_card"
        :paginate="paginate"
        :page="page"
        @filters:change="HandlerFilterResponse"
      />

      <div v-if="!loading">
        <v-row
          v-show="dataProducts.length > 0"
          class="mt-10"
          v-for="(item, index) in dataProducts"
          :key="index"
        >
          <v-col cols="12" md="12">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <!-- *** INFORMACIÓN COMPRADOR *** -->
                  <v-col cols="12" sm="3" md="3">
                    <div v-if="item.buyer != null">
                      <p class="subtitle-1 font-weight-bold">
                        <v-icon size="18">mdi-account</v-icon>
                        <span class="text-uppercase">
                          {{
                            `${item.buyer.first_name} ${item.buyer.last_name}`
                          }}
                        </span>
                      </p>
                      <p class="mt-7 mb-0 subtitle-2 font-weight-bold">
                        {{ item.buyer.fullname }}
                      </p>
                      <p class="mb-0">
                        <span class="subtitle-2 font-weight-bold">Tel:</span>
                        <span v-if="item.buyer.phone == null"> 0000000 </span>
                        <span v-else>
                          {{ item.buyer.phone }}
                        </span>
                        <br />
                        <span class="subtitle-2 font-weight-bold">
                          {{ item.buyer.doc_type }}:
                        </span>
                        {{ item.buyer.doc_number }}
                      </p>
                      <p>
                        <v-icon size="18" class="mr-1"
                          >mdi-email-outline</v-icon
                        >
                        <a @click="HandlerCopy(item)">{{ item.buyer.email }}</a>
                      </p>
                    </div>
                  </v-col>

                  <!-- *** INFORMACIÓN ENVIO *** -->
                  <v-col cols="12" sm="3" md="3">
                    <ship-information :order="item" />
                  </v-col>

                  <!-- *** INFORMACIÓN VENTA *** -->
                  <v-col cols="12" sm="3" md="3">
                    <div>
                      <p class="subtitle-1 font-weight-bold">
                        {{
                          item.store_id == 0
                            ? HandlerName(item) == undefined
                              ? ""
                              : HandlerName(item)
                            : item.store.name.toUpperCase()
                        }}
                      </p>
                    </div>
                    <div class="mt-7">
                      <payment-sales :order="item" />
                    </div>
                  </v-col>

                  <!-- *** INFORMACIÓN PAGO *** -->
                  <v-col cols="12" sm="3" md="3"
                    ><div>
                      <p class="subtitle-1 font-weight-bold">
                        INFORMACIÓN DEL PAGO
                      </p>
                    </div>
                    <div class="mt-7">
                      <payment-information :payment="item" />
                    </div>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <h5>Productos</h5>
                <v-divider></v-divider>
                <!-- {{item.installments}} -->
                <table-order-item
                  :orderItem="item.order_item"
                  :installments="item.installments"
                  :store_id="item.store_id"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-show="dataProducts.length == 0" class="mt-10">
          <v-col cols="12" md="12">
            <v-card flat class="elevation-0">
              <v-card-text>
                <v-alert outlined type="warning" text>
                  <span :class="isDark ? 'white--text' : 'black--text'">
                    No se encuentran productos con estos filtros
                  </span>
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-4 mr-4" justify="end">
          <v-pagination
            v-model="page"
            :length="paginate.lastPage"
            :total-visible="7"
            circle
          ></v-pagination>
        </v-row>
      </div>
      <div v-else class="mt-15">
        <v-row justify="center">
          <v-progress-circular
            :size="300"
            :width="7"
            indeterminate
            color="#7E82FA"
          ></v-progress-circular>
        </v-row>
      </div>
    </v-container>
  </section>
</template>

<script>
import moment from "moment";
import Filters from "./Utils/filters";
import PaymentInformation from "./Utils/payment_information";
import ShipInformation from "./Utils/ship-information";
import PaymentSales from "./Utils/payment_sale_information";
import TableOrderItem from "./Utils/table_order_item";
export default {
  components: {
    "table-order-item": TableOrderItem,
    "payment-information": PaymentInformation,
    "payment-sales": PaymentSales,
    "ship-information": ShipInformation,
    "filters-component": Filters,
  },
  data() {
    return {
      status_card: true,
      loading: false,
      page: 1,
      dataProducts: [],
      paginate: {},
      accountsML: [],
      filterDataValue: {},
    };
  },
  created() {
    this.HandlerGetOrder(this.page, this.filterDataValue);
    this.HandlerMLAccounts();
    // setInterval(() => {
    //   this.HandlerGetOrder();
    // }, 180000);
  },

  // beforeDestroyed() {
  //   clearInterval();
  // },

  watch: {
    page(val) {
      this.HandlerGetOrder(val, this.filterDataValue);
    },
  },

  filters: {
    date(val) {
      return moment(val).locale("es").format("DD-MM-YYYY");
    },
    date1(val) {
      return moment(val).locale("es").format("hh:mm");
    },
  },

  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },

  methods: {
    async HandlerGetOrder(page, filterData = {}) {
      try {
        this.loading = true;
        const myPage = page || 1;
        const request = {
          page: myPage,
          per_page: 10,
          paginate: true,
          meli_order_id:
            filterData.meli_order_id == undefined
              ? ""
              : filterData.meli_order_id,
          search_buyer:
            filterData.search_buyer == undefined ? "" : filterData.search_buyer,
          search_order_item:
            filterData.search_order_item == undefined
              ? ""
              : filterData.search_order_item,
          date_from:
            filterData.date_from == undefined ? "" : filterData.date_from,
          date_to: filterData.date_to == undefined ? "" : filterData.date_to,
          store_id: filterData.store_id == undefined ? "" : filterData.store_id,
          country_id: 3
        };

        const response = await this.$store.dispatch(
          "orders/GET_ORDERS",
          request
        );

        this.paginate = {
          lastPage: response.data.data.lastPage,
          page: response.data.data.page,
          perPage: response.data.data.perPage,
          total: response.data.data.total,
        };
        this.dataProducts = response.data.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerCopy(item) {
      const email = item.buyer.email;
      await this.$copyText(email);
      this.$snotify.success(`Correo copiado`, "Exitos");
    },

    HandlerFilterResponse(value, action, filterData) {
      if (action == 0) {
        this.filterDataValue = { ...filterData };
        this.HandlerGetOrder(value, filterData);
        this.page = value;
      } else if (action == 1) {
        this.filterDataValue = { ...filterData };
        this.loading = true;
        this.paginate = { ...value.paginate };
        this.dataProducts = [...value.data];
        this.page = value.paginate.page;
        this.loading = false;
      } else if (action == 2) {
        this.filterDataValue = { ...filterData };
        this.loading = true;
      } else if (action == 3) {
        this.filterDataValue = { ...filterData };
        this.loading = false;
      }
    },

    async HandlerMLAccounts() {
      try {
        const response = await this.$store.dispatch("ml/GET_ML_ACCOUNT");
        this.accountsML = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    HandlerName(item) {
      const filterData = this.accountsML.find(
        (val) => val.seller_id == item.meli_seller_id
      );
      if (filterData != null) {
        return filterData.name;
      } else {
        return "";
      }
    },
  },
};
</script>

<style>
</style>