<template>
  <v-container>
    <v-row>
      <div
        class="font-weight-medium primary--text mb-5 ml-3"
        style="font-size: 26px"
      >
        Transferir inventario
      </div>
      <v-col cols="12">
        <ValidationObserver ref="obs" v-slot="{ passes }">
          <v-card height="auto" class="mb-5">
            <v-progress-linear
              v-if="loading"
              indeterminate
              color="primary"
              rounded
              height="6"
            ></v-progress-linear>

            <v-card-text>
              <v-form>
                <v-row dense class="mt-3">
                  <v-col cols="12" class="subtitle-box">
                    <h5 class="subtitle-label">
                      Depósitos
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="6"
                    v-if="
                      (!!transferEditData && transferEditData.originDeposit) ||
                        isTransfer
                    "
                  >
                    <ValidationProvider
                      name="Depósito origen"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="deposits"
                        item-text="name"
                        :item-value="item => item"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Depósito origen"
                        :disabled="!!transferEditData"
                        name="Depósito origen"
                        color="blue-grey"
                        v-model="transferData.originStore"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <ValidationProvider
                      name="Depósito destino"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-select
                        :items="filteredDeposits"
                        item-text="name"
                        :item-value="item => item"
                        required
                        filled
                        rounded
                        class="rounded-small"
                        dense
                        label="Depósito destino"
                        name="Depósito destino"
                        :disabled="!!transferEditData || country !== 3"
                        color="blue-grey"
                        v-model="transferData.destinationStore"
                        :error-messages="errors"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>

                  <v-col cols="12" class="subtitle-box">
                    <h5 class="subtitle-label">
                      Fecha de envío
                    </h5>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu_open"
                      v-model="menu_open"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Fecha de envío"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            name="Fecha de envío"
                            class="rounded-small"
                            v-model="transferData.opening_date"
                            label="Fecha de envío"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-if="menu_open"
                        v-model="transferData.opening_date"
                        full-width
                        :max="transferData.close_date"
                        @input="menu_open = false"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      ref="menu_close"
                      v-model="menu_close"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          name="Fecha de entrega estimada"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            name="Fecha de entrega estimada"
                            class="rounded-small"
                            v-model="transferData.close_date"
                            label="Fecha de entrega estimada"
                            prepend-inner-icon="mdi-calendar-month-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker
                        v-if="menu_close"
                        v-model="transferData.close_date"
                        :min="transferData.opening_date"
                        full-width
                        @input="menu_close = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <div
                    v-if="transferData.destinationStore.id === 27"
                    style="width: 100%"
                  >
                    <v-col cols="12" class="subtitle-box">
                      <h5 class="subtitle-label">
                        Mercado Libre shipping ID
                      </h5>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <ValidationProvider
                        name="shipping id"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          required
                          filled
                          rounded
                          class="rounded-small"
                          dense
                          label="Shipping ID"
                          :name="mercadolibre_shipping_id"
                          color="blue-grey"
                          v-model="mercadolibre_shipping_id"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                  </div>
                  <div v-if="!transferEditData" :style="{ width: '100%' }">
                    <v-col cols="12" class="subtitle-box">
                      <h5 class="subtitle-label">
                        Productos
                      </h5>
                    </v-col>
                    <v-row
                      dense
                      v-for="(product, index) in transferData.products"
                      :key="product.id"
                      style="margin:0px;"
                    >
                      <v-col cols="12" sm="5" md="5">
                        <ValidationProvider
                          :name="`Selector de producto ${index + 1}`"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-autocomplete
                            :items="Products[index]"
                            :loading="searchLoadings[index]"
                            item-text="value.label"
                            item-value="value"
                            required
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            role="button"
                            label="Selector de producto"
                            :name="`Selector de producto ${index + 1}`"
                            color="blue-grey"
                            v-model="
                              transferData.products[index].productSelected
                            "
                            :search-input.sync="searchItems[index]"
                            :error-messages="errors"
                          ></v-autocomplete>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="2" md="2">
                        <ValidationProvider
                          :name="`Cantidad de producto ${index + 1}`"
                          rules="required|numeric"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            required
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            label="Cantidad"
                            :name="`Cantidad de producto ${index + 1}`"
                            color="blue-grey"
                            v-model="transferData.products[index].amount"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="3" md="3" v-if="country === 3">
                        <ValidationProvider
                          :name="`Valor de producto ${index + 1}`"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            required
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            label="Valor $"
                            :name="`Valor de producto ${index + 1}`"
                            color="blue-grey"
                            v-model="
                              transferData.products[index].productSelected.cost
                            "
                            :disabled="isTransfer"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="1" class="delete-box" v-if="index > 0">
                        <button @click.prevent="() => deleteProduct(index)">
                          <v-icon large>
                            mdi-delete-outline
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                    <v-col cols="12" class="btn-add-box">
                      <button @click.prevent="addProduct" class="btn-add">
                        AGREGAR PRODUCTO +
                      </button>
                    </v-col>
                  </div>
                  <v-col
                    cols="12"
                    class="subtitle-box"
                    v-if="country === 3 && !transferEditData"
                  >
                    <h5 class="subtitle-label">
                      Gastos asociados a la transferencia
                    </h5>
                  </v-col>
                  <div
                    :style="{ width: '100%' }"
                    v-if="country === 3 && !transferEditData"
                  >
                    <v-row
                      dense
                      v-for="(cost, index) in transferData.costs"
                      :key="cost.id"
                      style="margin:0px"
                    >
                      <v-col cols="12" sm="4" md="4">
                        <ValidationProvider
                          :name="`Prestador de servicio de gasto ${index + 1}`"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            label="Prestador de servicio"
                            :name="
                              `Prestador de servicio de gasto ${index + 1}`
                            "
                            color="blue-grey"
                            v-model="transferData.costs[index].serviceProvider"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" sm="4" md="4">
                        <ValidationProvider
                          :name="`Descripción de gasto ${index + 1}`"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            label="Descripción"
                            :name="`Descripción de gasto ${index + 1}`"
                            color="blue-grey"
                            v-model="transferData.costs[index].description"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="11" sm="3" md="3">
                        <ValidationProvider
                          :name="`Valor de gasto ${index + 1}`"
                          rules="numeric"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            filled
                            rounded
                            class="rounded-small"
                            dense
                            label="Valor $"
                            :name="`Valor de gasto ${index + 1}`"
                            color="blue-grey"
                            v-model="transferData.costs[index].costValue"
                            :error-messages="errors"
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col
                        cols="1"
                        class="delete-box"
                        v-if="index > 0 && !transferData.costs[index].id"
                      >
                        <button @click.prevent="() => deleteCost(index)">
                          <v-icon large>
                            mdi-delete-outline
                          </v-icon>
                        </button>
                      </v-col>
                    </v-row>
                    <v-col cols="12" class="btn-add-box">
                      <button @click.prevent="addCost" class="btn-add">
                        AGREGAR GASTO +
                      </button>
                    </v-col>

                    <v-col
                      cols="12"
                      class="subtitle-box"
                      v-if="!transferEditData"
                    >
                      <h5 class="subtitle-label">
                        Agregar comprobantes
                      </h5>
                    </v-col>

                    <v-row v-if="!transferEditData">
                      <v-col cols="12" sm="6" md="6" style="padding: 16px;">
                        <div
                          class="drop-box"
                          @drop.prevent="onDrop($event)"
                          @dragover.prevent="dragover = true"
                          @dragenter.prevent="dragover = true"
                          @dragleave.prevent="dragover = false"
                        >
                          <v-icon color="#2196F3">
                            mdi-file-upload-outline
                          </v-icon>
                          <div class="row-content">
                            <button
                              @click.prevent="openFilePicker"
                              class="btn-link"
                            >
                              Click para cargar
                            </button>
                            <h6 class="text-drop">o arrastrar y soltar</h6>
                          </div>
                          <h6 class="text-drop color-light">
                            SVG, PNG, JPG or GIF (max. 3MB)
                          </h6>
                        </div>
                        <div style="display: none;">
                          <v-file-input
                            ref="fileInput"
                            hidden
                            multiple
                            @change="onFileChange"
                          ></v-file-input>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="4" md="4" style="padding: 16px;">
                        <div
                          v-for="(file, index) in files"
                          :key="index"
                          class="file-box"
                        >
                          <button
                            class="close-icon"
                            @click.prevent="() => deleteFile(file)"
                          >
                            <v-icon>
                              mdi-close
                            </v-icon>
                          </button>
                          <h6 class="text-drop padding-right">
                            {{ file.name }}
                          </h6>
                          <div class="text-size">
                            <h6 class="text-drop color-light">
                              {{ (file.size / 1024).toFixed(2) }}kb
                            </h6>
                          </div>
                          <v-progress-linear
                            value="100"
                            color="#2196F3"
                            rounded
                            height="6"
                          ></v-progress-linear>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-row>
              </v-form>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="mx-2">
              <v-spacer></v-spacer>
              <v-hover v-slot:default="{ hover }">
                <v-btn
                  text
                  rounded
                  class="mr-1"
                  :disabled="loading"
                  :color="hover ? 'error' : 'grey'"
                  @click="closeModal(false)"
                  >Cancelar</v-btn
                >
              </v-hover>

              <v-hover v-slot:default="{ hover }">
                <v-btn
                  rounded
                  :loading="loading"
                  :disabled="loading"
                  color="success"
                  :class="hover ? 'shadow-2' : 'shadow-4'"
                  class="px-8"
                  @click="passes(HandlerAction)"
                  >enviar</v-btn
                >
              </v-hover>
            </v-card-actions>
          </v-card>
        </ValidationObserver>
        <!-- </v-dialog> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false
    },
    closeModal: {
      type: Function,
      required: false,
      default: () => {}
    },
    deposits: {
      type: Array,
      require: true,
      default: () => []
    },
    originDeposit: {
      type: Object,
      require: false,
      default: () => {}
    },
    isTransfer: {
      type: Boolean,
      required: false,
      default: true
    },
    transferEditData: {
      type: Object,
      required: false,
      default: () => null
    },
    country: {
      type: Number,
      required: false,
      default: () => 3
    }
  },
  data() {
    return {
      transferData: {
        originStore: this.originDeposit,
        country: 3,
        destinationStore: "",
        opening_date: null,
        close_date: null,
        mercadolibre_shipping_id: null,
        products: [
          {
            productSelected: "",
            amount: null,
            productValue: null
          }
        ],
        costs: [
          {
            costValue: null,
            serviceProvider: "",
            description: ""
          }
        ]
      },
      storeList: [
        { label: "destino 1", value: 1 },
        { label: "destino 2", value: 2 },
        { label: "destino 3", value: 3 }
      ],
      files: [],
      dragover: false,
      menu_open: false,
      menu_close: false,
      loading: false,
      searchItems: [""],
      searchLoadings: [false],
      Products: [[]]
    };
  },

  created() {
    if (this.country !== 3) {
      const transitWarehouse = this.country === 1 ? 17 : 36;
      this.transferData["destinationStore"] = {
        id: transitWarehouse,
        name: this.getWarehouseName(transitWarehouse)
      };
    }
    if (this.transferEditData) {
      const {
        origin_warehouse_id,
        destination_warehouse_id,
        estimated_delivery_date,
        shipping_date,
        products,
        costs,
        id
      } = this.transferEditData;
      this.transferData = {
        id,
        originStore: {
          id: origin_warehouse_id,
          name: this.getWarehouseName(origin_warehouse_id)
        },
        destinationStore: {
          id: destination_warehouse_id,
          name: this.getWarehouseName(destination_warehouse_id)
        },
        opening_date: moment(shipping_date).format("YYYY-MM-DD"),
        close_date: moment(estimated_delivery_date).format("YYYY-MM-DD"),
        mercadolibre_shipping_id: null,
        products: products.map(({ name, quantity }) => ({
          productSelected: name,
          amount: quantity,
          productValue: null
        })),
        costs: costs.map(({ amount, description, shipping_courier, id }) => ({
          id,
          costSelected: "",
          costValue: Number(amount),
          serviceProvider: shipping_courier,
          description: description
        }))
      };
      this.isTransfer = !!this.transferEditData.origin_warehouse_id;
    }
  },

  methods: {
    openFilePicker() {
      this.$refs.fileInput.$refs.input.click();
    },
    onFileChange(item) {
      if (item[0]) {
        item.forEach(element => {
          if (element.size < 3145728) {
            this.files.push(element);
          }
        });
      }
    },
    onDrop(item) {
      item.dataTransfer.files.forEach(element => {
        if (element.size < 3145728) {
          this.files.push(element);
        }
      });
    },
    deleteFile(item) {
      const index = this.files.findIndex(el => el.name === item.name);
      this.files.splice(index, 1);
    },
    addProduct() {
      const newProduct = {
        productSelected: "",
        amount: null,
        item: null,
        productValue: null,
        productId: Math.floor(Math.random() * 1000) / 100
      };
      this.transferData.products.push(newProduct);
      this.searchItems.push("");
      this.searchLoadings.push(false);
    },
    deleteProduct(index) {
      this.transferData.products.splice(index, 1);
      this.searchItems.splice(index, 1);
      this.searchLoadings.splice(index, 1);
    },
    addCost() {
      const newCost = {
        costSelected: "",
        costValue: null,
        serviceProvider: "",
        description: ""
      };
      this.transferData.costs.push(newCost);
    },
    deleteCost(index) {
      this.transferData.costs.splice(index, 1);
    },
    async HandlerAction() {
      this.loading = true;
      const request = new FormData();
      try {
        if (this.transferEditData) {
          request.append("id", this.transferEditData.id);
          request.append(
            "estimated_delivery_date",
            this.transferData.close_date
          );
          await this.updateAndCreateCosts();
          await this.$store.dispatch(
            "traceability/UPDATE_INVENTORY_TRANSFERS",
            request
          );
        } else {
          const products = this.transferData.products.map(elem => {
            return {
              id: elem.productSelected.id,
              cost: Number(elem.productSelected.cost),
              quantity: Number(elem.amount)
            };
          });
          const costs = this.transferData.costs.map(elem => {
            return {
              description: elem.description,
              shipping_courier: elem.serviceProvider,
              amount: Number(elem.costValue)
            };
          });

          if (this.files && this.files.length > 0) {
            this.files.forEach(file => request.append("receipts", file));
          }
          if (this.transferData.costs[0].costValue) {
            request.append("costs", JSON.stringify(costs));
          }
          request.append(
            "origin_warehouse_id",
            this.isTransfer ? this.transferData.originStore.id : null
          );
          request.append(
            "destination_warehouse_id",
            this.transferData.destinationStore.id
          );
          request.append(
            "estimated_delivery_date",
            this.transferData.close_date
          );
          request.append("shipping_date", this.transferData.opening_date);
          request.append("products", JSON.stringify(products));
          request.append(
            "mercadolibre_shipping_id",
            this.mercadolibre_shipping_id
          );
          await this.$store.dispatch(
            "traceability/CREATE_INVENTORY_TRANSFERS",
            request
          );
        }

        this.$router.push({ name: "Transferencias de Inventario" });
        this.closeModal(false);
      } catch (error) {
        const { data } = error.response;
        this.$snotify.error(data?.error?.message || "Error inesperado");
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async updateAndCreateCosts() {
      const costsToUpdate = this.transferData.costs
        .filter(cost => !!cost.id)
        .map(elem => {
          return {
            id: elem.id,
            description: elem.description,
            shipping_courier: elem.serviceProvider,
            amount: Number(elem.costValue)
          };
        });
      const costsToCreate = this.transferData.costs
        .filter(cost => !cost.id)
        .map(elem => {
          return {
            transferId: this.transferEditData.id,
            description: elem.description,
            shipping_courier: elem.serviceProvider,
            amount: Number(elem.costValue)
          };
        });
      await Promise.all(
        costsToCreate.map(cost => {
          return this.$store.dispatch("traceability/ADD_NEW_COST", cost);
        })
      );
      await Promise.all(
        costsToUpdate.map(cost => {
          return this.$store.dispatch("traceability/UPDATE_COST", cost);
        })
      );
    },
    async getProducts(val, index) {
      this.searchLoadings[index] = true;
      if (this.isTransfer) {
        try {
          const request = {
            warehouse_id: this.transferData.originStore.id,
            page: 1,
            per_page: 50,
            sku: val
          };
          const response = await this.$store.dispatch(
            "traceability/GET_PRODUCT_TO_TRANSFER",
            request
          );
          const products = response.data.data.data;
          if (products[0]) {
            this.$set(
              this.Products,
              index,
              products.map(val => {
                const item = val.products_erp[0];
                return {
                  value: {
                    ...item,
                    id: val.id,
                    label: item.sku + " - " + val.name,
                    cost: Number(val.product_warehouse[0].product_cost)
                  }
                };
              })
            );
          } else {
            this.$set(this.Products, index, []);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.searchLoadings[index] = false;
        }
      } else {
        try {
          const request = {
            page: 1,
            per_page: 20,
            active: true,
            paginate: false,
            countryId: this.country,
            sku: val
          };
          const response = await this.$store.dispatch(
            "traceability/GET_PRODUCT_TO_ENTRY",
            request
          );
          const products = response.data.data;
          if (products[0]) {
            this.$set(
              this.Products,
              index,
              products.map(val => {
                const item = val.products_erp[0];
                return {
                  value: {
                    ...item,
                    id: val.id,
                    label: item.sku + " - " + val.name
                  }
                };
              })
            );
          } else {
            this.$set(this.Products, index, []);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.searchLoadings[index] = false;
        }
      }
    },

    getWarehouseName(id) {
      if (id) {
        return this.deposits?.filter(elem => elem.id == id)[0]?.name;
      } else {
        return "Ingreso inventario";
      }
    }
  },

  watch: {
    searchItems: {
      deep: true,
      handler() {
        this.searchItems.forEach((_, index) => {
          const selectedProduct = this.transferData.products[index]
            .productSelected;
          const searchItem = this.searchItems[index];
          if (searchItem && searchItem !== selectedProduct?.label) {
            this.getProducts(searchItem, index);
          }
        });
      }
    }
  },

  computed: {
    fileList() {
      return this.files.map(file => file.name);
    },
    filteredDeposits() {
      if (this.isTransfer) {
        return this.deposits.filter(
          deposit => deposit.id !== this.transferData.originStore.id
        );
      } else {
        return this.deposits;
      }
    }
  }
};
</script>

<style>
.error-class {
  color: #ef5350;
  font-size: 12px;
}
.subtitle-label {
  padding: 0;
  margin: 0;
  color: #706dac;
  font-weight: 500 !important;
}
.subtitle-box {
  margin-top: -18px !important;
  margin-bottom: 4px !important;
}
.drop-box {
  height: 150px;
  width: 100%;
  border: 2px dashed rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text-drop {
  margin: 0px;
  font-weight: 500 !important;
  text-transform: none;
  color: rgba(0, 0, 0, 0.87);
}
.padding-right {
  padding-right: 14px;
}
.color-light {
  color: rgba(0, 0, 0, 0.6);
}
.row-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0 10px 0;
  align-items: center;
}
.btn-link {
  color: #2196f3;
  text-decoration: underline;
  margin-right: 6px;
}
.btn-add {
  color: #2196f3;
  text-decoration: underline;
  margin-right: 6px;
  font-size: 12px;
  font-weight: 600;
}
.btn-add-box {
  margin-top: -22px;
  margin-bottom: 14px;
  z-index: 2;
}
.file-box {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 5px;
}
.close-icon {
  position: absolute;
  right: 0;
  top: 1px;
  background-color: white;
}
.text-size {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 4px 0;
}
.file-point {
  width: 4px;
  height: 4px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0px 6px;
}
.delete-box {
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 13px !important;
}
</style>
