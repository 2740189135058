var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mt-3"},[(
        _vm.shipping.status != 'cancelled' &&
        _vm.shipping.logistic_type != 'fulfillment' &&
        _vm.shipping.shipping_mode != 'to_be_agreed' &&
        _vm.shipping.shipping_mode != 'Chazki' &&
        _vm.shipping.shipping_mode != 'CABA 24'
      )?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Logis (tradicional)")])]):(
        _vm.shipping.status != 'cancelled' &&
        _vm.shipping.logistic_type != 'fulfillment' &&
        _vm.shipping.shipping_mode == 'to_be_agreed'
      )?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2"},[_vm._v("Envío a Coordinar")])]):(
        _vm.shipping.status != 'cancelled' &&
        _vm.shipping.logistic_type == 'fulfillment'
      )?_c('p',{staticClass:"mb-0"},[_c('span',{staticStyle:{"color":"green","font-weight":"bold"}},[_vm._v(" Logis (FULL) ")])]):(_vm.shipping.shipping_mode == 'Chazki')?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2"},[_vm._v(" Envío Chazki ")])]):(_vm.shipping.shipping_mode == 'CABA 24')?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2"},[_vm._v(" Envío CABA 24 ")])]):_c('p',{staticClass:"mb-0"},[_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" Envío Cancelado ")])])]),_c('p',{staticClass:"mb-1 subtitle-2",attrs:{"color":"primary"}},[(
        _vm.shipping.order.buyer.first_name != null ||
        _vm.shipping.order.buyer.last_name != null
      )?_c('span',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(((_vm.shipping.order.buyer.first_name) + " " + (_vm.shipping.order.buyer.last_name)))+" ")]):_c('span',[_vm._v(" Sin Datos ")])]),_c('p',{staticClass:"mb-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.HandlerCopy(_vm.shipping.order.buyer.email)}}},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v("Email: ")]),_vm._v(" "+_vm._s(_vm.shipping.order.buyer.email)+" ")]),(_vm.shipping.order.store_id == 0)?_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold mb-0"},[_vm._v(" Fecha de creación de la orden (ML): ")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("date1")(_vm.shipping.date_created))+" a las "+_vm._s(_vm._f("dateHRS")(_vm.shipping.date_created))+" ")]):_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"subtitle-2 font-weight-bold"},[_vm._v(" Creación (En Sistema) ")]),_vm._v(" "+_vm._s(_vm.shipping.created_at)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }