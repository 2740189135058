<template>
  <v-row justify="center">
    <alert
      :data="errorData"
      :active="activeAlert"
      :colorAlert="colorAlert"
      @dialog:change="eventdialog"
    />
    <v-dialog v-model="active" persistent scrollable max-width="350">
      <ValidationObserver ref="obs" v-slot="{ passes }">
        <v-card>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
            rounded
            height="6"
          ></v-progress-linear>

          <v-card-title class="pa-0 shadow-1">
            <v-img
              :src="require('@/assets/illustrations/bg_modal_card.svg')"
              cover
              position="left bottom"
              class="align-center"
              height="65px"
            >
              <v-card-title class="font-weight-medium primary--text">
                <!-- {{ action == 0 ? "Nuevo" : "Editar" }}  -->
                Dias feriados
              </v-card-title>
            </v-img>
          </v-card-title>

          <vuescroll
            :ops="{
              bar: { background: '#bacfcec0', keepShow: true },
            }"
          >
            <v-card-text style="max-height: 350px">
              <!-- {{data}} -->
              <v-col cols="12">
                <v-date-picker
                  v-model="dates"
                  multiple
                  no-title
                ></v-date-picker> </v-col
            ></v-card-text>
          </vuescroll>

          <v-divider></v-divider>

          <v-card-actions class="mx-2">
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                text
                rounded
                class="mr-1"
                :color="hover ? 'error' : 'grey'"
                @click="closeModal"
                >Cancelar</v-btn
              >
            </v-hover>

            <v-hover v-slot:default="{ hover }">
              <v-btn
                rounded
                :loading="loading"
                :disabled="loading"
                color="success"
                :class="hover ? 'shadow-2' : 'shadow-4'"
                class="px-8"
                @click="passes(HandlerAction)"
                >Añadir</v-btn
              >
            </v-hover>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </v-row>
</template>

<script>
import vuescroll from "vuescroll";

import alert from "../Utils/Alert";
export default {
  components: {
    alert,
    vuescroll,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
    action: {
      type: Number,
      required: false,
      default: 0,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    country: {
      type: Array,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dates: [],
      menu: false,

      loading: false,
      errorData: "",
      activeAlert: false,
      colorAlert: "",
    };
  },

  created() {
    if (this.data.warehouse_free?.id) {
      this.dates = JSON.parse(this.data.warehouse_free.date);
    }
  },

  watch: {
    country_id(val) {
      this.HandlerGetState(val);
    },
    state_id(val) {
      this.HandlerGetLocations(val);
    },
  },

  methods: {
    closeModal() {
      this.$emit("dialog:change", false);
    },

    HandlerAction() {
      // if (this.action == 0)
      this.HandlerRegister();
      // else this.HandlerUpdate();
    },

    async HandlerRegister() {
      try {
        this.loading = true;
        const request = {
          warehouse_id: this.data.id,
          date: this.dates,
        };
        await this.$store.dispatch(
          "deposits/REGISTER_UPDATE_DEPOSITS_FREE",
          request
        );
        this.$snotify.success("Fecha creada con éxito.", "¡Hecho!");
        this.closeModal();
      } catch (error) {
        console.log(error.response.data)
        this.$snotify.error(error.response.data, "¡Error!");
        // this.errorData = error.response.data;
        // this.activeAlert = true;
        // this.colorAlert = "red";
      } finally {
        this.loading = false;
      }
    },

    eventdialog() {
      this.activeAlert = false;
    },
  },
};
</script>
