var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('div',{staticClass:"font-weight-medium primary--text mb-5",staticStyle:{"font-size":"26px"}},[_vm._v(" Crear / Editar Imagen ")]),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{staticClass:"mb-5"},[_c('v-card-title',{staticClass:"pa-0 shadow-1"},[_c('v-img',{staticClass:"align-center",attrs:{"src":require('@/assets/illustrations/bg_modal_card.svg'),"cover":"","position":"left bottom","height":"65px"}},[_c('v-card-title',{staticClass:"font-weight-medium primary--text"},[_vm._v(" Imagenes / Videos ")])],1)],1),_c('vuescroll',{attrs:{"ops":{
              bar: { background: '#bacfcec0', keepShow: true },
            }}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"imagenes / videos","rules":_vm.action == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-file-input',{attrs:{"prepend-icon":"","prepend-inner-icon":"mdi-camera-image","label":"Imagenes / videos","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('ValidationProvider',{attrs:{"name":"nombre","rules":_vm.action == 0 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre del archivo","filled":"","rounded":"","dense":"","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"loading":_vm.loadingPublication,"text":"","rounded":""},on:{"click":function($event){return _vm.closeModal({}, false)}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function () { return _vm.action == 0
                          ? passes(_vm.HandlerRegisterImages)
                          : _vm.HandlerUpdateImages(_vm.dataUpdate); }}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)],1)],1)]}}])}),_c('v-dialog',{model:{value:(_vm.activeModal),callback:function ($$v) {_vm.activeModal=$$v},expression:"activeModal"}},[_c('v-card',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }